// DRUH VOZIDLA

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from '../globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus, faClose, faUser, faRotateLeft, faMinus, faPaperPlane, faMoneyCheck, faBoxArchive, faShare, faXmark, faEye, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Alert, Button, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { DataGrid, skSK } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { CheckPermision, Debug, GetPrice, Today, Loading, AutocompleteText } from '../items.js';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styles } from '../styles.js';
import { PriestupokPreview } from '../priestupok_preview.js';


export const PokutoveBloky = (props) => {
    /*    
            USAGE:
            <Users func={UsersPress.bind(this)} />
    
            const UsersPress = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    // ZOZNAM BLOKOV
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [searchCislo, setSearchCislo] = useState('');
    const [redraw, setRedraw] = useState(false);
    const [editPrice, setEditPrice] = useState(false);

    // ZOZNAM LISTKOV
    const [blockItems, setBlockItems] = useState([]);
    const [blockItemsFiltered, setBlockItemsFiltered] = useState([]);
    const [blockItemSelected, setBlockItemSelected] = useState([]);
    const [blockIndex, setBlockIndex] = useState(0);
    const [blockCount, setBlockCount] = useState(0);
    const [blockSearch, setBlockSearch] = useState('');
    const [showPriestupok, setShowPriestupok] = useState(false);
    const [itemPriestupok, setItemPriestupok] = useState([]);

    // ZOZNAM LISTKOV - VYUCTOVANIE
    const [blockPayItems, setBlockPayItems] = useState([]);
    const [blockPayValues, setBlockPayValues] = useState([]);
    const [blockPayAll, setBlockPayAll] = useState(false);
    const [blockPayCount, setBlockPayCount] = useState(0);
    const [blockPaySum, setBlockPaySum] = useState(0);
    const [blockPayName, setBlockPayName] = useState('');
    const [blockPayNameErr, setBlockPayNameErr] = useState(false);
    const [blockPayDate, setBlockPayDate] = useState('');
    const [blockPayDateErr, setBlockPayDateErr] = useState(false);

    // ZOZNAM ARCHIVOVANYCH BLOKOV
    const [archiveItems, setArchiveItems] = useState([]);
    const [archiveItemsFiltered, setArchiveItemsFiltered] = useState([]);
    const [archiveItemsSelected, setArchiveItemsSelected] = useState([]);
    const [archiveIndex, setArchiveIndex] = useState(0);
    const [archiveSearch, setArchiveSearch] = useState('');

    // ZOZNAM ZRUSENYCH BLOKOV
    const [cancelItems, setCancelItems] = useState([]);
    const [cancelItemsFiltered, setCancelItemsFiltered] = useState([]);
    const [cancelItemsSelected, setCancelItemsSelected] = useState([]);
    const [cancelIndex, setCancelIndex] = useState(0);
    const [cancelSearch, setCancelSearch] = useState('');

    // ZOZNAM NAJDENYCH BLOKOV
    const [searchItems, setSearchItems] = useState([]);
    const [cancelSearchSelected, setSearchItemsSelected] = useState([]);
    const [searchIndex, setSearchIndex] = useState(0);

    // DIALOGOVE OKNA
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogObvod, setShowDialogObvod] = useState(false);
    const [showDialogTeren, setShowDialogTeren] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState([]);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState('');
    const [tab, setTab] = useState(0);

    // ----------------------------
    const [label, setLabel] = useState('');
    const [labelErr, setLabelErr] = useState(false);
    const [dateIn, setDateIn] = useState('');
    const [dateInErr, setDateInErr] = useState(false);
    const [dateOut, setDateOut] = useState('');
    const [dateOutErr, setDateOutErr] = useState(false);
    const [dateOutTeren, setDateOutTeren] = useState('');
    const [dateOutTerenErr, setDateOutTerenErr] = useState(false);
    const [numberFrom, setNumberFrom] = useState('');
    const [numberFromErr, setNumberFromErr] = useState(false);
    const [numberTo, setNumberTo] = useState('');
    const [numberToErr, setNumberToErr] = useState(false);
    const [pocet, setPocet] = useState(0);
    const [pocetErr, setPocetErr] = useState(false);
    const [cena, setCena] = useState(0);
    const [cenaErr, setCenaErr] = useState(false);
    const [suma, setSuma] = useState(0);
    const [sumaErr, setSumaErr] = useState(false);
    const [obvod, setObvod] = useState('');
    const [obvodErr, setObvodErr] = useState(false);
    const [obvodID, setObvodID] = useState(0);
    const [nameVydajObvod, setNameVydajObvod] = useState('');
    const [nameVydajObvodErr, setNameVydajObvodErr] = useState(false);
    const [nameVydajTeren, setNameVydajTeren] = useState('');
    const [nameVydajTerenID, setNameVydajTerenID] = useState('');
    const [nameVydajTerenErr, setNameVydajTerenErr] = useState(false);

    const [cancelDate, setCancelDate] = useState('');
    const [cancelDateErr, setCancelDateErr] = useState(false);
    const [cancelNote, setCancelNote] = useState('');

    const [note, setNote] = useState('');
    const [lock, setLock] = useState(false);
    const [divide, setDivide] = useState(false);

    // ----------------------------

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    const timer = useRef();
    const inputRef = useRef(null);
    const inputRefObvod = useRef(null);
    const inputRefTeren = useRef(null);

    useEffect(() => {

        db_get();
        return () => {
        };

    }, []);



    const db_get = async () => {
        setBusy(true);

        /*
        setItems([]);
        setItemsFiltered([]);
        setItemsSelected([]);
        */

        try {
            const response = await fetch(
                global.db_url + 'bloky', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variant: props.id,
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                setItems(json.items);
                setItemsFiltered(json.items);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_update = async (data, typ) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (editing == false) {
                    db_get();
                    setShowDialog(false);
                } else {
                    ChangeEditedRow(data, typ);
                    setShowDialog(false);
                    setShowDialogObvod(false);
                    setShowDialogTeren(false);
                }
            } else {
                setError(lang.db_error);
            }
        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }

    }

    const db_change_status = async (data, typ) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_status', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                if (typ == 0) {
                    db_get();
                }
                if (typ == 1) {
                    db_get_archived();
                }
                if (typ == 2) {
                    db_get();
                    setShowCancel(false);
                }
                if (typ == 3) {
                    db_get_canceled();
                }
            }

        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const db_search = async (txt) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    variant: props.id,
                    number: txt,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setSearchItems(json.items);
            }

        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const db_cell_update = async (id, key, value) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_cell_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    key: key,
                    value: value,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
            }

        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const db_check = async (_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_items_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (json.count_used == 0) {
                    setLock(false);
                    setShowDialogTeren(true);
                    timer.current = setTimeout(() => {
                        inputRefTeren.current.focus();
                    }, 350);

                } else {
                    setLock(true);
                    if (json.count_free > 0) {
                        setDivide(true);
                    } else {
                        setDivide(false);
                    }
                    setShowDialogTeren(true);
                }

            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_items = async (_id) => {
        // ZOZNAM BLOKOV JEDNEHO ZVAZKU
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = json.items;
                setBlockItems(tmp);
                setBlockItemsFiltered(tmp);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_pay_items = async (_id) => {
        // ZOZNAM BLOKOV JEDNEHO ZVAZKU, ktore nie su vyuctovane
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_pay_items', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = json.items;
                setBlockPayItems(tmp);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_pay_items_update = async () => {
        // VYUCTOVANIE BLOKOV
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_pay_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: itemsSelected.id,
                    all: blockPayAll,
                    values: blockPayValues,
                    name: blockPayName,
                    date: blockPayDate,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                var tmp = itemsSelected;
                tmp.pay = json.pay;
                setShowPayment(false);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_release = async (_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_release', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                db_get();
                setShowDialogTeren(false);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_get_archived = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_archived', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variant: props.id,
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                setArchiveItems(json.items);
                setArchiveItemsFiltered(json.items);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_get_canceled = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'bloky_canceled', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variant: props.id,
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                setCancelItems(json.items);
                setCancelItemsFiltered(json.items);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_priestupok = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var data = json.item;
                setItemPriestupok(data);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_info = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;
                setInfo(data);
                setShowInfo(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const columns_bloky = [
        {
            field: 'status',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 50,
            valueGetter: ({ value }) => value == 0 ? 'D' : value == 1 ? 'O' : value == 2 ? 'T' : value == 3 ? 'A' : 'S',
            editable: false,
            hide: false
        },
        {
            field: 'date_prijem',
            headerName: lang.bloky_date_prijem,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 120,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
            hide: false
        },
        {
            field: 'zvazok',
            headerName: lang.bloky_number,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'cislo_od',
            headerName: lang.bloky_from,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'cislo_do',
            headerName: lang.bloky_to,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'pocet',
            headerName: lang.bloky_count,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'price',
            headerName: lang.bloky_price,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'sume',
            headerName: lang.bloky_sum,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'obvod',
            headerName: lang.bloky_obvod,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null || value == 0 ? '' : value,
            width: 85,
            editable: false
        },
        {
            field: 'meno_vydaj_teren',
            headerName: lang.bloky_obvod_menu,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 220,
            editable: false
        },
        {
            field: 'pay',
            headerName: lang.bloky_to_pay,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 0 ? '' : value,
            width: 150,
            editable: false
        },
        {
            field: 'note',
            headerName: lang.note,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 250,
            editable: false
        },
    ];

    const columns_seky = [
        {
            field: 'status',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 50,
            valueGetter: ({ value }) => value == 0 ? 'D' : value == 1 ? 'O' : value == 2 ? 'T' : value == 3 ? 'A' : 'S',
            editable: false,
            hide: false
        },
        {
            field: 'date_prijem',
            headerName: lang.bloky_date_prijem,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 120,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
            hide: false
        },
        {
            field: 'zvazok',
            headerName: lang.bloky_number,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'cislo_od',
            headerName: lang.bloky_from,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'cislo_do',
            headerName: lang.bloky_to,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'pocet',
            headerName: lang.bloky_count,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'obvod',
            headerName: lang.bloky_obvod,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null || value == 0 ? '' : value,
            width: 85,
            editable: false
        },
        {
            field: 'meno_vydaj_teren',
            headerName: lang.bloky_obvod_menu,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 220,
            editable: false
        },
        {
            field: 'pay',
            headerName: lang.bloky_to_pay,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 0 ? '' : value,
            width: 150,
            editable: false
        },
        {
            field: 'note',
            headerName: lang.note,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 250,
            editable: false
        },
    ];


    const columns_used = [
        {
            field: 'number',
            headerName: lang.blok_cislo,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'status',
            headerName: lang.blok_status,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 1 ? lang.used : lang.not_used,
            width: 120,
            editable: false,
        },
        {
            field: 'date_priestupok',
            headerName: lang.blok_priestupok_date,
            type: 'date',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150,
            editable: false,
            align: 'center',
        },
        {
            field: 'price',
            headerName: lang.blok_price + ' (€)',
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: false,
            align: 'center',
        },
        {
            field: 'pay',
            headerName: lang.bloky_paymented,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 1 ? 'Áno' : 'Nie',
            width: 120,
            editable: false,
            align: 'center',
        },
        {
            field: 'pay_date',
            headerName: lang.blok_pay_date,
            type: 'date',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150,
            editable: false,
        },
        {
            field: 'pay_name',
            headerName: lang.blok_pay_name,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null ? '' : value,
            width: 250,
            editable: false,
        },
        {
            field: '',
            type: 'actions',
            headerName: props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? lang.preview : '',
            headerClassName: 'super-app-theme--header',
            width: 100,
            getActions: (params) => [
                <IconButton onClick={() => (ShowPriestupok(params.row.priestupok_id))} style={{ width: 22, height: 22 }} >
                    {params.row.status == 1 ?
                        <FontAwesomeIcon style={{ color: global.theme_light, width: 14 }} icon={faEye} />
                        : ''}
                </IconButton>
            ],
        }
    ];


    const columns_search = [
        {
            field: 'number',
            headerName: lang.blok_cislo,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'zvazok',
            headerName: lang.zvazok,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'status',
            headerName: lang.blok_status,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 1 ? lang.used : lang.not_used,
            width: 120,
            editable: false,
        },
        {
            field: 'date_priestupok',
            headerName: lang.blok_priestupok_date,
            type: 'date',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150,
            editable: false,
        },
        {
            field: 'pay',
            headerName: lang.bloky_paymented,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 1 ? 'Áno' : 'Nie',
            width: 120,
            editable: false,
        },
        {
            field: 'pay_date',
            headerName: lang.blok_pay_date,
            type: 'date',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150,
            editable: false,
        },
        {
            field: 'pay_name',
            headerName: lang.blok_pay_name,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null ? '' : value,
            width: 250,
            editable: false,
        },
        {
            field: '',
            type: 'actions',
            headerName: props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? lang.preview : '',
            headerClassName: 'super-app-theme--header',
            width: 100,
            getActions: (params) => [
                <IconButton onClick={() => (ShowPriestupok(params.row.status == 1 ? params.row.priestupok_id : 0))} style={{ width: 22, height: 22 }} >
                    {params.row.status == 1 ?
                        <FontAwesomeIcon style={{ color: global.theme_light, width: 14 }} icon={faEye} />
                        : ''}
                </IconButton>
            ],
        }
    ];



    const columns_cancel_bloky = [
        {
            field: 'status',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 50,
            valueGetter: ({ value }) => value == 0 ? 'D' : value == 1 ? 'O' : value == 2 ? 'T' : value == 3 ? 'A' : 'S',
            editable: false,
            hide: false
        },
        {
            field: 'cancel_date',
            headerName: lang.bloky_cancel_date,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 150,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
            hide: false
        },
        {
            field: 'zvazok',
            headerName: lang.bloky_number,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'cislo_od',
            headerName: lang.bloky_from,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'cislo_do',
            headerName: lang.bloky_to,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'pocet',
            headerName: lang.bloky_count,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'price',
            headerName: lang.bloky_price,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'sume',
            headerName: lang.bloky_sum,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'obvod',
            headerName: lang.bloky_obvod,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null || value == 0 ? '' : value,
            width: 85,
            editable: false
        },
        {
            field: 'meno_vydaj_teren',
            headerName: lang.bloky_obvod_menu,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 220,
            editable: false
        },
        {
            field: 'cancel_note',
            headerName: lang.bloky_cancel_dovod,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 0 ? '' : value,
            width: 300,
            editable: false
        },
    ];


    const columns_cancel_seky = [
        {
            field: 'status',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 50,
            valueGetter: ({ value }) => value == 0 ? 'D' : value == 1 ? 'O' : value == 2 ? 'T' : value == 3 ? 'A' : 'S',
            editable: false,
            hide: false
        },
        {
            field: 'cancel_date',
            headerName: lang.bloky_cancel_date,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 150,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
            hide: false
        },
        {
            field: 'zvazok',
            headerName: lang.bloky_number,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
        },
        {
            field: 'cislo_od',
            headerName: lang.bloky_from,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'cislo_do',
            headerName: lang.bloky_to,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 80,
            editable: false
        },
        {
            field: 'pocet',
            headerName: lang.bloky_count,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 85,
            editable: false
        },
        {
            field: 'obvod',
            headerName: lang.bloky_obvod,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == null || value == 0 ? '' : value,
            width: 85,
            editable: false
        },
        {
            field: 'meno_vydaj_teren',
            headerName: lang.bloky_obvod_menu,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 220,
            editable: false
        },
        {
            field: 'cancel_note',
            headerName: lang.bloky_cancel_dovod,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value == 0 ? '' : value,
            width: 300,
            editable: false
        },
    ];


    const AddNew = () => {
        // pridanie nového záznamu

        var today = Today();

        // ------- ZMENIT DATA --------        
        setLabel('')
        setLabelErr(false);
        setDateIn(today);
        setDateOut('');
        setNumberFrom('');
        setNumberTo('');
        setPocet('50');
        setCena('');
        setSuma('');
        setNameVydajObvod('');
        setNameVydajObvodErr('');
        // ----------------------------

        setError('');
        setEditing(false);
        setShowDialog(true);

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 350);
    }

    const EditLine = () => {
        // editácia existujúceho záznamu
        var data = items.find(x => x.id == index);

        if (parseInt(data.department_id) > 0 || CheckPermision(props.permissions, global.permission_bloky) == true) {
            if (data.status == 0 || data.status == 1) {

                // ------- ZMENIT DATA --------            
                setLabel(data.zvazok);
                setLabelErr(false);
                setDateIn(data.date_prijem);
                setNumberFrom(data.cislo_od);
                setNumberTo(data.cislo_do);
                setPocet(data.pocet);
                setCena(data.price);
                setSuma(data.sume);
                setNameVydajObvod(data.meno_vydaj_obvod);
                // ----------------------------

                setError('')
                setItemsSelected(data);
                setEditing(true);
                setShowDialog(true);

                timer.current = setTimeout(() => {
                    inputRef.current.focus();
                }, 350);
            }
            /*
            if (data.status == 1) {
                // VYDAJ NA OBVOD - vynechane - urobene v prvom kroku
                setLabel(data.zvazok);
                setDateIn(data.date_prijem);
                setNumberFrom(data.cislo_od);
                setNumberTo(data.cislo_do);
                setPocet(data.pocet);
                setCena(data.price);
                setSuma(data.sume);

                setObvod(data.obvod);
                setObvodID(data.obvod_id);
                setNameVydajObvod(data.meno_vydaj_obvod);
                setNote('');
                setDateOut(data.date_vydaj);
                setDateOutErr(false);
                setObvodErr(false);
                setNameVydajObvodErr(false);
                setNote(data.note);
                setError('');

                setEditing(true);
                setShowDialogObvod(true);

                timer.current = setTimeout(() => {
                    inputRefObvod.current.focus();
                }, 350);
            }
            */
            if (data.status == 2) {

                setLabel(data.zvazok);
                setDateIn(data.date_prijem);
                setNumberFrom(data.cislo_od);
                setNumberTo(data.cislo_do);
                setPocet(data.pocet);
                setCena(data.price);
                setSuma(data.sume);

                setNameVydajTeren(data.meno_vydaj_teren);
                setNameVydajTerenID(data.meno_vydaj_teren_id);
                setDateOutTeren(data.date_vydaj_teren);
                setNote(data.note);
                setDateOut(data.date_vydaj);
                setDateOutTerenErr(false);
                setNameVydajTerenErr(false);
                setError('');

                setEditPrice(false);
                setEditing(true);

                db_check(itemsSelected.id);
            }

        }
    }

    const VydajObvod = () => {
        setLabel(itemsSelected.zvazok);
        setNumberFrom(itemsSelected.cislo_od);
        setNumberTo(itemsSelected.cislo_do);
        setPocet(itemsSelected.pocet);
        setSuma(itemsSelected.sume);

        setObvod('');
        setObvodID(0);
        setNameVydajObvod('');
        setNote('');
        var today = Today();
        setDateOut(today);
        setDateOutErr(false);
        setObvodErr(false);
        setNameVydajObvodErr(false);
        setError('');
        setEditing(true);
        setShowDialogObvod(true);

        timer.current = setTimeout(() => {
            inputRefObvod.current.focus();
        }, 350);
    }


    const VydajTeren = () => {
        setLabel(itemsSelected.zvazok);
        setNumberFrom(itemsSelected.cislo_od);
        setNumberTo(itemsSelected.cislo_do);
        setPocet(itemsSelected.pocet);
        setSuma(itemsSelected.sume);
        setNote(itemsSelected.note);

        var today = Today();
        setDateOutTeren(today);
        setDateOutTerenErr(false);
        setNameVydajTeren('');
        setNameVydajTerenID(0);
        setNameVydajTerenErr(false);
        setError('');
        setEditing(true);

        setLock(false);
        setDivide(false);
        setShowDialogTeren(true);

        timer.current = setTimeout(() => {
            inputRefTeren.current.focus();
        }, 350);
    }


    const CheckData = () => {
        var err = false;
        setLabelErr(false);
        setDateInErr(false);
        setNumberFromErr(false);
        setNumberToErr(false);
        setPocetErr(false);
        setCenaErr(false);
        setSumaErr(false);
        setError('');

        var numberFrom_ = numberFrom.replace(',', '.');
        var numberTo_ = numberTo.replace(',', '.');
        var pocet_ = pocet.replace(',', '.');
        var cena_ = cena.replace(',', '.');
        var suma_ = suma.replace(',', '.');

        // ------- ZMENIT DATA --------
        if (label.trim() == '') {
            err = true;
            setLabelErr(true);
        }
        if (dateIn == '' || dateIn == null) {
            err = true;
            setDateInErr(true);
        }
        if (numberFrom.trim() == '') {
            err = true;
            setNumberFromErr(true);
        }
        if (numberTo.trim() == '') {
            err = true;
            setNumberToErr(true);
        }
        if (pocet.trim() == '') {
            err = true;
            setPocetErr(true);
        }
        if (props.id == 0) {
            if (cena.trim() == '') {
                err = true;
                setCenaErr(true);
            }
        }
        /*
        if (suma.trim() == '') {
            err = true;
            setSumaErr(true);
        }
        */
        if (isNaN(numberFrom_)) {
            err = true;
            setNumberFromErr(true);
        }
        if (isNaN(numberTo_)) {
            err = true;
            setNumberToErr(true);
        }
        if (isNaN(pocet_)) {
            err = true;
            setPocetErr(true);
        }
        if (props.id == 0) {
            if (isNaN(cena_)) {
                err = true;
                setCenaErr(true);
            }
        }
        /*
        if (isNaN(suma_)) {
            err = true;
            setSumaErr(true);
        }
        */
        if (err == false) {
            if (parseInt(numberFrom) >= parseInt(numberTo)) {
                setNumberFromErr(true);
                setNumberToErr(true);
                err = true;
            }
            if (parseInt(numberFrom) + parseInt(pocet) != (parseInt(numberTo) + 1)) {
                setNumberFromErr(true);
                setNumberToErr(true);
                setPocetErr(true);
                err = true;
            }
        }

        // ----------------------------

        if (err == true) {
            setError(lang.required);
        }

        return err;
    }

    const DialogSave = () => {
        if (CheckData() == false) {

            if (nameVydajObvod.trim() == '') {
                setNameVydajObvodErr(true);
                setError(lang.required);
            } else {

                var data = {
                    id: editing == true ? itemsSelected.id : 0,
                    variant: props.id,
                    date_prijem: dateIn,
                    date_vydaj: dateOut,
                    meno_vydaj_obvod: nameVydajObvod,
                    zvazok: label,
                    meno_prijem: props.user.name + ' ' + props.user.surname,
                    user_id: props.user.id,
                    cislo_od: numberFrom,
                    cislo_do: numberTo,
                    pocet: pocet,
                    price: cena,
                    sume: suma,
                    obvod: obvod,
                    obvod_id: obvodID,
                    status: 0,
                    department_id: props.department.id
                };

                db_update(data, 1);
            }
        }
    }

    const DialogSaveObvod = () => {
        // TENTO KROK JE VYNECHANY

        var err = false;

        setDateOutErr(false);
        setObvodErr(false);
        setNameVydajObvodErr(false);
        setError('');

        if (dateOut == '' || dateOut == null) {
            setDateOutErr(true);
            err = true;
        }
        if (obvod.trim() == '') {
            setObvodErr(true);
            err = true;
        }
        if (nameVydajObvod.trim() == '') {
            setNameVydajObvodErr(true);
            err = true;
        }
        if (err == false) {
            var data = {
                id: itemsSelected.id,
                date_vydaj: dateOut,
                meno_vydaj_obvod: nameVydajObvod,
                obvod: obvod,
                obvod_id: obvodID,
                status: 1,
                note: note
            };
            db_update(data, 1);
        } else {
            setError(lang.required);
        }
    }


    const DialogSaveTeren = () => {
        var err = false;

        setCenaErr(false);
        setSumaErr(false);
        setDateOutTerenErr(false);
        setNameVydajTerenErr(false);
        setError('');

        if (editPrice == true) {
            var suma_ = suma.replace(',', '.');
            var cena_ = cena.replace(',', '.');

            if (suma_.trim() == '') {
                err = true;
                setSumaErr(true);
            }

            if (cena_.trim() == '') {
                err = true;
                setCenaErr(true);
            }
        }

        if (dateOutTeren == '' || dateOutTeren == null) {
            setDateOutTerenErr(true);
            err = true;
        }
        if (nameVydajTeren.trim() == '') {
            setNameVydajTerenErr(true);
            err = true;
        }
        if (nameVydajTerenID == 0) {
            setNameVydajTerenErr(true);
            err = true;
        }

        if (err == false) {
            var data = {
                id: itemsSelected.id,
                date_vydaj_teren: dateOutTeren,
                meno_vydaj_teren: nameVydajTeren,
                meno_vydaj_teren_id: nameVydajTerenID,
                cislo_od: numberFrom,
                cislo_do: numberTo,
                status: 2,
                price: itemsSelected.price,
                note: note,
                sume: itemsSelected.sume,
                variant: props.id,
            };

            if (editPrice == true) {
                data.change = true;
                data.price = cena_;
                data.sume = suma_;
            }

            db_update(data, 2);
        } else {
            setError(lang.required);
        }
    }


    const ChangeEditedRow = (data, typ) => {
        // zmenit editovaný riadok v tabulke
        var item = itemsSelected;

        if (typ == 0 || typ == 1) {
            // ------- ZMENIT DATA --------
            item.zvazok = data.zvazok;
            item.date_prijem = data.date_prijem;
            item.date_vydaj = data.date_vydaj;
            item.meno_prijem = data.meno_prijem;
            item.user_id = data.user_id;
            item.cislo_od = data.cislo_od;
            item.cislo_do = data.cislo_do;
            item.pocet = data.pocet;
            item.price = data.price;
            item.sume = data.sume;
            item.obvod = data.status == 0 ? '' : data.obvod;
            item.obvod_id = data.obvod_id;
            item.status = data.status;
            item.department_id = data.department_id;
            // ----------------------------
        }
        /*
        if (typ == 1) {
            item.obvod = data.obvod;
            item.obvod_id = data.obvod_id;
            item.date_vydaj = data.date_vydaj;
            item.meno_vydaj_obvod = data.meno_vydaj_obvod;
            item.status = data.status;
            item.note = data.note;
        }
        */
        if (typ == 2) {
            item.meno_vydaj_teren = data.meno_vydaj_teren;
            item.meno_vydaj_teren_id = data.meno_vydaj_teren_id;
            item.date_vydaj_teren = data.date_vydaj_teren;
            item.status = data.status;
            item.note = data.note;
            item.sume = data.sume;
            item.price = data.price;
            console.log(data);
        }
        setRedraw(!redraw);
    }

    const CellSave = (params) => {
        // pri zmene bunky - nahrať do DB
        var result = items.filter(x => x.id == index);
        if (result.length == 1 && (CheckPermision(props.permissions, global.permission_bloky) == true || parseInt(result[0].department_id) != 0)) {
            var data = result[0];
            data[params.field] = params.value;
            db_cell_update(parseInt(params.id), params.field, params.value);
        } else {
            db_get();
        }
    }

    const ChangeSearch = (_str) => {
        setSearch(_str);
        if (_str.trim() != '') {
            var str = _str.trim();
            var data = items.filter(x => x.zvazok.toLowerCase().includes(str.toLowerCase()) || (x.meno_vydaj_teren != null ? x.meno_vydaj_teren.toLowerCase().includes(str.toLowerCase()) : null));
            setItemsFiltered(data);
        } else {
            setItemsFiltered(items);
        }
    }

    const ChangeBlockSearch = (_str) => {
        setBlockSearch(_str);
        var str = _str.trim();
        var data = blockItems.filter(x => x.number.includes(str));
        setBlockItemsFiltered(data);
    }

    const ChangeSearching = (_str) => {
        setSearchCislo(_str);
        setSearchItems([]);
    }


    const ChangeArchiveSearch = (_str) => {
        setArchiveSearch(_str);
        if (_str.trim() != '') {
            var str = _str.trim();
            var data = archiveItems.filter(x => x.zvazok.toLowerCase().includes(str.toLowerCase()) || (x.meno_vydaj_teren != null ? x.meno_vydaj_teren.toLowerCase().includes(str.toLowerCase()) : null));
            setArchiveItemsFiltered(data);
        } else {
            setArchiveItemsFiltered(archiveItems);
        }
    }

    const ChangeCancelSearch = (_str) => {
        setCancelSearch(_str);
        if (_str.trim() != '') {
            var str = _str.trim();
            var data = cancelItems.filter(x => x.zvazok.toLowerCase().includes(str.toLowerCase()) || (x.meno_vydaj_teren != null ? x.meno_vydaj_teren.toLowerCase().includes(str.toLowerCase()) : null));
            setCancelItemsFiltered(data);
        } else {
            setCancelItemsFiltered(cancelItems);
        }
    }

    const GetSum = () => {
        if (CheckData() == false) {
            var sum = parseInt(pocet) * parseInt(cena);
            setSuma(GetPrice(sum));
        }
    }

    const SetNumberTo = () => {
        if (numberFrom.trim() != '') {
            setNumberTo((parseInt(numberFrom) + 49).toString());
            setNumberFromErr(false);
            setError('');
        } else {
            setNumberFromErr(true);
            setError(lang.required);
        }
    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemsSelected(data);
        setIndex(id);
    }

    const ChangeBlockIndex = (id) => {
        var data = blockItems.find(x => x.id == id);
        setBlockItemSelected(data);
        setBlockIndex(id);
    }

    const ChangeSearchIndex = (id) => {
        var data = searchItems.find(x => x.id == id);
        setSearchItemsSelected(data);
        setSearchIndex(id);
    }

    const ChangeArchiveIndex = (id) => {
        var data = archiveItems.find(x => x.id == id);
        setArchiveItemsSelected(data);
        setArchiveIndex(id);
    }

    const ChangeCancelIndex = (id) => {
        var data = cancelItems.find(x => x.id == id);
        setCancelItemsSelected(data);
        setCancelIndex(id);
    }

    const SetObvod = (value, key) => {
        if (value != null) {
            setObvod(value.label);
            setObvodID(value.id);
        }
    }


    const SetMenoVydajObvod = (value, key) => {
        if (value != null) {
            setNameVydajObvod(value.label);
        }
    }


    const SetMenoVydajTeren = (value, key) => {
        if (value != null) {
            setNameVydajTeren(value.label);
            setNameVydajTerenID(value.id);
        }
    }


    const DialogRelease = () => {
        // UVOLNENIE ZOSTAVAJUCICH BLOKOV - VYTVORENIE NOVEHO SUBBLOKU
        db_release(itemsSelected.id);
    }


    const ChangeTab = (event, value) => {
        var lastTab = tab;
        setTab(value);

        // VYMAZANIE NEPOTREBNYCH UDAJOV
        setBlockItems([]);
        setBlockItemsFiltered([]);
        setBlockItemSelected([]);

        if (value == 0) {
            setBlockItems([]);
            setBlockItemsFiltered([]);
            setBlockItemSelected([]);
            setSearch('');
            db_get();
        }
        if (value == 1) {
            // POUZITE
            if (lastTab == 3) {
                db_items(archiveItemsSelected.id);
            } else {
                db_items(itemsSelected.id);
            }

        }
        if (value == 3) {
            // ARCHIVOVANE
            setArchiveItems([]);
            setArchiveItemsFiltered([]);
            setArchiveItemsSelected([]);
            setArchiveIndex(0);
            setArchiveSearch('');
            db_get_archived();
        }
        if (value == 4) {
            // ZRUSENE
            setCancelItems([]);
            setCancelItemsFiltered([]);
            setCancelItemsSelected([]);
            setCancelIndex(0);
            setCancelSearch('');
            db_get_canceled();
        }
    };


    const Vyuctovat = () => {
        setBlockPayValues([]);
        setBlockPayCount(0);
        setBlockPayAll(false);
        setBlockPayName('');
        setBlockPayNameErr(false);
        setBlockPaySum(0);
        var today = Today();
        setBlockPayDate(today);
        setError('');
        db_pay_items(itemsSelected.id);
        setShowPayment(true);
    }

    const Archive = () => {
        var data = {
            id: itemsSelected.id,
            typ: 1
        }
        db_change_status(data, 0);
    }

    const Unarchive = () => {
        var data = {
            id: archiveItemsSelected.id,
            typ: 0
        }
        db_change_status(data, 1);
    }

    const Uncancel = () => {
        var data = {
            id: cancelItemsSelected.id,
            typ: 0
        }
        db_change_status(data, 3);
    }

    const Cancel = () => {
        var today = Today();
        setCancelDate(today);
        setCancelDateErr(false);
        setCancelNote('');
        setShowCancel(true);
    }

    const PaymentChange = (event) => {
        const {
            target: { value },
        } = event;
        var values = typeof value === 'string' ? value.split(',') : value;
        setBlockPayValues(values);
        var count = values.length;

        if (count > 0) {
            var sum = 0;
            blockPayItems.forEach(item => {
                if (values.includes(item.id)) {
                    sum = sum + parseInt(item.price);
                }
            });
            //var sum = count * parseInt(itemsSelected.price);
            setBlockPayCount(count);
            setBlockPaySum(sum);
        } else {
            setBlockPayCount(0);
            setBlockPaySum(0);
        }
    };


    const ChangePayAll = (event) => {
        var value = event.target.checked;
        setBlockPayAll(value);

        if (value == true) {
            var count = blockPayItems.length
            //var sum = count * parseInt(itemsSelected.price);
            var sum = 0;
            blockPayItems.forEach(item => {
                sum = sum + parseInt(item.price);
            });

            setBlockPayCount(count);
            setBlockPaySum(sum);
            setBlockPayValues([]);
        } else {
            setBlockPayCount(0);
            setBlockPaySum(0);
        }
    }


    const SetMenoVyuctovat = (value, key) => {
        if (value != null) {
            setBlockPayName(value.label);
        }
    }


    const DialogSaveVyuctovanie = () => {
        setBlockPayNameErr(false);
        setError('');

        if (blockPayName != '') {
            if (blockPayDate != '') {
                db_pay_items_update();
            } else {
                setBlockPayNameErr(true);
                setError(lang.required);
            }
        } else {
            setBlockPayNameErr(true);
            setError(lang.required);
        }
    }

    const DialogCancel = () => {
        setError('');
        setCancelDateErr(false);

        if (cancelDate == '') {
            setError(lang.required);
            setCancelDateErr(true);
        } else {
            var data = {
                id: itemsSelected.id,
                typ: 2,
                cancel_date: cancelDate,
                cancel_note: cancelNote
            }
            db_change_status(data, 2);
        }
    }

    const ShowPriestupok = (id) => {
        if (id > 0) {
            db_priestupok(id);
            setShowPriestupok(true);
        }
    }

    const PriestupokPreviewResult = () => {
        setShowPriestupok(false);
    }

    const ShowInfo = (id) => {
        // informácie o zväzku        
        db_info(id);
    }

    const ZvazokDetails = (props) => {
        return (
            <div style={{ ...styles.Block, backgroundColor: global.theme_lighter, paddingTop: 15, paddingBottom: 25 }}>
                <div style={{ ...styles.Block, marginTop: 10 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 10 }}>
                            <p style={{ ...styles.TextInput }}>{lang.bloky_date_výdaj}</p>
                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} type="date" variant="outlined" value={props.item.date_vydaj_teren} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 10 }}>
                            <p style={{ ...styles.TextInput }}>{lang.bloky_number}</p>
                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={props.item.zvazok} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 10 }}>
                            <p style={{ ...styles.TextInput }}>{lang.bloky_pocet}</p>
                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={props.item.pocet} />
                        </div>
                        {props.id == 0 ?
                            <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput }}>{lang.bloky_eur}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={props.item.price} />
                            </div>
                            : null}
                        {props.id == 0 ?
                            <div style={{ ...styles.BlockLeft, width: 150, marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput }}>{lang.bloky_eur_sum}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={props.item.sume} />
                            </div>
                            : null}
                        <div style={{ ...styles.BlockLeft, width: 250, marginLeft: 10, marginRight: 10 }}>
                            <p style={{ ...styles.TextInput }}>{lang.bloky_obvod_menu}</p>
                            <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={props.item.meno_vydaj_teren} />
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div style={{ ...styles.Block, paddingTop: 0 }}>
            <div style={{ ...styles.BlockLeft }}>
                <Tabs
                    value={tab}
                    onChange={ChangeTab}
                    TabIndicatorProps={{
                        style: {
                            color: 'red',
                            backgroundColor: global.theme_light
                        }
                    }}
                    style={{ width: '100%' }}
                >
                    <Tab value={0} style={{ width: '20%' }} label={lang.bloky_tab_zvazky} />
                    <Tab value={1} style={{ width: 220 }} disabled={(tab == 0 && index > 0 && itemsSelected.status == 2) || (tab == 3 && archiveIndex > 0) || (tab == 1) ? false : true} label={lang.bloky_tab_zvazok_used} />
                    {/*
                    <Tab value={2} style={{ width: 220 }} label={lang.bloky_tab_used} />
                    */}
                    <Tab value={3} style={{ width: '20%' }} label={lang.bloky_tab_archive} />
                    <Tab value={4} style={{ width: '20%' }} label={lang.bloky_tab_canceled} />
                    <Tab value={5} style={{ width: '20%' }} label={lang.search} />
                </Tabs>
            </div>

            {tab == 0 ?
                // ************************************************************************************
                // ZOZNAM ZVAZKOV
                // ************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={search} onInput={e => ChangeSearch(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={itemsFiltered}
                                columns={props.id == 0 ? columns_bloky : columns_seky}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.status == 0 ? `super-app-theme--normal` : params.row.status == 1 ? `super-app-theme--blue` : `super-app-theme--red`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>
                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ?
                                <Button onClick={() => AddNew()} style={{ ...styles.ButtonNew, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPlus} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.new}</p>
                                </Button>
                                : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 ?
                                <Button onClick={() => EditLine()} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                                </Button>
                                : null : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 && itemsSelected.status == -1 ?
                                <Button onClick={() => VydajObvod()} style={{ ...styles.ButtonAction, width: 200, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPaperPlane} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_vydaj_obvod}</p>
                                </Button>
                                : null : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 && (itemsSelected.status == 0 || itemsSelected.status == 1) ?
                                <Button onClick={() => VydajTeren()} style={{ ...styles.ButtonAction, width: 200, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPaperPlane} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_vydaj_teren}</p>
                                </Button>
                                : null : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 && itemsSelected.pay != 0 ?
                                <Button onClick={() => Vyuctovat()} style={{ ...styles.ButtonAction, width: 200, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faMoneyCheck} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_payment}</p>
                                </Button>
                                : null : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 && itemsSelected.status == 2 && itemsSelected.free == 0 ?
                                <Button onClick={() => Archive()} style={{ ...styles.ButtonAction, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faBoxArchive} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_archive}</p>
                                </Button>
                                : null : null}
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? index > 0 && (itemsSelected.status < 2 || (itemsSelected.status == 2 && itemsSelected.used == 0)) ?
                                <Button onClick={() => Cancel()} style={{ ...styles.ButtonAction, width: 200, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faBoxArchive} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_cancel}</p>
                                </Button>
                                : null : null}
                        </div>
                        <div style={{ ...styles.BlockRight, width: '15%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {index > 0 ?
                                    <div style={{ width: 50 }}>
                                        <IconButton onClick={() => ShowInfo(itemsSelected.id)} style={{ backgroundColor: global.theme_lighter }}>
                                            <FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faInfo} />
                                        </IconButton>
                                    </div>
                                    : null}
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get()}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {tab == 1 ?
                // ************************************************************************************
                // POUZITE PB
                // ************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>
                    <ZvazokDetails item={itemsSelected} />
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={blockSearch} onInput={e => ChangeBlockSearch(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeBlockSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={blockItemsFiltered}
                                columns={columns_used}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeBlockIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.status == 0 ? `super-app-theme--red` : `super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                        </div>
                        <div style={{ ...styles.BlockRight, width: '10%' }}>
                            <IconButton onClick={() => db_items(itemsSelected.id)}>
                                <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                            </IconButton>
                        </div>
                    </div>

                </div>
                : null}


            {tab == 3 ?
                // ************************************************************************************
                // ZOZNAM ZVAZKOV - ARCHIVOVANE
                // ************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={archiveSearch} onInput={e => ChangeArchiveSearch(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeArchiveSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={archiveItemsFiltered}
                                columns={props.id == 0 ? columns_bloky : columns_seky}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeArchiveIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.status == 0 ? `super-app-theme--normal` : params.row.status == 1 ? `super-app-theme--blue` : `super-app-theme--red`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>
                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' && archiveIndex > 0 ?
                                <Button onClick={() => Unarchive()} style={{ ...styles.ButtonNew, width: 250, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faShare} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_unarchive}</p>
                                </Button>
                                : null}

                        </div>
                        <div style={{ ...styles.BlockRight, width: '15%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {archiveIndex > 0 ?
                                    <div style={{ width: 50 }}>
                                        <IconButton onClick={() => ShowInfo(archiveItemsSelected.id)} style={{ backgroundColor: global.theme_lighter }}>
                                            <FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faInfo} />
                                        </IconButton>
                                    </div>
                                    : null}
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get()}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}


            {tab == 4 ?
                // ************************************************************************************
                // ZOZNAM ZVAZKOV - ZRUSENE
                // ************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={cancelSearch} onInput={e => ChangeCancelSearch(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeCancelSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={cancelItemsFiltered}
                                columns={props.id == 0 ? columns_cancel_bloky : columns_cancel_seky}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeCancelIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.status == 0 ? `super-app-theme--normal` : params.row.status == 1 ? `super-app-theme--blue` : `super-app-theme--red`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>
                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                            {props.permissions.toString().substr(global.permission_bloky, 1) == '1' && cancelIndex > 0 ?
                                <Button onClick={() => Uncancel()} style={{ ...styles.ButtonNew, width: 250, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faShare} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.bloky_unarchive}</p>
                                </Button>
                                : null}

                        </div>
                        <div style={{ ...styles.BlockRight, width: '15%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {cancelIndex > 0 ?
                                    <div style={{ width: 50 }}>
                                        <IconButton onClick={() => ShowInfo(cancelItemsSelected.id)} style={{ backgroundColor: global.theme_lighter }}>
                                            <FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faInfo} />
                                        </IconButton>
                                    </div>
                                    : null}
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get()}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>                    </div>
                </div>
                : null}


            {tab == 5 ?
                // ************************************************************************************
                // HLADANIE POKUTOVEHO BLOKU
                // ************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.blocks_search} {}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={searchCislo} onInput={e => setSearchCislo(e.target.value)}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            db_search(searchCislo);
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeCancelSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={searchItems}
                                columns={columns_search}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeSearchIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.status == 0 ? `super-app-theme--red` : `super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            //onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '90%' }}>
                        </div>
                        <div style={{ ...styles.BlockRight, width: '10%' }}>
                            <IconButton onClick={() => db_search(searchCislo)}>
                                <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                            </IconButton>
                        </div>
                    </div>

                </div>
                : null}




            {/* ****************************************************************************************
                DIALOG - NOVY ZÁZNAM / UPRAVIT ZAZNAM - PRIJAT Z Danoveho Uradu
             **************************************************************************************** */}
            <Dialog open={showDialog} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.record_edit : props.id == 0 ? lang.register_blok : lang.register_sek}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowDialog(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>


                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                    {/* DATUM */}
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ margin: 10, width: 150 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_number}</p>
                            <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                        </div>
                        <div style={{ margin: 10, width: 150 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_date_prijem}</p>
                            <TextField size={'small'} type={'date'} error={dateInErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={dateIn} onInput={e => setDateIn(e.target.value)} />
                        </div>
                        <div style={{ margin: 10, width: 150 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={obvodErr} check={true} row={false} new_record={false} department={props.department} db={15} lang={lang} label={lang.bloky_obvod_kod} width={'100%'} value={obvod} value_id={obvodID} func={SetObvod.bind(this)} />
                        </div>
                        <div style={{ margin: 10, width: 110 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_cislo_od}</p>
                            <TextField onBlur={() => SetNumberTo()} size={'small'} error={numberFromErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={numberFrom} onInput={e => setNumberFrom(e.target.value)} />
                        </div>
                        <div style={{ marginTop: 10, paddingTop: 26 }}>
                            <FontAwesomeIcon style={{ color: global.theme_black, marginRight: 0, width: 10 }} icon={faMinus} />
                        </div>
                        <div style={{ margin: 10, width: 110 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_cislo_do}</p>
                            <TextField size={'small'} error={numberToErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={numberTo} onInput={e => setNumberTo(e.target.value)} />
                        </div>
                        <div style={{ margin: 10, width: 110 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_pocet}</p>
                            <TextField size={'small'}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                                }}
                                error={pocetErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pocet} onInput={e => setPocet(e.target.value)} />
                        </div>
                        {props.id == 0 ?
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_eur}</p>
                                <TextField onBlur={() => GetSum()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                    }}
                                    size={'small'} error={cenaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={cena} onInput={e => setCena(e.target.value)} />
                            </div>
                            : null}
                        {props.id == 0 ?
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_eur_sum}</p>
                                <TextField size={'small'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                    }}
                                    error={sumaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={suma} onInput={e => setSuma(e.target.value)} />
                            </div>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                        <div style={{ margin: 10, width: 300 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={nameVydajObvodErr} check={false} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.bloky_obvod_menu} width={'100%'} value={nameVydajObvod} func={SetMenoVydajObvod.bind(this)} />
                        </div>

                    </div>
                </div>



                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}

                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    <Button onClick={() => DialogSave()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                    <Button onClick={() => setShowDialog(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
                {isBusy ?
                    <Loading center={true} />
                    : null}
            </Dialog >


            {/* ****************************************************************************************
                DIALOG - VYDAJ NA OBVOD
             **************************************************************************************** */}
            <Dialog open={showDialogObvod} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{props.id == 0 ? lang.bloky_vydaj_obvod_label : lang.bloky_vydaj_obvod_sek_label}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowDialogObvod(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextNormal }}>{lang.bloky_number}: <b>{label}</b></p>
                    </div>
                    {/* VYDAJ - OBVOD */}
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ margin: 10, width: 150 }}>
                            <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.bloky_date_výdaj}</p>
                            <TextField inputRef={inputRefObvod} size={'small'} type={'date'} error={dateOutErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={dateOut} onInput={e => setDateOut(e.target.value)} />
                        </div>
                        <div style={{ margin: 10, width: 150 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={obvodErr} check={true} row={false} new_record={false} department={props.department} db={15} lang={lang} label={lang.bloky_obvod_kod} width={'100%'} value={obvod} value_id={obvodID} func={SetObvod.bind(this)} />
                        </div>
                        <div style={{ margin: 10, width: 300 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={nameVydajObvodErr} check={false} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.bloky_obvod_menu} width={'100%'} value={nameVydajObvod} func={SetMenoVydajObvod.bind(this)} />
                        </div>
                        <div style={{ margin: 10, width: 400 }}>
                            <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.note}</p>
                            <TextField size={'small'}
                                error={pocetErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}

                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    <Button onClick={() => DialogSaveObvod()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                    <Button onClick={() => setShowDialogObvod(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
            </Dialog >


            {/* ****************************************************************************************
                DIALOG - VYDAJ DO TERENU
             **************************************************************************************** */}
            <Dialog open={showDialogTeren} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.record_edit : lang.bloky_vydaj_teren_label}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowDialogTeren(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextNormal }}>{lang.bloky_number}: <b>{label}</b></p>
                    </div>

                    {lock == false ?
                        // VYDAJ - OBVOD - UPRAVA MOZNA - nie su pouzite este ziadne bloky
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ margin: 10, width: 150 }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.bloky_date_výdaj}</p>
                                    <TextField inputRef={inputRefTeren} size={'small'} type={'date'} error={dateOutTerenErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={dateOutTeren} onInput={e => setDateOutTeren(e.target.value)} />
                                </div>
                                <div style={{ margin: 10, width: 300 }}>
                                    <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={nameVydajTerenErr} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.bloky_obvod_menu} width={'100%'} value={nameVydajTeren} value_id={nameVydajTerenID} func={SetMenoVydajTeren.bind(this)} />
                                </div>
                                <div style={{ margin: 10, width: 400 }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.note}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                                </div>
                            </div>
                            {props.id == 0 ?
                                <FormControlLabel control={<Checkbox checked={editPrice} onChange={() => setEditPrice(!editPrice)} />} label={lang.bloky_change_price} />
                                : null}
                            {editPrice == true ?
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {props.id == 0 ?
                                        // JEDNA SA O BLOKY NIE O SEKY
                                        <div style={{ margin: 10, width: 150 }}>
                                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_eur}</p>
                                            <TextField onBlur={() => GetSum()}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                }}
                                                size={'small'} error={cenaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={cena} onInput={e => setCena(e.target.value)} />
                                        </div>
                                        : null}
                                    {props.id == 0 ?
                                        // JEDNA SA O BLOKY NIE O SEKY
                                        <div style={{ margin: 10, width: 150 }}>
                                            <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.bloky_eur_sum}</p>
                                            <TextField size={'small'}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                }}
                                                error={sumaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={suma} onInput={e => setSuma(e.target.value)} />
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>
                        :
                        // UVOLNENIE - UPRAVA NIE JE MOZNA, PRETO ZE MINIMALNE JEDEN LISTOK JE POUZITY
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.bloky_date_výdaj}</p>
                                <TextField disabled={true} size={'small'} type={'date'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={dateOutTeren} />
                            </div>
                            <div style={{ margin: 10, width: 300 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.bloky_obvod_menu}</p>
                                <TextField disabled={true} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={nameVydajTeren} />
                            </div>
                            <div style={{ margin: 10, width: 400 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.note}</p>
                                <TextField disabled={true} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                            </div>
                        </div>
                    }
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}
                </div>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    {lock == true && divide == true ?
                        <Button onClick={() => DialogRelease()} style={{ ...styles.ButtonSave, backgroundColor: global.theme_dark_red, width: 250, margin: 15 }} variant="contained">{lang.bloky_release}</Button>
                        : null}
                    {lock == false ?
                        <Button onClick={() => DialogSaveTeren()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                        : null}
                    <Button onClick={() => setShowDialogTeren(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
                {isBusy ?
                    <Loading center={true} />
                    : null}
            </Dialog >


            {/* ****************************************************************************************
                DIALOG - VYUCTOVAT BLOKY
             **************************************************************************************** */}
            <Dialog open={showPayment} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.record_edit : props.id == 0 ? lang.bloky_to_pay_text : lang.bloky_to_pay_sek_text}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowPayment(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 0, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block }}>
                        <ZvazokDetails item={itemsSelected} />
                    </div>
                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>Počet označených: {blockPayCount} ks</p>
                        <p style={{ ...styles.TextSmall, marginTop: 5, fontWeight: 'bold' }}>Suma k vyučtovaniu: {blockPaySum} €</p>
                    </div>

                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.blok_pay_date}</p>
                                <TextField size={'small'} type={'date'} error={blockPayDateErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={blockPayDate} onInput={e => setBlockPayDate(e.target.value)} />
                            </div>
                            <div style={{ width: 250, paddingTop: 28, marginLeft: 10 }}>
                                <FormControlLabel control={<Checkbox checked={blockPayAll} onChange={ChangePayAll} />} label={lang.blok_pay_all} />
                            </div>
                            <div style={{ width: 250, paddingTop: 28 }}>
                                <Select
                                    multiple
                                    disabled={blockPayAll}
                                    displayEmpty
                                    value={blockPayValues}
                                    onChange={PaymentChange}
                                    renderValue={(selected) => blockPayAll == true ? lang.blok_pay_none : 'označené: ' + selected.length + ' ks'}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    <MenuItem disabled value="">
                                        {lang.blok_pay_list}
                                    </MenuItem>
                                    {blockPayItems.map((item) => (
                                        <MenuItem key={item.id} value={item.id} style={{}} disabled={false}>
                                            <Checkbox style={{ marginTop: 0, marginLeft: item.sub == true ? 30 : 0, marginRight: 5, padding: 0 }} checked={blockPayValues.indexOf(item.id) > -1} />
                                            <p style={{ ...styles.TextTiny }}>{item.number}</p>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div style={{ margin: 10, width: 300 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={blockPayNameErr} check={false} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.bloky_obvod_menu} width={'100%'} value={blockPayName} func={SetMenoVyuctovat.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}
                </div>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    {blockPayCount > 0 ?
                        <Button onClick={() => DialogSaveVyuctovanie()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                        : null}
                    <Button onClick={() => setShowPayment(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
                {isBusy ?
                    <Loading center={true} />
                    : null}
            </Dialog >


            {/* ****************************************************************************************
                DIALOG - STORNOVAT BLOKY
             **************************************************************************************** */}
            <Dialog open={showCancel} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{lang.bloky_cancel_label} {itemsSelected.zvazok}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowCancel(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_cancel_date}</p>
                                <TextField size={'small'} type={'date'} error={cancelDateErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={cancelDate} onInput={e => setCancelDate(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: 600 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_cancel_dovod}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={cancelNote} onInput={e => setCancelNote(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}
                </div>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    <Button onClick={() => DialogCancel()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                    <Button onClick={() => setShowCancel(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
                {isBusy ?
                    <Loading center={true} />
                    : null}
            </Dialog >


            {/* ****************************************************************************************
                DIALOG - INFORMACIE O ZVAZKU
             **************************************************************************************** */}
            <Dialog open={showInfo} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faInfoCircle} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{lang.bloky_zvazok_info} {info.zvazok}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowInfo(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 0, paddingBottom: 20 }}>
                    <div style={{ ...styles.Block }}>

                        {/* ZVAZOK, CISLA, POCET KUSOV, HODNOTA, SUMA */}
                        <div style={{ ...styles.BlockRowRaw, backgroundColor: global.theme_lighter, paddingTop: 20, paddingBottom: 20 }}>
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.blocks_zvazok}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.zvazok} />
                            </div>
                            <div style={{ margin: 10, width: 145 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_cislo_od}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.cislo_od} />
                            </div>
                            <div style={{ marginTop: 10, paddingTop: 26 }}>
                                <FontAwesomeIcon style={{ color: global.theme_black, marginRight: 0, width: 10 }} icon={faMinus} />
                            </div>
                            <div style={{ margin: 10, width: 145 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_to}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.cislo_do} />
                            </div>
                            <div style={{ margin: 10, width: 150 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_pocet}</p>
                                <TextField size={'small'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                                    }}
                                    error={pocetErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.pocet} />
                            </div>
                            {props.id == 0 ?
                                <div style={{ margin: 10, width: 150 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_eur}</p>
                                    <TextField onBlur={() => GetSum()}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        size={'small'} error={cenaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.price} />
                                </div>
                                : null}
                            {props.id == 0 ?
                                <div style={{ margin: 10, width: 150 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_eur_sum}</p>
                                    <TextField size={'small'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        error={sumaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.sume} />
                                </div>
                                : null}
                        </div>

                        {/* PRIJATE Z DU */}
                        <div style={{ ...styles.BlockLeft }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ margin: 10, width: 150 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_date_prijem_du}</p>
                                    <TextField size={'small'} type={'date'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.date_prijem} />
                                </div>
                                {info.date_vydaj != null ?
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_date_výdaj_obvod}</p>
                                        <TextField size={'small'} type={'date'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.date_vydaj} />
                                    </div>
                                    : null}
                                {info.date_vydaj != null ?
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_obvod}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.obvod} />
                                    </div>
                                    : null}
                                {info.date_vydaj != null ?
                                    <div style={{ margin: 10, width: 490 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_obvod_menu}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.meno_vydaj_obvod} />
                                    </div>
                                    : null}
                            </div></div>

                        {/* VYDAJ DO TERENU */}
                        <div style={{ ...styles.BlockLeft }}>
                            {info.date_vydaj_teren != null ?
                                <div style={{ ...styles.BlockRowRaw }}>

                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_vydaj_teren}</p>
                                        <TextField size={'small'} type={'date'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.date_vydaj_teren} />
                                    </div>
                                    <div style={{ margin: 10, width: 320 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_obvod_menu}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.meno_vydaj_teren} />
                                    </div>
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.not_used}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.count_free} />
                                    </div>
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.used}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.count_used} />
                                    </div>
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_paymented}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.count_payed} />
                                    </div>

                                </div>
                                : null}

                        </div>

                        {/* STORNOVANE */}
                        {info.typ == 2 ?
                            <div style={{ ...styles.BlockLeft }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ margin: 10, width: 150 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_cancel_date}</p>
                                        <TextField size={'small'} type={'date'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.cancel_date} />
                                    </div>
                                    <div style={{ margin: 10, width: 830 }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_cancel_dovod}</p>
                                        <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={info.cancel_note} />
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>


                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    <Button onClick={() => setShowInfo(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.close}</Button>
                </div>
                {isBusy ?
                    <Loading center={true} />
                    : null}
            </Dialog >


            {showPriestupok == true ?
                <PriestupokPreview dialog={true} item={itemPriestupok} isBusy={isBusy} func={PriestupokPreviewResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div>
    )
}
