import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk } from './globals.js';
import { Button, TextField, Select, IconButton, Paper } from '@mui/material';
import { SaveLogin, SaveUser } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import { ItemList } from './item_list.js';
import { faEdit, faFaceFrown, faFileLines, faList, faPeace, faPen, faPrint, faTableCells } from '@fortawesome/free-solid-svg-icons';



export const LogoutUser = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    useEffect(() => {

        var today = new Date();
        var data = { token: '', expire: 0 };
        SaveLogin(data);
        SaveUser([]);
        navigate('/login/' + params.apptoken);

        return () => {
        };

    }, []);
    return (
        <div></div>
    )

};

