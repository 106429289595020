import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, GetDateYear, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';


export const SpravnyDeliktNew = (props) => {

    /*
            USAGE:
            <SpravnyDeliktNew editing={true} department={department} obvod={obvod} user={user} func={SpravnyDeliktNewResult.bind(this)} />
 
            const SpravnyDeliktNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');

    // POLOZKY DIALOGU
    const [cislo, setCislo] = useState('');
    const [pokutaZaplatena, setPokutaZaplatena] = useState(false);
    const [datumZaplatena, setDatumZaplatena] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const offset = 15;

    useEffect(() => {

        setLabel(lang.item_edit);
        db_get();

        return () => {
        };

    }, []);


    const db_get = async () => {

        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                    log: global.log_uhrada,
                    user_id: props.user.id,
                    department_id: props.department.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;
                setPokutaZaplatena(data.pokuta_zaplatena == false ? false : true);
                if (data.datum_zaplatena == null) {
                    setDatumZaplatena(Today());
                } else {
                    setDatumZaplatena(data.datum_zaplatena);
                }
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_uhrada_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                Press(true, data);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {

        var data = {
            id: props.item_id,
            pokuta_zaplatena: pokutaZaplatena,
            datum_zaplatena: pokutaZaplatena == true ? datumZaplatena : null
        }

        console.log(data);
        db_update(data);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.BlockRow, width: 600, paddingTop: 40, paddingBottom: 40, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, width: '50%', marginRight: 20, paddingTop: 16 }}>
                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.uhradena}</p>}
                        control={<Checkbox checked={pokutaZaplatena} size={'small'} onChange={() => setPokutaZaplatena(!pokutaZaplatena)} />}
                    />
                </div>
                <div style={{ width: '50%' }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.datum_uhradena}</p>
                    <TextField type={'date'} value={datumZaplatena} size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" onInput={e => setDatumZaplatena(e.target.value)} />
                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {isLoading ?
                <Loading center></Loading>
                : null}
        </Dialog >
    );
}
