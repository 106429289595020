import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from '../globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { CheckPermision, Debug, Loading, Today, GetDefaultFilter, GetSearchText, StatsSearch, LogsSearch, Capitalize } from '../items.js';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { faClose, faUser } from '@fortawesome/free-solid-svg-icons'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { styles } from '../styles.js';
import { setMonth, setYear } from 'date-fns';


export const LogsLogin = (props) => {
    /*    
            USAGE:
            <LogsLogin func={LogsLoginResult.bind(this)} />
    
            const LogsLoginResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [index, setIndex] = useState(0);
    const [searchYear, setSearchYear] = useState('');
    const [searchMonth, setSearchMonth] = useState(-1);

    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [searchDefault, setSearchDefault] = useState(null);
    const search_default = GetDefaultFilter(props.obvod, true);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dateRef = useRef(null);
    const timer = useRef();

    let { func } = props;
    var lang = sk;


    useEffect(() => {

        setSearch(search_default);
        setSearchText(GetSearchText(search_default));
        setSearchDefault(search_default);

        db_get(search_default);

        var today = new Date();
        var y = today.getFullYear();
        var m = today.getMonth();
        setSearchYear(y);
        setSearchMonth(m);

        return () => {
        };

    }, []);


    const db_get = async (data) => {        
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'logs_logins', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    filter: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setItems(json.items);
                setItemsFiltered(json.items);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const columns = [
        {
            field: 'datum',
            headerName: lang.date,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
            sortable: false,
            hide: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'cas_od',
            headerName: lang.time_from,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
            sortable: false,
            hide: false,
            filterable: false,
            align: 'center'
        },
        {
            field: 'cas_do',
            headerName: lang.time_to_,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
            sortable: false,
            hide: false,
            filterable: false,
            align: 'center'
        },
        {
            field: 'name',
            headerName: lang.name,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: false,
            sortable: false,
            hide: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'surname',
            headerName: lang.surname,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: false,
            sortable: false,
            hide: false,
            filterable: true,
            align: 'left'
        },

        {
            field: 'ip_address',
            headerName: lang.ip_address,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 140,
            editable: false,
            sortable: false,
            hide: false,
            filterable: true,
            align: 'left'
        },
    ];


    const RestoreSearch = () => {
        var tmp = search_default;
        setSearch(tmp);
        setSearchText(GetSearchText(tmp));
        db_get(tmp);
    }

    const ShowFilter = () => {
        setShowFilter(true);
    }

    const FilterResult = (typ, data) => {

        setShowFilter(false);
        if (typ == 1) {
            setSearch(data);
            setSearchText(GetSearchText(data));
            db_get(data);
        }
    }

    const LogsSearchResult = (typ, data) => {

        setShowSearch(false);
        if (typ == 1 && data != null) {
            setSearch(data);
            setSearchText(GetSearchText(data));
            db_get(data);
        }
    }

    const CustomColumnMenuComponent = (props) => {
        const [text, setText] = useState('');
        const { hideMenu, currentColumn, color, ...other } = props;
        const textRef = useRef(null);
        const timer = useRef();

        useEffect(() => {

            if (currentColumn.filterable == true) {
                textRef.current.focus();
            }

            return () => {
            };

        }, []);

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                ownerState={{ color }}
                {...other}
            >
                {/*
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />                
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                */}

                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, margin: 10 }}>
                        {currentColumn.filterable == true ?
                            <TextField
                                inputRef={textRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: 200 }}
                                label={''} variant="outlined"
                                value={text}
                                placeholder={currentColumn.headerName}
                                onInput={e => setText(e.target.value)}
                                onBlur={hideMenu}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (text.trim() != '') {
                                            var data = search;
                                            data.stlpec = currentColumn.field;
                                            data.text = currentColumn.field == 'name' || currentColumn.field == 'surname' ? Capitalize(text) : text.toUpperCase();
                                            data.default = false;
                                            setSearchText(GetSearchText(data));
                                            setSearch(data);
                                            db_get(data);
                                        }
                                    }

                                }}
                            />
                            :
                            <p style={{ ...styles.TextTiny, marginTop: 9 }}>{currentColumn.headerName}</p>
                        }
                    </div>
                    <IconButton onClick={hideMenu} style={{ ...styles.Block, height: 36, width: 36, marginTop: 10, marginRight: 10 }}>
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 12 }} icon={faXmark} />
                    </IconButton>
                </div>
            </GridColumnMenuContainer>
        );
    }

    const ShowSearch = () => {
        setShowSearch(true);
    }

    return (
        <div style={{ ...styles.Block, paddingTop: 0 }}>
            <div style={{ ...styles.Block, width: '100%' }}>
                <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                    {searchText != '' ?
                        <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.search_text}:</p>
                            {search.default == true ?
                                <Chip
                                    label={searchText}
                                    style={{ marginLeft: 10, marginTop: 10 }}
                                />
                                :
                                <Chip
                                    label={searchText}
                                    onDelete={() => RestoreSearch()}
                                    style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                                />
                            }

                        </div>
                        : null}
                </div>
                <Box sx={{
                    height: 410,
                    width: 1,
                    '& .super-app-theme--header': {
                        backgroundColor: global.theme_table_header_color,
                    },
                    '& .super-app-theme--normal': {
                        bgcolor: (theme) => (global.theme_white),
                        color: (theme) => (global.theme_black),
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                    '& .super-app-theme--disabled': {
                        bgcolor: (theme) => (global.theme_white),
                        color: (theme) => (global.theme_medium_gray),
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                }}>
                    <DataGrid
                        sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                        rows={itemsFiltered}
                        columns={columns}

                        // vlastná menu po kliknuti na header menu                        
                        components={{
                            ColumnMenu: CustomColumnMenuComponent,
                        }}

                        rowHeight={30}
                        onRowClick={(params) => { setIndex(params.id) }}
                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                        getRowClassName={(params) => (`super-app-theme--normal`)}
                        hideFooterRowCount={true}
                        hideFooterSelectedRowCount={true}
                    //onCellEditCommit={(params) => { CellSave(params) }}
                    />
                </Box>
            </div>

            <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRow, width: '50%' }}>
                    {/*
                    {CheckPermision(props.permissions, global.permission_read) == true ?
                        <div style={{ ...styles.BlockLeft, width: 50 }}>
                            <IconButton onClick={() => ShowSearch()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_dark }}>
                                <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faSearch} />
                            </IconButton>
                        </div>
                        : null}
                    */}
                    {CheckPermision(props.permissions, global.permission_read) == true ?
                        <Button onClick={() => ShowFilter()} style={{ ...styles.ButtonEdit }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFilter} />
                            <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.filter}</p>
                        </Button>
                        : null
                    }
                </div>
                <div style={{ ...styles.BlockRight, width: '50%' }}>
                    <IconButton onClick={() => db_get()}>
                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                    </IconButton>
                </div>
            </div>

            {/* VYHLADAVANIE */}
            {showSearch == true ?
                <LogsSearch department={props.department} lang={lang} func={LogsSearchResult.bind(this)} />
                : null}

            {/* FILTROVANIE */}
            {showFilter == true ?
                <StatsSearch cislo={true} data={search} department={props.department} lang={lang} func={FilterResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div>
    )
}
