import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars, faBook, faWindowMaximize, faWindowMinimize, faCar } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Accordion, Drawer, Slide } from '@mui/material';
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import { Pivot as Hamburger } from 'hamburger-react'
import { AutocompleteText, GetPrice } from './items.js';
import { useHotkeys } from 'react-hotkeys-hook';
import { DataGrid, skSK, GridActionsCellItem } from '@mui/x-data-grid';


export const PriestupokBloky = (props) => {
    /*
            USAGE:
            <PriestupokBloky lang={lang} department={props.department} func={PriestupokBlokyResult.bind(this)} />

            const PriestupokBlokyResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');


    const [items, setItems] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [index, setIndex] = useState(0);

    const [showAdd, setShowAdd] = useState(false);

    const [meno, setMeno] = useState('');
    const [menoID, setMenoID] = useState(0);
    const [zvazky, setZvazky] = useState([]);
    const [zvazok, setZvazok] = useState(0);
    const [zvazokSelected, setZvazokSelected] = useState([]);
    const [cisla, setCisla] = useState([]);
    const [bloky, setBloky] = useState([]);
    const [blokyOpen, setBlokyOpen] = useState(false);
    const [cena, setCena] = useState('');
    const [cenaErr, setCenaErr] = useState('');
    const [suma, setSuma] = useState(0);
    const [sumaCelkom, setSumaCelkom] = useState(0);

    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const InputRef = useRef(null);

    useEffect(() => {

        setItems(props.items);
        GetSumaCelkom(props.items);

        setMeno(props.meno);
        setMenoID(props.meno_id);

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);

    const db_get_zvazok = async (_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_get_zvazok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    variant: props.variant,
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setZvazky(json.items);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_bloky = async (_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bloky_get_bloky', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: _id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setBloky(json.items);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, _items, _suma) => {
        func(typ, _items, _suma);
    }

    const Save = () => {
        Press(1, items, sumaCelkom);
    }

    const Close = () => {
        Press(0, null, null);
    }


    const columns = [
        {
            field: 'zvazok',
            headerName: lang.blocks_zvazok,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
        },
        {
            field: 'block_cislo',
            headerName: lang.blok_cislo,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            hide: false
        },
        {
            field: 'suma',
            headerName: lang.blocks_suma,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 150,
            editable: false,
        },
        {
            field: 'meno',
            headerName: lang.blocks_pouzil,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 280,
            editable: false,
        },
    ];


    const columns_small = [
        {
            field: 'block_cislo',
            headerName: lang.blok_cislo,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            hide: false
        },
        {
            field: 'suma',
            headerName: lang.blocks_suma,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 150,
            editable: false,
        }
    ];

    const columns_delete = [
        {
            field: 'id',
            headerName: lang.blocks_zvazok,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
        },
        {
            field: 'block_cislo',
            headerName: lang.blok_cislo,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 150,
            editable: false,
            hide: false
        },
        {
            field: 'suma',
            headerName: lang.blocks_suma,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 150,
            editable: false,
        },
        {
            field: 'meno',
            headerName: lang.blocks_pouzil,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 280,
            editable: false,
        },
        {
            field: '',
            type: 'actions',
            headerName: props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? lang.delete : '',
            headerClassName: 'super-app-theme--header',
            width: 100,
            getActions: (params) => [
                <IconButton onClick={() => (DeleteRow(params.id))} style={{ width: 20, height: 20 }} >
                    {params.row.status < 2 ?
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 10 }} icon={faXmark} />
                        : <p></p>}
                </IconButton>
            ],
        }
    ];

    const columns_delete_small = [
        {
            field: 'block_cislo',
            headerName: lang.blok_cislo,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 130,
            editable: false,
            hide: false
        },
        {
            field: 'suma',
            headerName: lang.blocks_suma,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 130,
            editable: false,
        },
        {
            field: '',
            type: 'actions',
            headerName: props.permissions.toString().substr(global.permission_bloky, 1) == '1' ? lang.delete : '',
            headerClassName: 'super-app-theme--header',
            width: 50,
            getActions: (params) => [
                <IconButton onClick={() => (DeleteRow(params.id))} style={{ width: 20, height: 20 }} >
                    {params.row.status < 2 ?
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 10 }} icon={faXmark} />
                        : <p></p>}
                </IconButton>
            ],
        }
    ];

    const DeleteRow = (id) => {
        setTimeout(() => {
            var tmp = items.find(x => x.id == id);
            tmp.status = 2;
            GetSumaCelkom(items);
        });

    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemsSelected(data);
        setIndex(id);
    }


    const AddNew = () => {
        setMeno(props.meno);
        setMenoID(props.meno_id);

        setZvazky([]);
        setZvazokSelected([]);
        setZvazok(0);
        setBloky([]);
        setCisla([]);
        setSuma(0);
        setCena('');
        if (props.meno_id > 0) {
            db_get_zvazok(props.meno_id);
        }
        setShowAdd(true);
    }


    const InsertNew = () => {
        var cena_ = cena.toString().replace(',', '.');
        if (props.variant == 1 && (isNaN(cena_) || cena_.trim() == '')) {
            setCenaErr(lang.required);
        } else {
            setCenaErr('');

            var data = {};
            var list = [];

            items.forEach(item => {
                list.push(item);
            });

            cisla.forEach(item => {
                var tmp = bloky.find(x => x.id == item);

                data = {
                    id: list.length + 1,
                    zvazok: zvazokSelected.zvazok,
                    zvazok_id: zvazokSelected.id,
                    block_cislo: tmp.number,
                    block_id: item,
                    suma: props.variant == 0 ? zvazokSelected.price : cena_,
                    meno: zvazokSelected.meno_vydaj_teren,
                    status: tmp.status,
                    price: cena_
                }
                // KONTROLA CI SA UZ NENACHADZA V ZOZNAME
                var finded = items.find(x => x.zvazok_id == zvazokSelected.id && x.block_cislo == tmp.number && x.status == 0);
                if (finded == undefined) {
                    list.push(data);
                }
            });

            GetSumaCelkom(list);
            setItems(list);
            setShowAdd(false);
        }
    }


    const SetMeno = (value, key) => {

        if (value != null) {
            setMeno(value.label);
            setMenoID(value.id);
            setZvazky([]);
            setZvazokSelected([]);
            setZvazok(0);
            setBloky([]);
            setCisla([]);
            setSuma(0);
            db_get_zvazok(value.id);
        }
    }

    const BlokyChange = (event) => {
        const {
            target: { value },
        } = event;
        var tmp = typeof value === 'string' ? value.split(',') : value;
        tmp = tmp.filter(x => x != '');
        setCisla(tmp);
        var cena = parseInt(zvazokSelected.price) * tmp.length;
        setSuma(cena);
    };

    const ZvazokChange = (event) => {
        var value = event.target.value;
        setZvazok(value);
        var tmp = zvazky.find(x => x.id == value);
        setZvazokSelected(tmp);
        db_get_bloky(value);
    };

    const GetSumaCelkom = (list) => {
        var sum = 0;
        list.forEach(item => {
            if (item.status < 2) {
                sum = sum + parseInt(item.suma);
            }
        });
        setSumaCelkom(sum);
    }

    return (
        <Dialog open={true} maxWidth={'xl'} fullScreen={isSmall == true ? true : false}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{props.label}</p>
                </div>
                <div style={{ width: global.dialog_title_height }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 1100 }}>
                <div style={{ width: '95%', paddingTop: 20, paddingBottom: 10 }}>
                    {isSmall == true && showAdd == true ? null :
                        <div>
                            <div style={{ paddingTop: 0, paddingBottom: 10 }}>
                                <p style={{ ...styles.TextNormal }}>{lang.blocks_celkom}: {GetPrice(sumaCelkom)} €</p>
                            </div>

                            <Box sx={{
                                height: 310,
                                width: '100%',
                                '& .super-app-theme--header': {
                                    backgroundColor: global.theme_table_header_color,
                                },
                                '& .super-app-theme--normal': {
                                    backgroundColor: global.theme_white,
                                    color: '#000000',
                                    '&:hover': {
                                        bgcolor: (theme) => (global.theme_gray),
                                    },
                                },
                                '& .super-app-theme--red': {
                                    backgroundColor: global.theme_white,
                                    color: global.theme_dark_red,
                                    fontWeight: 'normal',
                                    '&:hover': {
                                        bgcolor: (theme) => (global.theme_gray),
                                    },
                                },
                                '& .super-app-theme--gray': {
                                    backgroundColor: global.theme_white,
                                    color: '#BBBBBB',
                                    fontWeight: 'normal',
                                    '&:hover': {
                                        bgcolor: (theme) => (global.theme_gray),
                                    },
                                },
                            }}>
                                <DataGrid
                                    sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color, height: 300 }}
                                    rows={items}
                                    columns={props.permissions.toString().substr(global.permission_bloky, 1) == '1' || props.editing == false ? isSmall ? columns_delete_small : columns_delete : isSmall ? columns_small : columns}
                                    //pageSize={global.table_rows}
                                    //rowsPerPageOptions={[global.table_rows]}
                                    //checkboxSelection
                                    //disableSelectionOnClick  
                                    rowHeight={30}
                                    onRowClick={(params) => { ChangeIndex(params.id) }}
                                    localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                    getRowClassName={(params) => (params.row.status < 2 ? `super-app-theme--normal` : `super-app-theme--gray`)}
                                    hideFooterRowCount={true}
                                    hideFooterSelectedRowCount={true}
                                />
                            </Box>
                        </div>
                    }
                    <div style={{ ...styles.Block, height: isSmall ? undefined : 130, backgroundColor: showAdd == true ? global.theme_gray : undefined, paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {showAdd == true ?
                                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>Vložiť nový</p>
                                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 10 }}>
                                        <div style={{ width: isSmall ? '80%' : 250 }}>
                                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.blocks_riesitel} width={'100%'} value={meno} value_id={menoID} func={SetMeno.bind(this)} />
                                        </div>
                                        <div style={{ marginLeft: isSmall ? 0 : 10, width: isSmall ? '80%' : 250, marginTop: isSmall ? 15 : 0 }}>
                                            {zvazky.length > 0 ?
                                                <div>
                                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.blocks_zvazok}</p>
                                                    <Select
                                                        value={zvazok}
                                                        onChange={ZvazokChange}
                                                        size='small'
                                                        style={{ backgroundColor: global.theme_white, width: '100%' }}
                                                    >
                                                        {zvazky.map((item) => (
                                                            <MenuItem key={item.id} value={item.id} style={{ marginBottom: item.sub == true ? 10 : 0 }} >
                                                                {item.zvazok}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                : null}

                                        </div>
                                        <div style={{ marginLeft: isSmall ? 0 : 10, width: isSmall ? '80%' : 250, marginTop: isSmall ? 15 : 0 }}>
                                            {bloky.length > 0 ?
                                                <div style={{ ...styles.Block }}>
                                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.blok_cislo}</p>

                                                    <Select
                                                        multiple
                                                        displayEmpty
                                                        onClose={() => setBlokyOpen(false)}
                                                        onOpen={() => setBlokyOpen(true)}
                                                        open={blokyOpen}
                                                        value={cisla}
                                                        onChange={BlokyChange}
                                                        renderValue={(selected) => selected.length == 0 ? lang.select : lang.blocks_count + ': ' + selected.length}
                                                        size='small'
                                                        style={{ backgroundColor: global.theme_white, width: '100%' }}
                                                    >
                                                        <MenuItem onClick={() => setBlokyOpen(false)} disabled={isSmall ? false : true} value="" style={{ backgroundColor: isSmall == true ? global.theme_dark_green : global.theme_white, color: isSmall == true ? global.theme_white : global.theme_black }}>
                                                            <div style={{ ...styles.Block }}>
                                                                <p style={{ ...styles.TextSmall }}>
                                                                    {isSmall == true ? lang.close : 'Použité bloky'}
                                                                </p>
                                                            </div>
                                                        </MenuItem>
                                                        {bloky.map((item) => (
                                                            <MenuItem key={item.id} value={item.id} style={{ marginBottom: item.sub == true ? 10 : 0 }} >
                                                                <Checkbox style={{ marginTop: 0, marginLeft: 0, marginRight: 5, padding: 0 }} checked={cisla.indexOf(item.id) > -1} />
                                                                <p style={{ ...styles.TextTiny }}>{item.number}</p>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                : null}
                                        </div>
                                        {bloky.length > 0 && props.variant == 1 ?
                                            <div style={{ marginLeft: isSmall ? 0 : 10, width: isSmall ? '80%' : 120, marginTop: isSmall ? 15 : 0 }}>
                                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.blok_price_kus}</p>
                                                <TextField error={cenaErr != '' ? true : false} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={cena} onInput={e => setCena(e.target.value)} />
                                            </div>
                                            : null}
                                        <div style={{ marginLeft: isSmall ? 0 : 10, width: isSmall ? 200 : 100, paddingTop: 20 }}>
                                            {cisla.length > 0 ?
                                                <Button onClick={() => InsertNew()} style={{ ...props.mobile == true ? styles.ButtonGreenMobile : styles.ButtonGreen, width: isSmall ? '100%' : 100 }}>
                                                    {lang.blocks_insert}
                                                </Button>
                                                : null}
                                        </div>
                                        {isSmall ?
                                            <div style={{ ...styles.Block, width: isSmall ? 200 : 100, marginTop: 20 }}>
                                                <Button onClick={() => setShowAdd(false)} style={{ ...props.mobile == true ? styles.ButtonDarkMobile : styles.ButtonAction, width: '100%' }}>
                                                    {lang.delete}
                                                </Button>
                                            </div>
                                            :
                                            <div style={{ marginLeft: 10, width: 40, paddingTop: 20 }}>
                                                <IconButton onClick={() => setShowAdd(false)} style={{ width: 36, height: 36 }}>
                                                    <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                    {props.variant == 0 ?
                                        <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: 'normal' }}>Suma za označené: {GetPrice(suma)} €</p>
                                        : null}
                                </div>
                                : null}
                        </div>
                    </div>

                    <div style={{ ...styles.Block, paddingTop: 50, paddingBottom: 0 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                                {showAdd == false ?
                                    <Button onClick={() => AddNew()} style={{ ...props.mobile == true ? styles.ButtonDarkMobile : styles.ButtonAction, margin: isSmall ? 5 : 10 }}>
                                        <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 10, width: 16 }} icon={faPlus} />
                                        {lang.bloky_add}
                                    </Button>
                                    : null}
                            </div>
                            <div style={{ ...styles.BlockRight, marginTop: isSmall ? 30 : 0 }}>
                                {showAdd == false ?
                                    <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, height: isSmall ? undefined : 55 }}>
                                        <Button onClick={() => Save()} style={{ ...props.mobile == true ? styles.ButtonGreenMobile : styles.ButtonGreen, margin: isSmall ? 5 : 10 }}>{lang.save}</Button>
                                        <Button onClick={() => Close()} style={{ ...props.mobile == true ? styles.ButtonMobileCancel : styles.ButtonClose, margin: isSmall ? 5 : 10 }}>{lang.close}</Button>
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, height: 55 }}></div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Dialog >
    );
}


