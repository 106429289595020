import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { sk } from './globals.js';
import { Button, TextField, Select, IconButton, Paper } from '@mui/material';
import { Debug, Menu, MenuTop, LoadLogin, CheckPermision, Loading, UserData, SaveUser, WelcomeDialog, LoadDepartment, ChooseObvod, SaveObvod, LoadObvod, SaveLogin, GetTodayYear, MenuTopSmall } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import { ItemList } from './item_list.js';
import { faCalculator, faEdit, faFaceFrown, faFileLines, faList, faPaperPlane, faPeace, faPen, faPrint, faQuestionCircle, faTableCells } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { useHotkeys } from 'react-hotkeys-hook';


// CISLENIKY
import { Users } from './ciselniky/users.js';
import { Countries } from './ciselniky/countries.js';

// AGENDA
import { Priestupky } from './priestupky.js';
import { Osoby } from './osoby';
import { Vozidla } from './vozidla.js';
import { PokutoveBloky } from './ciselniky/pokutove_bloky.js';
import { Posta } from './posta';
import { Trestneciny } from './trestne_ciny';
import { PredvedeneOsoby } from './predvedene_osoby'
import { OstatneCinnosti } from './ostatne_cinnosti.js';
import { KnihaPriestupkov } from './kniha_priestupkov.js';

// LOGY
import { LogsLogin } from './ciselniky/logs_login.js';

// NASTEVANIE
import { Settings } from './settings.js';

// STATISTIKY
import { StatsDialog } from './stats_dialog';
import { columnsDefault, columnsDefaultDetail, columnsDefault2, columnsPosta, columnsPostaPopis, columnsCinnosti, columnsPriestupkyExport, columnsKnihaPriestupkov, ciselnik_default, ciselnik_druh_priestupku, ciselnik_vzn, ciselnik_sposob_riesenia, ciselnik_dovod_predvedenia, ciselnik_ostatne, ciselnik_trestne_ciny, ciselnik_obvody, ciselnik_ulice, ciselnik_mesta, columnsLocalities, columnsUdalostiUlice, columnsUdalosti } from './statistic_columns.js';
import { EvidenciaPsov } from './evidencia_psov.js';
import { SpravaCinnost } from './sprava_cinnost.js';
import { PriestupkyExport } from './priestupky_export.js';
import { Udalosti } from './udalosti.js';
import { Ciselnik } from './ciselniky/ciselnik.js';
import { style } from '@mui/system';
import { KamerovyZaznam } from './kamerovy_zaznam.js';
import { Usep } from './usep.js';
import { VybaveniePolicie } from './vybavenie_policie.js';
import { SpravnyDelikt } from './spravny_delikt.js';



export const Main = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    // APP
    const [isBusy, setBusy] = useState(true);
    const [decodeKey, setDecodeKey] = useState('');
    const [buttonOver, setButtonOver] = useState(0);
    const [showNews, setShowNews] = useState(true);

    // prihlásený užívazeľ
    const [logged, setLogged] = useState(false);
    const [user, setUser] = useState(null);
    const [label, setLabel] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [showUser, setShowUser] = useState(false);
    const [permissions, setPermissions] = useState('0000000000');

    // oddelenie
    const [department, setDepartment] = useState([]);
    const [obvod, setObvod] = useState([]);
    const [showObvod, setShowObvod] = useState(false);

    // NASTAVENIA
    const [settings, setSettings] = useState([]);
    const [actualYear, setActualYear] = useState('');
    const [appToken, setAppToken] = useState('');

    // OZNAM
    const [oznamEnabled, setOznamEnabled] = useState(false);
    const [oznamLabel, setOznamLabel] = useState('');
    const [oznamText, setOznamText] = useState('');


    const [bodyID, setBodyID] = useState(0);
    const [subBody, setSubBody] = useState(0);
    const [year, setYear] = useState('2022');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let params = useParams();
    var lang = language;
    var timer = null;
    const homeRef = useRef(null)

    const heightQuickBar = 120;
    const widthInfoDialog = 600;


    const logo = require('./react/app/logo.png');
    useHotkeys('F12', (key) => handleKey(key), true)


    useEffect(() => {

        // ULOZENIE TOKENU APLIKACIE
        setAppToken(params.apptoken);

        // AKTUALNY ROK
        setActualYear(GetTodayYear());

        // RELOAD PAGE - AK SA ZMENI VELKOST OKNA        
        function handleResize() {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }, 500);
        }
        if (isLarge == true) {
            window.addEventListener('resize', handleResize);
        }

        var now = new Date();
        var year_ = now.getFullYear();
        setYear(year_);

        // KONTROLA PRIHLASENIA
        var data = LoadLogin();

        if (data != null) {
            CheckLoginExpire();
            if (data.token != '') {
                db_login_check(data.token);
            } else {
                navigate('/login/' + params.apptoken);
            }
        }

        setLabel(lang.menu_main);

        setBusy(false);

        setTimeout(() => {
            setShowNews(false);
        }, 5 * 60 * 1000);

        return () => {
        };

    }, []);

    const handleKey = (key) => {
        if (key.key == 'F12') {
            setSubBody(true);
            setBodyID(3001);
        }
    }

    const CheckLoginExpire = () => {
        // KONTROLA EXPIRACIE PRIHLASENIA
        var data = LoadLogin();
        var logout = false;
        var curtime = new Date() / 1000; // aktualny dátuma čas -> sekundy

        if (data != null) {
            if (parseInt(curtime) > parseInt(data.expire)) {
                // LOGIN -> expirovaný
                logout = true;
            }
        } else {
            logout = true;
        }

        if (logout == true) {
            var data = { token: '', expire: 0 };
            SaveLogin(data);
            navigate('/login/' + params.apptoken);
        }
    }

    // ******************************************************************************************
    // KONTROLA PRIHLASENIA A STIAHNUTIE NASTAVENI PROGRAMU
    // ******************************************************************************************
    const db_login_check = async (token) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'login_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    decode_key: params.decode_key,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.logged == true) {
                    // ULOZENIE DOKODOVACIEHO RETAZCA
                    setDecodeKey(json.key);

                    setLogged(true);
                    var user = json.user;
                    if (user.id > 0 && user.type == 0) {
                        user.permissions = global.permisions_admin_default;
                    }
                    setUser(user);
                    setName(user.name);
                    setSurname(user.surname);
                    setEmail(user.email);
                    setPermissions(user.permissions);

                    // ODDELENIE
                    var dep = json.department;
                    setDepartment(dep);

                    // OBVOD
                    var obv = json.obvod;
                    if (obv == null) {
                        var tmp = { kod: global.centrala_kod, id: global.centrala_id };
                        setObvod(tmp);
                    } else {
                        setObvod(obv);
                    }

                    // NASTAVENIA
                    setSettings(json.settings);
                    //setActualYear(json.settings.aktualny_rok);

                    // OZNAM
                    setOznamEnabled(json.oznam_enabled == false ? false : true);
                    setOznamLabel(json.oznam_label);
                    setOznamText(json.oznam_text);

                } else {
                    var data = { token: '', expire: 0 };
                    SaveLogin(data);
                    navigate('/login/' + params.apptoken);
                }
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const MenuPress = (id, children_id, subbody) => {
        if (id == 1000) {
            Home();
            setShowObvod(true);
        } else if (id != 0) {
            var menu_id = parseInt(id * 1000) + parseInt(children_id);

            var menu = lang.menuitems.find(x => parseInt(x.id) == id);
            var submenu = menu.children.find(x => parseInt(x.id) == children_id)

            setLabel(submenu.label);
            setSubBody(subbody);
            setBodyID(menu_id);
        } else {
            Home();
        }
        if (global.expire_check == true) {
            // kontrola expiracie
            CheckLoginExpire();
        }
    }

    const Home = () => {
        setLabel(lang.menu_main);
        setSubBody(false);
        setBodyID(0);
    }

    const MenuTopPress = (typ, children_id, item) => {
        if (typ == 999) {
            // ADMINISTRATOR - UDAJE
            setShowUser(true);
        }
        else {
            // stlacene menu-button
            MenuPress(typ, children_id, item);
        }
    }

    const UserDataPress = (typ, data) => {
        if (typ == false) {
            setShowUser(false);
        }
        if (typ == true) {
            setUser(data);
            setName(data.name);
            setSurname(data.surname);
            setShowUser(false);
            SaveUser(data);
        }

    }

    const ChooseObvodResult = (typ, _obvod) => {
        setShowObvod(false)
        if (typ == 1) {
            SaveObvod(_obvod);
            setObvod(_obvod);
        }
        if (typ == 2) {
            var tmp = { kod: global.centrala_kod, id: global.centrala_id };
            setObvod(tmp);
            SaveObvod(tmp);
        }
    }

    const SettingsResult = (item) => {
        setDepartment(item);
        Home();
    }

    const MenuTopSmallPress = (value) => {
        if (value == true) {
            Home();
        }
    }

    return (
        <div>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, width: isSmall ? undefined : width, height: isSmall ? undefined : height }}>
                {(bodyID == 0) || (bodyID > 0 && width > global.max_screen) ?
                    <Menu body_id={bodyID} decodeKey={decodeKey} language={lang} department={department} obvod={obvod} height={height} email={email} func={MenuPress.bind(this)} />
                    : null}
                <div style={{ ...styles.Block, width: (bodyID == 0) || (bodyID > 0 && width > global.max_screen) ? width : width }}>
                    {(bodyID == 0) || (bodyID > 0 && width > global.max_screen) ?
                        <MenuTop appToken={appToken} decodeKey={decodeKey} language={lang} department={department} height={height} label={label} name={name} surname={surname} email={email} search={false} func={MenuTopPress.bind(this)} />
                        :
                        // MALA OBRAZOVKA - prechod DOMOV
                        <MenuTopSmall func={MenuTopSmallPress.bind(this)} />
                    }
                    {/* BODY */}
                    <div style={{ ...styles.BlockCenter, height: height - 80, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>


                        {/* MAIN BODY - HOME */}
                        {bodyID == 0 ?
                            <div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 0 }}>
                                {department.id > 0 ?
                                    <div style={{ ...styles.BlockRowRaw, paddingTop: 0, height: heightQuickBar }}>

                                        {CheckPermision(permissions, global.permission_write) == true ?
                                            <Button onClick={() => MenuPress(3, 1, true)} style={{ ...styles.ButtonDashboard, margin: 10, backgroundColor: global.theme_dark_blue, border: buttonOver == 1 ? '2px solid ' + global.theme_dark_blue : '2px solid white' }} onPointerOver={() => setButtonOver(1)} onPointerLeave={() => setButtonOver(0)} >
                                                <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: '50%', backgroundColor: global.theme_blue }}>
                                                    <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_white }} icon={faEdit} />
                                                </div>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3, fontWeight: '500' }}>{lang.priestupok_new}</p>
                                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, opacity: 0.6 }}>{lang.priestupky}</p>
                                            </Button>
                                            : null}

                                        <Button onClick={() => MenuPress(3, 1, false)} style={{ ...styles.ButtonDashboard, margin: 10, backgroundColor: global.theme_dark_blue, border: buttonOver == 2 ? '2px solid ' + global.theme_dark_blue : '2px solid white' }} onPointerOver={() => setButtonOver(2)} onPointerLeave={() => setButtonOver(0)} >
                                            <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: '50%', backgroundColor: global.theme_blue }}>
                                                <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_white }} icon={faList} />
                                            </div>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3, fontWeight: '500' }}>{'Zoznam priestupkov'}</p>
                                            <p style={{ ...styles.TextXXTiny, color: global.theme_white, opacity: 0.6 }}>{lang.priestupky}</p>
                                        </Button>

                                        {CheckPermision(permissions, global.permission_write) == true ?
                                            <Button onClick={() => MenuPress(3, 12, true)} style={{ ...styles.ButtonDashboard, margin: 10, backgroundColor: global.theme_darker_green, marginLeft: 20, border: buttonOver == 3 ? '2px solid ' + global.theme_darker_green : '2px solid white' }} onPointerOver={() => setButtonOver(3)} onPointerLeave={() => setButtonOver(0)} >
                                                <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: '50%', backgroundColor: global.theme_dark_green }}>
                                                    <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_white }} icon={faEdit} />
                                                </div>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3, fontWeight: '500' }}>{'Nová udalosť'}</p>
                                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, opacity: 0.6 }}>{lang.udalosti}</p>
                                            </Button>
                                            : null}

                                        <Button onClick={() => MenuPress(3, 12, false)} style={{ ...styles.ButtonDashboard, margin: 10, backgroundColor: global.theme_darker_green, border: buttonOver == 4 ? '2px solid ' + global.theme_darker_green : '2px solid white' }} onPointerOver={() => setButtonOver(4)} onPointerLeave={() => setButtonOver(0)} >
                                            <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: '50%', backgroundColor: global.theme_dark_green }}>
                                                <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_white }} icon={faList} />
                                            </div>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3, fontWeight: '500' }}>{'Zoznam udalostí'}</p>
                                            <p style={{ ...styles.TextXXTiny, color: global.theme_white, opacity: 0.6 }}>{lang.udalosti}</p>
                                        </Button>

                                        {CheckPermision(permissions, global.permission_write) == true ?
                                            <Button onClick={() => MenuPress(2, 12, true)} style={{ ...styles.ButtonDashboard, margin: 10, backgroundColor: global.theme_dark_blue, marginLeft: 20, border: buttonOver == 3 ? '2px solid ' + global.theme_dark_blue : '2px solid white' }} onPointerOver={() => setButtonOver(3)} onPointerLeave={() => setButtonOver(0)} >
                                                <div style={{ ...styles.Block, width: 36, height: 36, borderRadius: '50%', backgroundColor: global.theme_blue }}>
                                                    <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_white }} icon={faPaperPlane} />
                                                </div>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 3, fontWeight: '500' }}>{'USEP'}</p>
                                                <p style={{ ...styles.TextXXTiny, color: global.theme_white, opacity: 0.6 }}>{'Nastavenia'}</p>
                                            </Button>
                                            : null}

                                    </div>
                                    :
                                    <div style={{ ...styles.BlockRowRaw, paddingTop: 0, height: heightQuickBar }}></div>
                                }
                                <div style={{ ...styles.Block, height: height - 80 - heightQuickBar }}>

                                    {oznamEnabled == true && showNews == true ?
                                        <Paper elevation={5} style={{ ...styles.BlockCenter, width: widthInfoDialog, backgroundColor: global.theme_white, border: '1px solid #AAAAAA' }}>
                                            <div style={{ ...styles.BlockRow, height: 50, backgroundColor: global.theme_lighter }}>
                                                <div style={{ ...styles.Block, height: 50, width: 50 }}>
                                                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 40, maxHeight: 40, objectFit: 'contain' }}></img>
                                                </div>
                                                <div style={{ ...styles.Block, height: 50, width: '80%' }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{oznamLabel}</p>
                                                </div>
                                                <div style={{ ...styles.Block, height: 50, width: 50 }}>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, paddingTop: 15, paddingBottom: 20 }}>
                                                <div style={{ ...styles.TextSmall }} dangerouslySetInnerHTML={{ __html: oznamText }} />
                                            </div>
                                        </Paper>
                                        : null}
                                </div>

                            </div>
                            : null}

                        <div style={{ ...styles.Block, width: '98%' }}>

                            {/* **************************************************************************************** */}
                            {/* AGENDA */}
                            {/* **************************************************************************************** */}
                            {bodyID == 3001 ?
                                <Priestupky decodeKey={decodeKey} actualYear={actualYear} subBody={subBody} department={department} obvod={obvod} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 3012 ?
                                <Udalosti decodeKey={decodeKey} actualYear={actualYear} subBody={subBody} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3005 ?
                                <Osoby decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 3004 ?
                                <Vozidla decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 3002 ?
                                <PokutoveBloky decodeKey={decodeKey} id={0} department={department} obvod={obvod} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 3003 ?
                                <PokutoveBloky decodeKey={decodeKey} id={1} department={department} obvod={obvod} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 3006 ?
                                <Posta decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3007 ?
                                <Trestneciny decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3008 ?
                                <PredvedeneOsoby decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3009 ?
                                <EvidenciaPsov decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3013 ?
                                <KamerovyZaznam decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3010 ?
                                <OstatneCinnosti decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3014 ?
                                <SpravnyDelikt decodeKey={decodeKey} actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 3011 ?
                                <KnihaPriestupkov
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsKnihaPriestupkov}
                                    title={lang.kniha_priestupkov}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {/* **************************************************************************************** */}
                            {/* STATISTIKY */}
                            {/* **************************************************************************************** */}
                            {bodyID == 4001 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={1}
                                    columns1={columnsDefault}
                                    field1={'meno'}
                                    header_name={lang.surname_name}
                                    columns2={columnsDefaultDetail}
                                    db1={'stats_riesitelia'}
                                    db2={'stats_riesitelia_priestupky'}
                                    tab1={lang.stats_riesitelia}
                                    tab2={lang.stats_riesitel}
                                    table_selected={true} // musi byť oznaceny riadok v prvej tabulke
                                    title={lang.stats_label_riesitelia}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4002 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.druh_priestupku}
                                    columns2={null}
                                    db1={'stats_druhy'}
                                    db2={''}
                                    title={lang.stats_label_druh}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4003 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.stats_vzn}
                                    columns2={null}
                                    db1={'stats_vzn'}
                                    db2={''}
                                    title={lang.stats_label_vzn}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4004 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={1}
                                    columns1={columnsDefault2}
                                    field1={'ulica'}
                                    header_name={lang.streets}
                                    columns2={columnsLocalities}
                                    field2={'label'}
                                    db1={'stats_miesta'}
                                    db2={'stats_lokality'}
                                    tab1={lang.stats_label_streets}
                                    tab2={lang.stats_label_localities}
                                    title={lang.stats_label_miesta}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4005 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.stats_sposob_zistenia}
                                    columns2={null}
                                    db1={'stats_sposob_zistenia'}
                                    db2={''}
                                    title={lang.stats_label_sposob_zistenia}
                                    graph={true}
                                    graph_settings={{ size: 70, show_values: true }}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4006 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.stats_sposob_riesenia}
                                    columns2={null}
                                    db1={'stats_sposob_riesenia'}
                                    db2={''}
                                    title={lang.stats_label_sposob_riesenia}
                                    editing={true}
                                    graph={true}
                                    graph_settings={{ size: 70, show_values: true }}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4007 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.stats_mesiace}
                                    columns2={null}
                                    db1={'stats_mesiace'}
                                    db2={''}
                                    title={lang.stats_label_mesiace}
                                    graph={true}
                                    graph_settings={{ size: 70, show_values: true }}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4008 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsDefault2}
                                    field1={'label'}
                                    header_name={lang.stats_hodiny}
                                    columns2={null}
                                    db1={'stats_hodiny'}
                                    db2={''}
                                    graph={true}
                                    graph_settings={{ size: 44, show_values: false }}
                                    title={lang.stats_label_hodiny}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4009 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={1}
                                    columns1={columnsPosta}
                                    field1={'label'}
                                    header_name={lang.posta_odosielatel}
                                    columns2={columnsPostaPopis}
                                    field2={'label'}
                                    db1={'stats_posta'}
                                    db2={'stats_posta_details'}
                                    tab1={lang.posta_tab1}
                                    tab2={lang.posta_tab2}
                                    title={lang.stats_label_posta}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4012 ?
                                <StatsDialog
                                    decodeKey={decodeKey}
                                    typ={1}
                                    columns1={columnsUdalosti}
                                    field1={'label'}
                                    header_name={lang.udalost}
                                    columns2={columnsUdalostiUlice}
                                    field2={'ulica'}
                                    db1={'stats_udalosti'}
                                    db2={'stats_udalosti_ulice'}
                                    tab1={lang.udalost}
                                    tab2={lang.streets}
                                    title={lang.stats_label_udalosti}
                                    editing={true}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4010 ?
                                <SpravaCinnost
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsCinnosti}
                                    title={lang.sprava_cinnost}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}
                            {bodyID == 4011 ?
                                <PriestupkyExport
                                    decodeKey={decodeKey}
                                    typ={0}
                                    columns1={columnsPriestupkyExport}
                                    title={lang.sprava_cinnost}
                                    actualYear={actualYear} department={department} obvod={obvod} user={user} permissions={permissions} settings={settings} />
                                : null}


                            {/* **************************************************************************************** */}
                            {/* CISELNIKY */}
                            {/* **************************************************************************************** */}
                            {bodyID == 1001 ?
                                <Users decodeKey={decodeKey} department={department} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 1002 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={1}
                                    label={label}
                                    db_get={'ranks'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 1003 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={15}
                                    label={label}
                                    db_get={'streets'}
                                    columns={ciselnik_ulice}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 1004 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={16}
                                    label={label}
                                    db_get={'towns'}
                                    columns={ciselnik_mesta}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {bodyID == 1005 ?
                                <Countries department={department} user={user} permissions={permissions} />
                                : null}
                            {/* DOVOD PREDVEDENIA */}
                            {bodyID == 1006 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={11}
                                    label={label}
                                    db_get={'invite_reasons'}
                                    columns={ciselnik_dovod_predvedenia}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* MIESTO PREDVEDENIA */}
                            {bodyID == 1007 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={6}
                                    label={label}
                                    db_get={'invite_places'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* TRESTNY CIN */}
                            {bodyID == 1008 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={13}
                                    label={label}
                                    db_get={'crimes'}
                                    columns={ciselnik_trestne_ciny}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* DRUH VOZIDLA */}
                            {bodyID == 1009 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={5}
                                    label={label}
                                    db_get={'cars'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* PREUKAZ TOTOZNOSTI */}
                            {bodyID == 1010 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={2}
                                    label={label}
                                    db_get={'id_type'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* SPOSOB ZISTENIA */}
                            {bodyID == 1011 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={3}
                                    label={label}
                                    db_get={'identify'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* STAV RIESENIA */}
                            {bodyID == 1012 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={4}
                                    label={label}
                                    db_get={'status'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* SPOSOB RIESENIA */}
                            {bodyID == 1013 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={10}
                                    label={label}
                                    db_get={'solve'}
                                    columns={ciselnik_sposob_riesenia}
                                    department={department} user={user} permissions={permissions} />
                                : null}

                            {/* LOKALITY */}
                            {bodyID == 1021 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={17}
                                    label={label}
                                    db_get={'places'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}

                            {/* DRUH PRIESTUPKU */}
                            {bodyID == 1015 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={8}
                                    label={label}
                                    db_get={'offense'}
                                    columns={ciselnik_druh_priestupku}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* VZN */}
                            {bodyID == 1016 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={9}
                                    label={label}
                                    db_get={'vzn'}
                                    columns={ciselnik_vzn}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* OBVODY */}
                            {bodyID == 1017 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={14}
                                    label={label}
                                    db_get={'obvody'}
                                    columns={ciselnik_obvody}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* POSTA - ODOSIELATEL */}
                            {bodyID == 1018 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={7}
                                    label={label}
                                    db_get={'odosielatel'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* OSTATNE */}
                            {bodyID == 1019 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={12}
                                    label={label}
                                    db_get={'ostatne'}
                                    columns={ciselnik_ostatne}
                                    department={department} user={user} permissions={permissions} />
                                : null}
                            {/* Udalosti */}
                            {bodyID == 1020 ?
                                <Ciselnik
                                    decodeKey={decodeKey}
                                    typ={0}
                                    label={label}
                                    db_get={'ciselnik_udalosti'}
                                    columns={ciselnik_default}
                                    department={department} user={user} permissions={permissions} />
                                : null}

                            {/* ------------------------------------------------------------------------------------------
                                NASTAVENIA 
                             ------------------------------------------------------------------------------------------ */}
                            {/* LOGS */}
                            {bodyID == 2010 ?
                                <LogsLogin decodeKey={decodeKey} department={department} user={user} permissions={permissions} />
                                : null}

                            {/* NASTAVENIA */}
                            {bodyID == 2011 ?
                                <Settings decodeKey={decodeKey} department={department} obvod={obvod} user={user} permissions={permissions} func={SettingsResult.bind(this)} />
                                : null}

                            {/* VYBAVENIE POLICIE */}
                            {bodyID == 2013 ?
                                <VybaveniePolicie decodeKey={decodeKey} department={department} obvod={obvod} user={user} permissions={permissions} func={SettingsResult.bind(this)} />
                                : null}

                            {/* USEP */}
                            {bodyID == 2012 ?
                                <Usep decodeKey={decodeKey} department={department} obvod={obvod} user={user} permissions={permissions} func={SettingsResult.bind(this)} />
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, height: 25 }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <a href='https://datadream.sk' target={'_blank'} style={{ margin: 0, color: global.theme_dark_gray, fontSize: global.font_tiny, textDecoration: 'none', marginLeft: 10 }}>© {lang.copyright} {year} {global.version} - {global.creator}</a>
                        </div>
                        <div style={{ ...styles.BlockRight }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginRight: 20 }}></p>
                        </div>
                    </div>
                </div>

                {showUser == true ?
                    <UserData decodeKey={decodeKey} language={lang} user={user} func={UserDataPress.bind(this)} />
                    : null}

            </div >

            {
                showObvod == true ?
                    <ChooseObvod decodeKey={decodeKey} department={department} func={ChooseObvodResult.bind(this)} />
                    : null
            }


            {
                isBusy ?
                    <Loading />
                    : null
            }
        </div >
    )
};

