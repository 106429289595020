/*
*
* =================================================================================================================
* MOBILE - Hlavná obrazovka
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, LoadLogin, LoadUser, LoadLanguage, SaveUser, SaveLogin, SaveDepartment, SaveObvod, ResetLogin, DialogMobileAsk } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronLeft, faChevronRight, faHome, faList, faPlus, faRightFromBracket, faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { ForgotPasswordDialog } from './forgot_password.js';
import CircularProgress from '@mui/material/CircularProgress';
import { MobilePriestupok } from './mobile_priestupok.js';
import { MobilePriestupky } from './mobile_priestupky.js';
import { MobileSearch } from './mobile_search.js';


export const Mobile = (props) => {
    const navigate = useNavigate();
    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    //APP
    const [isBusy, setBusy] = useState(true);
    const [index, setIndex] = useState(0);
    const [user, setUser] = useState([]);
    const [department, setDepartment] = useState([]);
    const [obvody, setObvody] = useState([]);
    const [obvod, setObvod] = useState([]);
    const [decodeKey, setDecodeKey] = useState('');
    const [permissions, setPermissions] = useState('0000000000');
    const [loginToken, setLoginToken] = useState('');
    const [year, setYear] = useState('2022');
    const [showLogout, setShowLogout] = useState(false);

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const headerHeight = 100;
    const footerHeight = 40;

    var lang = language;
    let params = useParams();

    const timer = useRef();
    const inputRef = useRef(null);

    /*
    const logo = require('./react/app/logo_white.png');
    const logo_color = require('./react/app/logo.png');
    */

    useEffect(() => {

        /*
        // GEOLOKACIA
        if (!navigator.geolocation) {
            console.log('nie je');
        } else {            
            navigator.geolocation.getCurrentPosition((position) => {                
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            });
        }
        */

        // LANGUAGE        
        setLanguage(LoadLanguage());

        var tmp = LoadLogin();

        if (tmp.token == '' || tmp.logged == false) {
            // token neexistuje -> návrat na login
            GotoLogin();
        } else {
            setLoginToken(tmp.token);
            db_get(params.apptoken, tmp.token);
        }

        var now = new Date();
        var year_ = now.getFullYear();
        setYear(year_);

        setBusy(false);

        return () => {
        };

    }, []);




    // *****************************************************************************
    // KONTROLA PRIHLASOVACIEHO TOKENU -> ZISKANIE UDAJOV
    // *****************************************************************************
    const db_get = async (app_token, login_token) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'mobile_token', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    app_token: app_token,
                    login_token: login_token
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {

                if (json.verifed == false) {
                    // token nieje v DB -> návrat na loign
                    GotoLogin();
                }

                // VSETKO OK
                setUser(json.user);
                setDepartment(json.department);
                setObvody(json.obvody);
                setDecodeKey(json.decode_key);
                setPermissions(json.user.permissions);
                if (json.obvody.length == 1) {
                    setObvod(json.obvody[0]);
                }
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    // *****************************************************************************
    // ODHLASENIE
    // *****************************************************************************
    const db_logout = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'logout_mobile', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: loginToken
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setShowLogout(false);
                ResetLogin();
                GotoLogin();
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const GotoLogin = () => {
        navigate('/mobile/login/' + params.apptoken);
    }

    const Logout = (value) => {
        if (value == false) {
            setShowLogout(false);
        } else {
            db_logout();
        }
    }

    const Home = () => {
        setIndex(0);
    }


    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_gary, height: height, width: width }}>
            {index == 0 ?
                <div style={{ ...styles.Block }}>
                    <Paper elevation={5} style={{ ...styles.Block, height: headerHeight, backgroundColor: global.theme_darker, borderRadius: 0 }}>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 40 }}>
                            <img src={global.images + 'app/logo_square.png'} style={{ maxWidth: 32 }}></img>
                            <p style={{ ...styles.TextXXLarge, color: global.theme_white, marginLeft: 10, fontWeight: '300' }}><b>EVID</b> M</p>
                        </div>
                    </Paper>
                    <div style={{ ...styles.BlockCenter, height: height - headerHeight - footerHeight }}>
                        <div style={{ ...styles.Block, height: 50 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: 'bold' }}>{department.name} {department.town}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_black, marginTop: 3 }}>{user.name} {user.surname}</p>
                        </div>
                        <div style={{ ...styles.Block, height: height - headerHeight - footerHeight - 50 }}>

                            <Button onClick={() => setIndex(1)} style={{ ...styles.ButtonMobile }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faPlus} />
                                {lang.priestupok_new}
                            </Button>

                            <Button onClick={() => setIndex(2)} style={{ ...styles.ButtonMobile, marginTop: 15 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faList} />
                                {lang.priestupky}
                            </Button>

                            <Button onClick={() => setIndex(3)} style={{ ...styles.ButtonMobile, marginTop: 15 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faSearch} />
                                {lang.search}
                            </Button>

                            <Button onClick={() => setShowLogout(true)} style={{ ...styles.ButtonMobile, backgroundColor: global.theme_dark_red, marginTop: 60 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faRightFromBracket} />
                                {lang.logout}
                            </Button>

                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: footerHeight }}>
                        <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray, marginTop: 3 }}>© {lang.copyright} {year} {global.version_mobile} - {global.creator}</p>
                    </div>

                </div>
                : null}

            {/* PRIESTUPOK */}
            {index == 1 ?
                <MobilePriestupok preview={false} decodeKey={decodeKey} obvod={obvod} lang={language} department={department} editing={false} user={user} permissions={permissions} height={height} item={[]} func={Home.bind(this)} />
                : null}

            {/* ZOZNAM PRIESTUPKOV */}
            {index == 2 ?
                <MobilePriestupky decodeKey={decodeKey} obvod={obvod} lang={language} department={department} user={user} permissions={permissions} height={height} func={Home.bind(this)} />
                : null}

            {/* HĽADAŤ */}
            {index == 3 ?
                <MobileSearch decodeKey={decodeKey} obvod={obvod} lang={language} department={department} user={user} permissions={permissions} height={height} func={Home.bind(this)} />
                : null}

            {/* ODHLASIT SA ? */}
            {showLogout == true ?
                <DialogMobileAsk label={lang.logout} text={lang.logout_question} func={Logout.bind(this)} />
                : null}

        </div >
    )
};

