import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button, TextField, Alert, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetPSC, Init, SearchTown, ValidateEmail } from './items.js';
import { styles } from './styles.js';
import ReCAPTCHA from 'react-google-recaptcha'


export const Register = () => {
    const navigate = useNavigate();
    const [showOrder, setShowOrder] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [isChecking, setChecking] = useState(false);
    const [index, setIndex] = useState(0);

    const [department, setDepartment] = useState('');
    const [departmentID, setDepartmentID] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPSC] = useState('');
    const [town, setTown] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mobil, setMobil] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [departmentErr, setDepartmentErr] = useState(false);
    const [streetErr, setStreetErr] = useState(false);
    const [streetNumberErr, setStreetNumberErr] = useState(false);
    const [pscErr, setPSCErr] = useState(false);
    const [townErr, setTownErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [mobilErr, setMobilErr] = useState(false);
    const [usernameErr, setUsernameErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');

    const [error, setError] = useState('');
    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;

    const logo = require('./react/app/logo_circle.png');


    useEffect(() => {

        return () => {
        };

    }, []);


    const db_email_check = async () => {
        setChecking(true);

        try {
            const response = await fetch(
                global.db_url + 'department_register_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    username: username,                    
                })
            })

            const json = await response.json();
            setChecking(false);

            if (json.ok > 0) {
                if (index == 1) {
                    // kontrola emailovej adresy
                    if (json.email == true) {
                        setIndex(2);
                    } else {
                        setEmailErr(true);
                        setError(lang.password_exists);
                    }
                }
                if (index == 2) {
                    // kontrola USERNAME
                    if (json.username == true) {
                        setIndex(3);
                    } else {
                        setUsernameErr(true);
                        setError(lang.username_exists);
                    }
                }

            }
        } catch (error) {
            Debug(error);
            setChecking(false);
        }
    }


    const db_register = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'department_register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department: department,
                    street: street,
                    street_number: streetNumber,
                    psc: psc,
                    town: town,
                    email: email,
                    phone: phone,
                    mobil: mobil,
                    username: username,
                    password: password,                    
                })
            })

            const json = await response.json();
            Debug(json);
            setBusy(false);

            if (json.ok > 0) {
                setDepartmentID(json.department_id);
                setIndex(4);
            } else {
                setError(lang.register_error)
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Register = () => {
        if (recaptcha == '') {
            setError(lang.captcha_error);
        } else {
            setError('');
            db_register();
        }
    }

    const Check = (id) => {
        var err = false;
        var err_text = '';
        setError('');
        setDepartmentErr(false);
        setStreetErr(false);
        setStreetNumberErr(false);
        setPSCErr(false);
        setTownErr(false);
        setEmailErr(false);
        setMobilErr(false);
        setPhoneErr(false);
        setUsernameErr(false);
        setPasswordErr(false);

        if (id == 0) {
            if (department.trim() == '') {
                setDepartmentErr(true);
                err = true;
            }
            if (street.trim() == '') {
                setStreetErr(true);
                err = true;
            }
            if (streetNumber.trim() == '') {
                setStreetNumberErr(true);
                err = true;
            }
            if (psc.trim() == '') {
                setPSCErr(true);
                err = true;
            } else {
                var _psc = GetPSC(psc);
                setPSC(_psc);
            }
            if (town.trim() == '') {
                setTownErr(true);
                err = true;
            }
            if (err == true) {
                setError(lang.required);
            }
        }

        if (id == 1) {
            if (email.trim() == '') {
                setEmailErr(true);
                err = true;
                err_text = lang.required;
            } else if (ValidateEmail(email) == false) {
                setEmailErr(true);
                err = true;
                err_text = lang.email_error;
            }
            if (phone.trim() == '') {
                setPhoneErr(true);
                err = true;
                err_text = lang.required;
            }
            if (mobil.trim() == '') {
                setMobilErr(true);
                err = true;
                err_text = lang.required;
            }
            if (err == true) {
                setError(err_text);
            } else {
                if (username.trim() == '') {
                    setUsername(email);
                }
                // kontrola či už nie je email zaregistrovaný
                db_email_check();
            }

        }

        if (id == 2) {
            if (ValidateEmail(username) == false) {
                setUsernameErr(true);
                err = true;
                setError(lang.username_error);
            }
            if (password.trim() == '') {
                setPasswordErr(true);
                err = true;
                setError(lang.required);
            } else {
                if (password.trim().length < 8) {
                    setPasswordErr(true);
                    err = true;
                    setError(lang.password_min_len);
                }
            }

            if (err == false) {
                db_email_check();
            }
        }

        return err;
    }

    const Next = (id) => {
        if (id == 0) {
            if (Check(0) == false) {
                setIndex(1);
            }
        }
        if (id == 1) {
            Check(1);
        }
        if (id == 2) {
            Check(2);
        }
    }

    const Back = (id) => {
        setError('');
        setIndex(id);
    }

    const ToLogin = () => {
        navigate('/');
    }

    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
            setError('');
        }
    }

    const SearchTownResult = (_town, _psc, _ulica) => {
        if (_town != undefined) {
            if (psc == '') {
                setPSC(_psc);
            }
            setTown(_town);
        }
    }

    return (
        <div style={{ ...styles.Block, justifyContent: 'center', backgroundColor: global.theme_back, height: height }}>

            <Paper elevation={3} style={{ ...styles.Block, justifyContent: 'flex-start', backgroundColor: global.theme_white, maxWidth: 600, borderRadius: global.radius }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_dark, paddingBottom: 20, marginBottom: 10 }}>
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <img src={logo} height={100} />
                    </div>
                    <p style={{ ...styles.TextXXLarge, color: global.theme_white }}>{lang.register}</p>
                </div>
                {index == 0 ?
                    <div style={{ width: '100%' }}>
                        <p>{lang.register_data}</p>

                        {/* ODDELENIE */}
                        <div style={{ padding: 10 }}>
                            <TextField size={'small'} error={departmentErr} style={{ width: '100%' }} label={lang.department_name} variant="outlined" value={department} onInput={e => setDepartment(e.target.value)} />
                        </div>

                        {/* ULICA, CISLO */}
                        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                            <TextField size={'small'} error={streetErr} style={{ width: '70%' }} label={lang.street} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                            <TextField size={'small'} error={streetNumberErr} style={{ width: '30%', marginLeft: 10 }} label={lang.street_number} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                        </div>

                        {/* PSC, MESTO */}
                        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                            <TextField size={'small'} error={pscErr} style={{ width: '30%' }} label={lang.psc} variant="outlined" value={psc} onInput={e => setPSC(e.target.value)} />
                            {/*
                            <TextField error={townErr} style={{ width: '70%', marginLeft: 10 }} label={lang.town} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                            */}
                            <div style={{ width: '70%', marginLeft: 10 }}>
                                <SearchTown street={street} town={town} func={SearchTownResult.bind(this)} />
                            </div>
                        </div>

                        {/* ERROR */}
                        <div style={{ ...styles.Block, height: 50 }}>
                            {error != '' ?
                                <Alert severity="error">{error}</Alert>
                                : null}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', padding: 10 }}>
                            </div>

                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', padding: 10 }}>
                                <Button onClick={() => Next(0)} style={{ ...styles.ButtonNext }} variant="contained">{lang.continue}</Button>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 1 ?
                    <div style={{ width: '100%' }}>
                        <p>{lang.register_contact}</p>

                        {/* EMAIL */}
                        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                            <TextField size={'small'} error={emailErr} style={{ width: '100%' }} label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                        </div>

                        {/* TELEFON, MOBIL */}
                        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                            <TextField size={'small'} error={phoneErr} style={{ width: '50%' }} label={lang.phone} variant="outlined" value={phone} onInput={e => setPhone(e.target.value)} />
                            <TextField size={'small'} error={mobilErr} style={{ width: '50%', marginLeft: 10 }} label={lang.mobil} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                        </div>


                        {/* ERROR */}
                        <div style={{ ...styles.Block, height: 50 }}>
                            {error != '' ?
                                <Alert severity="error">{error}</Alert>
                                : null}
                        </div>

                        {/* KONTROLA EMAILU */}
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10 }}>
                            {isChecking ?
                                <p>{lang.email_checking}</p>
                                : null}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', padding: 10 }}>
                                <Button onClick={() => Back(0)} style={{ ...styles.ButtonBack }} variant="contained">{lang.back}</Button>
                            </div>

                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', padding: 10 }}>
                                <Button onClick={() => Next(1)} style={{ ...styles.ButtonNext }} variant="contained">{lang.continue}</Button>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 2 ?
                    <div style={{ width: '100%' }}>
                        <p>{lang.register_login}</p>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {/* PRIHLASOVACIE MENO */}
                            <div style={{ width: '60%', padding: 10 }}>
                                <TextField error={usernameErr} style={{ width: '100%' }} label={lang.loginname} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                            </div>

                            {/* HESLO */}
                            <div style={{ width: '60%', padding: 10 }}>
                                <TextField error={passwordErr} style={{ width: '100%' }} label={lang.password} variant="outlined" type='password' value={password} onInput={e => setPassword(e.target.value)} />
                            </div>
                        </div>

                        {/* ERROR */}
                        <div style={{ ...styles.Block, height: 50 }}>
                            {error != '' ?
                                <Alert severity="error">{error}</Alert>
                                : null}
                        </div>

                        {/* KONTROLA USERNAME */}
                        <div style={{ ...styles.Block, height: 20 }}>
                            {isChecking ?
                                <p style={{ ...styles.TextSmall }}>{lang.username_checking}</p>
                                : null}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', padding: 10 }}>
                                <Button onClick={() => Back(1)} style={{ ...styles.ButtonBack }} variant="contained">{lang.back}</Button>
                            </div>

                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', padding: 10 }}>
                                <Button onClick={() => Next(2)} style={{ ...styles.ButtonNext }} variant="contained">{lang.continue}</Button>
                            </div>
                        </div>
                    </div>
                    : null}

                {index == 3 ?
                    <div style={{ width: '100%' }}>

                        <p style={{ ...styles.TextNormal }}>{lang.register_sumar}</p>

                        <p style={{ margin: 0, marginTop: 10, fontSize: global.font_normal, color: global.theme_black }}>{lang.register_data}:</p>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_dark_gray }}>{department}</p>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_dark_gray }}>{street} {streetNumber}</p>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_dark_gray }}>{psc} {town}</p>

                        <p style={{ margin: 0, marginTop: 20, fontSize: global.font_normal, color: global.theme_black }}>{lang.register_contact}:</p>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_dark_gray }}>{lang.email.toLowerCase()}: {email}</p>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_dark_gray }}>{lang.mobil.toLocaleLowerCase()}: {mobil}</p>
                        <p style={{ margin: 0, marginBottom: 20, fontSize: global.font_small, color: global.theme_dark_gray }}>{lang.phone.toLowerCase()}: {phone}</p>

                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <ReCAPTCHA
                                sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                onChange={onChangeCaptcha}
                            />
                        </div>

                        {/* ERROR */}
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10 }}>
                            {error != '' ?
                                <Alert severity="error">{error}</Alert>
                                : null}
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start', padding: 10 }}>
                                <Button onClick={() => Back(2)} style={{ ...styles.ButtonBack }} variant="contained">{lang.back}</Button>
                            </div>

                            <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end', padding: 10 }}>
                                <Button onClick={() => Register()} style={{ ...styles.ButtonNext }} variant="contained">{lang.register_button}</Button>
                            </div>
                        </div>
                    </div>
                    : null}
                {index == 4 ?
                    <div style={{ width: '100%' }}>
                        <p style={{ fontSize: global.font_large }}>{lang.register_ok}</p>
                        <p style={{ fontSize: global.font_small, marginTop: 10, color: global.theme_dark_gray }}>ID: {departmentID}</p>
                        {/*
                        <div style={{ padding: 20 }}>
                            <Button onClick={() => ToLogin()} style={{ backgroundColor: global.theme_button, width: '50%' }} variant="contained">{lang.register_to_login}</Button>
                        </div>
                        */}
                    </div>
                    : null}

            </Paper>
        </div >
    )
}