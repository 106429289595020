import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars, faBook, faWindowMaximize, faWindowMinimize, faCar } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Accordion, Drawer, Slide } from '@mui/material';
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import { Pivot as Hamburger } from 'hamburger-react'
import { AutocompleteText, GetPrice, GetSume, Loading } from './items.js';
import { useHotkeys } from 'react-hotkeys-hook';
import { DataGrid, skSK, GridActionsCellItem } from '@mui/x-data-grid';


export const PriestupokPreview = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useHotkeys('Escape', (key) => handleKey(key), true)

    useEffect(() => {

        return () => {
        };

    }, [props.isBusy]);

    const handleKey = (key) => {
        if (key.key == 'Escape') {
            Close();
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const DrawData = (props) => {
        return (
            <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
                <div style={{ ...styles.BlockLeft, width: '98%', paddingTop: 10, paddingBottom: 10 }}>
                    {/* LABEL, CLOSE */}
                    {props.close == true ?
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft }}>

                            </div>
                            <div style={{ ...styles.BlockRight }}>
                                <IconButton onClick={() => Close()} style={{ ...styles.Block, height: 20, width: 20 }}>
                                    <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                                </IconButton>
                            </div>
                        </div>
                        : null}

                    {props.isBusy == false ?
                        <div style={{ ...styles.BlockLeft, height: 300 }}>

                            {/* PACHATEL */}
                            {props.item.priezvisko != '' ?
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{props.item.priezvisko != '' ? lang.priestupok_pachatel : lang.neznamy}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 15, paddingBottom: 15 }}>
                                        <TextField value={props.item.meno + ' ' + props.item.priezvisko} label={props.item.pravnicka_osoba == true ? lang.firma : lang.name_surname} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                                        <TextField value={props.item.rodne_cislo} label={props.item.pravnicka_osoba == true ? lang.ico : lang.rodne_cislo} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                        {props.item.pravnicka_osoba == false ?
                                            <div style={{ ...styles.BlockRow, width: '70%' }}>
                                                <TextField value={props.item.narodeny} label={lang.birth_date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                                <TextField value={props.item.vek} label={lang.age} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 60 }} />
                                                <TextField value={lang.sex_types[props.item.pohlavie]} label={lang.sex} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 130 }} />
                                                <TextField value={props.item.narodnost} label={lang.prislusnost_short} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 240 }} />
                                                <TextField value={props.item.ulica + ' ' + props.item.ulica_cislo + ', ' + props.item.mesto} label={lang.address} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                : <div style={{ height: 15 }}></div>
                            }

                            {/* PRIESTUPOK */}
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.priestupok}</p>
                                </div>
                                <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 15, paddingBottom: 15 }}>
                                    <TextField value={props.item.datum} label={lang.blok_priestupok_date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                    <TextField value={props.item.priestupok_id == 1 ? props.item.vzn : props.item.priestupok} label={lang.priestupok} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 400 }} />
                                    <TextField value={props.item.sposob_zistenia} label={lang.sposob_zistenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 180 }} />
                                    <TextField value={props.item.miesto_priestupku + ' ' + props.item.miesto_priestupku_cislo} label={lang.miesto_priestupku} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 500 }} />
                                    <TextField value={props.item.spis == true ? 'Áno' : 'Nie'} label={lang.spis} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 110 }} />
                                </div>
                            </div>

                            {/* RIESENIE */}
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.riesitel}</p>
                                </div>
                                <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 15, paddingBottom: 15 }}>
                                    <TextField value={props.item.riesitel_1} label={lang.riesitel + ' 1'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 220 }} />
                                    <TextField value={props.item.riesitel_2} label={lang.riesitel + ' 2'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 220 }} />
                                    <TextField value={props.item.stav_riesenia} label={lang.stav_riesenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                    <TextField value={props.item.sposob_riesenia} label={lang.sposob_riesenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                                    <TextField value={GetPrice(props.item.hotovost_suma) + ' €'} label={lang.hotovost} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 100 }} />
                                    <TextField value={GetPrice(props.item.sek_sum) + ' €'} label={lang.sek} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 100 }} />
                                    <TextField value={GetPrice(props.item.pokuta) + ' €'} label={lang.pokuta} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 100 }} />
                                    <TextField value={props.item.obmedzenie_slobody == true ? 'Áno' : 'Nie'} label={lang.obmedzenie_slobody_} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                </div>
                            </div>


                            {/* AUTO */}
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{props.item.spz != '' ? lang.auto : lang.bez_auta}</p>
                                </div>
                                {props.item.spz != '' ?
                                    <div style={{ ...styles.BlockRow, width: '90%', paddingTop: 15, paddingBottom: 15 }}>
                                        <TextField value={props.item.spz} label={lang.auto_spz} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                        <TextField value={props.item.auto_typ} label={lang.auto_typ} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                        <TextField value={props.item.auto_druh} label={lang.auto_druh} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 290 }} />
                                        <TextField value={props.item.auto_farba} label={lang.auto_farba} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 200 }} />
                                    </div>

                                    : null}
                            </div>

                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {props.item.created_meno} ({props.item.created_datum} {props.item.created_cas} hod.)</p>
                                </div>
                                {props.item.updated_meno != '' ?
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {props.item.updated_meno} ({props.item.updated_datum} {props.item.updated_cas} hod.)</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        : <div style={{ ...styles.Block, height: 300 }}>
                            <CircularProgress style={{ color: global.theme_light }} size={50} />
                        </div>}
                </div>
            </div>
        )
    }
    return (
        props.dialog == true ?
            <Dialog open={true} maxWidth={'xl'}>
                <div style={{ ...styles.Block, width: 1400, height: 400, backgroundColor: global.theme_back }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextLarge, marginLeft: 10, fontWeight: 'bold' }}>Priestupok<span style={{ marginLeft: 20, fontWeight: '400' }}>číslo: {props.item.jednacie_cislo}</span></p>
                        </div>
                        <div style={{ ...styles.Block, width: 50 }}>
                            <IconButton onClick={() => Close()} style={{ ...styles.Block, height: 20, width: 20 }}>
                                <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>

                    <DrawData close={false} item={props.item} isBusy={props.isBusy} />
                </div>
            </Dialog>
            :
            <Paper elevation={5} style={{ ...styles.Block }}>
                <DrawData close={true} item={props.item} isBusy={props.isBusy} />
            </Paper>
    );
}