// SPRAVA O CINNOSTI MESTSKEJ POLICIE

import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, StatsSearch, GetTodayYear, GetSearchText, DialogEnterNote, DialogInfo, DialogSelectRows, CheckPermision } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faFilter, faListDots, faPrint, faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenuContainer,
    GridToolbarContainer, GridToolbarExport
} from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { columnsPosta } from './statistic_columns.js';

export const SpravaCinnost = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [column1, setColumn1] = useState([]);

    const [index, setIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);

    const search_default = { datum: props.actualYear, default: true };
    const [search, setSearch] = useState(search_default);
    const [searchText, setSearchText] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [selectionModel, setSelectionModel] = useState();

    const [showExport, setShowExport] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showSelect, setShowSelect] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);



    var language = 0;
    var lang = sk;

    let params = useParams();

    useEffect(() => {

        setColumn1(props.columns1);

        // FILTER - LEN AKTUALNY ROK;

        setSearch(search_default);
        setSearchText(GetSearchText(search_default));

        db_get(search_default);

        return () => {
        };

    }, []);

    const db_get = async (data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'sprava_o_cinnosti', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    filter: data,
                    data: lang.sprava_o_cinnosti,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.items);
                var tmp = json.items;

                if (data.fields != undefined) {
                    tmp = tmp.filter(x => data.fields.includes(x.id));
                }

                setItemsFiltered(tmp);

                setIndex(0);
                setSelectionModel(0); // DESELECT LINE


            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_export = async (note, pdf, xls) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'cinnost_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    title: props.title,
                    grid_columns: column1,
                    grid_data: itemsFiltered,
                    token: params.apptoken,
                    note: note,
                    user: props.user,
                    filter: searchText.trim(),
                    pdf: pdf,
                    xls: xls,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
Debug(json);
            if (json.ok > 0) {

            } else {
                setShowInfo(true);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemSelected(data);
        setIndex(id);
    }

    const ShowSearch = () => {
        setShowSearch(true);
    }

    const StatsSearchResult = (typ, data) => {

        setShowSearch(false);
        if (typ == 1) {
            setSearch(data);
            setSearchText(GetSearchText(data));
            db_get(data);
        }
    }


    const RestoreSearch = () => {
        setSearch(search_default);
        setSearchText(GetSearchText(search_default));
        db_get(search_default);
    }


    const DialogEnterNoteResult = (typ, note, pdf, xls) => {
        setShowExport(false);
        if (typ == true) {
            db_export(note, pdf, xls);
        }
    }

    const DialogInfoResult = () => {
        setShowInfo(false);
    }


    const ShowSelect = () => {
        setShowSelect(true);
    }

    const DialogSelectRowsResult = (typ, data) => {
        setShowSelect(false);
        if (typ == true) {
            if (data.length > 0) {
                search.fields = data;
                search.default = false;
            } else {
                if (search.fields != undefined) {
                    delete search.fields;
                }
            }
            setSearchText(GetSearchText(search));
            db_get(search);
        }
    }

    const Export = (typ) => {
        setShowExport(true);
    }

    const CellSave = (params) => {
        // ZMENA BUNKY
        var result = items.filter(x => x.id == index);
        if (result.length == 1 && (CheckPermision(props.permissions, global.permission_system_admin) == true || parseInt(result[0].department_id) != 0)) {
            var data = result[0];
            data[params.field] = params.value;
        }
    }

    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>


            <div style={{ ...styles.Block, width: '100%' }}>
                <div style={{ ...styles.Block, width: '100%' }}>

                    {/* FILTER */}
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                        <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.filtered}:</p>
                        {search.default == true ?
                            <Chip
                                label={searchText}
                                style={{ marginLeft: 10, marginTop: 10 }}
                            />
                            :
                            <Chip
                                label={searchText}
                                onDelete={() => RestoreSearch()}
                                style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                            />
                        }
                    </div>


                    <Box sx={{
                        height: 635,
                        width: 1,
                        '& .super-app-theme--header': {
                            backgroundColor: global.theme_table_header_color,
                        },
                        '& .super-app-theme--normal': {
                            backgroundColor: global.theme_white,
                            color: '#000000',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--red': {
                            backgroundColor: global.theme_white,
                            fontWeight: 'normal',
                            borderTop: '2px solid black',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--blue': {
                            backgroundColor: global.theme_white,
                            color: global.theme_blue,
                            fontWeight: 'normal',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                    }}>
                        <DataGrid
                            sx={{
                                fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color,
                                // HEADER TEXT WRAP
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    textOverflow: "clip",
                                    whiteSpace: "break-spaces",
                                    textAlign: 'center',
                                    fontSize: global.font_table,
                                    fontWeight: 'bold',
                                    lineHeight: 1.1
                                }
                            }}
                            headerHeight={70}

                            rows={itemsFiltered}
                            columns={column1}


                            // vlastná menu po kliknuti na header menu
                            components={{
                                //ColumnMenu: CustomColumnMenuComponent,
                                //Toolbar: CustomToolbar
                            }}
                            disableColumnMenu={true}
                            rowHeight={30}
                            onRowClick={(params) => { ChangeIndex(params.id) }}
                            //onCellKeyDown={(params,event) => { console.log(event) }}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => (params.row.cislo_ods == 'I. (1)' || params.row.cislo_ods == 'II. (1)' || params.row.cislo_ods == 'III. (1)' || params.row.cislo_ods == 'IV. (1)' || params.row.cislo_ods == 'V. (1)' ? `super-app-theme--red` : `super-app-theme--normal`)}
                            hideFooterRowCount={true}
                            hideFooterSelectedRowCount={true}
                            onCellEditCommit={(params) => { CellSave(params) }}
                        />
                    </Box>

                </div>

                <div style={{ ...styles.BlockRow, paddingTop: 30, paddingBottom: 10 }}>
                    <div style={{ ...styles.BlockRow, width: '70%' }}>

                        {CheckPermision(props.permissions, global.permission_read) == true ?
                            <div style={{ ...styles.BlockLeft, width: 160 }}>
                                <Button onClick={() => ShowSearch()} style={{ ...styles.ButtonAction }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFilter} />
                                    <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.filter}</p>
                                </Button>
                            </div>
                            : null}


                        {props.editing == true && CheckPermision(props.permissions, global.permission_read) == true ?
                            <div style={{ ...styles.BlockLeft, width: 160 }}>
                                <Button onClick={() => ShowSelect()} style={{ ...styles.ButtonAction }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faListDots} />
                                    <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.select}</p>
                                </Button>
                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.BlockRight, width: '30%' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {CheckPermision(props.permissions, global.permission_print) == true ?
                                <Button onClick={() => Export(0)} style={{ ...styles.ButtonEdit }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFileExport} />
                                    <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.exporting}</p>
                                </Button>
                                : null}
                            <div style={{ width: 50 }}>
                                <IconButton onClick={() => db_get(search)}>
                                    <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* VYHLADAVANIE */}
            {showSearch == true ?
                <StatsSearch typ={0} data={search} department={props.department} lang={lang} func={StatsSearchResult.bind(this)} />
                : null}

            {/* EXPORT */}
            {showExport == true ?
                <DialogEnterNote disable_choose={true} func={DialogEnterNoteResult.bind(this)} />
                : null}

            {/* INFO */}
            {showInfo == true ?
                <DialogInfo label={lang.error} text={lang.stats_export_error} error={true} func={DialogInfoResult.bind(this)} />
                : null}

            {/* SELECT - vyber riadkov štatistiky */}
            {showSelect == true ?
                <DialogSelectRows items={items} column={props.field} func={DialogSelectRowsResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div >
    )
};

