import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, GetTodayTime, IsEmpty, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faMinus, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { width } from '@mui/system';


export const KamerovyZaznamNew = (props) => {

    /*
            USAGE:
            <EvidenciaPsovNew editing={true} department={department} obvod={obvod} user={user} func={EvidenciaPsovNewResult.bind(this)} />
 
            const EvidenciaPsovNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');
    const [tab, setTab] = useState(0);

    // --------------------------------------------------------
    // POLOZKY DIALOGU
    const [obvod, setObvod] = useState('');
    const [obvodID, setObvodID] = useState('');
    const [cislo, setCislo] = useState('');
    const [datumPoziadavky, setDatumPoziadavky] = useState('');
    const [datumOd, setDatumOd] = useState('');
    const [datumDo, setDatumDo] = useState('');
    const [casOd, setCasOd] = useState('07:00');
    const [casDo, setCasDo] = useState('08:00');
    const [ziadatel, setZiadatel] = useState('');
    const [ziadatelErr, setZiadatelErr] = useState(false);
    const [poznamka, setPoznamka] = useState('');
    const [kamera, setKamera] = useState('');
    const [vystup, setVystup] = useState(0);
    const [riesitel, setRiesitel] = useState('');
    const [riesitelErr, setRiesitelErr] = useState(false);
    const [prevzal, setPrevzal] = useState('');
    const [prevzalErr, setPrevzalErr] = useState(false);
    const [prevzalDatum, setPrevzalDatum] = useState('');

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const offset = 15;

    useEffect(() => {

        if (props.editing == true) {

            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();

        } else {

            // NOVY ZAZNAM
            setDatumPoziadavky(Today());
            setLabel(lang.new_record);
            setDatumOd(Today());
            setDatumDo(Today());
            setPrevzalDatum(Today());
        }

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'kamera_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;

                setObvod(data.obvod);
                setObvodID(data.obvod_id);
                setCislo(data.cislo);
                setDatumPoziadavky(data.datum_poziadavky)
                setZiadatel(data.ziadatel);
                setDatumOd(data.datum_od);
                setDatumDo(data.datum_do);
                setCasOd(data.cas_od);
                setCasDo(data.cas_do);
                setKamera(data.kamera);
                setVystup(data.vystup);
                setRiesitel(data.riesitel);
                setPrevzal(data.prevzal);
                setPrevzalDatum(data.prevzal_datum);
                setPoznamka(data.poznamka);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'kamera_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data);
                } else {
                    Press(2, data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        setError('');

        error += IsEmpty(ziadatel) == true ? setZiadatelErr(true) ?? true : setZiadatelErr(false) ?? false;
        error += IsEmpty(riesitel) == true ? setRiesitelErr(true) ?? true : setRiesitelErr(false) ?? false;
        error += IsEmpty(prevzal) == true ? setPrevzalErr(true) ?? true : setPrevzalErr(false) ?? false;

        if (error > 0) {
            setError(lang.required);
        } else {
            var data = {
                id: props.editing == true ? props.item_id : 0,
                updated_user_id: props.user.id,
                department_id: props.department.id,
                obvod: props.editing == true ? obvod : props.obvod.kod,
                obvod_id: props.editing == true ? obvodID : props.obvod.id,
                cislo: props.editing == true ? cislo : '',
                rok: props.actualYear,
                datum_poziadavky: datumPoziadavky,
                ziadatel: ziadatel,
                datum_od: datumOd,
                datum_do: datumDo,
                cas_od: casOd,
                cas_do: casDo,
                kamera: kamera,
                vystup: vystup,
                riesitel: riesitel,
                prevzal: prevzal,
                prevzal_datum: prevzalDatum,
                poznamka: poznamka
            }
            if (props.editing == false) {
                data.created_user_id = props.user.id;
            }

            db_update(data);

        }
    }

    const RiesitelResult = (value, key) => {
        if (value != null) {
            setRiesitel(value.label);
        }
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 650, paddingTop: 0, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 0, paddingBottom: 25 }}>
                    {/* DATUM - CAS */}
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        <div style={{ width: 200, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_date}</p>
                            <TextField inputRef={inputRef} type={'date'} value={datumPoziadavky} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumPoziadavky(e.target.value)} />
                        </div>
                        <div style={{ width: '95%' }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_ziadatel}</p>
                            <TextField error={ziadatelErr} value={ziadatel} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setZiadatel(e.target.value)} />
                        </div>

                    </div>
                </div>

                <div style={{ ...styles.Block, marginTop: offset }}>
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        <div style={{ width: 200, marginRight: 5 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_date_od}</p>
                            <TextField type={'date'} value={datumOd} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumOd(e.target.value)} />
                        </div>
                        <div style={{ width: 120, marginRight: 20 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time}</p>
                            <TextField type={'time'} value={casOd} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasOd(e.target.value)} />
                        </div>
                        <div style={{ width: 200, marginRight: 5 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_date_do}</p>
                            <TextField type={'date'} value={datumDo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumDo(e.target.value)} />
                        </div>
                        <div style={{ width: 120, marginRight: 0 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time}</p>
                            <TextField type={'time'} value={casDo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasDo(e.target.value)} />
                        </div>
                    </div>

                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        {/* kamera číslo */}
                        <div style={{ width: '50%', marginRight: 20 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_číslo}</p>
                            <TextField value={kamera} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setKamera(e.target.value)} />
                        </div>

                        {/* výstup záznamu */}
                        <div style={{ width: '50%' }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_vystup}</p>
                            <Select
                                value={vystup}
                                onChange={event => setVystup(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >
                                {lang.kamera_vystupy.map((item, index) => (
                                    <MenuItem key={index} value={index}>{item}</MenuItem>
                                ))}

                            </Select>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        {/* riesiteľ */}
                        <div style={{ width: '35%', marginRight: 20 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={riesitelErr} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.kamera_riesitel} width={'100%'} value={riesitel} value_id={0} func={RiesitelResult.bind(this)} />
                        </div>

                        {/* datum prevzatia */}
                        <div style={{ width: '30%', marginRight: 20 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 0, marginBottom: 3 }}>{lang.prevzal_datum}</p>
                            <TextField type={'date'} value={prevzalDatum} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPrevzalDatum(e.target.value)} />
                        </div>

                        {/* prevzal */}
                        <div style={{ width: '35%' }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 0, marginBottom: 3 }}>{lang.kamera_prevzal}</p>
                            <TextField error={prevzalErr} value={prevzal} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPrevzal(e.target.value)} />
                        </div>
                    </div>


                    {/* poznamka */}
                    <div style={{ width: '95%', marginTop: offset }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                        <TextField value={poznamka} multiline={true} rows={5} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka(e.target.value)} />
                    </div>


                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {
                isLoading ?
                    <Loading center></Loading>
                    : null
            }
        </Dialog >
    );
}

export const KamerovyZaznamNewReview = (props) => {

    /*
            USAGE:
            <EvidenciaPsovReview item_id={} func={EvidenciaPsovReviewResult.bind(this)} />
 
            const EvidenciaPsovReviewResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'kamera_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{lang.kamera_ziadost}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ ...styles.BlockRow, width: '100%', paddingTop: 15 }}>
                                <div style={{ width: '20%' }}>
                                    <TextField value={'KZ-'+data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '20%', marginLeft: 20 }}>
                                    <TextField value={data.datum_poziadavky} label={lang.kamera_date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '60%', marginLeft: 20 }}>
                                    <TextField value={data.ziadatel} label={lang.kamera_ziadatel} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: '13%' }}>
                                    <TextField value={data.datum_od} label={lang.kamera_datum_od} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '7%', marginLeft: 5 }}>
                                    <TextField value={data.cas_od} label={lang.time} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '13%', marginLeft: 20 }}>
                                    <TextField value={data.datum_do} label={lang.kamera_datum_do} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '7%', marginLeft: 5 }}>
                                    <TextField value={data.cas_do} label={lang.time} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '40%', marginLeft: 20 }}>
                                    <TextField value={data.kamera} label={lang.kamera_číslo} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>

                                <div style={{ width: '20%', marginLeft: 20 }}>
                                    <TextField value={lang.kamera_vystupy[data.vystup]} label={lang.kamera_vystup} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>

                            </div>
                            <div style={{ ...styles.BlockRow, width: '100%', paddingTop: 15 }}>
                                <div style={{ width: '20%' }}>
                                    <TextField value={data.riesitel} label={lang.kamera_riesitel} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '20%', marginLeft: 20 }}>
                                    <TextField value={data.prevzal} label={lang.kamera_prevzal} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '20%', marginLeft: 20 }}>
                                    <TextField value={data.prevzal_datum} label={lang.prevzal_datum} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '40%', marginLeft: 20 }}>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: '100%' }}>
                                    <TextField value={data.poznamka} label={lang.note} multiline={true} rows={1} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                                </div>
                                {data.updated_meno != '' ?
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div >
    );
}
