import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars, faBook, faWindowMaximize, faWindowMinimize, faCar, faLock, faSave, faPhotoVideo, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Accordion, Drawer, Slide, Tab, Tabs } from '@mui/material';
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import { Pivot as Hamburger } from 'hamburger-react'
import { AutocompleteText, GetDate2, GetPrice, Today, GetAge, GetDate, GetPSC, Loading, Capitalize, GetDateYear, GetTodayTime, DialogMobilePhotoPreview, AskDialog, IsEmpty, notNumber } from './items.js';
import { PriestupokBloky } from './priestupok_bloky.js';
import { useHotkeys } from 'react-hotkeys-hook';
import { nextDay } from 'date-fns';
import { da } from 'date-fns/locale';


export const MobilePriestupok = (props) => {
    /*
            USAGE:
            <Priestupok lang={lang} editing={editing} func={PriestupokResult.bind(this)} />

            const PriestupokResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(0);
    const [fullScreen, setFullScreen] = useState(false);
    //const [actualYear, setActualYear] = useState('');

    // PACHATEL
    const [osobaID, setOsobaID] = useState(0);
    const [narodnost, setNarodnost] = useState('');
    const [narodnostID, setNarodnostID] = useState(0);
    const [cudzinec, setCudzinec] = useState(0);
    const [narodeny, setNarodeny] = useState('');
    const [pohlavie, setPohlavie] = useState(0);
    const [showRodneCislo, setShowRodneCislo] = useState(true);
    const [rodneCislo1, setRodneCislo1] = useState('');
    const [rodneCislo2, setRodneCislo2] = useState('');
    const [age, setAge] = useState(0);
    const [titul1, setTitul1] = useState('');
    const [titul2, setTitul2] = useState('');
    const [meno, setMeno] = useState('');
    const [priezvisko, setPriezvisko] = useState('');
    const [ulica, setUlica] = useState('');
    const [ulicaCislo, setUlicaCislo] = useState('');
    const [psc, setPSC] = useState('');
    const [mesto, setMesto] = useState('');
    const [stat, setStat] = useState('');
    const [preukaz, setPreukaz] = useState('');
    const [preukazID, setPreukazID] = useState(0);
    const [preukazCislo, setPreukazCislo] = useState('');
    const [poznamka, setPoznamka] = useState('');
    const [lastRecord, setLastRecord] = useState([]);
    const [updateChanges, setUpdateChanges] = useState(false);

    // PRIESTUPOK
    const [datum, setDatum] = useState('');
    const [casOd, setCasOd] = useState('07:00');
    const [casDo, setCasDo] = useState('07:15');

    const [priestupok, setPriestupok] = useState('');
    const [priestupokErr, setPriestupokErr] = useState(false);
    const [priestupokID, setPriestupokID] = useState(0);
    const [vzn, setVZN] = useState('');
    const [vznErr, setVZNErr] = useState(false);
    const [vznID, setVZNID] = useState(0);
    const [showVZN, setShowVZN] = useState(false);
    const [zakon, setZakon] = useState('');
    const [paragraf, setParagraf] = useState('');
    const [odstavec, setOdstavec] = useState('');
    const [pismeno, setPismeno] = useState('');

    const [sposobZistenia, setSposobZistenia] = useState('');
    const [sposobZisteniaErr, setSposobZisteniaErr] = useState(false);
    const [sposobZisteniaID, setSposobZisteniaID] = useState(0);
    const [miestoPriestupku, setMiestoPriestupku] = useState('');
    const [miestoPriestupkuID, setMiestoPriestupkuID] = useState(0);
    const [miestoPriestupkuCislo, setMiestoPriestupkuCislo] = useState('');
    const [popisMiestaPriestupku, setPopisMiestaPriestupku] = useState('');

    const [riesitel1, setRiesitel1] = useState('');
    const [riesitel1Err, setRiesitel1Err] = useState(false);
    const [riesitelID1, setRiesitelID1] = useState(0);
    const [riesitel2, setRiesitel2] = useState('');
    const [riesitelID2, setRiesitelID2] = useState(0);
    const [stavRiesenia, setStavRiesenia] = useState('');
    const [stavRieseniaErr, setStavRieseniaErr] = useState(false);
    const [stavRieseniaID, setStavRieseniaID] = useState(0);
    const [spis, setSpis] = useState(false);

    const [sposobRiesenia, setSposobRiesenia] = useState('');
    const [sposobRieseniaErr, setSposobRieseniaErr] = useState(false);
    const [sposobRieseniaID, setSposobRieseniaID] = useState(0);
    const [datumDoriesenia, setDatumDoriesenia] = useState('');
    const [zakroky, setZakroky] = useState([]);
    const [zakrokyOpen, setZakrokyOpen] = useState(false);
    const [obmedzenieSlobody, setObmedzenieSlobody] = useState(false);

    // POKUTOVE BLOKY
    const [hotovost, setHotovost] = useState([]);
    const [enableHotovost, setEnableHotovost] = useState(false);
    const [hotovostSuma, setHotovostSuma] = useState(0);
    const [hotovostSumaErr, setHotovostSumaErr] = useState(false);
    const [showHotovost, setShowHotovost] = useState(false);

    // POKUTOVE SEKY
    const [sek, setSek] = useState([]);
    const [enableSek, setEnableSek] = useState(false);
    const [sekSuma, setSekSuma] = useState(0);
    const [sekSumaErr, setSekSumaErr] = useState(false);
    const [showSek, setShowSek] = useState(false);

    // POKUTA - SPRAVNE KONANIE
    const [pokuta, setPokuta] = useState('0');
    const [enablePokuta, setEnablePokuta] = useState(false);
    const [pokutaErr, setPokutaErr] = useState(false);
    const [pokutaZaplatena, setPokutaZaplatena] = useState(false);

    const [popis, setPopis] = useState('');

    // AUTO
    const [autoID, setAutoID] = useState(0);
    const [spz, setSPZ] = useState('');
    const [autoTyp, setAutoTyp] = useState(''); // do 3.5 t, nad 3.5 t
    const [autoTypErr, setAutoTypErr] = useState('');
    const [autoTypID, setAutoTypID] = useState(0);
    const [autoDruh, setAutoDruh] = useState(''); // znacka auta
    const [autoDruhID, setAutoDruhID] = useState(0);
    const [autoFarba, setAutoFarba] = useState('');
    const [autoFarbaID, setAutoFarbaID] = useState(0);

    // FOTOGRAFIE
    const [photos, setPhotos] = useState([]);
    const [photoLAT, setPhotoLAT] = useState(0);
    const [photoLNG, setPhotoLNG] = useState(0);
    const [photoSelected, setPhotoSelected] = useState([]);
    const [showPhotoPreview, setShowPhotoPreview] = useState([]);

    const [redraw, setRedraw] = useState([]);
    const [showAskClose, setShowAskClose] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const headerHeight = 85;
    const boxOffset = 10;

    let { func } = props;
    var lang = sk;

    const timer = useRef();
    const textvariant = "outlined";
    const textSize = 'medium';
    var inputRef = useRef(null);
    const scrollRef = useRef(null);

    useHotkeys('F2', (key) => handleKey(key), true)
    useHotkeys('F3', (key) => handleKey(key), true)
    useHotkeys('F4', (key) => handleKey(key), true)
    useHotkeys('Control+Enter', (key) => handleKey(key), true)

    useEffect(() => {

        if (props.editing == true) {
            // UPRAVA ZAZNAMU

            setEnableHotovost(true);
            setEnableSek(true);
            db_priestupok(props.item.id);

        } else {
            // NOVY ZAZNAM

            setLoading(false);
            setCasOd(GetTodayTime());
            CasOd(GetTodayTime());
            setDatum(Today());
            setDatumDoriesenia(Today());
            setNarodeny(Today());
            setRiesitel1(props.user.name + ' ' + props.user.surname + ' (' + props.user.badge + ')');
            setRiesitelID1(props.user.id);
        }


        return () => {
        };

    }, []);

    const db_priestupok = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                    log: props.preview == true ? global.log_view : global.log_open,
                    user_id: props.user.id,
                    department_id: props.department.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;
                setLastRecord(data);
                // PACHATEL                         
                setNarodnost(data.narodnost);
                setNarodnostID(data.narodnost_id);
                setCudzinec(data.cudzinec);
                setNarodeny(data.narodeny);
                setPohlavie(data.pohlavie);
                setRodneCislo1(data.rodne_cislo.substring(0, 6));
                setRodneCislo2(data.rodne_cislo.substring(6, data.rodne_cislo.length));
                setAge(data.vek);
                setTitul1(data.titul1);
                setMeno(data.meno);
                setPriezvisko(data.priezvisko);
                setTitul2(data.titul2);
                setUlica(data.ulica);
                setUlicaCislo(data.ulica_cislo);
                setPSC(data.psc);
                setMesto(data.mesto);
                setStat(data.stat);
                setPreukaz(data.preukaz);
                setPreukazID(data.preukaz_id);
                setPreukazCislo(data.preukaz_cislo);
                setPoznamka(data.poznamka);

                //PRIESTUPOK
                setDatum(data.datum);
                setCasOd(data.cas_od);
                setCasDo(data.cas_do);
                setPriestupok(data.priestupok);
                setPriestupokID(data.priestupok_id);
                if (data.priestupok_id == 1) {
                    setShowVZN(true);
                } else {
                    setShowVZN(false);
                }
                setVZN(data.vzn);
                setVZNID(data.vzn_id);
                setZakon(data.zakon);
                setParagraf(data.paragraf);
                setOdstavec(data.odstavec);
                setPismeno(data.pismeno);
                setSposobZistenia(data.sposob_zistenia);
                setSposobZisteniaID(data.sposob_zistenia_id);
                setMiestoPriestupku(data.miesto_priestupku);
                setMiestoPriestupkuID(data.miesto_priestupku_id);
                setMiestoPriestupkuCislo(data.miesto_priestupku_cislo);
                setPopisMiestaPriestupku(data.popis_miesta_priestupku);
                setRiesitel1(data.riesitel_1);
                setRiesitelID1(data.riesitel_id_1);
                setRiesitel2(data.riesitel_2);
                setRiesitelID2(data.riesitel_id_2);
                setStavRiesenia(data.stav_riesenia);
                setStavRieseniaID(data.stav_riesenia_id);

                setSposobRiesenia(data.sposob_riesenia);
                setSposobRieseniaID(data.sposob_riesenia_id);
                setDatumDoriesenia(data.datum_doriesenia);
                setHotovostSuma(data.hotovost_suma);
                setHotovostSuma(data.hotovost_suma);
                setSekSuma(data.sek_sum);
                setPokuta(data.pokuta);

                /*
                if (parseInt(data.hotovost_suma) > 0) {
                    setEnableHotovost(true);
                } else {
                    setEnableHotovost(false);
                }

                if (parseInt(data.sek_sum) > 0) {
                    setEnableSek(true);
                } else {
                    setEnableSek(false);
                }
                */
                setZakroky(data.zakroky == '' ? [] : JSON.parse(data.zakroky));
                setPopis(data.popis);
                setSpis(data.spis == 0 ? false : true);
                setObmedzenieSlobody(data.obmedzenie_slobody == 0 ? false : true);

                // AUTO
                setSPZ(data.spz);
                setAutoTyp(data.auto_typ);
                setAutoTypID(data.auto_typ_id);
                setAutoDruh(data.auto_druh);
                setAutoDruhID(data.auto_druh_id);
                setAutoFarba(data.auto_farba);
                setAutoFarbaID(data.auto_farba_id);

                // HOTOVOST / SEK / POKUTA
                setEnableHotovost(json.hotovost);
                setEnableSek(json.sek);
                setEnablePokuta(json.pokuta);

                // BLOKY
                db_bloky(data.id);

                // FOTOGRAFIE
                setPhotos(json.photos);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_bloky = async (id) => {

        try {
            const response = await fetch(
                global.db_url + 'priestupok_bloky', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setHotovost(json.hotovost);
                setSek(json.seky);
            }
        } catch (error) {
            console.log(error);

        }
    }

    const db_update = async (data_pachatel, data_priestupok, data_hotovost, data_sek, data_auto, data_photos) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data_pachatel: data_pachatel,
                    data_priestupok: data_priestupok,
                    data_hotovost: data_hotovost,
                    data_sek: data_sek,
                    data_auto: data_auto,
                    data_photos: data_photos,
                    decode_key: props.decodeKey,
                    log: props.editing == true ? data_priestupok.locked == true ? global.log_update_lock : global.log_update : data_priestupok.locked == true ? global.log_new_lock : global.log_new,
                    user_id: props.user.id,
                    department_id: props.department.id
                })
            })

            const json = await response.json();

            setBusy(false);

            if (json.ok > 0) {
                Press(1, data_priestupok);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_person_find = async (rodne_cislo) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_find_person', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    rodne_cislo: rodne_cislo,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.person != null) {
                    setOsobaID(json.person.id);
                    setNarodnost(json.person.narodnost);
                    setNarodnostID(json.person.narodnost_id);
                    setPreukaz(json.person.preukaz);
                    setPreukazID(json.person.preukaz_id);
                    setPreukazCislo(json.person.preukaz_cislo);
                    setTitul1(json.person.titul1);
                    setMeno(json.person.meno);
                    setPriezvisko(json.person.priezvisko);
                    setTitul2(json.person.titul2);
                    setMesto(json.person.mesto);
                    setUlica(json.person.ulica);
                    setUlicaCislo(json.person.ulica_cislo);
                    setPSC(json.person.psc);
                    setStat(json.person.stat);
                    setPoznamka(json.person.poznamka);
                } else {
                    setOsobaID(0);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_car_find = async (_spz) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_find_car', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    spz: _spz,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.car != null) {
                    setAutoID(json.car.id);
                    setSPZ(_spz);
                    setAutoDruh(json.car.auto_druh);
                    setAutoDruhID(json.car.auto_druh_id);
                    setAutoTyp(json.car.auto_typ);
                    setAutoTypID(json.car.auto_typ_id);
                    setAutoFarba(json.car.auto_farba);
                    setAutoFarbaID(json.car.auto_farba_id);
                } else {
                    setAutoID(0);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    // ******************************************************************************************
    // UPLOAD IMAGES TO SERVER
    // ******************************************************************************************
    const images_upload = async (files) => {

        setBusy(true);
        const data = new FormData();
        data.append('typ', 0);
        data.append("name", "image");
        data.append("count", files.length);
        data.append("department_id", props.department.id);

        var n = files.length;
        while (n > 0) {
            n--;
            data.append("file_attachment_" + n, files[n]);
        }

        try {
            const response = await fetch(
                global.db_url + 'upload_images', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = photos;
                var photos_ = json.photos;
                photos_.forEach(item => {
                    item.new = true;
                    tmp.push(item);
                });
                setPhotos(tmp);
                setRedraw(!redraw);
            }
            return;

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };

    const handleKey = (key) => {

        if (key.key == 'F2') {
            setOpenAcc(0);
            timer.current = setTimeout(() => {
                setOpen(true);
            }, 350);
        }
        if (key.key == 'F3') {
            setOpenAcc(1);
            timer.current = setTimeout(() => {
                setOpen(true);
            }, 350);
        }
        if (key.key == 'F4') {
            setOpenAcc(2);
            timer.current = setTimeout(() => {
                setOpen(true);
            }, 350);
        }

        if (key.key == 'Control+Enter') {
            // ULOZENIE
            Save();
        }

        if (key.key == 'Enter') {
            // ULOZENIE
            if (key.ctrlKey == true) {
                Save();
            }
        }
        if (key.key == 'Escape') {
            // KONIEC
            //Press(false, false); // ZATVORENIE OKNA
        }
    }

    const Press = (typ, data) => {
        func(typ, data);
    }

    const Check = () => {
        setError('');
        setPriestupokErr(false);
        setVZNErr(false);
        setSposobZisteniaErr(false);
        setStavRieseniaErr(false);
        setSposobRieseniaErr(false);
        setHotovostSumaErr(false);
        setRiesitel1Err(false);
        setAutoTypErr(false);

        var error = false;
        var tab = 1;

        if (priestupokID == 0) {
            setPriestupokErr(true);
            error = true;
        }
        if (priestupokID == 1) {
            // VZN
            if (vznID == 0) {
                setVZNErr(true);
                error = true;
            }
        }

        if (sposobZisteniaID == 0) {
            setSposobZisteniaErr(true);
            error = true;
        }
        if (stavRieseniaID == 0) {
            setStavRieseniaErr(true);
            error = true;
        }
        if (riesitel1 == 0) {
            setRiesitel1Err(true);
            error = true;
        }
        if (stavRieseniaID == 1) {
            if (sposobRieseniaID == 0) {
                setSposobRieseniaErr(true);
                error = true;
            }
        }

        /*
        if (spz.trim() != '' && autoTypID == 0) {
            setAutoTypErr(true);
            error = true;
            tab = 2;
        }
        */

        if (enableHotovost == true && enableSek == false) {
            if (hotovostSuma == 0) {
                setHotovostSumaErr(true);
                error = true;
                tab = 1;
            }
        }

        if (enableSek == true && enableHotovost == false) {
            if (sekSuma == 0) {
                setSekSumaErr(true);
                error = true;
                tab = 1;
            }
        }

        if (enableSek == true && enableHotovost == true) {
            if (sekSuma == 0 && hotovostSuma == 0) {
                setSekSumaErr(true);
                setHotovostSumaErr(true);
                error = true;
                tab = 1;
            }
        }

        if (enablePokuta == true) {
            error = IsEmpty(pokuta) == true ? setPokutaErr(true) ?? true : setPokutaErr(false) ?? false;
            error += notNumber(pokuta) == true ? setPokutaErr(true) ?? true : setPokutaErr(false) ?? false;
            if (parseInt(pokuta) == 0) {
                setPokutaErr(true);
                error = true;
            }
            tab = 1;
        }

        if (error == true) {
            // CHYBA
            setError(lang.required);
            setIndex(tab);

        }

        return error;
    }

    const Save = (locked) => {
        var pachalel_zmeny = false;
        if (CheckChanges() == true) {
            pachalel_zmeny = true;
        }

        if (Check() == false) {
            var data_pachatel = {
                id: osobaID,
                pachatel_zmeny: pachalel_zmeny,
                department_id: props.department.id,

                narodnost: narodnost,
                narodnost_id: narodnostID,
                cudzinec: cudzinec,
                narodeny: narodeny,
                pohlavie: pohlavie,
                rodne_cislo: rodneCislo1 + rodneCislo2,
                vek: age,
                titul1: titul1.trim(),
                meno: Capitalize(meno.trim()),
                priezvisko: Capitalize(priezvisko.trim()),
                titul2: titul2.trim(),
                ulica: ulica.trim(),
                ulica_cislo: ulicaCislo,
                psc: GetPSC(psc),
                mesto: mesto.trim(),
                stat: stat.trim(),
                preukaz: preukaz,
                preukaz_id: preukazID,
                preukaz_cislo: preukazCislo.trim().replaceAll(' ', '').toUpperCase(),
                poznamka: poznamka
            }

            var data_priestupok = {
                id: props.editing == true ? props.item.id : 0,
                department_id: props.department.id,
                obvod: props.obvod.kod,
                obvod_id: props.obvod.id,
                rok: GetDateYear(datum),
                locked: locked,

                pravnicka_osoba: false,
                firma: '',
                ico: '',
                narodnost: narodnost,
                narodnost_id: narodnostID,
                cudzinec: cudzinec,
                narodeny: narodeny,
                pohlavie: pohlavie,
                rodne_cislo: rodneCislo1 + rodneCislo2,
                vek: age,
                titul1: titul1.trim(),
                meno: Capitalize(meno.trim()),
                priezvisko: Capitalize(priezvisko.trim()),
                titul2: titul2.trim(),
                ulica: ulica.trim(),
                ulica_cislo: ulicaCislo,
                psc: GetPSC(psc),
                mesto: mesto.trim(),
                stat: stat.trim(),
                preukaz: preukaz,
                preukaz_id: preukazID,
                preukaz_cislo: preukazCislo.trim().replaceAll(' ', '').toUpperCase(),
                poznamka: poznamka,

                datum: datum,
                cas_od: casOd,
                cas_do: casDo,
                priestupok: priestupok,
                priestupok_id: priestupokID,
                vzn: vzn,
                vzn_id: vznID,
                zakon: zakon,
                paragraf: paragraf,
                odstavec: odstavec,
                pismeno: pismeno,
                sposob_zistenia: sposobZistenia,
                sposob_zistenia_id: sposobZisteniaID,
                miesto_priestupku: miestoPriestupku,
                miesto_priestupku_id: miestoPriestupkuID,
                miesto_priestupku_cislo: miestoPriestupkuCislo,
                popis_miesta_priestupku: popisMiestaPriestupku,
                riesitel_1: riesitel1,
                riesitel_id_1: riesitelID1,
                riesitel_2: riesitel2,
                riesitel_id_2: riesitelID2,
                stav_riesenia: stavRiesenia,
                stav_riesenia_id: stavRieseniaID,
                sposob_riesenia: sposobRiesenia,
                sposob_riesenia_id: sposobRieseniaID,
                datum_doriesenia: stavRieseniaID == 1 ? datumDoriesenia : null,
                hotovost_suma: hotovostSuma,
                sek_sum: sekSuma,
                zakroky: JSON.stringify(zakroky),
                popis: popis,
                spis: spis,
                obmedzenie_slobody: obmedzenieSlobody,

                spz: spz.trim().replaceAll(' ', '').toUpperCase(),
                auto_typ: autoTyp,
                auto_typ_id: autoTypID,
                auto_druh: autoDruh,
                auto_druh_id: autoDruhID,
                auto_farba: autoFarba,
                auto_farba_id: autoFarbaID,

                usep: 0
            }

            // ARRAY -> ID LEN FOTOGRAFII, KTORE SU NOVE PRIDANE
            var tmp = photos.filter(x => x.new == true);
            var tmp_id = [];
            tmp.forEach(item => {
                tmp_id.push(item.id)
            });
            var data_photos = {
                photos: tmp_id.length > 0 ? tmp_id : null
            }

            if (props.editing == true) {
                data_priestupok.updated_user_id = props.user.id;
            } else {
                data_priestupok.user_id = props.user.id;
                data_priestupok.user_name = (props.user.name + ' ' + props.user.surname).trim();
                data_priestupok.jednacie_cislo = 0;
            }

            var data_auto = {
                id: autoID,
                department_id: props.department.id,
                spz: spz.trim().replaceAll(' ', '').toUpperCase(),
                auto_typ: autoTyp,
                auto_typ_id: autoTypID,
                auto_druh: autoDruh,
                auto_druh_id: autoDruhID,
                auto_farba: autoFarba,
                auto_farba_id: autoFarbaID,
            }

            db_update(data_pachatel, data_priestupok, hotovost, sek, data_auto, data_photos);
        }
    }


    const SetNarodnost = (value, key) => {

        if (value != null) {
            setNarodnost(value.short);
            setNarodnostID(value.id);
            if (value.id > 0) {
                setCudzinec(value.cudzinec == true ? 1 : 0);
                setShowRodneCislo(value.rodne_cislo == 0 ? false : true);
                setStat(value.stat);
            }
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
            if (value.psc != null) {
                setPSC(value.psc);
            }
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetMesto = (value, key) => {
        if (value != null) {
            setMesto(value.label);
            if (value.id > 0 && cudzinec == false) {
                if (value.psc != '') {
                    setPSC(value.psc);
                }
            }
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetStat = (value, key) => {
        if (value != null) {
            setStat(value.label);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetPreukaz = (value, key) => {
        if (value != null) {
            setPreukaz(value.label);
            setPreukazID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetPriestupok = (value, key) => {
        if (value != null) {
            setPriestupok(value.label);
            setPriestupokID(value.id);
            if (value.id > 0) {
                setZakon(value.zakon);
                setParagraf(value.paragraf);
                setOdstavec(value.odstavec);
                setPismeno(value.pismeno);
                if (value.id == 1) {
                    setShowVZN(true);
                } else {
                    setShowVZN(false);
                    setVZN('');
                    setVZNID(0);
                }
            }
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetVZN = (value, key) => {
        if (value != null) {
            setVZN(value.label);
            setVZNID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetSposobZistenia = (value, key) => {
        if (value != null) {
            setSposobZistenia(value.label);
            setSposobZisteniaID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetMiestoPriestupku = (value, key) => {

        if (value != null) {
            setMiestoPriestupku(value.label);
            setMiestoPriestupkuID(value.id);
            setPopisMiestaPriestupku(value.lokalita);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetRiesitel1 = (value, key) => {
        if (value != null) {
            setRiesitel1(value.label);
            setRiesitelID1(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetRiesitel2 = (value, key) => {
        if (value != null) {
            setRiesitel2(value.label);
            setRiesitelID2(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetStavRiesenia = (value, key) => {
        if (value != null) {
            setStavRiesenia(value.label);
            if (value.id == 1) {
                setDatumDoriesenia(Today());
                setEnableHotovost(false);
                setEnableSek(false);
            } else {
                setSposobRiesenia('');
                setSposobRieseniaID(0);
            }
            setStavRieseniaID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetSposobRiesenia = (value, key) => {

        if (value != null) {
            setSposobRiesenia(value.label);
            setSposobRieseniaID(value.id);
            if (value.hotovost == true) {
                setEnableHotovost(true);
            } else {
                setEnableHotovost(false);
            }
            if (value.sek == true) {
                setEnableSek(true);
            } else {
                setEnableSek(false);
            }

        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }

    const SetAutoTyp = (value, key) => {
        if (value != null) {
            setAutoTyp(value.label);
            setAutoTypID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }
    const SetAutoDruh = (value, key) => {
        if (value != null) {
            setAutoDruh(value.label);
            setAutoDruhID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }
    const SetAutoFarba = (value, key) => {
        if (value != null) {
            setAutoFarba(value.label);
            setAutoFarbaID(value.id);
        } else {
            if (key != undefined) {
                var tmp = { key: key };
                handleKey(tmp)
            }
        }
    }
    const SetOpenAcc = (value) => {
        if (value == openAcc) {
            setOpenAcc(-1)
        } else {
            setOpenAcc(value)
        }
    }

    const ChangePohlavie = (event) => {
        var value = event.target.value;
        setPohlavie(value);
    }

    const ChangeCudzinec = (event) => {
        var value = event.target.value;
        setCudzinec(value);
    }

    const SetRodneCislo1 = (value) => {
        setRodneCislo1(value);
        if (value.length == 6) {
        }
    }

    const RodneCislo = () => {
        var value = rodneCislo1;
        if (value.length == 6) {
            var r = parseInt(value.substring(0, 2));
            var m = parseInt(value.substring(2, 4));
            var d = parseInt(value.substring(4, 6));
            if (m > 12) {
                m = m - 50;
                setPohlavie(2);
            } else {
                setPohlavie(1);
            }

            if (r < 54) {
                if (rodneCislo2.length == 3) {
                    r = r + 1900;
                } else {
                    r = r + 2000;
                }
            } else {
                r = r + 1900;
            }
            //var datum = d.toString() + '.' + m.toString() + '.' + r.toString();
            var datum = r.toString() + '-' + m.toString().padStart(2, '0') + '-' + d.toString().padStart(2, '0');

            setNarodeny(datum);

            if (datum != null || datum != '') {
                setAge(GetAge(datum));
            }

            db_person_find(rodneCislo1 + rodneCislo2);
        }
    }

    const Narodeny = () => {
        var datum = narodeny;
        if (datum != null || datum != '') {
            setAge(GetAge(datum));
        }
    }

    const zakrokChange = (event) => {
        const {
            target: { value },
        } = event;
        var tmp = typeof value === 'string' ? value.split(',') : value;
        tmp = tmp.filter(x => x != '');
        setZakroky(tmp);
    };

    const CheckKey = (event) => {

        if (event.key === 'F2') {
            handleKey({ key: 'F2' })
        }
        if (event.key === 'F3') {
            handleKey({ key: 'F3' })
        }
        if (event.key === 'F4') {
            handleKey({ key: 'F4' })
        }
        if (event.key === 'Enter') {
            if (event.ctrlKey == true) {
                handleKey({ key: 'Control+Enter' })
            }
        }
        if (event.key === 'Escape') {
            //Press(false, false) // ZATVORENIE OKNA
        }
    }

    const Hotovost = () => {
        setShowHotovost(true);
    }

    const Sek = () => {
        setShowSek(true);
    }

    const PriestupokBlokyResult = (typ, _items, _suma) => {
        setShowHotovost(false);
        if (typ == 1) {
            setHotovost(_items);
            setHotovostSuma(_suma);
        }
    }

    const PriestupokSekyResult = (typ, _items, _suma) => {
        setShowSek(false);
        if (typ == 1) {
            setSek(_items);
            setSekSuma(_suma);
        }
    }

    const CasOd = (cas_od) => {
        var cas = casOd;
        if (cas_od != undefined) {
            var cas = cas_od;
        }
        var tmp = cas.split(':');
        var h = parseInt(tmp[0]);
        var m = parseInt(tmp[1]);

        m = m + global.CasAdd;
        if (m > 59) {
            m = m - 60;
            h = h + 1;
            if (h > 23) {
                h = h - 24;
            }
        }

        setCasDo(h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0'))
    }

    const Next = (id) => {

    }

    const SPZBlur = () => {
        var _spz = spz.trim().replaceAll(' ', '').toUpperCase();
        if (_spz != '') {
            db_car_find(_spz);
        }
    }

    const CheckChanges = () => {
        var changes = false;
        if (props.editing == true) {
            if (narodnost != lastRecord.narodnost) {
                changes = true;
            }
            if (narodnost != lastRecord.narodnost) {
                changes = true;
            }
            if (narodeny != lastRecord.narodeny) {
                changes = true;
            }
            if (pohlavie != lastRecord.pohlavie) {
                changes = true;
            }
            if (rodneCislo1 != lastRecord.rodne_cislo.substring(0, 6)) {
                changes = true;
            }
            if (rodneCislo2 != lastRecord.rodne_cislo.substring(6, lastRecord.rodne_cislo.length)) {
                changes = true;
            }
            if (age != lastRecord.vek) {
                changes = true;
            }
            if (pohlavie != lastRecord.pohlavie) {
                changes = true;
            }
            if (titul1 != lastRecord.titul1) {
                changes = true;
            }
            if (meno != lastRecord.meno) {
                changes = true;
            }
            if (priezvisko != lastRecord.priezvisko) {
                changes = true;
            }
            if (titul2 != lastRecord.titul2) {
                changes = true;
            }
            if (ulica != lastRecord.ulica) {
                changes = true;
            }
            if (ulicaCislo != lastRecord.ulica_cislo) {
                changes = true;
            }
            if (psc != lastRecord.psc) {
                changes = true;
            }
            if (psc != lastRecord.psc) {
                changes = true;
            }
            if (mesto != lastRecord.mesto) {
                changes = true;
            }
            if (stat != lastRecord.stat) {
                changes = true;
            }
            if (preukaz != lastRecord.preukaz) {
                changes = true;
            }
            if (preukazCislo != lastRecord.preukaz_cislo) {
                changes = true;
            }
        } else {
            // pri novom zázname - vzdy nahrat najnovsie údaje
            changes = true;
        }
        return changes
    }

    const TabChange = (event, value) => {
        setIndex(value);
        var timer = setTimeout(() => {
            ScrollHome();
        }, 300);
    };

    function SelectFile(e) {
        // FOTOGRAFIE - VYBER SUBOROV A ODOSLANIE NA SERVER        
        var files = e.target.files;
        images_upload(files);
    }

    const DeletePhoto = (item) => {
        var tmp = photos.filter(x => x.id != item.id);
        setPhotos(tmp);
        setRedraw(!redraw);
    }

    const PreviewPhoto = (item) => {
        setPhotoSelected(global.web + '/' + item.path + item.filename);
        setShowPhotoPreview(true);
    }

    const DialogPhotoPreviewResult = () => {
        setShowPhotoPreview(false);
    }

    const AskClose = () => {
        if (props.editing == true) {
            Press(false, false);
        } else {
            setShowAskClose(true);
        }
    }

    const AskCloseResult = (value) => {
        setShowAskClose(false);
        if (value == true) {
            Press(false, false);
        }
    }

    const ScrollHome = () => {
        scrollRef.current.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.Block, justifyContent: 'flex-end', height: headerHeight, backgroundColor: global.theme_lighter }}>
                <Tabs value={index} onChange={TabChange}>
                    <Tab label={lang.priestupok_pachatel} style={{ ...styles.TextXXTiny, fontWeight: '500', textTransform: 'none', color: index == 0 ? global.theme_black : global.theme_medium }} />
                    <Tab label={lang.priestupok} style={{ ...styles.TextXXTiny, fontWeight: '500', textTransform: 'none', color: index == 1 ? global.theme_black : global.theme_medium }} />
                    <Tab label={lang.auto} style={{ ...styles.TextXXTiny, fontWeight: '500', textTransform: 'none', color: index == 2 ? global.theme_black : global.theme_medium }} />
                    <Tab label={lang.photos} style={{ ...styles.TextXXTiny, fontWeight: '500', textTransform: 'none', color: index == 3 ? global.theme_black : global.theme_medium }} />
                </Tabs>
            </div>

            <div ref={scrollRef} style={{ ...styles.BlockCenter, height: props.height - headerHeight, overflowY: 'scroll' }}>

                {/* ************************************************************************************************************                
                    PACHATEL                
                ************************************************************************************************************ */}
                {index == 0 ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>Základné údaje</p>
                        </div>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ width: '100%' }}>
                                <AutocompleteText size={textSize} decodeKey={props.decodeKey} check={true} row={false} department={props.department} db={0} lang={lang} label={lang.prislusnost_short} width={'100%'} value={narodnost} value_id={narodnostID} func={SetNarodnost.bind(this)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.cudzinec}</p>
                                <Select
                                    value={cudzinec}
                                    onChange={ChangeCudzinec}
                                    size={textSize}
                                    variant={textvariant}
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    <MenuItem value={0}>Nie</MenuItem>
                                    <MenuItem value={1}>Áno</MenuItem>
                                </Select>
                            </div>

                            {showRodneCislo == true ?
                                <div style={{ ...styles.BlockRow, marginTop: boxOffset }}>

                                    <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                        <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.rodne_cislo}</p>
                                        <TextField
                                            onKeyDown={(event) => { CheckKey(event) }}
                                            inputProps={{
                                                inputMode: 'decimal'
                                            }}
                                            size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={rodneCislo1} onInput={e => SetRodneCislo1(e.target.value)} />
                                    </div>


                                    <div style={{ ...styles.Block, width: '10%' }}>
                                        <p style={{ ...styles.TextLarge, marginLeft: 5, marginRight: 5, marginTop: 25 }}>/</p>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <TextField
                                            onKeyDown={(event) => { CheckKey(event) }}
                                            inputProps={{
                                                inputMode: 'decimal'
                                            }}
                                            size={textSize}
                                            onBlur={() => RodneCislo()} style={{ width: '100%', backgroundColor: global.theme_white, marginTop: 20 }} label={''} variant={textvariant} value={rodneCislo2} onInput={e => setRodneCislo2(e.target.value)} />
                                    </div>
                                </div>
                                : null}

                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.birth_date}</p>
                                <TextField
                                    onKeyDown={(event) => { CheckKey(event) }}
                                    onBlur={() => Narodeny()}
                                    size={textSize} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={narodeny} onInput={e => setNarodeny(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.sex}</p>
                                <Select
                                    value={pohlavie}
                                    onChange={ChangePohlavie}
                                    size={textSize}
                                    variant={textvariant}
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    <MenuItem value={0}>{lang.sex_types[0]}</MenuItem>
                                    <MenuItem value={1}>{lang.sex_types[1]}</MenuItem>
                                    <MenuItem value={2}>{lang.sex_types[2]}</MenuItem>
                                </Select>
                            </div>


                            {/* PREUKAZ */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} decodeKey={props.decodeKey} check={true} row={false} department={props.department} db={5} lang={lang} label={lang.preukaz} width={'100%'} value={preukaz} value_id={preukazID} func={SetPreukaz.bind(this)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.number}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={preukazCislo} onInput={e => setPreukazCislo(e.target.value)} />
                                </div>
                                <div style={{ ...styles.Block, width: '100%', marginTop: boxOffset }}>
                                    {age > 0 ?
                                        <p style={{ ...styles.TextSmall, fontWeight: age < 18 ? 'bold' : '400', color: age < 18 ? global.theme_dark_red : global.theme_black }}>Vek: {age} rokov</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 10 }}>Trvalé bydlisko</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%' }}>
                            {/* MENO A PRIEZVISKO */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{'Titul pre menom'}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={titul1} onInput={e => setTitul1(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.name}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={meno} onInput={e => setMeno(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.surname}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={priezvisko} onInput={e => setPriezvisko(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{'Titul za menom'}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={titul2} onInput={e => setTitul2(e.target.value)} />
                                </div>
                            </div>

                            {/*MESTO, ULICA */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} decodeKey={props.decodeKey} new_record={cudzinec == true ? true : false} row={false} check={false} department={props.department} db={cudzinec == true ? 16 : 3} lang={lang} label={lang.town} width={'100%'} value={mesto} func={SetMesto.bind(this)} />
                                </div>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} decodeKey={props.decodeKey} new_record={cudzinec == true ? true : false} row={false} check={false} department={props.department} db={cudzinec == true ? 17 : 2} lang={lang} label={lang.street} width={'100%'} value={ulica} keys={mesto} func={SetUlica.bind(this)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.street_number}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={ulicaCislo} onInput={e => setUlicaCislo(e.target.value)} />
                                </div>
                            </div>

                            {/* PSC, STAT, POZNAMKA */}

                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.psc}</p>
                                <TextField
                                    onKeyDown={(event) => { CheckKey(event) }}
                                    inputProps={{
                                        inputMode: 'decimal'
                                    }}
                                    size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={psc} onInput={e => setPSC(e.target.value)} />
                            </div>
                            <div style={{ width: '100%', marginTop: boxOffset }}>
                                <AutocompleteText size={textSize} decodeKey={props.decodeKey} row={false} check={false} department={props.department} db={4} lang={lang} label={lang.state} width={'100%'} value={stat} func={SetStat.bind(this)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.note}</p>
                                <TextField
                                    onKeyDown={(event) => { CheckKey(event) }}
                                    onBlur={() => Next(1)}
                                    multiline={true}
                                    rows={4}
                                    size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={poznamka} onInput={e => setPoznamka(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    : null}


                {/* ************************************************************************************************************                
                        PRIESTUPOK                
            ************************************************************************************************************ */}
                {index == 1 ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 10 }}>Popis priestupku</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%' }}>
                            {/* DATUM, CAS, DRUH PRIESTUPKU, ZAKON, PAR, ODS, PISM */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.date}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        type={'date'}
                                        size={textSize} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={datum} onInput={e => setDatum(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: boxOffset }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.time_from}</p>
                                        <TextField
                                            onKeyDown={(event) => { CheckKey(event) }}
                                            onBlur={() => CasOd()}
                                            type={'time'} size={textSize} placeholder={lang.time_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={casOd} onInput={e => setCasOd(e.target.value)} />

                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.time_to}</p>
                                        <TextField
                                            onKeyDown={(event) => { CheckKey(event) }}
                                            type={'time'} size={textSize} placeholder={lang.time_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={casDo} onInput={e => setCasDo(e.target.value)} />

                                    </div>
                                </div>

                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} error={priestupokErr} decodeKey={props.decodeKey} inputRef={null} check={true} row={false} department={props.department} db={6} lang={lang} label={showVZN == true ? lang.priestupok : lang.druh_priestupku} width={'100%'} value={priestupok} value_id={priestupokID} func={SetPriestupok.bind(this)} />
                                </div>
                                {showVZN == true ?
                                    <div style={{ width: '100%', marginTop: boxOffset }}>
                                        <AutocompleteText size={textSize} error={vznErr} decodeKey={props.decodeKey} inputRef={null} check={true} row={false} department={props.department} db={7} lang={lang} label={lang.vzn} width={'100%'} value={vzn} value_id={vznID} func={SetVZN.bind(this)} />
                                    </div>
                                    : null}
                            </div>

                            {/* SPOSOB ZISTENIA, MIESTO PRIESTUPKU, POPIS MIESTA */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} error={sposobZisteniaErr} decodeKey={props.decodeKey} inputRef={null} check={true} row={false} department={props.department} db={8} lang={lang} label={lang.sposob_zistenia} width={'100%'} value={sposobZistenia} value_id={sposobRieseniaID} func={SetSposobZistenia.bind(this)} />
                                </div>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={true} department={props.department} db={1} lang={lang} label={lang.miesto_priestupku} width={'100%'} value={miestoPriestupku} value_id={miestoPriestupkuID} func={SetMiestoPriestupku.bind(this)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.orientacne_cislo}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={miestoPriestupkuCislo} onInput={e => setMiestoPriestupkuCislo(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.popis_miesta_priestupku}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={popisMiestaPriestupku} onInput={e => setPopisMiestaPriestupku(e.target.value)} />
                                </div>
                            </div>
                        </div>


                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 10 }}>Riešenie priestupku</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%' }}>
                            {/* RIESITEL, STAV RIESENIA, JEDNACIE CISLO */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} error={riesitel1Err} decodeKey={props.decodeKey} inputRef={null} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 1'} width={'100%'} value={riesitel1} value_id={riesitelID1} func={SetRiesitel1.bind(this)} />
                                </div>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 2'} width={'100%'} value={riesitel2} value_id={riesitelID2} func={SetRiesitel2.bind(this)} />
                                </div>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} error={stavRieseniaErr} decodeKey={props.decodeKey} inputRef={null} check={true} row={false} new_record={false} department={props.department} db={10} lang={lang} label={lang.stav_riesenia} width={'100%'} value={stavRiesenia} value_id={stavRieseniaID} disabled={hotovostSuma > 0 || sekSuma > 0 || parseInt(pokuta) > 0 ? true : false} func={SetStavRiesenia.bind(this)} />
                                </div>
                            </div>

                            {/* SPOSOB RIESENIA, DATUM DORIESENIA, HOTOVOST, SEK, ZAKROK */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ width: '100%', marginTop: boxOffset }}>
                                    <AutocompleteText size={textSize} error={sposobRieseniaErr} decodeKey={props.decodeKey} inputRef={null} disabled={stavRieseniaID == 1 ? hotovostSuma > 0 || sekSuma > 0 || parseInt(pokuta) > 0 ? true : false : true} check={true} row={false} new_record={false} department={props.department} db={11} lang={lang} label={lang.sposob_riesenia} width={'100%'} value={sposobRiesenia} value_id={sposobRieseniaID} func={SetSposobRiesenia.bind(this)} />
                                </div>

                                <div style={{ width: '100%' }}>
                                    {stavRieseniaID == 1 ?
                                        <div style={{ ...styles.BlockLeft, marginTop: boxOffset }}>
                                            <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.datum_doriesenia}</p>
                                            <TextField
                                                onKeyDown={(event) => { CheckKey(event) }}
                                                size={textSize} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={datumDoriesenia} onInput={e => setDatumDoriesenia(e.target.value)} />
                                        </div>
                                        :
                                        <div style={{ ...styles.BlockLeft, marginTop: boxOffset }}>
                                            <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.datum_doriesenia}</p>
                                            <TextField
                                                onKeyDown={(event) => { CheckKey(event) }}
                                                size={textSize} disabled={true} style={{ width: '100%', backgroundColor: global.theme_gray }} label={''} variant={textvariant} value={''} />
                                        </div>
                                    }

                                </div>

                                <div style={{ ...styles.Block, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.zakrok}</p>
                                    <Select
                                        multiple
                                        displayEmpty
                                        value={zakroky}
                                        open={zakrokyOpen}
                                        onClose={() => setZakrokyOpen(false)}
                                        onOpen={() => setZakrokyOpen(true)}
                                        onChange={zakrokChange}
                                        renderValue={(selected) => selected.length == 0 ? lang.no : lang.yes}
                                        size={textSize}
                                        variant={textvariant}
                                        style={{ backgroundColor: global.theme_white, width: '100%' }}
                                    >
                                        <MenuItem onClick={() => setZakrokyOpen(false)} disabled={false} value='' style={{ backgroundColor: global.theme_dark_green, color: global.theme_white }}>
                                            <div style={{ ...styles.Block }}>
                                                <p style={{ ...styles.TextSmall }}>
                                                    {lang.close}
                                                </p>
                                            </div>
                                        </MenuItem>
                                        {lang.zakroky.map((item) => (
                                            <MenuItem key={item.id} value={item.id} style={{ marginBottom: item.sub == true ? 10 : 0 }} disabled={item.sub == true ? zakroky.indexOf(item.root) > -1 ? false : true : false}>
                                                <Checkbox style={{ marginTop: 0, marginLeft: item.sub == true ? 30 : 0, marginRight: 5, padding: 0 }} checked={zakroky.indexOf(item.id) > -1} />
                                                <p style={{ ...styles.TextTiny }}>{item.label}</p>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            {/* HOTOVOST, SEK */}
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.Block, width: '100%' }}>
                                    {enableHotovost == true ?
                                        <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                            <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.hotovost}</p>
                                            <Button onClick={() => Hotovost()} style={{ width: '100%', backgroundColor: global.theme_light_gray, height: 50, color: global.theme_black, border: hotovostSumaErr == true ? '2px solid red' : '1px solid black' }}>
                                                {GetPrice(hotovostSuma)} €
                                            </Button>
                                        </div>
                                        : null}

                                    {enableSek == true ?
                                        <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                            <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.sek}</p>
                                            <Button onClick={() => Sek()} style={{ width: '100%', backgroundColor: global.theme_light_gray, height: 50, color: global.theme_black, border: '1px solid black' }}>
                                                {GetPrice(sekSuma)} €
                                            </Button>
                                        </div>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, width: '100%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '100%', marginTop: boxOffset }}>
                                    <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.popis_priestupku}</p>
                                    <TextField
                                        onKeyDown={(event) => { CheckKey(event) }}
                                        size={textSize}
                                        multiline={true}
                                        rows={3}
                                        style={{ width: '100%', backgroundColor: global.theme_white }}
                                        label={''}
                                        variant={textvariant}
                                        value={popis}
                                        onBlur={() => Next(2)}
                                        onInput={e => setPopis(e.target.value)} />
                                </div>
                                {/*
                                <div style={{ ...styles.BlockLeft }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.spis}</p>}
                                        control={<Checkbox checked={spis} size={textSize} style={{ marginTop: boxOffset }} onChange={() => setSpis(!spis)} />}
                                    />
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.obmedzenie_slobody}</p>}
                                        control={<Checkbox checked={obmedzenieSlobody} size={textSize} style={{ marginTop: boxOffset }} onChange={() => setObmedzenieSlobody(!obmedzenieSlobody)} />}
                                    />
                                </div>
                                */}
                            </div>
                        </div>
                    </div>

                    : null}

                {/* ************************************************************************************************************                
                        EVIDENCIA AUTA                
                    ************************************************************************************************************ */}
                {index == 2 ?
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 10 }}>Údaje o vozidle</p>
                        </div>

                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.auto_spz}</p>
                                <TextField
                                    onKeyDown={(event) => { CheckKey(event) }}
                                    size={textSize}
                                    onBlur={() => SPZBlur()}
                                    style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={textvariant} value={spz} onInput={e => setSPZ(e.target.value)} />
                            </div>

                            <div style={{ width: '100%', marginTop: boxOffset }}>
                                <AutocompleteText size={textSize} inputRef={null} error={autoTypErr} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={13} lang={lang} label={lang.auto_typ} width={'100%'} value={autoTyp} value_id={autoTypID} func={SetAutoTyp.bind(this)} />
                            </div>
                            <div style={{ width: '100%', marginTop: boxOffset }}>
                                <AutocompleteText size={textSize} inputRef={null} allowedit={true} decodeKey={props.decodeKey} check={true} row={false} new_record={true} department={props.department} db={12} lang={lang} label={lang.auto_druh} width={'100%'} value={autoDruh} value_id={autoDruhID} func={SetAutoDruh.bind(this)} />
                            </div>
                            <div style={{ width: '100%', marginTop: boxOffset }}>
                                <AutocompleteText size={textSize} inputRef={null} allowedit={true} decodeKey={props.decodeKey} check={true} row={false} new_record={true} department={props.department} db={14} lang={lang} label={lang.auto_farba} width={'100%'} value={autoFarba} value_id={autoFarbaID} func={SetAutoFarba.bind(this)} />
                            </div>
                        </div>
                    </div >
                    : null}

                {/* ************************************************************************************************************                
                        Fotografie
                    ************************************************************************************************************ */}
                {index == 3 ?
                    <div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 0 }}>
                        <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 10 }}>{lang.photos}</p>
                        </div>

                        <div style={{ ...styles.Block }}>
                            {photos.map((item, index) => (
                                <div key={item.id} style={{ ...styles.Block, marginTop: 0, marginBottom: 15 }}>
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray }}>
                                        <div style={{ ...styles.BlockRow, height: 36 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 8, marginLeft: 15, color: global.theme_dark_gray }}>{lang.photo}: {index + 1}</p>
                                            </div>
                                            {item.new == true ?
                                                <div style={{ ...styles.BlockRight, width: '20%' }}>
                                                    <IconButton onClick={() => DeletePhoto(item)} style={{ width: 34, height: 34, marginRight: 10 }}>
                                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                                                    </IconButton>
                                                </div>
                                                : null}
                                        </div>

                                        <img onClick={() => PreviewPhoto(item)} src={global.web + '/' + item.path + item.filename} style={{ width: '100%', height: '100%', maxWidth: '90%', maxHeight: 300, objectFit: 'contain' }}></img>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <Button variant="contained" component="label" style={{ ...styles.ButtonMobile }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                {lang.photos_add}
                                <input ref={inputRef} onChange={SelectFile} hidden accept="image/jpeg,image/png,application/pdf,video/mp4" multiple type="file" />
                            </Button>
                        </div>
                    </div>
                    : null}


                <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20 }}>
                    <div style={{ ...styles.Block, paddingBottom: 20 }}>
                        {error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRight, width: '100%', justifyContent: 'center', paddingBottom: 20, paddingBottom: 40, borderTop: '1px solid #AAAAAA', backgroundColor: global.theme_light_gray }}>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            {props.preview == false ?
                                <Button onClick={() => Save(true)} style={{ ...styles.ButtonGreenMobile, marginBottom: 20 }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 12, height: 12, marginRight: 8 }} icon={faLock} />
                                    {lang.save_finish}
                                </Button>
                                : null}
                            {props.preview == false ?
                                <Button onClick={() => Save(false)} style={{ ...styles.ButtonDarkMobile, marginBottom: 60 }}>
                                    {lang.save}
                                </Button>
                                : null}
                            <Button onClick={() => AskClose()} style={{ ...styles.ButtonMobileCancel }}>
                                <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14, marginRight: 8 }} icon={faXmark} />
                                {lang.cancel}
                            </Button>
                        </div>
                    </div>
                </div>

            </div>


            {/* ************************************************************************************************************                
                VYBER POKUTOVEHO BLOKU / SEKU
                ************************************************************************************************************ */}
            {
                showHotovost == true ?
                    <PriestupokBloky mobile={true} decodeKey={props.decodeKey} meno={riesitel1} meno_id={riesitelID1} variant={0} items={hotovost} label={lang.blocks_used} lang={lang} department={props.department} user={props.user} permissions={props.permissions} editing={props.editing} func={PriestupokBlokyResult.bind(this)} />
                    : null
            }

            {
                showSek == true ?
                    <PriestupokBloky mobile={true} decodeKey={props.decodeKey} meno={riesitel1} meno_id={riesitelID1} variant={1} items={sek} label={lang.blocks_used} lang={lang} department={props.department} user={props.user} permissions={props.permissions} editing={props.editing} func={PriestupokSekyResult.bind(this)} />
                    : null
            }


            {showPhotoPreview == true ?
                <DialogMobilePhotoPreview image={photoSelected} func={DialogPhotoPreviewResult.bind(this)} />
                : null}

            {
                isBusy ?
                    <Loading center={true} />
                    : null
            }

            {showAskClose == true ?
                <AskDialog label='Zatvoriť priestupok' question='Chcete zatvoriť neuložený priestupok?' text='Zadané údaje nebudú uložené!' button='Áno' button2='Nie' func={AskCloseResult.bind(this)} />
                : null}
            {
                isLoading ?
                    <Loading center={true} />
                    : null
            }
        </div >
    );
}


