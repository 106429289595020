// STATISTIKY - DAFAULT DIALOG

import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, StatsSearch, GetDefaultFilter, GetSearchText, DialogEnterNote, DialogInfo, DialogSelectRows, CheckPermision } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarChart, faFileExport, faFilter, faListDots, faPrint, faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenuContainer,
    GridToolbarContainer, GridToolbarExport
} from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { compose } from '@mui/system';
import { Graph } from './graph.js';

export const StatsDialog = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [column1, setColumn1] = useState([]);
    const [column2, setColumn2] = useState([]);
    const [subTitle, setSubTitle] = useState('');

    const [index, setIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [hideZero, sethideZero] = useState(false);
    const [hideZeroDetails, sethideZeroDetails] = useState(false);

    const [detailsIndex, setDetailsIndex] = useState(0);
    const [itemsDetails, setItemsDetails] = useState([]);
    const [itemsDetailsFiltered, setItemsDetailsFiltered] = useState([]);

    const search_default1 = GetDefaultFilter(props.obvod);
    const search_default2 = GetDefaultFilter(props.obvod);
    const [search1, setSearch1] = useState(search_default1);
    const [search2, setSearch2] = useState(search_default2);
    const [searchText1, setSearchText1] = useState('');
    const [searchText2, setSearchText2] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [selectionModel, setSelectionModel] = useState();
    const [tab, setTab] = useState(0);

    const [showExport, setShowExport] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showSelect, setShowSelect] = useState(false);

    const [graphData, setGraphData] = useState([]);
    const [showGraph, setShowGraph] = useState(false);

    const [sum, setSum] = useState(['', '', '', '', '', '', '', '', '']);
    const [sumDetails, setSumDetails] = useState(['', '', '', '', '', '', '', '', '']);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);



    var language = 0;
    var lang = sk;

    let params = useParams();

    useEffect(() => {

        // NAZOV PRVEHO STLPCA
        var tmp = props.columns1;
        tmp[0].field = props.field1;
        tmp[0].headerName = props.header_name;

        setColumn1(tmp);
        setColumn2(props.columns2);

        // FILTER - LEN AKTUALNY ROK;

        setSearch1(search_default1);
        setSearchText1(GetSearchText(search_default1));

        setSearch2(search_default2);
        setSearchText2(GetSearchText(search_default2));

        db_get(search_default1);

        return () => {
        };

    }, []);

    const db_get = async (data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + props.db1, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    filter: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                var tmp = json.items;

                if (data.fields != undefined) {
                    tmp = tmp.filter(x => data.fields.includes(x.id));
                }

                if (hideZero == true) {
                    tmp = tmp.filter(x => x.celkom_pocet > 0);
                }
                setItemsFiltered(tmp);

                setIndex(0);
                setSelectionModel(0); // DESELECT LINE

                if (json.stats != undefined) {
                    setGraphData(json.stats);
                } else {
                    setGraphData([]);
                }
                setSum(json.sum);

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_get_details = async (id, data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + props.db2, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    item: itemSelected,
                    filter: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (data.fields != undefined) {
                    tmp = tmp.filter(x => data.fields.includes(x.id));
                }

                if (hideZeroDetails == true) {
                    tmp = tmp.filter(x => x.celkom_pocet > 0);
                }
                //tmp = tmp.filter(x => x.celkom_pocet > 0);
                setItemsDetails(tmp);
                setItemsDetailsFiltered(tmp);
                setDetailsIndex(0);
                setSelectionModel(0); // DESELECT LINE                

                setSumDetails(json.sum);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_export = async (note, pdf, xls) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'stats_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    title: props.title,
                    sub_title: tab == 0 ? '' : subTitle,
                    grid_columns: tab == 0 ? column1 : column2,
                    grid_data: tab == 0 ? itemsFiltered : itemsDetailsFiltered,
                    grid_sum: tab == 0 ? sum : sumDetails,
                    token: params.apptoken,
                    note: note,
                    user: props.user,
                    filter: tab == 0 ? searchText1.trim() : searchText2.trim(),
                    pdf: pdf,
                    xls: xls,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            } else {
                setShowInfo(true);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);

        // DEFINOVANIE SUB-TITLE v PDF
        if (props.typ == 1) {
            // priestupky riesitela - meno, priezvisko
            setSubTitle(' - ' + data.name + ' ' + data.surname);
        }

        setItemSelected(data);
        setIndex(id);
    }

    const ChangeTab = (event, value) => {
        setTab(value);

        if (value == 0) {
            if (props.table_selected != true) {
                setItems([]);
                setItemsFiltered([]);
                db_get(search1);
            }
        }

        if (value == 1) {
            setItemsDetails([]);
            setItemsDetailsFiltered([]);
            db_get_details(itemSelected.id, search2);
        }
    }

    const ShowSearch = () => {
        setShowSearch(true);
    }

    const StatsSearchResult = (typ, data) => {

        setShowSearch(false);
        if (typ == 1) {
            if (tab == 0) {
                setSearch1(data);
                setSearchText1(GetSearchText(data));

                db_get(data);
            }
            if (tab == 1) {
                setSearch2(data);
                setSearchText2(GetSearchText(data));

                db_get_details(itemSelected.id, data);
            }
        }
    }


    const RestoreSearch = () => {
        if (tab == 0) {
            setSearch1(search_default1);
            setSearchText1(GetSearchText(search_default1));

            db_get(search_default1);
        }
        if (tab == 1) {
            setSearch2(search_default2);
            setSearchText2(GetSearchText(search_default2));

            db_get_details(itemSelected.id, search_default2);
        }
    }


    const DialogEnterNoteResult = (typ, note, pdf, xls) => {
        setShowExport(false);

        if (typ == true) {
            db_export(note, pdf, xls);
        }
    }

    const DialogInfoResult = () => {
        setShowInfo(false);
    }

    const ChangehideZero = () => {
        var value = !hideZero;
        sethideZero(value);
        if (value == true) {
            var tmp = items.filter(x => x.celkom_pocet > 0);
            setItemsFiltered(tmp);
        } else {
            var tmp = items;
            setItemsFiltered(tmp);
        }
    }

    const ChangehideZeroDetails = () => {
        var value = !hideZeroDetails;
        sethideZeroDetails(value);
        if (value == true) {
            var tmp = itemsDetails.filter(x => x.celkom_pocet > 0);
            setItemsDetailsFiltered(tmp);
        } else {
            var tmp = itemsDetails;
            setItemsDetailsFiltered(tmp);
        }
    }


    const ShowSelect = () => {
        setShowSelect(true);
    }

    const DialogSelectRowsResult = (typ, data) => {
        setShowSelect(false);
        if (typ == true) {
            if (tab == 0) {
                if (data.length > 0) {
                    search1.fields = data;
                    search1.default = false;
                } else {
                    if (search1.fields != undefined) {
                        delete search1.fields;
                    }
                }
                setSearchText1(GetSearchText(search1));
                db_get(search1);
            }
            if (tab == 1) {
                if (data.length > 0) {
                    search2.fields = data;
                    search2.default = false;
                } else {
                    if (search2.fields != undefined) {
                        delete search2.fields;
                    }
                }
                setSearchText2(GetSearchText(search2));
                db_get_details(itemSelected.id, search2);
            }
        }
    }

    const Export = (typ) => {
        setShowExport(true);
    }

    const GraphResult = (value) => {
        if (value == -10) {
            setShowGraph(false);
        }
    }


    const CustomColumnMenuComponent = (props) => {
        const [text, setText] = useState('');
        const { hideMenu, currentColumn, color, ...other } = props;
        const textRef = useRef(null);
        const timer = useRef();

        useEffect(() => {

            if (currentColumn.filterable == true) {
                textRef.current.focus();
            }

            return () => {
            };

        }, []);

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                ownerState={{ color }}
                {...other}
            >
                {/*
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                */}
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, margin: 10 }}>
                        {currentColumn.filterable == true ?
                            <TextField
                                inputRef={textRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: 200 }}
                                label={''} variant="outlined"
                                value={text}
                                placeholder={currentColumn.headerName}
                                onInput={e => setText(e.target.value)}
                                onBlur={hideMenu}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (text.trim() != '') {

                                            var data = {
                                                text: text,
                                                column: currentColumn.field,
                                                search: true
                                            }
                                            db_get(data);
                                        }
                                    }

                                }}
                            />
                            :
                            <p style={{ ...styles.TextTiny, marginTop: 9 }}>{currentColumn.headerName}</p>
                        }
                    </div>
                    <IconButton onClick={hideMenu} style={{ ...styles.Block, height: 36, width: 36, marginTop: 10, marginRight: 10 }}>
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 12 }} icon={faXmark} />
                    </IconButton>
                </div>
            </GridColumnMenuContainer>
        );
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>
        );
    }

    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>

            {props.typ == 1 ?
                <Tabs
                    value={tab}
                    onChange={ChangeTab}
                    TabIndicatorProps={{
                        style: {
                            color: 'red',
                            backgroundColor: global.theme_light
                        }
                    }}
                    style={{ width: 400 }}
                >
                    <Tab value={0} style={{ width: 200 }} label={props.tab1} />
                    <Tab value={1} style={{ width: 200 }} label={props.tab2} disabled={props.table_selected == true ? index > 0 ? false : true : false} />

                </Tabs>
                : null}

            {tab == 0 ?
                // *************************************************************************************
                //    ZOZNAM
                // *************************************************************************************

                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>

                        {/* FILTER */}
                        <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.filtered}:</p>
                            {search1.default == true ?
                                <Chip
                                    label={searchText1}
                                    style={{ marginLeft: 10, marginTop: 10 }}
                                />
                                :
                                <Chip
                                    label={searchText1}
                                    onDelete={() => RestoreSearch()}
                                    style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                                />
                            }
                            <div style={{ paddingTop: 7, marginLeft: 30 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.stats_show_zero}</p>}
                                    control={<Checkbox checked={hideZero} size={'small'} onChange={() => ChangehideZero()} />}
                                />
                            </div>

                        </div>


                        <Box sx={{
                            height: 490,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{
                                    fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color,
                                    // HEADER TEXT WRAP
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        textAlign: 'center',
                                        fontSize: global.font_table,
                                        fontWeight: 'bold',
                                        lineHeight: 1.1
                                    }
                                }}
                                headerHeight={70}

                                rows={itemsFiltered}
                                columns={column1}


                                // vlastná menu po kliknuti na header menu
                                components={{
                                    ColumnMenu: CustomColumnMenuComponent,
                                    //Toolbar: CustomToolbar
                                }}

                                rowHeight={30}
                                onRowClick={(params) => { ChangeIndex(params.id) }}
                                //onCellKeyDown={(params,event) => { console.log(event) }}

                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (`super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 5, paddingBottom: 5, backgroundColor: global.theme_white, height: 18 }}>
                        {column1.map((item, index) => (
                            <div key={index} style={{ ...styles.BlockLeft, alignItems: item.sum_align, width: item.width, borderLeft: index == 0 ? '' : '1px solid' + global.theme_gray }}>
                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.sum_enabled == true ? sum[index] : ''}</p>
                            </div>
                        ))}
                    </div>
                    <div style={{ ...styles.BlockRow, paddingTop: 30, paddingBottom: 10 }}>
                        <div style={{ ...styles.BlockRow, width: '70%' }}>

                            {CheckPermision(props.permissions, global.permission_read) == true ?
                                <div style={{ ...styles.BlockLeft, width: 160 }}>
                                    <Button onClick={() => ShowSearch()} style={{ ...styles.ButtonAction }}>
                                        <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFilter} />
                                        <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.filter}</p>
                                    </Button>
                                </div>
                                : null}


                            {props.editing == true && CheckPermision(props.permissions, global.permission_read) == true ?
                                <div style={{ ...styles.BlockLeft, width: 160 }}>
                                    <Button onClick={() => ShowSelect()} style={{ ...styles.ButtonAction }}>
                                        <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faListDots} />
                                        <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.select}</p>
                                    </Button>
                                </div>
                                : null}

                            {props.graph == true ?
                                CheckPermision(props.permissions, global.permission_read) == true ?
                                    <div style={{ ...styles.BlockLeft, width: 160 }}>
                                        <Button onClick={() => setShowGraph(true)} style={{ ...styles.ButtonAction }}>
                                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faBarChart} />
                                            <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.graph}</p>
                                        </Button>
                                    </div>
                                    : null : null}


                        </div>
                        <div style={{ ...styles.BlockRight, width: '30%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {CheckPermision(props.permissions, global.permission_print) == true ?
                                    <Button onClick={() => Export(0)} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                        <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFileExport} />
                                        <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.exporting}</p>
                                    </Button>
                                    : null}
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get(search1)}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {tab == 1 ?
                // *************************************************************************************
                //  DETAILNY ROZPIS
                // *************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>

                    {/* FILTER */}
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                        <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.filtered}:</p>
                        {search2.default == true ?
                            <Chip
                                label={searchText2}
                                style={{ marginLeft: 10, marginTop: 10 }}
                            />
                            :
                            <Chip
                                label={searchText2}
                                onDelete={() => RestoreSearch()}
                                style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                            />
                        }
                        <div style={{ paddingTop: 7, marginLeft: 30 }}>
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.stats_show_zero}</p>}
                                control={<Checkbox checked={hideZeroDetails} size={'small'} onChange={() => ChangehideZeroDetails()} />}
                            />
                        </div>

                    </div>

                    <div style={{ ...styles.Block, width: '100%' }}>
                        <Box sx={{
                            height: 490,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{
                                    fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color,
                                    // HEADER TEXT WRAP
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        textAlign: 'center',
                                        fontSize: global.font_table,
                                        fontWeight: 'bold',
                                        lineHeight: 1.1
                                    }
                                }}
                                headerHeight={70}

                                rows={itemsDetailsFiltered}
                                columns={props.columns2}


                                // vlastná menu po kliknuti na header menu
                                components={{
                                    ColumnMenu: CustomColumnMenuComponent,
                                    //Toolbar: CustomToolbar
                                }}

                                rowHeight={30}
                                onRowClick={(params) => { ChangeIndex(params.id) }}
                                //onCellKeyDown={(params,event) => { console.log(event) }}

                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (`super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 5, paddingBottom: 5, backgroundColor: global.theme_white, height: 18 }}>
                        {column2.map((item, index) => (
                            <div key={index} style={{ ...styles.BlockLeft, alignItems: item.sum_align, width: item.width, borderLeft: index == 0 ? '' : '1px solid' + global.theme_gray }}>
                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.sum_enabled == true ? sumDetails[index] : ''}</p>
                            </div>
                        ))}
                    </div>
                    <div style={{ ...styles.BlockRow, paddingTop: 30, paddingBottom: 10 }}>
                        <div style={{ ...styles.BlockRow, width: '70%' }}>
                            {CheckPermision(props.permissions, global.permission_read) == true ?
                                <div style={{ ...styles.BlockLeft, width: 160 }}>
                                    {props.table_selected != true ?
                                        <Button onClick={() => ShowSearch()} style={{ ...styles.ButtonAction }}>
                                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFilter} />
                                            <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.filter}</p>
                                        </Button>
                                        : null}
                                </div>
                                : null}


                            {props.editing == true && CheckPermision(props.permissions, global.permission_read) == true ?
                                <div style={{ ...styles.BlockLeft, width: 160 }}>
                                    {props.table_selected != true ?
                                        <Button onClick={() => ShowSelect()} style={{ ...styles.ButtonAction }}>
                                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faListDots} />
                                            <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.select}</p>
                                        </Button>
                                        : null}
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockRight, width: '30%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {CheckPermision(props.permissions, global.permission_print) == true ?
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <Button onClick={() => Export(1)} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFileExport} />
                                            <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.exporting}</p>
                                        </Button>
                                    </div>
                                    : null}
                                {props.table_selected != true ?
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ width: 50 }}>
                                            <IconButton onClick={() => db_get(search2)}>
                                                <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                </div>
                : null}

            {/* VYHLADAVANIE */}
            {showSearch == true ?
                <StatsSearch typ={0} data={tab == 0 ? search1 : search2} department={props.department} lang={lang} func={StatsSearchResult.bind(this)} />
                : null}

            {/* EXPORT */}
            {showExport == true ?
                <DialogEnterNote func={DialogEnterNoteResult.bind(this)} />
                : null}

            {/* INFO */}
            {showInfo == true ?
                <DialogInfo label={lang.error} text={lang.stats_export_error} error={true} func={DialogInfoResult.bind(this)} />
                : null}

            {/* SELECT - vyber riadkov štatistiky */}
            {showSelect == true ?
                <DialogSelectRows items={tab == 0 ? items : itemsDetails} column={tab == 0 ? props.field1 : props.field2} func={DialogSelectRowsResult.bind(this)} />
                : null}

            {/* GRAF*/}
            {showGraph == true ?
                <Graph
                    elevation={0}
                    title={props.title}
                    sub_title={''}
                    width={1200}
                    height={400}
                    typ={0}
                    data={graphData}
                    barWidth={props.graph_settings.size}
                    show_value={props.graph_settings.show_values}
                    highColor={global.theme_red}
                    overColor={global.theme_blue}
                    showMax={false}
                    lines={true}
                    units={''}
                    marginTop={0}
                    selectable={true}
                    tableName={'Priestupky celkom'}
                    tableName2={'Priestupky s pokutou'}
                    legenda={'Priestupky celkom'}
                    legenda2={'Priestupky s pokutou'}
                    func={GraphResult.bind(this)}
                />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div >
    )
};

