/*
*
* =================================================================================================================
* MOBILE - LOGIN do systému evid
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, LoadLogin, LoadUser, LoadLanguage, SaveUser, SaveLogin, SaveDepartment, SaveObvod, ResetLogin } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import CircularProgress from '@mui/material/CircularProgress';


export const MobileLogin = (props) => {
    const navigate = useNavigate();

    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    //APP
    const [isBusy, setBusy] = useState(true);

    const [name, setName] = useState(-1);
    const [pin, setPin] = useState('');
    const [pinTry, setPinTry] = useState(0);
    const [button, setButton] = useState(-1);
    const [pinIndex, setPinIndex] = useState(2);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);
    const [decodeKey, setDecodeKey] = useState('');
    const [users, setUsers] = useState([]);
    const [showToken, setShowToken] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const maxTry = 5;
    const logoSize = 140;
    const radius = 10;
    const logo = require('./react/app/logo_circle.png');

    var lang = language;
    let params = useParams();


    useEffect(() => {

        // LANGUAGE        
        setLanguage(LoadLanguage());
        setPinTry(maxTry);

        // RESETOVANIE PRIHLASENIA
        ResetLogin();
        SaveUser([]);


        db_token(params.apptoken);

        return () => {
        };

    }, []);




    // *****************************************************************************
    // KONTROLA TOKENU APLIKACIE
    // *****************************************************************************
    const db_token = async (token) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'token_app_check_mobile', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setUsers(json.users);
                setDecodeKey(json.decode_key);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_pin = async (pin) => {
        setBusy(true);

        // expiracia prihlásenia
        var expire = new Date() / 1000 + global.login_mobile_expire;

        try {
            const response = await fetch(
                global.db_url + 'login_pin', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: name,
                    pin: pin,
                    decode_key: decodeKey,
                    expire: expire
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (json.verifed == true) {
                    // ------------------------------------------------------------------------------------
                    // PIN JE SPRAVNY
                    // ------------------------------------------------------------------------------------
                    var data = { token: json.token, expire: expire };
                    SaveLogin(data);

                    // Hlavný screen
                    navigate('/mobile/' + params.apptoken);

                } else {
                    // ------------------------------------------------------------------------------------
                    // PIN JE NESPRAVNY
                    // ------------------------------------------------------------------------------------
                    setError(lang.pin_error);
                    var pin_try = pinTry;
                    pin_try--;
                    if (pin_try < 1) {
                        setError(lang.pin_error_max);
                        setIndex(2);
                        var timer = setTimeout(() => {
                            setIndex(0);
                        }, 60 * 1000);

                    } else {
                        var timer = setTimeout(() => {
                            setPinIndex(0);
                            setPin('');
                        }, 500);
                    }
                    setPinTry(pin_try);
                }
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const Process = (value) => {
        if (value == 1) {
            setPinIndex(0);
            setError('');
            setPin('');
        }
        setIndex(value)
    }

    const Press = (value) => {
        var txt = pin;
        txt += value.toString();
        var index = pinIndex;
        index++;

        setPin(txt);
        setButton(value);
        setPinIndex(index);

        if (pinIndex >= 0) {
            setError('');
        }

        if (index == 6) {
            db_pin(txt);
        }

        var timer = setTimeout(() => {
            setButton(-1);
        }, 150);

    }

    const clear = () => {
        setPin('');
        setPinIndex(0);
    }

    const ChangeName = (value) => {
        setName(value);
        setPinTry(maxTry);
    }

    const handleError = (err) => {
        console.err(err)
    }

    const handleScan = (result) => {
        console.err(result);
    }


    return (
        <div style={{ ...styles.Block, background: global.theme_mobile_background, width: width, height: height }}>
            {index == 0 ?
                // *********************************************************************************************************
                // VYBER MENA A PRIEZVISKA
                // *********************************************************************************************************
                <div style={{ ...styles.Block, height: height }}>
                    <div style={{ ...styles.Block, height: height / 2, background: global.theme_mobile_background }}>
                        <Paper elevation={5} style={{ ...styles.Block, width: logoSize, height: logoSize, borderRadius: logoSize / 2 }}>
                            <img onClick={() => setShowToken(!showToken)} src={logo} style={{ width: '100%', height: '100%', maxWidth: logoSize, maxHeight: logoSize, objectFit: 'contain' }}></img>
                        </Paper>
                        <p style={{ ...styles.TextXXLarge, color: global.theme_white, marginTop: 10, fontWeight: '500' }}>Evid M</p>
                        <p style={{ ...styles.TextXXTiny, color: global.theme_white, marginTop: 3 }}>{global.version_mobile}</p>

                        <div style={{ ...styles.Block, height: 20 }}>
                            {showToken == true ?
                                <p style={{ ...styles.TextXXTiny, color: global.theme_dark_blue, marginTop: 0 }}>#{params.apptoken.toString().substr(0, 8)}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: height / 2, backgroundColor: global.theme_back, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockCenter, height: 70 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'bold' }}>{lang.login}</p>
                        </div>
                        <div style={{ ...styles.BlockCenter, height: (height / 2) - 150 }}>
                            <p style={{ ...styles.TextTinyMobile }}>{lang.name_surname}</p>
                            <Select
                                value={name}
                                onChange={event => ChangeName(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '95%', marginTop: 5 }}
                            >
                                <MenuItem disabled key={-1} value={0}>Vyberte</MenuItem>
                                {users.map((item, index) => (
                                    <MenuItem key={item.id} value={item.id}>{item.surname} {item.name} ({item.badge})</MenuItem>
                                ))}

                            </Select>
                        </div>
                        <div style={{ ...styles.Block, height: 80 }}>
                            {name >= 0 ?
                                <Button onClick={() => Process(1)} style={{ ...styles.ButtonDarkMobile, backgroundColor: global.theme_darker_green }}>                                    
                                    {lang.continue}
                                    <FontAwesomeIcon style={{ height: 16, marginLeft: 15 }} icon={faAngleRight} />
                                </Button>
                                : null}
                        </div>
                    </div>
                </div>
                : null}

            {
                index == 1 ?
                    // *********************************************************************************************************
                    // ZADANIE PINU
                    // *********************************************************************************************************

                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>Zadajte PIN</p>
                        <div style={{ ...styles.BlockRow, width: '90%', height: 20, marginTop: 15, backgroundColor: global.theme_dark, paddingTop: 10, paddingBottom: 10, borderRadius: radius, border: '1px solid ' + global.theme_light }}>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 0 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 1 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 2 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 3 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 4 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                            <div style={{ ...styles.Block, width: '16%' }}>
                                <div style={{ ...styles.TextPin, backgroundColor: pinIndex > 5 ? global.theme_white : global.theme_darker }}></div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, height: 50, marginTop: 5 }}>
                            {isBusy == true ?
                                <CircularProgress size={20} style={{ color: global.theme_white }}></CircularProgress>
                                :
                                error != '' ?
                                    <p style={{ ...styles.TextSmall, color: global.theme_yellow }}>
                                        <FontAwesomeIcon style={{ width: 16, color: global.theme_yellow, marginRight: 10 }} icon={faWarning} />
                                        {error}
                                    </p>
                                    : null}
                            {error != '' ?
                                <p style={{ ...styles.TextTiny, color: global.theme_yellow, marginTop: 5 }}>{lang.pin_try}: {pinTry}</p>
                                : null}
                        </div>

                        <div style={{ ...styles.Block, marginTop: 30 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(1)} style={{ ...styles.ButtonPin, backgroundColor: button == 1 ? global.theme_darker : global.theme_dark }}>1</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(2)} style={{ ...styles.ButtonPin, backgroundColor: button == 2 ? global.theme_darker : global.theme_dark }}>2</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(3)} style={{ ...styles.ButtonPin, backgroundColor: button == 3 ? global.theme_darker : global.theme_dark }}>3</div>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(4)} style={{ ...styles.ButtonPin, backgroundColor: button == 4 ? global.theme_darker : global.theme_dark }}>4</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(5)} style={{ ...styles.ButtonPin, backgroundColor: button == 5 ? global.theme_darker : global.theme_dark }}>5</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(6)} style={{ ...styles.ButtonPin, backgroundColor: button == 6 ? global.theme_darker : global.theme_dark }}>6</div>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(7)} style={{ ...styles.ButtonPin, backgroundColor: button == 7 ? global.theme_darker : global.theme_dark }}>7</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(8)} style={{ ...styles.ButtonPin, backgroundColor: button == 8 ? global.theme_darker : global.theme_dark }}>8</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(9)} style={{ ...styles.ButtonPin, backgroundColor: button == 9 ? global.theme_darker : global.theme_dark }}>9</div>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <Button onClick={() => Process(0)} style={{ width: 80, height: 80, borderRadius: '50%', color: global.theme_light }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faAngleLeft} />
                                        {lang.back}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <div onClick={() => Press(0)} style={{ ...styles.ButtonPin, backgroundColor: button == 0 ? global.theme_darker : global.theme_dark }}>0</div>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <Button onClick={() => clear()} style={{ width: 80, height: 80, borderRadius: '50%', color: global.theme_light }}>
                                        {lang.delete}
                                    </Button>

                                </div>
                            </div>
                        </div>

                    </div>
                    : null
            }

            {
                index == 2 ?
                    // *********************************************************************************************************
                    // DOSIAHNUTY MAX.POCET POKUSOV
                    // *********************************************************************************************************

                    <div style={{ ...styles.Block, height: height }}>
                        <FontAwesomeIcon style={{ height: 50, color: global.theme_red }} icon={faWarning} />
                        <p style={{ ...styles.TextSmall, color: global.theme_white, marginTop: 20 }}>{lang.pin_error_max}</p>
                    </div>
                    : null
            }

        </div >
    )
};

