import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IsSmall, styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './items.css';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { columnsLogs } from './statistic_columns.js';
import GoogleMapReact from 'google-map-react';

const pin = require('./react/app/pin.png');


/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }
}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');
    number = number.replaceAll(',', '.');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

export function strShuffle(s) {
    // tvorba náhodného kluca 
    var arr = s.split('');

    arr.sort(function () {
        return 0.5 - Math.random();
    });
    s = arr.join('');
    return s;
}

export function strShuffleCode(key, pass) {
    // tvorba kluca na základe hesla
    // key - kodovaci retazec
    // pass - heslo k zakodovaniu

    var n = 0;
    var sume = 0;
    while (n < pass.length) {
        sume += parseInt(pass.charCodeAt(n));
        n++;
    }
    sume = sume % 10;
    var offset = (sume * 4) % key.length;
    var code = key.substring(offset, key.length) + key.substring(0, offset);
    var arr = code.split('');

    n = 0;
    arr.sort(function () {
        let rand = pass.charCodeAt(n) % 10;
        n++;
        if (n == pass.length) {
            rand = sume;
            n = 0;
        }
        return rand - 5;
    });

    var str = arr.join('');
    return str;
}


export function getGridCardArray(password) {
    // TVORBA Grid Card kódov =>>> 64 * 4characters z hesla -> vráti array['aaaa','bbbb'...]

    // sifrovaci retazec - 256 znakov - tvorba GRID karty
    const grid_string = 'Q8ERTZUPASDFGHJKLYXCVBNMU123456789Q8ERTZUPASDFGHJKLYXCVBNML123456789Q8ERTZUPASDFGHJKLYXCVBNMS123456789Q8ERTZUPASDFGHJKLYXCVBNMQ123456789Q8ERTZUPASDFGHJKLYXCVBNMK123456789Q8ERTZUPASDFGHJKLYXCVBNMG123456789Q8ERTZUPASDFGHJKLYXCVBNMM123456789Q8ERTZUPASDFGHJKLY';

    // zasifrovany sifrovaci retazec heslom
    var string = strShuffleCode(grid_string, password);

    // tvorba grid kodov 64 * 4znaky 
    var n = 0;
    var codes = [];
    while (n < string.length) {
        codes[n / 4] = string.substring(n, n + 4);
        n += 4;
    }

    return codes;
}

export function getRndGridCode(password) {
    // vráti náhodný kód z grid karty -> slúži na overenie

    // definovanie slpcov
    var columns = 'ABCDEFGH';

    // náhodne cislo 0-63
    var rnd = parseInt((Math.random() * 64) % 64);

    // array of codes
    var codes = getGridCardArray(password);

    // tvorba kodu - stlpec, riadok
    var col = rnd % 8;
    var row = parseInt(rnd / 8);
    var code = codes[rnd];
    var grid = columns.charAt(col) + (row + 1);

    return { code: code, grid: grid };
}

export function getSerialNumber() {
    // VYTVORENIE RANDOM SERIALOVEHO CISLA PRE GRID KARTU - 10 znakov
    var value = Date.now().toString();
    value = strShuffle(value).substring(0, 10);
    return value;
}

/*
*
* =================================================================================================================
* 
* =================================================================================================================
*
*/

export const Init = () => {
    //localStorage.removeItem('settings');
}

export const SaveLanguage = (lang) => {
    localStorage.setItem(global.local_language, lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem(global.local_language);
    if (lang != null) {
        var result = sk;

        if (lang == 0) {
            result = sk;
        }
        return result;
    } else {
        return sk;
    }
}

export const ResetLogin = () => {
    var data = { token: '', expire: 0 };
    SaveLogin(data);
}

export const LoadLogin = () => {
    var data = localStorage.getItem(global.local_login);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { logged: false, date: null };
    }
}

export const SaveLogin = (data) => {
    localStorage.setItem(global.local_login, JSON.stringify(data));
}

// ********************************************************
export const SaveUniqueID = (data) => {
    localStorage.setItem(global.local_unique_id, data);
}

export const LoadUniqueID = () => {
    var data = localStorage.getItem(global.local_unique_id);
    if (data != null) {
        return (data);
    } else {
        return null;
    }
}

// ********************************************************
export const LoadUser = () => {
    var data = localStorage.getItem(global.local_user);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveUser = (data) => {
    localStorage.setItem(global.local_user, JSON.stringify(data));
}


// ********************************************************
export const LoadDepartment = () => {
    var data = localStorage.getItem(global.local_department);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveDepartment = (data) => {
    localStorage.setItem(global.local_department, JSON.stringify(data));
}

// ********************************************************
export const LoadObvod = () => {
    var data = localStorage.getItem(global.local_obvod);
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveObvod = (data) => {
    localStorage.setItem(global.local_obvod, JSON.stringify(data));
}

// ********************************************************
export const LoadSettings = () => {
    var data = localStorage.getItem(global.local_settings);

    if (data != null) {
        return JSON.parse(data);
    } else {
        var tmp = {
            show: global.show_1,
            display: 0
        }
        return tmp;
    }
}

export const SaveSettings = (show, display) => {
    var data = { show: show, display: display };
    localStorage.setItem(global.local_settings, JSON.stringify(data));
}

// ********************************************************
export const Debug = (data, color, typ) => {
    /*
        USAGE:
        ---------------------------------------
        Debug('text');
        Debug('text', 'gray', 'info');
        Debug('text', 'yellow', 'debug');
        Debug('text', 'red', 'warn');

    */

    if (global.testing == true) {
        if (color == undefined && typ == undefined) {
            console.log(data);
        } else {
            if (typ == 'warn') {
                console.warn('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'info') {
                console.info('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'debug') {
                console.debug('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else {
                console.log('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            }
        }
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};


export const Menu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

    */

    const [index, setIndex] = useState(0);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(-1);
    const [name, setName] = useState('');
    const [town, setTown] = useState('');

    const heightMenuTop = 200;

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    //const logo = require('./react/app/logo_white.png');

    const boxHeight = 25;

    var lang = props.language;
    let { func } = props;
    const navigate = useNavigate();


    useEffect(() => {

        if (props.department != undefined) {
            setName(props.department.name);
            setTown(props.department.town);
        }

        if (props.body_id == 0) {
            setChildrenIndex(-1);
            setOpenAcc(-1);
        };

        return () => {
        };

    }, [props.department, props.obvod, props.body_id]);

    const Press = (type, id, item) => {
        func(type, parseInt(id));
    }

    const Click = (id, children_id, item) => {

        if (id == index && children_id == 0) {
            setIndex(0);
            setChildrenIndex(0);

            Press(0, 0, 0);
        } else {
            setIndex(id);
            setChildrenIndex(children_id);

            Press(id, children_id, item)
        }
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
        setChildrenIndex(-1);
    }

    const GoHome = () => {
        setChildrenIndex(-1);
        setOpenAcc(-1);
        Press(0, 0, 0);
    }

    const Submenu = (props) => {
        var children = props.item;

        return (
            <div style={{ width: '98%' }}>
                {
                    /* ------------------------------------------------
                        SUBMENU - CHILDRENS
                    ------------------------------------------------ */
                }
                {children != null ?
                    children.map((item, index) => (
                        (item.enabled == true && item.id > 0 && item.admin == false && props.department_id > 0) || (item.enabled == true && item.id > 0 && props.department_id == 0) ?
                            <Button key={index} onClick={() => Click(props.menu_id, item.id, item)} style={{ ...styles.ButtonMenu, background: childrenIndex == item.id ? global.theme_orange : global.theme_darker }}>
                                <FontAwesomeIcon style={{ width: childrenIndex == item.id ? 15 : 8, marginRight: 10, marginLeft: 15, color: childrenIndex == item.id ? global.theme_darker : global.theme_light_gray }} icon={childrenIndex == item.id ? faChevronRight : faCircle} />
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextTiny, color: childrenIndex == item.id ? global.theme_darker : global.theme_light_gray, fontWeight: childrenIndex == item.id ? 'bold' : 'normal' }}>
                                        {item.name}
                                    </p>
                                </div>
                            </Button >
                            : item.enabled == true && item.id == 0 ?
                                item.name == '' ?
                                    <div key={index} style={{ ...styles.Block, width: '80%', height: 1, borderTop: '1px solid ' + global.theme_dark, marginLeft: 20, marginTop: 10, marginBottom: 10 }}>
                                    </div>
                                    :
                                    <div key={index} style={{ ...styles.BlockLeft, width: '90%', marginLeft: 15, marginTop: 15, borderBottom: '1px solid ' + global.theme_dark, paddingBottom: 5 }}>
                                        <p style={{ ...styles.TextXXTiny, color: global.theme_light }}>{item.name.toUpperCase()}</p>
                                    </div>

                                : null
                    ))
                    : null
                }

            </div >
        )
    }

    return (
        <Paper elevation={10} style={{}} >
            <div style={{ ...styles.BlockMenu, height: props.height, borderRadius: 0 }}>
                <div style={{ ...styles.BlockCenter, height: heightMenuTop }}>
                    {/* LOGO */}
                    <div onClick={() => GoHome()} style={{ ...styles.Block, height: 50, backgroundColor: global.theme_darker, cursor: 'pointer' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <img onClick={() => GoHome()} src={global.images + 'app/logo_square.png'} style={{ maxWidth: 32 }}></img>
                            <p style={{ ...styles.TextXXLarge, fontWeight: 300, color: global.theme_white, marginLeft: 15, marginTop: 1 }}><b>EVID</b> SK</p>
                        </div>
                    </div>

                    {/* ODDELENIE */}
                    <div onClick={() => Press(1000, 0, 0)} style={{ ...styles.Block, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_lighter, width: global.menu_width, cursor: 'pointer' }}>
                        <div style={{ ...styles.Block, width: '95%' }}>
                            <p style={{ ...styles.TextNormal, color: global.theme_black, textAlign: 'center' }}>{name == undefined ? '' : name}</p>
                            {props.obvod != null ?
                                <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_black, textAlign: 'center' }}>{props.obvod.kod == global.centrala_kod ? '' : props.obvod.kod == undefined ? '' : lang.obvod + ': ' + props.obvod.kod}</p>
                                : null}
                        </div>
                    </div>

                    {/* HOME BUTTON */}
                    <Button onClick={() => GoHome()} style={{ ...styles.ButtonMenu, marginBottom: 10, marginTop: 20 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, marginLeft: 0, color: global.theme_light_gray }} icon={faHome} />
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextMenu, color: global.theme_white, marginLeft: 5 }}>
                                {lang.home}
                            </p>
                        </div>
                    </Button >
                </div>
                <div style={{ ...styles.BlockCenter, height: props.height - heightMenuTop, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    {/* MENU */
                        lang.menuitems.map(item => (
                            props.department.id > 0 || props.department.id == 0 && item.admin == true ?
                                <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ ...styles.BlockLeft, width: '100%', backgroundColor: global.theme_darker }}>
                                    <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_light_gray }} icon={faChevronUp} />}>
                                        <div style={{ ...styles.Block, width: 30, height: 30, backgroundColor: item.color, borderRadius: 15 }}>
                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={item.icon} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: boxHeight, width: global.menu_width - 125 }}>
                                            <p style={{ ...styles.TextMenu, color: global.theme_white, marginLeft: 10, textAlign: 'left', letterSpacing: 0.4 }}>{item.name}</p>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ ...styles.BlockLeft, width: global.menu_width - 40, margin: 0, padding: 0 }}>
                                        <Submenu department_id={props.department.id} item={item.children} menu_id={item.id} />
                                    </AccordionDetails>
                                </Accordion>
                                : null
                        ))
                    }
                </div>
            </div>

        </Paper>
    )

};



export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isOpen, setOpen] = useState(false);


    const [search, setSearch] = React.useState('');
    const [isBusy, setBusy] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [err, setErr] = React.useState('');
    const [showLogout, setShowLogout] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();

    //const logo = require('./react/app/logo.png');

    let { func } = props;
    var lang = props.language;

    const BarHeight = 50;
    const BarHeightSmall = 50;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, value, item) => {
        func(type, value);
    }

    const db_logout = async (txt) => {
        var data = LoadLogin();
        var token = data.token;
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'logout', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                var data = { token: '', expire: 0 };
                SaveLogin(data);
                navigate('/login/' + props.appToken);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }

    }


    const Logout = () => {
        setShowLogout(true);
    }

    const AskLogoutResult = (value) => {
        if (value == true) {
            db_logout();
        } else {
            setShowLogout(false);
        }
    }

    const User = () => {
        Press(999, null);
    }

    const OpenMenu = () => {
        setOpen(true);
    }

    const CloseMenu = () => {
        setOpen(false);
    }

    const ChangeMenu = (id) => {
        setOpen(false);

    }

    const MenuPress = (id, children_id, item) => {
        setOpen(false);
        func(id, children_id, item);
    }

    return (
        <div style={{ ...styles.BlockRow, background: global.theme_menu_top_gradient, height: isSmall ? BarHeightSmall : BarHeight, borderBottom: '1px solid ' + global.theme_light_gray }}>
            {isSmall ?
                <div style={{ ...styles.BlockRowRaw, width: 100, justifyContent: 'flex-start', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight, paddingLeft: 10 }}>
                    <IconButton onClick={() => OpenMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_black }} icon={faBars} />
                    </IconButton>
                    <IconButton onClick={() => Press(0)} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_dark }} icon={faHome} />
                    </IconButton>

                    <Drawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={CloseMenu}
                    >
                        <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_darker, width: global.menu_width, height: '100%' }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <IconButton onClick={() => CloseMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white }} icon={faXmark} />
                                </IconButton>
                            </div>
                            <Menu language={props.language} company={props.company} height={props.height} email={props.email} func={MenuPress.bind(this)} />
                        </div>
                    </Drawer>
                </div>
                :
                <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'center', alignItems: 'flex-start', height: BarHeight }}>
                    {props.label != null ?
                        <p style={{ ...styles.TextNormal, fontWeight: 400, color: global.theme_white, marginLeft: 10 }}>{props.label}</p>
                        :
                        null
                    }
                </div>
            }

            <div style={{ ...styles.BlockRight, width: isSmall ? '100%' : '50%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight }}>
                <div style={{ display: 'flex', marginRight: 10 }}>
                    <Button onClick={() => User()} style={{ textTransform: 'none', fontWeight: 'normal', margin: 0 }} >
                        {isSmall ? null :
                            <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_blue }} icon={faUser} />
                        }
                        <div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white }}>{props.name} {props.surname}</p>
                        </div>
                    </Button>
                    <IconButton onClick={() => Logout()} style={{ width: 35, height: 35, borderRadius: 0, marginLeft: 10 }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faRightFromBracket} />
                    </IconButton>
                </div>
            </div>

            {showLogout == true ?
                <AskLogout func={AskLogoutResult.bind(this)} />
                : null}

        </div>
    )

};


export const MenuTopSmall = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    let { func } = props;
    var lang = props.language;

    const BarHeight = 50;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, value, item) => {
        func(type, value);
    }

    return (
        <div style={{ ...styles.BlockRow, background: global.theme_menu_top_gradient, height: BarHeight, borderBottom: '1px solid ' + global.theme_light_gray }}>
            <div style={{ ...styles.BlockRight, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: BarHeight }}>
                <div style={{ display: 'flex', marginRight: 10 }}>
                    <IconButton onClick={() => Press(true)} style={{ width: 36, height: 36, borderRadius: 18, marginLeft: 10, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
        </div>
    )

};



export const Loading = (props) => {
    /*
        USAGE: 
                <Loading center={true} />
    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const logo = require('./react/app/logo_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: isSmall ? 0 : props.center != undefined && props.center == true ? 0 : global.menu_width - 40, width: 75, height: 75, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={70} />
            </Paper>
        </Backdrop>);
}



export const PlusMinus = (props) => {
    /*

            USAGE:
            <PlusMinus quantity={quantity} func={PlusMinusPress.bind(this)} />

            const PlusMinusPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [quantity, setQuantity] = useState(1);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setQuantity(props.quantity);

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id, props.id);
    }

    const ChangeQuantity = (id) => {
        var q = parseInt(quantity);
        q = q + id;
        if (q < 1) {
            q = 1;
        }
        setQuantity(q);
        Press(q);
    }

    const EnterQuantity = (text) => {
        if (/^\d+$/.test(text)) {
            setQuantity(text);
        }
        if (text.trim() == '') {
            //setQuantity(text);
            text = '1';
        }
        if (parseInt(text) < 1) {
            text = '1';
            setQuantity(text);
        }
        Press(parseInt(text));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 175, justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: 4 }}>
                <IconButton onClick={() => ChangeQuantity(-1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faMinus} />
                </IconButton>
                <div style={{ display: 'flex', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        style={{ fontSize: global.fone_normal, margin: 0, width: 90, border: '0px', textAlign: 'center' }}
                        variant="outlined"
                        label={''}
                        value={quantity}
                        size={'small'}
                        onInput={e => EnterQuantity(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                        }}
                    />
                </div>
                <IconButton onClick={() => ChangeQuantity(1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faPlus} />
                </IconButton>
            </div>
        </div>
    )

};


export const UserData = (props) => {
    /*

            USAGE:
            <UserData user={user} func={UserDataPress.bind(this)} />

            const UserDataPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordOld, setPasswordOld] = useState('');
    const [error, setError] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = props.language;
    let { func } = props;
    const timer = useRef();

    useEffect(() => {

        setName(props.user.name);
        setSurname(props.user.surname);
        setUsername(props.user.username);

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);

    const Press = (id, data) => {
        func(id, data);
    }

    const db_update = async () => {

        if (isBusy == false) {
            setBusy(true);

            var data = {
                name: name,
                surname: surname,
            }

            if (username.trim() != '') {
                data.username = username;
            }

            if (password.trim() != '') {
                data.password = password.trim();
            } else {
                data.password = '';
            }

            if (passwordOld.trim() != '') {
                data.password_old = passwordOld.trim();
            } else {
                data.password_old = '';
            }


            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        data: data,
                        decode_key: props.decodeKey,
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {

                    data.id = props.user.id;
                    data.typ = props.user.typ;
                    data.enabled = props.user.enabled;
                    data.company_id = props.user.company_id;

                    if (json.password_error == false) {
                        Press(true, data);
                    } else {
                        setError(lang.password_old_err);
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_check_email = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'department_register_check', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        username: username,
                        decode_key: props.decodeKey,
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    if (json.count > 0) {
                        setError(lang.username_exists);
                    } else {
                        db_update();
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const Save = () => {
        var error = '';

        setNameErr('');
        setSurnameErr('');
        setError('');

        if (name.trim() == '') {
            setNameErr(lang.required);
            error = lang.required;
        }

        /*
        if (surname.trim() == '') {
            setSurnameErr(lang.required);
            error = lang.required;
        }
        */

        if (ValidateEmail(username) == false) {
            error = lang.email_error;
        }

        if (error != '') {
            setError(error);
        } else {
            db_check_email();
        }

    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, minWidth: 250, width: 700, paddingTop: 20, paddingBottom: 20, marginTop: 20 }}>
                {/* MENO, PRIEZVISKO */}
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '90%' : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginRight: isSmall ? 0 : 10 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                        <TextField error={nameErr != '' ? true : false} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={name} onInput={e => setName(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                        <TextField error={surnameErr != '' ? true : false} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={surname} onInput={e => setSurname(e.target.value)} />
                    </div>
                </div>

                {/* USERNAME, PASSWORD */}
                <div style={{ ...styles.Block, marginTop: 30 }}>
                    <p style={{ ...styles.TextNormal }}>{lang.change_logins}</p>
                </div>
                <div style={{ ...styles.BlockRow, marginTop: 20, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                    <div style={{ ...styles.BlockLeft, width: '33%', marginLeft: 15 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.loginname}</p>
                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={username} onInput={e => setUsername(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '33%', marginLeft: 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.password_old}</p>
                        <TextField type={'password'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={passwordOld} onInput={e => setPasswordOld(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '33%', marginLeft: 10, marginRight: 15, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.new_password}</p>
                        <TextField type={'password'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={password} onInput={e => setPassword(e.target.value)} />
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, height: isSmall ? undefined : 110 }}>
                <div style={{ height: isSmall ? undefined : 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonSave, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    )

};


export const UniqueID = () => {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}


export const GetPriceRound = (price_) => {
    // VRATI zaokrúhlenú cenu
    var tmp = parseFloat(price_).toFixed(2);
    return tmp;
}

export const GetPrice = (price_) => {
    // VRATI CENU NAFORMATOVANU 0,00
    var tmp = GetPriceRound(price_).toString();;
    return tmp.replace('.', ',');
}

export const ChooseImage = (props) => {
    /*      VYBER JEDNEHO OBRAZKU NA UPLOAD

            USAGE:
            
            <ChooseImage folder={productID.toString()} type={global.image_product} label={lang.product_main_image} note={'600x600 px'} image_name={imageName} image={image} func={ChooseImagePress.bind(this)} />

            const ChooseImagePress = (value) => {                
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [file, setFile] = useState(null); // celá adresa k obrázku
    const [fileName, setFileName] = useState(null); // len názov obrázku
    const [type, setType] = useState(0);

    const [showImage, setShowImage] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const inputRef = useRef(null);
    let { func } = props;
    var lang = sk;

    useEffect(() => {

        setFile(props.image);
        setFileName(props.image_name);
        setType(props.type);

        return () => {
        };

    }, []);


    const Press = (img) => {
        func(img);
    }

    const image_upload = async (file) => {
        // UPLOAD IMAGE TO SERVER
        setBusy(true);
        const data = new FormData();
        data.append('typ', type);
        data.append("name", "image");
        if (props.folder != undefined) {
            data.append("folder", props.folder);
        }
        data.append("file_attachment", file);
        try {
            const response = await fetch(
                global.db_url + 'upload_image', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();
            if (json.ok > 0) {
                // ALL OK   
                setFile(global.web + '/' + json.file);
                setFileName(json.filename);
                Press(json.filename);
            }
            inputRef.current.value = null;
            setBusy(false);
            return;

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };

    const image_delete = async (image) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'delete_image', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    image: image,
                    typ: type,
                    folder: props.folder,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setFile(null);
                inputRef.current.value = null;
                Press(null);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    function handleChange(e) {
        var img = URL.createObjectURL(e.target.files[0]);
        var file = e.target.files[0];
        setFile(img);
        image_upload(file);
    }

    const ClearImage = () => {
        image_delete(fileName);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <p style={{ ...styles.TextTiny, fontWeight: 'bold', marginBottom: 10 }}>{props.label}</p>
            <div style={{ ...styles.BlockLeft, flexDirection: 'row' }}>
                {file != null ?
                    <div style={{ ...styles.Block, width: 120, height: 120, marginRight: 10, borderRadius: 10, backgroundColor: global.theme_gray }}>
                        <Tooltip title={lang.delete}>
                            <IconButton onClick={() => ClearImage()} style={{ ...styles.BlockRound, width: 20, height: 20, backgroundColor: global.theme_dark_gray, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </Tooltip>
                        <div style={{ ...styles.Block, height: '100%' }}>
                            {isBusy == true ?
                                <CircularProgress color="inherit" size={20} />
                                :
                                <img onClick={() => setShowImage(true)} src={file} style={{ maxWidth: 90, maxHeight: 90, aspectRatio: 1.0, cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                    : null}
                <div>
                    <label htmlFor="filePicker" style={{ ...styles.Block, width: 120, height: 120, background: global.theme_white, justifyContent: 'center', alignItems: 'center', cursor: 'pointer', border: '2px solid ' + global.theme_dark_gray, borderRadius: 10 }}>
                        <p style={{ ...styles.TextTiny, marginBottom: 10, color: global.theme_dark_gray }}>{props.note}</p>
                        <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_gray }} icon={faImage} />
                        <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.choose_image}</p>
                    </label>
                    <input ref={inputRef} name={'myimage'} onChange={handleChange} id="filePicker" style={{ visibility: "hidden" }} type={"file"}></input>
                </div>

            </div>

            {showImage == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowImage(false)} >
                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.image_preview}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowImage(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <img src={file} style={{ maxWidth: 600, maxHeight: 600, aspectRatio: 1.0 }} />
                </Dialog >
                : null}
        </div>
    );
}


export const Spacer = (props) => {
    /*
            USAGE:
            <Spacer height={50} />
    */

    return (
        <div style={{ ...styles.Block, height: props.height == undefined ? 20 : parseInt(props.height) }}>
            <p></p>
        </div >
    );
}




export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600 }}>
                <p>Body</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}

export const GetAge = (_date) => {
    var date = new Date(_date);
    var diff_ms = Date.now() - date.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetTodayYearMonth = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m;

    return today;
}

export function AddDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function SubDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}


export const GetDateYear = (date) => {
    var today = new Date(date);
    var year = today.getFullYear();
    return year;
}

export const GetTodayYear = () => {
    var today = new Date();
    var year = today.getFullYear();
    return year;
}


export const GetTodayMonth = () => {
    var today = new Date();
    var month = today.getMonth();
    return month;
}

export const GetTodayTime = () => {
    var today = new Date();
    var h = String(today.getHours()).padStart(2, '0');
    var m = String(today.getMinutes()).padStart(2, '0');
    return h + ':' + m;
}


export const GetDate = (str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}


export const GetDate2 = (str) => {
    var tmp = str.split('.');
    var date = null;
    if (tmp.length == 3) {
        var d = tmp[0].toString().padStart(2, '0');
        var m = tmp[1].toString().padStart(2, '0');;
        var y = tmp[2];
        date = y + '-' + m + '-' + d;
    }
    if (tmp.length == 2) {
        var m = tmp[0].toString().padStart(2, '0');;
        var y = tmp[1];
        date = y + '-' + m;
    }
    if (tmp.length == 1) {
        var y = tmp[0];
        date = y;
    }
    console.log(date, tmp);
    return date;
}

export const GetDate3 = (str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GoHome = () => {
    window.scrollTo(0, 0);
}

export const SelectColor = (props) => {
    /*
            USAGE:
            <SelectColor func={SelectColorPress.bind(this)} />

            const SelectColorPress = (value) => {                
            }

            */
    const [color, setColor] = useState('#000000');
    const [showOpen, setShowOpen] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setColor(props.default);

        return () => {
        };

    }, []);


    const Press = (item) => {
        setColor(item);
        setShowOpen(false);
        func(item);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <Button onClick={() => setShowOpen(true)} style={{ ...styles.Button, width: 180, backgroundColor: global.theme_light_gray, color: global.theme_black, textTransform: 'none', border: '1px solid ' + global.theme_dark_gray }}>
                <div style={{ ...styles.Block, width: 40 }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: color, marginRight: 10 }} icon={faSquare} />
                </div>
                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                    {props.label}
                </div>
                <div style={{ width: 20 }}>
                    <FontAwesomeIcon style={{ marginLeft: 10, width: 16, height: 16, color: global.theme_light }} icon={faChevronRight} />
                </div>
            </Button>
            {showOpen == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(true)}>
                    <div style={{ ...styles.BlockRow, width: 400, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.choose_color}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowOpen(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: 400, flexWrap: 'wrap' }}>
                        {global.colors.map(item => (
                            <IconButton onClick={() => Press(item.color)} style={{ backgroundColor: item.color, width: 40, height: 40, borderRadius: 0, border: '1px solid black', borderRadius: 20, margin: 5 }}>

                            </IconButton>
                        ))}
                    </div>
                </Dialog>
                : null}
        </div >
    );
}

export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={3} style={{ ...styles.Block, marginLeft: global.menu_width - 40, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_green }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const CustomerSearch = (props) => {
    /*
            Hladat zákazníka
 
            USAGE:
            <CustomerSearch func={CustomerSearchPress.bind(this)} />
 
            const CustomerSearchPress = (value) => {
            }
 
    */
    const [isBusy, setBusy] = useState(false);

    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [err, setErr] = React.useState('');


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();


    let { func } = props;
    var lang = sk;

    const BarHeight = 70;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (items, str) => {
        func(items, str);
    }

    const db_search = async (txt, _quick) => {
        // SEARCH DURING TYPING
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_searching', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: props.companyID,
                    search: txt,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                if (_quick == false) {
                    Press(json.arr, txt);
                }
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }

    }


    const SearchItem = (value) => {
        // zobrazenie vybratého produktu
        if (value.id == undefined) {
            // jedna sa o hladany výraz
            if (search.length > 2) {
                // zobraziť výsledok

                var txt = value.trim();
                txt = txt.replaceAll('/', '');
                txt = txt.replaceAll('+', '');
                txt = txt.replaceAll('#', '');
                txt = txt.replaceAll(' ', '+');

                db_search(txt, false);
            }
        } else {
            // bol označený konkrétny produkt
            // zobraziť produkt
            Press([value.id], search);
        }
    }

    const Search = (txt_) => {
        var txt = txt_.trim();
        setSearch(txt);
        if (txt.length > 2) {
            var search = txt.trim();
            search = search.replaceAll('/', '');
            search = search.replaceAll('+', '');
            search = search.replaceAll('#', '');
            search = search.replaceAll(' ', '+');

            setErr('');
            db_search(search, true);
        }
    }

    return (

        <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Autocomplete
                freeSolo
                disableClearable
                style={{ width: '100%', maxWidth: 700, backgroundColor: global.theme_gray }}
                options={items}
                getOptionLabel={(option) => option.keys ? option.keys : ""}
                loading={loading}
                size={'small'}
                onChange={(event, newValue) => { SearchItem(newValue); }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Prevent's default 'Enter' behavior.
                        if (search.length < 3) {
                            event.defaultMuiPrevented = true;
                            setErr(lang.search_error);
                        } else {
                            setErr('');
                            SearchItem(search);
                        }

                    }
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <p style={{ ...styles.TextTiny }}>
                            {option.name} {option.surname} - ({option.town})
                        </p>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={err == '' ? lang.search : err}
                        onInput={e => Search(e.target.value)}
                        error={err == '' ? false : true}
                        style={{ backgroundColor: global.theme_white }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    )
}


export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }
    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }
    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}



export const AskDialog = (props) => {
    /*
            USAGE:
            <AskDialog label='' question='' text='' button='' func={AskDialogResult.bind(this)} />

            const AskDialogResult = (value) => {   
                if(value == true){
                }
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? 300 : 600, paddingTop: 30, paddingBottom: 30 }}>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...isSmall ? styles.TextSmall : styles.TextNormal, fontWeight: 'bold', textAlign: 'center' }}>{props.question}</p>
                    <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
                </div>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 20 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...isSmall ? styles.ButtonGreenMobile : styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{props.button}</Button>
                    <Button onClick={() => Press(false)} style={{ ...isSmall ? styles.ButtonMobile : styles.ButtonBack, margin: 10 }}>{props.button2 != undefined ? props.button2 : lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const AskLogout = (props) => {
    /*
            USAGE:
            <AskLogout func={AskLogoutResult.bind(this)} />

            const AskLogoutResult = (value) => {                
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.logout_}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? 300 : 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{lang.logout_question}</p>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{lang.logout}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}

export const Languages = (props) => {
    /*
            USAGE:
            <Languages language={lang} func={LanguagesResult.bind(this)} />

            const LanguagesResult = (value) => {                
                setLanguage(value);
            }

            */
    const [language, setLanguage] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {

        if (props.language == sk) {
            setLanguage(0);
        }


        return () => {
        };

    }, []);


    const Press = (value) => {

        if (value == sk) {
            setLanguage(0);
            SaveLanguage(0);
        }
        func(value);
    }

    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.BlockRowRaw }}>
                <IconButton onClick={() => Press(sk)} style={{ margin: 5, opacity: language == 0 ? 1 : 0.25 }}>
                    <img src={global.images + 'app/flag_sk.png'} width={40} height={40} />
                </IconButton>
            </div>
        </div>
    );
}

export const Empty = (props) => {
    // PRAZDNA STRÁNKA - ZOBRAZOVANA POCAS RENDEROVANIA - isBusy?
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
        </div>
    );
}

export const CheckPermision = (permissions, type) => {
    return permissions.toString().substr(type, 1) == '1' ? true : false;
}

export const NoPermission = (props) => {
    // <NoPermission lang={lang} />
    return (
        <div style={{ ...styles.Block, paddingTop: 50 }}>
            <p style={{ ...styles.TextLarge }}>{props.lang.no_permission} !</p>
        </div>
    );
}

export const SearchTown = (props) => {
    // HLADANIE MESTA - autocomplete mesto - VRATI PSC, ULICU A MESTO
    /*

        <SearchTown street={street} town={town} func={SearchTownResult.bind(this)} />

    */

    const [street, setStreet] = useState('');
    const [psc, setPSC] = useState('');
    const [towns, setTowns] = useState([]);
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState('');
    const [searching, setSearching] = useState(false);

    let { func } = props;

    useEffect(() => {

        setTown(props.town);

        return () => {
        };

    }, [props.town]);

    const db_searching = async (town, psc) => {
        // SEARCH DURING TYPING

        setSearching(true);
        try {
            const response = await fetch(
                global.db_url + 'town_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    town: town,
                    psc: null,
                    street: props.street,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setTowns(json.items);
            }
            setSearching(false);
        } catch (error) {
            Debug(error);
            setSearching(false);
        }

    }


    const SearchTown = (txt) => {
        setTown(txt);
        if (txt.length > 3) {
            db_searching(txt, null);
        } else {
            setTowns([]);
        }
    }

    const ChooseTown = (item) => {
        setTown(item.mesto);
        func(item.mesto, item.psc, item.ulica);
    }

    return (

        <Autocomplete
            freeSolo
            disableClearable
            style={{ width: '100%', maxWidth: 700, backgroundColor: global.theme_gray }}
            options={towns}
            getOptionLabel={(option) => option.mesto ? option.mesto : ""}
            loading={searching}
            size={'small'}
            onChange={(event, newValue) => { ChooseTown(newValue); }}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    // Prevent's default 'Enter' behavior.
                    //event.defaultMuiPrevented = true;                    
                    //func(town, '', '');
                }
            }}
            renderOption={(props, option, index) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    <p style={{ ...styles.TextTiny }}>
                        {option.mesto} {option.ulica} {option.psc}
                    </p>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={'Mesto'}
                    onInput={e => SearchTown(e.target.value)}
                    error={townErr == '' ? false : true}
                    style={{ backgroundColor: global.theme_white }}
                    placeholder={town}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
                        endAdornment: (
                            <React.Fragment>
                                {searching ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        style: { color: global.theme_black }
                    }}
                />
            )}
        />

    )
}


export const GetPSC = (_psc) => {
    // oprava PSC

    if (_psc != undefined) {
        var tmp = _psc.trim();
        tmp = tmp.replaceAll(' ', '');
        var psc = tmp.substring(0, 3) + ' ' + tmp.substring(3, 5);
    } else {
        psc = '';
    }
    return psc;
}


export const AutocompleteText = (props) => {

    /*

        <AutocompleteText inputRef={null}  allowdelete={true} allowedit={true} disabled={false} error={false} row={false} check={false} new_record={true} department={props.department} db={1} lang={lang} label={lang.miesto_priestupku} width={'100%'} value={miestoPriestupku} func={SetMiestoPriestupku.bind(this)} />

        row - label je vedla textfieldu (true)
        new_record - ak nie je najdeny vysledok - moznost ulozit novy zaznam do db
        department
        db - typ databazy na hladanie, alebo ukladanie novych zaznamov
        check - kontrola, ci je zaznam vybraty z DB, alebo len napisany
        error - oznacenie policka ze je v nom chyba
        disabled - true textové pole je disabled
    */

    const [value, setValue] = useState('');
    const [selected, setSelected] = useState([]);
    const [valueID, setValueID] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [searching, setSearching] = useState(false);
    const [open, setOpen] = useState(false);
    const [showNewRecord, setShowNewRecord] = useState(false);
    const [newRecord, setNewRecord] = useState('');
    const [msg, setMsg] = useState('');
    const [choosen, setChoosen] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [selectedID, setSelectedID] = useState(-1);
    const [editItem, setEditItem] = useState([]);
    const [editItemID, setEditItemID] = useState(0);
    const [editText, setEditText] = useState('');
    const [filterText, setFilterText] = useState('');
    const [newText, setNewText] = useState('');
    const [showNew, setShowNew] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const editRef = useRef(null);
    const newRef = useRef(null);
    const filterRef = useRef(null);

    var lang = props.lang;
    let { func } = props;
    const textvariant = "outlined";
    const timer = useRef();

    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    useEffect(() => {

        setEditItemID(0);

        // POVODNA HODNOTA - EDITACIA
        var tmp = {
            id: props.value_id == undefined ? 0 : props.value_id,
            label: props.value,
            keys: props.value
        };
        setSelected(tmp);
        if (props.value_id != undefined) {
            setValueID(props.value_id);
            setChoosen(true);
        }

        setValue(props.value);
        if (props.value_id != undefined) {
            setValueID(props.value_id);
            if (props.value_id > 0) {
                setChoosen(true);
            }
        }

        return () => {
        };

    }, [props.value, props.error]);

    const db_searching = async (_text, listing) => {
        // SEARCH DURING TYPING
        setSearching(true);
        try {
            const response = await fetch(
                global.db_url + 'autocomplete_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    value: _text,
                    keys: props.keys,
                    db: props.db,
                    department_id: props.department.id,
                    pravnicka_osoba: props.pravnicka_osoba == true ? true : false,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                if (listing == true) {
                    setItemsFiltered(json.items)
                } else {
                    if (json.items.length > 0) {
                        setOpen(true);
                    }
                    setChoosen(false);

                    if (json.items.length == 0) {
                        if (props.new_record == true && _text.trim() != '') {
                            var tmp = [{ id: 0, label: _text, keys: lang.new_record }]
                            setItems(tmp);
                        }

                    }
                }
            }
            setSearching(false);
        } catch (error) {
            Debug(error);
            setSearching(false);
        }

    }

    const db_new_record = async (_text) => {
        // ULOZIT NOVY ZAZNAM DO DB * Ak je povolene
        setSearching(true);
        setMsg('');
        try {
            const response = await fetch(
                global.db_url + 'autocomplete_new_record', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    value: _text,
                    db: props.db,
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (showSelect == true) {
                    // pridať nový záznam v listingu
                    db_searching('', true);
                } else {
                    setMsg('');
                    setChoosen(true);
                    var data = json.data
                    data.lostfocus = false;
                    func(data);
                }
            }
            setSearching(false);
        } catch (error) {
            Debug(error);
            setSearching(false);
        }

    }

    const db_delete_record = async (id) => {
        // VYMAZANIE ZÁZNAMU
        setSearching(true);

        try {
            const response = await fetch(
                global.db_url + 'autocomplete_delete_record', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    db: props.db,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.filter(x => x.id != id);
                setItems(tmp);
                setItemsFiltered(tmp);
            }
            setSearching(false);
        } catch (error) {
            Debug(error);
            setSearching(false);
        }

    }

    const db_update_record = async (item) => {
        // ZMENA ZÁZNAMU
        setSearching(true);

        try {
            const response = await fetch(
                global.db_url + 'autocomplete_update_record', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    item: item,
                    db: props.db,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

            setSearching(false);
        } catch (error) {
            Debug(error);
            setSearching(false);
        }

    }

    const SearchValue = (txt) => {
        setValue(txt);
        if (txt.length > 0) {
            db_searching(txt);
        } else {
            setOpen(false);
            setItems([]);
            setValueID(0);
            setChoosen(false);
        }
    }

    const ChooseValue = (item) => {

        if (item.id > 0) {
            setValue(item.label);
            setMsg('');
            setChoosen(true);
            var data = item;
            data.lostfocus = false;
            func(data);
        } else {
            if (props.new_record == true) {
                // VYTVORENIE NOVEHO ZAZNAMU
                setNewRecord(item.label);
                setShowNewRecord(true);
                setMsg(' - ' + lang.no_exist);
                setChoosen(false);
            }
        }
    }

    const NewRecord = () => {
        setShowNewRecord(false);
        db_new_record(newRecord);
    }

    const CancelRecord = () => {
        setShowNewRecord(false);
        setValue(newRecord);
    }

    const LostFocus = () => {
        setOpen(false);
        setItems([]);

        if (props.check == true) {
            // KONTROLA - MUSI BYT VYBRATY ZAZNAM Z DB
            if (choosen == true) {
                setMsg('');
            } else {
                if (value == '' || value == null) {
                    setMsg('');
                    var tmp = {
                        label: '',
                        id: 0,
                        lostfocus: true
                    }
                    func(tmp);
                } else {
                    setMsg(' - ' + lang.no_exist);
                }
            }
        }
        else {
            var tmp = {
                label: value,
                id: 0,
                lostfocus: true
            }
            func(tmp);
        }
    }

    const ShowSelect = () => {
        if (props.disabled != true) {
            db_searching('', true);
            setSelectedID(-1);
            setEditItemID(0);
            setFilterText('');
            setShowSelect(true);
            timer.current = setTimeout(() => {
                filterRef.current.focus();
            }, 300);
        }
    }

    const SelectItem = (item) => {
        ChooseValue(item);
        setShowSelect(false);
    }

    const Delete = (item) => {
        db_delete_record(item.id);
    }

    const Edit = (item) => {
        if (editItemID == item.id) {
            setEditItemID(0);
        } else {
            setEditText(item.label);
            setShowNew(false);
            setEditItem(item);
            setEditItemID(item.id);
            timer.current = setTimeout(() => {
                editRef.current.focus();
            }, 300);
        }
    }

    const EditEnd = () => {
        editItem.label = editText;
        setEditItemID(0);
        db_update_record(editItem);
    }

    const FilterText = (str_) => {
        var str = str_.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        setFilterText(str);
        // hladanie bez diakritiky
        var tmp = items.filter(x => x.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(str.toLowerCase()));
        //var tmp = items.filter(x => x.label.toLowerCase().includes(str.toLowerCase()));
        setItemsFiltered(tmp);
    }

    const ResetFilter = () => {
        setFilterText('');
        var tmp = items;
        setItemsFiltered(tmp);
    }

    const AddNew = () => {
        setNewText('');
        setEditItemID(0);
        setShowNew(true);
        timer.current = setTimeout(() => {
            newRef.current.focus();
        }, 300);
    }

    const Save = () => {
        if (newText.trim() != '') {
            db_new_record(newText);
            setShowNew(false);
        }
    }

    const AddNewClose = () => {
        setShowNew(false);
    }

    const Sort = (typ) => {

        if (typ == 0) {
            // ZORADENIE PODLA ABECEDY
            var tmp = items;
            tmp.sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                } else {
                    return -1;
                }
            })
            setItemsFiltered(tmp);
        }
        if (typ == 1) {
            // ZORADENIE PODLA PORADIA
            var tmp = items;
            tmp.sort((a, b) => {
                if (parseInt(a.poradie) > parseInt(b.poradie)) {
                    return 1;
                } else {
                    return -1;
                }
            })
            setItemsFiltered(tmp);
        }
        setRedraw(!redraw);

    }

    return (
        <div style={{ ...styles.BlockLeft, flexDirection: props.row == true ? 'row' : 'column' }}>
            <p style={{ ...styles.TextInput, marginTop: props.row == true ? 10 : 0, marginRight: props.row == true ? 10 : 0, marginBottom: props.row == true ? 0 : 3, marginLeft: 2, color: msg == '' ? global.theme_black : global.theme_red }}>{props.label} {msg}</p>
            <Autocomplete
                freeSolo
                disableClearable
                disabled={props.disabled}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onFocus={() => { setOpen(false); setItems([]) }}
                onBlur={() => LostFocus()}
                style={{ width: '100%', maxWidth: props.width, backgroundColor: global.theme_gray }}
                options={items}
                getOptionLabel={(option) => option.label ? option.label : ""}
                loading={searching}
                size={props.size != undefined ? props.size : 'small'}
                onChange={(event, newValue) => { ChooseValue(newValue); }}
                value={selected}
                onKeyDown={(event) => {
                    //console.log(event);
                    if (event.key === 'Enter') {
                        if (event.ctrlKey == true) {
                            func(null, 'Control+Enter');
                        }
                    }
                    if (event.key === 'ArrowDown') {
                        if (open == false) {
                            setValue('');
                            db_searching('');
                        }
                    }
                    if (event.key === 'F1') {
                        func(null, 'F1');
                    }
                    if (event.key === 'F2') {
                        func(null, 'F2');
                    }
                    if (event.key === 'F3') {
                        func(null, 'F3');
                    }
                    if (event.key === 'Escape') {
                        //func(null, 'Escape');
                    }
                }}
                renderOption={(props, option, index) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                        <p style={{ ...styles.TextTiny }}>
                            {option.keys}
                        </p>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={''}
                        inputRef={props.inputRef}
                        onInput={e => SearchValue(e.target.value)}
                        style={{ backgroundColor: props.disabled == true ? global.theme_gray : global.theme_white }}
                        placeholder={value}
                        error={msg != '' || props.error == true ? true : false}
                        InputLabelProps={{
                            style: { color: 'red' },
                        }}
                        variant={textvariant}
                        InputProps={{
                            ...params.InputProps,
                            //startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
                            endAdornment: (
                                <React.Fragment>
                                    {searching ? <CircularProgress color="inherit" size={20} />
                                        :
                                        <FontAwesomeIcon onClick={() => ShowSelect()} style={{ color: '#BBBBBB', width: 12, cursor: 'pointer' }} icon={faBars} />
                                    }
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            style: { color: global.theme_black },
                        }}
                    />
                )}
            />

            {showNewRecord == true ?
                <Dialog open={true} maxWidth={'lg'}>

                    <div style={{ ...styles.Block, width: isSmall ? '100%' : 600, paddingTop: 30, paddingBottom: 30 }}>
                        <p style={{ ...styles.TextNormal, fontWeight: 'bold', margin: 5, textAlign: 'center' }}>{lang.new_record_db}?</p>
                    </div>

                    <div style={{ ...styles.Block }}>
                        <div style={{ ...isSmall == true ? styles.Block : styles.BlockRowRaw }}>
                            <Button onClick={() => NewRecord()} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{lang.yes}</Button>
                            <Button onClick={() => CancelRecord()} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.no}</Button>
                        </div>
                    </div>
                </Dialog >
                : null}

            {showSelect == true ?
                <Dialog open={true} maxWidth={'lg'} fullScreen={isSmall ? true : false} >
                    <div style={{ ...styles.Block, width: isSmall == true ? '100%' : 650, paddingTop: 0, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, paddingTop: isSmall ? 40 : 10, paddingBottom: 10 }}>
                            {isSmall == false ?
                                <div style={{ ...styles.BlockLeft, width: '30%', paddingTop: 7 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginLeft: 20, marginTop: 5 }}>{lang.select_item}</p>
                                </div>
                                : null}
                            <div style={{ ...styles.BlockRow, width: isSmall ? '100%' : '70%' }}>
                                <TextField
                                    inputRef={filterRef}
                                    size={isSmall ? 'medium' : "small"}
                                    style={{ backgroundColor: global.theme_white, width: '60%', marginLeft: 10 }}
                                    placeholder={lang.filter}
                                    variant="outlined"
                                    value={filterText}
                                    onInput={e => FilterText(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <FontAwesomeIcon onClick={() => ResetFilter('')} style={{ width: 12, height: 12, color: global.theme_medium_gray, cursor: 'pointer' }} icon={faXmark} />
                                        </InputAdornment>,
                                    }}
                                />
                                {props.new_record == true && showNew == false ?
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonClose, width: 90, marginLeft: 20, marginTop: 2 }}>
                                        <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 14 }} icon={faPlus} />
                                        <p style={{ margin: 0, fontSize: global.font_tiny, color: global.theme_white }}>{lang.new}</p>
                                    </Button>
                                    : null}
                            </div>
                            <div style={{ width: 50, paddingTop: 5, maginLeft: 10 }}>
                                <IconButton onClick={() => setShowSelect(false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ ...isSmall ? styles.BlockRow : styles.BlockRowRaw, }}>
                                <Button onClick={() => Sort(0)} style={{ ...styles.ButtonLink, width: isSmall ? '50%' : 170, height: isSmall ? 40 : 20, fontSize: 12 }}>{'Triediť abecedne'}</Button>
                                <Button onClick={() => Sort(1)} style={{ ...styles.ButtonLink, width: isSmall ? '50%' : 170, height: isSmall ? 40 : 20, fontSize: 12 }}>{'Triediť podľa poradia'}</Button>
                            </div>
                        </div>

                        {searching == true ?
                            <div style={{ ...styles.Block }}>
                                <CircularProgress></CircularProgress>
                            </div>
                            : null}

                        <div style={{ ...styles.BlockCenter, width: '95%', height: isSmall ? '100%' : 600, marginTop: 20, paddingBottom: 10, overflowY: 'scroll' }}>
                            {showNew == true ?
                                <div style={{ ...styles.BlockLeft, paddingBottom: 20 }}>
                                    <p style={{ ...styles.TextXXTiny, fontWeight: 'bold' }}>{lang.new_record}</p>
                                    <div style={{ ...styles.BlockRow }}>
                                        <TextField
                                            inputRef={newRef}
                                            size={isSmall ? 'medium' : "small"}
                                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                                            label={''}
                                            variant="outlined"
                                            value={newText}
                                            onInput={e => setNewText(e.target.value)}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    Save();
                                                }
                                            }}
                                        />
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonClose, width: 100, marginLeft: 20, marginTop: 2 }}>
                                            <p style={{ margin: 0, fontSize: global.font_tiny, color: global.theme_white }}>{lang.save}</p>
                                        </Button>
                                        <IconButton onClick={() => AddNewClose()} style={{ ...styles.BlockRound, width: 40, height: 40, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                                        </IconButton>
                                    </div>
                                </div>
                                : null}
                            {
                                itemsFiltered.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, alignItems: 'flex-start', height: isSmall ? 55 : 35, backgroundColor: index % 2 == 0 ? global.theme_gray : global.theme_white }}  >
                                        <div style={{ ...styles.BlockRow, height: isSmall ? 50 : 30, alignItems: 'center' }}>
                                            {props.allowdelete == true ?
                                                <IconButton onClick={() => Delete(item)} style={{ ...styles.Block, height: 26, width: 26 }}>
                                                    <FontAwesomeIcon style={{ width: 13, height: 13, color: global.theme_medium_gray }} icon={faTrash} />
                                                </IconButton>
                                                : null}
                                            {props.allowedit == true ?
                                                <IconButton onClick={() => Edit(item)} style={{ ...styles.Block, height: 26, width: 26 }}>
                                                    <FontAwesomeIcon style={{ width: 13, height: 13, color: global.theme_medium_gray }} icon={faEdit} />
                                                </IconButton>
                                                : null}
                                            {editItemID == item.id ?
                                                <TextField
                                                    inputRef={editRef}
                                                    size={isSmall ? 'medium' : "small"}
                                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                                    label={''}
                                                    variant="standard"
                                                    value={editText}
                                                    onInput={e => setEditText(e.target.value)}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            EditEnd();
                                                        }
                                                    }}
                                                />
                                                :
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '100%', cursor: 'pointer' }} onPointerOver={() => setSelectedID(item.id)} onPointerLeave={() => setSelectedID(-1)} >
                                                    {isSmall == true ?
                                                        <p style={{ ...styles.TextTiny, color: selectedID == item.id ? global.theme_blue : global.theme_black, marginTop: 4, marginLeft: 5 }}>{item.label_mobil != undefined ? item.label_mobil != '' ? item.label_mobil : item.label : item.label}</p>
                                                        :
                                                        <p style={{ ...styles.TextTiny, color: selectedID == item.id ? global.theme_blue : global.theme_black, marginTop: 4, marginLeft: 5 }}>{item.label}</p>
                                                    }
                                                </div>

                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </Dialog >
                : null}

        </div >
    )
}

export const InputDate = (props) => {
    /*
            USAGE:
            <InputDate value={''} error={false} func={InputDateResult.bind(this)} />

            const InputDateResult = (value) => {                
            }
            */

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {
        setValue(props.value);
        setError(props.error);

        return () => {
        };

    }, []);


    const Press = () => {
        func(value);
    }

    const ChangeValue = (str) => {
        if (/^\d+$/.test(str)) {
            var tmp = str;
            if (str.length == 2) {
                //tmp = tmp + '.';
            }
            if (str.length == 5) {
                //tmp = tmp + '.';
            }
            setValue(tmp);
        }
    }


    return (
        <TextField
            onBlur={() => Press()}
            /*
            onKeyDown={(event) => {
                console.log(event.key);
                if (event.key === 'Delete') {
                    setValue('');
                }
                if (event.key === 'Backspace') {
                    setValue('');
                }
            }}
            */
            size={'small'}
            error={error}
            style={{ backgroundColor: global.theme_white, width: '100%' }}
            label={''}
            variant="outlined"
            type={'date'}
            value={value}
            onInput={e => setValue(e.target.value)} />
    );
}


export const CopyData = (source, dest, exclude) => {
    // SKOPIROVANIE VSYTKYCH KEYS Z OBJEKTU - source DO OBJEKTU - dest
    // exclude - array[id, ...] - ktory keys nebudú skopírované

    var keys = Object.keys(source);
    keys.forEach(key => {
        if (!exclude.includes(key)) {
            dest[key] = source[key];
        }
    });
}


export const ChooseObvod = (props) => {
    /*
            USAGE:
            <ChooseObvod department={department} func={ChooseObvodResult.bind(this)} />

            const ChooseObvodResult = (value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        db_get();

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'obvody_select', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                setItems(json.items);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_get_settings = async (_obvod) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    obvod_id: _obvod.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);
            if (json.ok > 0) {
                var settings = json.settings;
                Press(1, _obvod, settings);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const Press = (typ, _obvod, _settings) => {
        func(typ, _obvod, _settings);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.obvod_choose}</p>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500, paddingTop: 10 }}>
                <div key={0} onClick={() => Press(2, null)} style={{ ...styles.Block, margin: 5, backgroundColor: global.theme_gray, paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}>
                    <p style={{ ...styles.TextSmall }}>{lang.centrala}</p>
                </div>
                {items.map(item => (
                    <div key={item.id} onClick={() => Press(1, item)} style={{ ...styles.Block, margin: 5, backgroundColor: global.theme_gray, paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}>
                        <p style={{ ...styles.TextSmall }}>{item.kod} {item.nazov}</p>
                    </div>
                ))}
            </div>

            <div style={{ ...styles.Block, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Press(0, null, null)} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.close}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const PriestupokSearch = (props) => {
    /*
            USAGE:
            <PriestupokSearch lang={lang}  func={PriestupokSearchResult.bind(this)} />

            const PriestupokSearchResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [number, setNumber] = useState('')
    const [status, setStatus] = useState('')
    const [statusID, setStatusID] = useState(0)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [rodneCislo, setRodneCislo] = useState('')
    const [obvod, setObvod] = useState('')
    const [spz, setSPZ] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [riesitel, setRiesitel] = useState('')
    const [riesitelID, setRiesitelID] = useState(0)
    const [druhPriestupku, setDruhPriestupku] = useState('')
    const [druhPriestupkuID, setDruhPriestupkuID] = useState(0)
    const [sposobZistenia, setSposobZistenia] = useState('')
    const [sposobZisteniaID, setSposobZisteniaID] = useState(0)
    const [sposobRiesenia, setSposobRiesenia] = useState('')
    const [sposobRieseniaID, setSposobRieseniaID] = useState(0)

    const [obmedzenieSlobody, setObmedzenieSlobody] = useState(false)
    const [showDate, setShowDate] = useState(false)

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {


        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        var today = new Date();
        var y = today.getFullYear();
        var date = y + '-01-01';
        setDateFrom(date);

        var date = y + '-12-31';
        setDateTo(date);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        var data = {};
        var searching = false;

        if (number.trim() != '') {
            data.jednacie_cislo = number.trim();
            searching = true;
        }
        if (status.trim() != '') {
            data.stav_riesenia = status.trim();
            data.stav_riesenia_id = statusID;
            searching = true;
        }
        if (name.trim() != '') {
            data.meno = Capitalize(name.trim());
            searching = true;
        }
        if (surname.trim() != '') {
            data.priezvisko = Capitalize(surname.trim());
            searching = true;
        }
        if (rodneCislo.trim() != '') {
            data.rodne_cislo = rodneCislo.trim();
            searching = true;
        }
        if (spz.trim() != '') {
            var tmp = spz.trim();
            data.spz = tmp.replaceAll(' ', '');
            searching = true;
        }
        if (obvod.trim() != '') {
            data.obvod = obvod.trim();
            searching = true;
        }
        if (riesitelID != 0) {
            data.riesitel_id = riesitelID;
            data.riesitel = riesitel;
            searching = true;
        }
        if (druhPriestupkuID != 0) {
            data.priestupok_id = druhPriestupkuID;
            data.priestupok = druhPriestupku;
            searching = true;
        }
        if (sposobZisteniaID != 0) {
            data.sposob_zistenia_id = sposobZisteniaID;
            data.sposob_zistenia = sposobZistenia;
            searching = true;
        }
        if (sposobRieseniaID != 0) {
            data.sposob_riesenia_id = sposobRieseniaID;
            data.sposob_riesenia = sposobRiesenia;
            searching = true;
        }
        if (showDate == true) {
            data.datum_od = dateFrom;
            data.datum_do = dateTo;
            searching = true;
        }
        if (obmedzenieSlobody == true) {
            data.obmedzenie_slobody = true;
            searching = true;
        }
        if (searching == true) {
            data.search = true;
            data.default = false;
            Press(1, data);
        } else {
            Press(false, false)
        }
    }

    const SetObvod = (value, key) => {
        if (value != null) {
            setObvod(value.label);
        }
    }

    const ShowDate = (value) => {
        setShowDate(value);
    }

    const SetStavRiesenia = (value, key) => {
        if (value != null) {
            setStatus(value.label);
            setStatusID(value.id);
        }
    }

    const SetRiesitel = (value, key) => {
        if (value != null) {
            setRiesitel(value.label);
            setRiesitelID(value.id);
        }
    }

    const SetDruhPriestupku = (value, key) => {
        if (value != null) {
            setDruhPriestupku(value.label);
            setDruhPriestupkuID(value.id);
        }
    }

    const SetSposobRiesenia = (value, key) => {
        if (value != null) {
            setSposobRiesenia(value.label);
            setSposobRieseniaID(value.id);
        }
    }

    const SetSposobZistenia = (value, key) => {
        if (value != null) {
            setSposobZistenia(value.label);
            setSposobZisteniaID(value.id);
        }
    }
    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.search}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block }}>
                <div style={{ ...styles.BlockRow }}>
                    {props.typ == 0 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 150, paddingTop: 3 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.number}</p>
                            <TextField
                                inputRef={inputRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                                label={''}
                                variant="outlined"
                                value={number}
                                onInput={e => setNumber(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        Save();
                                    }
                                }}
                            />
                        </div>
                        : null}
                    {props.typ == 0 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 150, paddingTop: 3 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={10} lang={lang} label={lang.stav_riesenia} width={'100%'} value={status} value_id={statusID} func={SetStavRiesenia.bind(this)} />
                        </div>
                        : null}
                    {props.typ == 1 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.surname} / {lang.firma_}</p>
                            <TextField
                                inputRef={props.typ == 0 ? null : inputRef}
                                size="small"
                                helperText={lang.diacritics}
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                                label={''}
                                variant="outlined"
                                value={surname}
                                onInput={e => setSurname(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        Save();
                                    }
                                }}
                            />
                        </div>
                        : null}
                    {props.typ == 1 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.name}</p>
                            <TextField
                                size="small"
                                helperText={lang.diacritics}
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                                label={''}
                                variant="outlined"
                                value={name}
                                onInput={e => setName(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        Save();
                                    }
                                }}
                            />
                        </div>
                        : null}
                    {props.typ == 1 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 150, paddingTop: 3 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.rodne_cislo} / {lang.ico}</p>
                            <TextField
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                                label={''}
                                variant="outlined"
                                value={rodneCislo}
                                onInput={e => setRodneCislo(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        Save();
                                    }
                                }}
                            />
                        </div>
                        : null}
                    { // DRUH PRIESTUPKU
                        props.typ == 0 ?
                            <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={6} lang={lang} label={lang.druh_priestupku} width={'100%'} value={druhPriestupku} value_id={druhPriestupkuID} func={SetDruhPriestupku.bind(this)} />
                            </div>
                            : null}

                    { // SPOSOB ZISTENIA
                        props.typ == 0 ?
                            <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={8} lang={lang} label={lang.sposob_zistenia} width={'100%'} value={sposobZistenia} value_id={sposobZisteniaID} func={SetSposobZistenia.bind(this)} />
                            </div>
                            : null}

                    { // SPOSOB RIESENIA
                        props.typ == 0 ?
                            <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={11} lang={lang} label={lang.sposob_riesenia} width={'100%'} value={sposobRiesenia} value_id={sposobRieseniaID} func={SetSposobRiesenia.bind(this)} />
                            </div>
                            : null}
                    { // RIESITEL
                        props.typ == 0 ?
                            <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel} width={'100%'} value={riesitel} value_id={riesitelID} func={SetRiesitel.bind(this)} />
                            </div>
                            : null}
                    {props.typ == 0 ? props.department.obvody_pocet > 1 ?
                        <div style={{ ...styles.BlockLeft, margin: 10, width: 150, paddingTop: 3 }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} error={''} check={false} row={false} new_record={false} department={props.department} db={15} lang={lang} label={lang.obvod} width={'100%'} value={''} func={SetObvod.bind(this)} />
                        </div>
                        : null : null}
                </div>
                {props.typ == 0 ?
                    <div style={{ ...styles.Block }}>
                        <FormControlLabel style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }} label={lang.obmedzenie_slobody}
                            control={<Checkbox checked={obmedzenieSlobody} size={'small'} onChange={() => setObmedzenieSlobody(!obmedzenieSlobody)} />}
                        />
                    </div>
                    : null}

                {props.typ == 0 ?
                    <div style={{ ...styles.Block, marginTop: 20, backgroundColor: global.theme_gray, height: 140 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <FormControlLabel style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }} label={lang.search_date}
                                control={<Checkbox checked={showDate} size={'small'} onChange={() => ShowDate(!showDate)} />}
                            />
                        </div>
                        {showDate == true ?
                            <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                <div style={{ width: 200, marginRight: 10 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_from}</p>
                                    <TextField inputRef={dateRef} value={dateFrom} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} type={'date'} variant="outlined" onInput={e => setDateFrom(e.target.value)} />
                                </div>
                                <div style={{ width: 200, marginLeft: 10 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.bloky_to}</p>
                                    <TextField inputRef={dateRef} value={dateTo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} type={'date'} variant="outlined" onInput={e => setDateTo(e.target.value)} />
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}

                {props.typ == 2 ?
                    <div style={{ ...styles.BlockLeft, margin: 10, width: 150, paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.auto_spz}</p>
                        <TextField
                            inputRef={props.typ == 2 ? inputRef : null}
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={spz}
                            onInput={e => setSPZ(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>
                    : null}

            </div>

            <div style={{ ...styles.Block, height: 120 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 10 }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.search}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const PsiSearch = (props) => {
    /*
            USAGE:
            <PsiSearch lang={lang}  func={PsiSearchResult.bind(this)} />

            const PsiSearchResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [ulica, setUlica] = useState('')
    const [cisloZnamky, setCisloZnamky] = useState('')
    const [cisloCipu, setCisloCipu] = useState('')

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {


        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        var data = {};
        var searching = false;


        if (name.trim() != '') {
            data.meno = Capitalize(name.trim());
            searching = true;
        }
        if (surname.trim() != '') {
            data.priezvisko = Capitalize(surname.trim());
            searching = true;
        }
        if (ulica.trim() != '') {
            data.ulica = Capitalize(ulica.trim());
            searching = true;
        }
        if (cisloZnamky.trim() != '') {
            data.cislo_znamky = cisloZnamky.trim();
            searching = true;
        }
        if (cisloCipu.trim() != '') {
            data.cislo_cipu = Capitalize(cisloCipu.trim());
            searching = true;
        }

        if (searching == true) {
            data.default = false;
        } else {
            data = null;
        }

        Press(1, data);
    }

    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.search}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500 }}>
                {/* MENO, PRIEZVISKO, ULICA */}
                <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                    <div style={{ ...styles.BlockLeft, margin: 10, width: '50%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.pes_majitel_surname}</p>
                        <TextField
                            inputRef={inputRef}
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={surname}
                            onInput={e => setSurname(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>
                    <div style={{ ...styles.BlockLeft, margin: 10, width: '50%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.pes_majitel_name}</p>
                        <TextField
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={name}
                            onInput={e => setName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>
                    {/*
                    <div style={{ ...styles.BlockLeft, margin: 10, width: '33%', paddingTop: 3 }}>
                        <AutocompleteText inputRef={null} new_record={false} row={false} check={false} department={props.department} db={2} lang={lang} label={lang.street} width={'100%'} value={ulica} func={SetUlica.bind(this)} />
                    </div>
                    */}
                </div>

                {/* CISLO ZNAMKY, CISLO ČIPU */}
                <div style={{ ...styles.BlockRowRaw, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.cislo_znamky}</p>
                        <TextField
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={cisloZnamky}
                            onInput={e => setCisloZnamky(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>
                    <div style={{ ...styles.BlockLeft, margin: 10, width: 200, paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5 }}>{lang.cislo_cipu}</p>
                        <TextField
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={cisloCipu}
                            onInput={e => setCisloCipu(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 20 }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.search}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}



export const Capitalize = (text) => {
    var _text = text;
    if (text != undefined) {
        if (text != null && text.trim() != '') {
            var _text = text[0].toUpperCase() + text.slice(1).toLowerCase();
        }
    }
    return _text;
}


export const StatsSearch = (props) => {
    /*
            USAGE:
            <StatsSearch cislo={true} lang={lang} func={StatsSearchResult.bind(this)} />

            const StatsSearchResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');
    const [tab, setTab] = useState(0);

    const [month, setMonth] = useState(-1);
    const [year, setYear] = useState('');
    const [yearErr, setYearErr] = useState('');
    const [obvod, setObvod] = useState('');
    const [cislo, setCislo] = useState('');
    const [cisloErr, setCisloErr] = useState('');

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [dateToErr, setDateToErr] = useState(false);

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const dateRef = useRef(null);
    const yearRef = useRef(null);
    const cisloRef = useRef(null);

    useEffect(() => {

        if (props.data.default == true) {
            timer.current = setTimeout(() => {
                yearRef.current.focus();
            }, 300);
        }

        var today = new Date();
        var y = today.getFullYear();
        var date = y + '-01-01';
        setDateFrom(date);

        var date = y + '-12-31';
        setDateTo(date);

        var today = new Date();
        var y = today.getFullYear();
        var m = today.getMonth();
        setYear(y);
        setMonth(-1);

        if (props.data != undefined) {
            if (props.data.obvod != undefined) {
                if (props.data.obvod != global.centrala_kod) {
                    setObvod(props.data.obvod);
                }
            }
            if (props.data.datum != undefined) {
                var tmp = props.data.datum.toString().split('-');
                setYear(tmp[0]);
                if (tmp.length > 1) {
                    setMonth(parseInt(tmp[1]) - 1);
                }
            }
            if (props.data.datum_od != undefined) {
                setDateFrom(props.data.datum_od);
                setTab(1);
            }
            if (props.data.datum_do != undefined) {
                setDateTo(props.data.datum_do);
                setTab(1);
            }
            if (props.data.cislo != undefined) {
                setCislo(props.data.cislo);
                setTab(2);
                timer.current = setTimeout(() => {
                    cisloRef.current.focus();
                }, 300);
            } else {
                setCislo(GetTodayYear() + '-000001');

            }
        }

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const ChangeTab = (event, value) => {
        setTab(value);
        if (value == 0) {
            timer.current = setTimeout(() => {
                yearRef.current.focus();
            }, 300);
        }
        if (value == 1) {
            timer.current = setTimeout(() => {
                dateRef.current.focus();
            }, 300);
        }
        if (value == 2) {
            timer.current = setTimeout(() => {
                cisloRef.current.focus();
            }, 300);
        }
    }

    const ChangeMonth = (event) => {
        var value = event.target.value;
        setMonth(value);
    }

    const Process = () => {
        var error = false;
        setYearErr('');
        setDateToErr('');
        setCisloErr(false);

        if (tab == 0) {

            if (year == '*' || year == '') {
                var data = {
                    default: false
                }
                if (obvod != '' && obvod != '*') {
                    data.obvod = obvod;
                }
                Press(1, data);
            } else {
                var _year = parseInt(year);
                if (_year < 2000 || _year > 2100) {
                    setYearErr(lang.value_error);
                    error = true;
                }

                if (error == false) {
                    if (month == -1) {
                        var datum = year.toString();
                    } else {
                        var datum = year.toString() + '-' + (month + 1).toString().padStart(2, '0');
                    }
                    var data = {
                        datum: datum,
                        default: false
                    }
                    if (obvod != '' && obvod != '*') {
                        data.obvod = obvod;
                    }
                    Press(1, data);
                }
            }
        }
        if (tab == 1) {
            if (dateFrom < dateTo) {
                var data = {
                    datum_od: dateFrom,
                    datum_do: dateTo,
                    default: false
                }
                if (obvod != '' && obvod != '*') {
                    data.obvod = obvod
                }
                Press(1, data);
            } else {
                setDateToErr('Nesprávny dátum - zlý rozsah');
            }
        }
        if (tab == 2) {
            if (cislo.trim() != '') {
                var data = {
                    cislo: cislo,
                }
                Press(1, data);
            } else {
                setCisloErr(lang.required);
            }
        }

    }

    const SetObvod = (value, key) => {
        if (value != null) {
            setObvod(value.label);
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.filter_data}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, backgroundColor: global.theme_gray, width: 700, paddingTop: 0, paddingBottom: 40 }}>
                <div style={{ ...styles.Block, paddingBottom: 20, backgroundColor: global.theme_white }}>
                    <Tabs
                        value={tab}
                        onChange={ChangeTab}
                        TabIndicatorProps={{
                            style: {
                                color: 'red',
                                backgroundColor: global.theme_light
                            }
                        }}
                        style={{ width: props.cislo == true ? 600 : 400 }}
                    >
                        <Tab value={0} style={{ width: 200 }} label={lang.stats_search_age} />
                        <Tab value={1} style={{ width: 200 }} label={lang.stats_search_range} />
                        {props.cislo == true ?
                            <Tab value={2} style={{ width: 200 }} label={lang.stats_from_number} />
                            : null}
                    </Tabs>
                </div>

                {/* HLADANE OBDOBIE - MESIAC / ROK */}
                {tab == 0 ?
                    <div style={{ ...styles.Block, height: 120, paddingTop: 20 }}>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 15 }}>
                            <div style={{ width: 200, marginRight: 10 }}>
                                <AutocompleteText inputRef={yearRef} decodeKey={props.decodeKey} error={''} check={false} row={false} new_record={false} department={props.department} db={15} lang={lang} label={lang.obvod} width={'100%'} value={obvod} func={SetObvod.bind(this)} />
                            </div>
                            <div style={{ width: 100, marginRight: 10 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.year}</p>
                                <TextField
                                    onKeyDown={(event) => { if (event.key === 'Enter') { Process() } }}
                                    helperText={yearErr} error={yearErr == '' ? false : true} value={year} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setYear(e.target.value)} />
                            </div>
                            <div style={{ width: 200, marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.month}</p>
                                <Select
                                    value={month}
                                    onChange={ChangeMonth}
                                    style={{ width: '100%', backgroundColor: global.theme_white }}
                                    size="small"
                                >
                                    <MenuItem style={{ color: global.theme_blue }} value={-1}>{lang.whole_year}</MenuItem>
                                    {lang.months.map((item, index) => (
                                        <MenuItem key={index} value={index}>{item}</MenuItem>
                                    )
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                    : null}

                {/* HLADANE OBDOBIE - ROZSAH OBDOBIA */}
                {tab == 1 ?
                    <div style={{ ...styles.Block, height: 120, paddingTop: 20 }}>
                        <p style={{ ...styles.TextSmall }}>{lang.stats_search_time}</p>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 15 }}>
                            <div style={{ width: 200, marginRight: 10 }}>
                                <AutocompleteText inputRef={dateRef} decodeKey={props.decodeKey} error={''} check={false} row={false} new_record={false} department={props.department} db={15} lang={lang} label={lang.obvod} width={'100%'} value={obvod} func={SetObvod.bind(this)} />
                            </div>
                            <div style={{ width: 200, marginRight: 10 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.bloky_from}</p>
                                <TextField
                                    onKeyDown={(event) => { if (event.key === 'Enter') { Process() } }}
                                    value={dateFrom} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} type={'date'} variant="outlined" onInput={e => setDateFrom(e.target.value)} />
                            </div>
                            <div style={{ width: 200, marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.bloky_to}</p>
                                <TextField
                                    onKeyDown={(event) => { if (event.key === 'Enter') { Process() } }}
                                    helperText={dateToErr} error={dateToErr == '' ? false : true} value={dateTo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} type={'date'} variant="outlined" onInput={e => setDateTo(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    : null}

                {/* ZOBRAZIT ZAZNAMY OD CISLA */}
                {tab == 2 ?
                    <div style={{ ...styles.Block, height: 120, paddingTop: 20 }}>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 15 }}>
                            <div style={{ ...styles.Block, width: 400, marginRight: 10 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3 }}>{lang.stats_from_number_text}</p>
                                <div style={{ ...styles.Block }}>
                                    <TextField
                                        onKeyDown={(event) => { if (event.key === 'Enter') { Process() } }}
                                        inputRef={cisloRef} helperText={cisloErr} error={cisloErr == '' ? false : true} value={cislo} size="small" style={{ backgroundColor: global.theme_white, width: 180 }} label={''} variant="outlined" onInput={e => setCislo(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

            </div>

            <div style={{ ...styles.Block, height: 120 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 10 }}>
                    <Button onClick={() => Process()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.filter}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export function GetSearchText(data) {
    // VYTVORI RETAZEC Z JSON - SEARCHING DATA - zobrazi ako Chip text v statistikach

    var lang = sk;
    var text = '';
    if (data.obvod != undefined) {
        if (data.obvod != global.centrala_kod) {
            text = text + ' ' + lang.obvod.toLowerCase() + ': ' + data.obvod;
        }
    }
    if (data.datum != undefined) {
        if (text != '') {
            text = text + ', ';
        }

        var tmp = data.datum.toString().split('-');
        var year = tmp[0];
        var month = '';
        var datum = year;
        if (tmp.length > 1) {
            month = lang.months[tmp[1] - 1].toLowerCase();
            datum = month + ' ' + year;
        } else {
            var datum = lang.year.toLowerCase() + ' ' + datum;
        }

        text = text + ' ' + lang.stats_search_age.toLowerCase() + ': ' + datum;
    }
    if (data.datum_od != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + ' ' + lang.stats_search_age.toLowerCase() + ': ' + GetDate(data.datum_od) + ' - ' + GetDate(data.datum_do);
    }
    if (data.stlpec != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + ' ' + lang.string.toLowerCase() + ': ' + data.text;
    }
    if (data.fields != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + ' ' + lang.rows_selected.toLowerCase();
    }

    if (data.meno != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'meno: ' + data.meno;
    }

    if (data.priezvisko != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'priezvisko: ' + data.priezvisko;
    }

    if (data.rodne_cislo != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'rodné číslo: ' + data.rodne_cislo;
    }

    if (data.cislo != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        if (data.cislo == '') {
            text = text + 'od posledného exportovaného čísla';
        } else {
            text = text + 'od čísla: ' + data.cislo;
        }
    }

    if (data.jednacie_cislo != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'číslo: ' + data.jednacie_cislo;
    }

    if (data.oznamovatel != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'oznamovateľ: ' + data.oznamovatel;
    }

    if (data.stav_riesenia != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'stav riešenia: ' + data.stav_riesenia;
    }

    if (data.priestupok != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'druh priestupku: ' + data.priestupok;
    }

    if (data.sposob_zistenia != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'spôsob zistenia: ' + data.sposob_zistenia;
    }

    if (data.sposob_riesenia != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'spôsob riešenia: ' + data.sposob_riesenia;
    }

    if (data.riesitel != undefined) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'riešiteľ: ' + data.riesitel;
    }

    if (data.obmedzenie_slobody == true) {
        if (text != '') {
            text = text + ', ';
        }
        text = text + 'obmedzenie slobody: Áno';
    }
    return text;
}


export const DialogEnterNote = (props) => {
    /*
            USAGE:
            <DialogEnterNote func={DialogEnterNoteResult.bind(this)} />

            const DialogEnterNoteResult = (value) => {   
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [note, setNote] = useState('');
    const [pdf, setPdf] = useState(true);
    const [xls, setXls] = useState(true);
    const [error, setError] = useState('');
    const [detailed, setDetailed] = useState(false);

    const logo_pdf = require('./react/app/icon_pdf.png');
    const logo_pdf_bw = require('./react/app/icon_pdf_bw.png');
    const logo_xls = require('./react/app/icon_xls.png');
    const logo_xls_bw = require('./react/app/icon_xls_bw.png');


    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        timer.current = setTimeout(() => {
            setOpen(true);
            inputRef.current.focus();
        }, 200);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        if (typ == false) {
            func(typ, note, pdf, xls, detailed);
        } else {
            if (xls == true || pdf == true) {
                func(typ, note, pdf, xls, detailed);
            } else {
                setError(lang.export_select_none_error);
            }
        }
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>

            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.stats_note_enter}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500, paddingTop: 30, paddingBottom: props.disable_choose != true ? 10 : props.choose_typ == true ? 10 : 50, backgroundColor: global.theme_gray }}>
                <div style={{ width: '95%' }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                    <TextField inputRef={inputRef} value={note} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setNote(e.target.value)} />
                </div>
                {props.disable_choose != true ?
                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, marginTop: 20 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.export_select}</p>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <img onClick={() => setPdf(!pdf)} src={pdf == true ? logo_pdf : logo_pdf_bw} style={{ width: 50, opacity: pdf == true ? 1.0 : 0.7, cursor: 'pointer', margin: 10 }} />
                            <img onClick={() => setXls(!xls)} src={xls == true ? logo_xls : logo_xls_bw} style={{ width: 50, opacity: xls == true ? 1.0 : 0.7, cursor: 'pointer', margin: 10 }} />
                        </div>
                        <div style={{ ...styles.Block, height: 20 }}>
                            {error != '' ?
                                <p style={{ ...styles.TextTiny, color: global.theme_red }}>{error}</p>
                                : null}
                        </div>
                    </div>
                    : null}

                {props.choose_typ == true ?
                    <div style={{ ...styles.Block, width: '95%', marginTop: 20, paddingBottom: 10 }}>
                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{props.choose_typ_label != undefined ? props.choose_typ_label : lang.export_detailed}</p>}
                            control={<Checkbox checked={detailed} size={'small'} onChange={() => setDetailed(!detailed)} />}
                        />
                    </div>
                    : null}

            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.exporting}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const DialogInfo = (props) => {
    /*
            USAGE:
            <DialogInfo label={''} text={''} func={DialogInfoResult.bind(this)} />

            const DialogInfoResult = (value) => {   
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        timer.current = setTimeout(() => {
            setOpen(true);
        }, 150);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>

            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 30, paddingBottom: 30, backgroundColor: global.theme_gray }}>
                <FontAwesomeIcon style={{ height: 30, marginBottom: 20, color: props.error == true ? global.theme_red : global.theme_dark_green }} icon={props.error == true ? faTriangleExclamation : faInfoCircle} />
                <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{props.text}</p>
            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.ok}</Button>
                </div>
            </div>
        </Dialog >
    );
}

export const DialogSelectRows = (props) => {
    /*
            USAGE:
            <DialogSelectRows func={DialogSelectRowsResult.bind(this)} />

            const DialogSelectRowsResult = (value) => {   
            }

            */
    const [redraw, setRedraw] = useState(false);
    const [items, setItems] = useState([]);


    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {
        var tmp = props.items;
        tmp.forEach(item => {
            if (item.selected == undefined) {
                item.selected = true
            }
        });

        // SKOPIROVANIE UDAJOV DO PREMENNEJ
        var keys = Object.keys(tmp);
        var data = [];
        keys.forEach(key => {
            data[key] = tmp[key];
        });

        // ZORADENIE PODLA ABECEDY
        data.sort((a, b) => {
            if (a[props.column].toUpperCase() > b[props.column].toUpperCase()) {
                return 1;
            } else {
                return -1;
            }
        })

        setItems(data);
        return () => {
        };

    }, []);


    const Press = (typ) => {
        var result = [];
        items.forEach(item => {
            if (item.selected == true) {
                result.push(item.id);
            }
        });
        func(typ, result);
    }

    const Change = (item) => {
        var tmp = items.find(x => x.id == item.id);
        tmp.selected = !tmp.selected;
        setRedraw(!redraw);
    }

    const Select = (typ) => {
        items.forEach(item => {
            item.selected = typ;
        });
        setRedraw(!redraw);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>

            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.stats_select}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.BlockCenter, width: 500, height: 500, paddingTop: 10, paddingBottom: 10, backgroundColor: global.theme_gray, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }}>
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Button onClick={() => Select(false)} style={{ ...styles.ButtonAction, height: 28, margin: 10 }}>{lang.select_none}</Button>
                        <Button onClick={() => Select(true)} style={{ ...styles.ButtonAction, height: 28, margin: 10 }}>{lang.select_all}</Button>
                    </div>
                </div>

                {items.map(item => (
                    <div key={item.id} style={{ ...styles.BlockLeft, width: '90%' }}>
                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{item[props.column]}</p>}
                            control={<Checkbox checked={item.selected} size={'small'} onChange={() => Change(item)} />}
                        />
                    </div>
                ))}

            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const KnihaPriestupkovExport = (props) => {
    /*
            USAGE:
            <KnihaPriestupkovExport func={KnihaPriestupkovExportResult.bind(this)} />

            const KnihaPriestupkovExportResult = (value) => {   
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [note, setNote] = useState('');
    const [pdf, setPdf] = useState(true);
    const [xls, setXls] = useState(true);
    const [pageNumber, setPageNumber] = useState('');
    const [pageNumberErr, setPageNumberErr] = useState(false);
    const [celeStrany, setCeleStrany] = useState(true);
    const [error, setError] = useState('');

    const logo_pdf = require('./react/app/icon_pdf.png');
    const logo_pdf_bw = require('./react/app/icon_pdf_bw.png');
    const logo_xls = require('./react/app/icon_xls.png');
    const logo_xls_bw = require('./react/app/icon_xls_bw.png');


    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        setPageNumber(props.pageNumber);

        timer.current = setTimeout(() => {
            setOpen(true);
            inputRef.current.focus();
        }, 200);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        setPageNumberErr(false);

        if (typ == false) {
            func(typ, note, pdf, xls, pageNumber, celeStrany);
        } else {
            if (pageNumber != '') {
                func(typ, note, pdf, xls, pageNumber, celeStrany);
            } else {
                setError(lang.required);
                setPageNumberErr(true);
            }
        }
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>

            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.kniha_priestupkov_export}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500, paddingTop: 30, paddingBottom: props.disable_choose != true ? 10 : 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.BlockRow, width: '95%' }}>
                    <div style={{ ...styles.BlockLeft, width: '35%' }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kniha_priestupkov_cislo_strany}</p>
                        <TextField inputRef={inputRef} error={pageNumberErr} value={pageNumber} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPageNumber(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockRight, width: '65%', marginLeft: 0, paddingTop: 18 }}>
                        <FormControlLabel label={<div>
                            <p style={{ ...styles.TextTiny }}>{lang.kniha_priestupkov_cele_strany}</p>
                            <p style={{ ...styles.TextXXTiny }}>{lang.kniha_priestupkov_cele_strany_2}</p>
                        </div>
                        }
                            control={<Checkbox checked={celeStrany} size={'small'} onChange={() => setCeleStrany(!celeStrany)} />}
                        />
                    </div>
                </div>

                <div style={{ width: '95%', marginTop: 20 }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                    <TextField value={note} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setNote(e.target.value)} />
                </div>
                {props.disable_choose != true ?
                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, marginTop: 20 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.export_select}</p>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <img onClick={() => setPdf(!pdf)} src={pdf == true ? logo_pdf : logo_pdf_bw} style={{ width: 50, opacity: pdf == true ? 1.0 : 0.7, cursor: 'pointer', margin: 10 }} />
                            <img onClick={() => setXls(!xls)} src={xls == true ? logo_xls : logo_xls_bw} style={{ width: 50, opacity: xls == true ? 1.0 : 0.7, cursor: 'pointer', margin: 10 }} />
                        </div>
                    </div>
                    : null}

                <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                    <div style={{ ...styles.Block, height: 25 }}>
                        {error != '' ?
                            <p style={{ ...styles.TextTiny, color: global.theme_red }}>{error}</p>
                            : null}
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.exporting}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const UdalostSearch = (props) => {
    /*
            USAGE:
            <UdalostSearch  func={UdalostSearchResult.bind(this)} />

            const UdalostSearchResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [name, setName] = useState('')
    const [cislo, setCislo] = useState('')

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        var data = {};
        var searching = false;


        if (name.trim() != '') {
            data.oznamovatel = name.trim();
            data.default = false;
        }
        if (cislo.trim() != '') {
            var tmp = cislo.trim().toLowerCase();
            tmp = tmp.replace('u-', '');
            data.jednacie_cislo = tmp;
            data.default = false;
        }

        if (data.default != false) {
            data = null;
        }

        Press(1, data);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.search}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500 }}>
                {/* MENO, PRIEZVISKO, ULICA */}
                <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>

                    <div style={{ ...styles.BlockLeft, margin: 10, width: '30%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.number}</p>
                        <TextField
                            inputRef={inputRef}
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={cislo}
                            onInput={e => setCislo(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>

                    <div style={{ ...styles.BlockLeft, margin: 10, width: '70%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.oznamovatel}</p>
                        <TextField
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={name}
                            onInput={e => setName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>

                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 20 }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.search}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const KameraSearch = (props) => {
    /*
            USAGE:
            <UdalostSearch  func={UdalostSearchResult.bind(this)} />

            const UdalostSearchResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [name, setName] = useState('')
    const [cislo, setCislo] = useState('')

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        var data = {};
        var searching = false;


        if (name.trim() != '') {
            data.ziadatel = name.trim();
            data.default = false;
        }
        if (cislo.trim() != '') {
            var tmp = cislo.trim().toLowerCase();
            tmp = tmp.replace('kz-', '');
            data.jednacie_cislo = tmp;
            data.default = false;
        }

        if (data.default != false) {
            data = null;
        }

        Press(1, data);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.search}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500 }}>
                {/* MENO, PRIEZVISKO, ULICA */}
                <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>

                    <div style={{ ...styles.BlockLeft, margin: 10, width: '30%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.number}</p>
                        <TextField
                            inputRef={inputRef}
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={cislo}
                            onInput={e => setCislo(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>

                    <div style={{ ...styles.BlockLeft, margin: 10, width: '70%', paddingTop: 3 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.kamera_ziadatel}</p>
                        <TextField
                            size="small"
                            style={{ backgroundColor: global.theme_white, width: '100%' }}
                            label={''}
                            variant="outlined"
                            value={name}
                            onInput={e => setName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Save();
                                }
                            }}
                        />
                    </div>

                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 20 }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.search}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const LogsSearch = (props) => {
    /*
            USAGE:
            <LogsSearch  func={LogsSearchResult.bind(this)} />

            const LogsSearchResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const [name, setName] = useState('')
    const [nameID, setNameID] = useState(0)

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        var data = {};

        if (nameID != 0) {
            data.meno = name;
            data.user_id = nameID;
            data.default = false;
        }

        if (data.default != false) {
            data = null;
        }

        Press(1, data);
    }

    const SetName = (value, key) => {
        if (value != null) {
            setName(value.label);
            setNameID(value.id);
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.search}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 500 }}>
                {/* CISLO, MENO */}
                <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_gray }}>

                    <div style={{ ...styles.BlockLeft, margin: 10, width: '100%', paddingTop: 3 }}>
                        <AutocompleteText inputRef={inputRef} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.name_surname} width={'100%'} value={name} value_id={nameID} func={SetName.bind(this)} />
                    </div>

                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.BlockRow, width: undefined, paddingBottom: 20 }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.search}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const GetDefaultFilter = (obvod, mesiac) => {
    var month = '';
    if (mesiac != undefined) {
        if (mesiac == true) {
            var m = GetTodayMonth();
            month = '-' + (m + 1).toString().padStart(2, '0');
        }
    }
    var result = { datum: GetTodayYear() + month, default: true };
    if (obvod != undefined) {
        if (obvod.kod == global.centrala_kod) {
            result = { datum: GetTodayYear() + month, default: true };
        } else {
            result = { datum: GetTodayYear() + month, obvod: obvod.kod, default: true };
        }
    }
    return result;
}



export const LogsDialog = (props) => {
    /*
            USAGE:
            <LogsDialog decodeKey={props.decodeKey} id={id} func={LogsDialogPress.bind(this)} />

            const LogsDialogPress = (value) => {
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();

    useEffect(() => {

        if (props.id != undefined) {
            db_get(props.id);
        }

        return () => {
        };

    }, []);

    const db_get = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    typ: props.typ,
                    decode_key: props.decodeKey
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.priestupok_logs} {props.item.jednacie_cislo}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 900 }}>
                <Box sx={{
                    height: 410,
                    width: 1,
                    '& .super-app-theme--header': {
                        backgroundColor: global.theme_table_header_color,
                    },
                    '& .super-app-theme--normal': {
                        backgroundColor: global.theme_white,
                        color: '#000000',
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                    '& .super-app-theme--disabled': {
                        backgroundColor: global.theme_white,
                        color: global.theme_protected,
                        fontWeight: 'normal',
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                }}>
                    <DataGrid
                        sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                        rows={items}
                        columns={columnsLogs}
                        rowHeight={30}
                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                        getRowClassName={(params) => (`super-app-theme--normal`)}
                        hideFooterRowCount={true}
                        hideFooterSelectedRowCount={true}
                    />
                </Box>
            </div>

            <div style={{ ...styles.Block, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                </div>
            </div>
        </Dialog >
    );
}

export const DialogMobileAsk = (props) => {
    /*
            USAGE:
            <DialogMobileAsk lang={lang} label={} text={} func={DialogMobileAskResult.bind(this)} />

            const DialogMobileAskResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.Block, height: 50, backgroundColor: global.theme_lighter }}>
                <div style={{ ...styles.BlockRow }}>
                    <FontAwesomeIcon style={{ height: 20, color: global.theme_darker, marginLeft: 10, marginRight: 10 }} icon={faLayerGroup} />
                    <p style={{ ...styles.TextSmall, color: global.theme_darker }}>{props.label}</p>
                </div>
            </div>


            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <p style={{ ...styles.TextTiny }}>{props.text}</p>
            </div>

            <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10 }}>
                <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreenMobile, width: 140, marginLeft: 10, marginRight: 10 }}>{lang.yes}</Button>
                <Button onClick={() => Press(false)} style={{ ...styles.ButtonMobile, width: 140, marginLeft: 10, marginRight: 10 }}>{lang.no}</Button>
            </div>

        </Dialog >
    );
}

export const DialogPhotoPreview = (props) => {
    /*
            USAGE:
            <DialogPhotoPreview image={}  func={DialogPhotoPreviewResult.bind(this)} />

            const DialogPhotoPreviewResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [showGPS, setShowGPS] = useState(false)


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = 1200;
    const dialogHeight = 800;

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    const GPS = () => {
        setShowGPS(true);
    }
    const DialogMapResult = () => {
        setShowGPS(false);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.photo_preview}</p>
                </div>
                {parseInt(props.lat) != 0 ?
                    <div style={{ width: 120 }}>
                        <Button onClick={() => GPS()} style={{ ...styles.ButtonDark, width: 100, height: 30 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white, marginRight: 5 }} icon={faLocationDot} />
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.gps}</p>
                        </Button>
                    </div>
                    : null}

                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, maxWidth: 1000, backgroundColor: global.theme_dark_gray }}>
                <img src={props.image} style={{ width: '100%', height: '100%', maxWidth: dialogWidth, maxHeight: dialogHeight - global.dialog_title_height, objectFit: 'contain' }}></img>
            </div>

            {showGPS == true ?
                <DialogMap lat={props.lat} lng={props.lng} func={DialogMapResult.bind(this)} />
                : null}

        </Dialog >
    );
}

const AnyReactComponent = ({ text }) => (
    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
        <img src={pin} height={40} />
    </div>
);

export const DialogMap = (props) => {
    /*
            USAGE:
            <DialogMap lan={} lng={}  func={DialogMapResult.bind(this)} />

            const DialogMapResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = 800;
    const dialogHeight = 800;

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.photo_gps}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: dialogWidth, height: dialogHeight }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }}
                    defaultZoom={17}
                >
                    <AnyReactComponent
                        lat={props.lat}
                        lng={props.lng}
                    />
                </GoogleMapReact>
            </div>


        </Dialog >
    );
}

export const DialogMobilePhotoPreview = (props) => {
    /*
            USAGE:
            <DialogMobilePhotoPreview image={}  func={DialogMobilePhotoPreviewResult.bind(this)} />

            const DialogMobilePhotoPreviewResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [showGPS, setShowGPS] = useState(false)


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    return (
        <Dialog open={true} fullScreen={true}>
            <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_black }}>
                <img onClick={() => Press(false)} src={props.image} style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}></img>
            </div>
        </Dialog >
    );
}


export const DialogUsepError = (props) => {
    /*
            USAGE:
            <DialogUsepError id={''}  func={DialogUsepErrorResult.bind(this)} />

            const DialogUsepErrorResult = (value) => {   
            }

            */
    const [isBusy, setBusy] = useState(true);
    const [text, setText] = useState('');
    const [popis1, setPopis1] = useState('');
    const [popis2, setPopis2] = useState('');

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'usep_error', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    priestupok_id: props.id,
                    decode_key: props.decodeKey
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var log = json.log;
                if (log != false) {
                    setText(log.text);
                    setPopis1(log.popis1);
                    setPopis2(log.popis2);
                } else {
                    setPopis1(lang.usep_error_null);
                }
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>

            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{lang.usep_error}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 700, minHeight: 150, paddingTop: 30, paddingBottom: 30, backgroundColor: global.theme_gray }}>
                {isBusy == false ?
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{text}</p>
                        <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 20 }}>{lang.usep_error_text}:</p>
                        <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 2 }}>{popis1}</p>
                        <p style={{ ...styles.TextSmall, textAlign: 'center', marginTop: 5 }}>{popis2}</p>
                    </div>
                    : null}
            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.ok}</Button>
                </div>
            </div>

            {isBusy ?
                <Loading center={true} />
                : null}

        </Dialog >
    );
}

