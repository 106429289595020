// SPôSOB ZISTENIA

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from '../globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faPen, faPlus, faClose, faUser, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { faEnvelope, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { Drawer } from '@mui/material';
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { render } from '@testing-library/react';
import { DataGrid, gridTabIndexStateSelector, skSK } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { CheckPermision, Debug, DialogEnterNote, Loading, DialogInfo, AutocompleteText } from '../items.js';
import Box from '@mui/material/Box';
import { styles } from '../styles.js';


export const Ciselnik = (props) => {
    /*    
            USAGE:
            <Ciselnik func={CiselnikResult.bind(this)} />
    
            const CiselnikResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [index, setIndex] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState([]);
    const [showExport, setShowExport] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    // ----------------------------

    const [enabled, setEnabled] = useState(true);
    const [pravnickaOsoba, setPravnickaOsoba] = useState(false);

    const [poradie, setPoradie] = useState('');
    const [vzn, setVzn] = useState('');
    const [label, setLabel] = useState('');
    const [labelMobil, setLabelMobil] = useState('');
    const [labelErr, setLabelErr] = useState(false);

    const [zakon, setZakon] = useState('');
    const [paragraf, setParagraf] = useState('');
    const [odstavec, setOdstavec] = useState('');
    const [pismeno, setPismeno] = useState('');

    const [zakon2, setZakon2] = useState('');
    const [paragraf2, setParagraf2] = useState('');
    const [odstavec2, setOdstavec2] = useState('');
    const [pismeno2, setPismeno2] = useState('');

    const [hotovost, setHotovost] = useState(false);
    const [sek, setSek] = useState(false);
    const [pokuta, setPokuta] = useState(false);
    const [bodovySystem, setBodovySystem] = useState(false);
    const [body, setBody] = useState(false);
    const [points, setPoints] = useState('');

    const [ulica, setUlica] = useState('');
    const [psc, setPSC] = useState('');
    const [mesto, setMesto] = useState('');
    const [ulicaErr, setUlicaErr] = useState(false);
    const [pscErr, setPSCErr] = useState(false);
    const [mestoErr, setMestoErr] = useState(false);
    const [cislo, setCislo] = useState('');

    const [okres, setOkres] = useState('');
    const [kodOkres, setKodOkres] = useState('');
    const [kraj, setKraj] = useState('');
    const [okresErr, setOkresErr] = useState(false);
    const [kodOkresErr, setKodOkresErr] = useState(false);
    const [krajErr, setKrajErr] = useState(false);
    const [kod, setKod] = useState('');
    const [kodErr, setKodErr] = useState(false);
    const [lokalita, setLokalita] = useState('');
    const [lokalitaID, setLokalitaID] = useState(0);

    const [exporting, setExporting] = useState(false);
    // ----------------------------

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const defaultTypes = [0, 1, 2, 3, 4, 5, 6, 7, 17];

    let params = useParams();
    let { func } = props;
    var lang = sk;

    const timer = useRef();
    const inputRef = useRef(null);

    useEffect(() => {

        // NASTAVENIE MOZNOSTI EDITOVAT BUNKY - AK JE POVOLENIE
        var tmp = props.columns;
        if (CheckPermision(props.permissions, global.permission_edit) == true) {
            tmp.forEach(item => {
                item.editable = true;
            });
        }
        setColumns(tmp);

        db_get();
        return () => {
        };

    }, []);



    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + props.db_get, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setItems(json.items);
                setItemsFiltered(json.items);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_update = async () => {

        if (defaultTypes.includes(props.typ)) {
            // CISELNIK UDALOSTI, FUNKCIE
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                label: label,
                enabled: enabled
            }
        }
        if (props.typ == 8) {
            // DRUH PRIESTUPKU
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                label: label,
                //enabled: enabled,
                zakon: zakon,
                paragraf: paragraf,
                odstavec: odstavec,
                pismeno: pismeno,
                exporting: exporting,
                poradie: poradie,
                body: body,
                label_mobil: labelMobil
            }
        }

        if (props.typ == 9) {
            // VZN
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                vzn: vzn,
                label: label,
                //enabled: enabled,
                zakon: zakon,
                paragraf: paragraf,
                odstavec: odstavec,
                pismeno: pismeno,
                zakon2: zakon2,
                paragraf2: paragraf2,
                odstavec2: odstavec2,
                pismeno2: pismeno2,
                //export: exporting,
                poradie: poradie,
                body: body,
                label_mobil: labelMobil
            }
        }
        if (props.typ == 10) {
            // Sposob riesenia
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                label: label,
                //enabled: enabled,
                zakon: zakon,
                paragraf: paragraf,
                odstavec: odstavec,
                pismeno: pismeno,
                hotovost: hotovost,
                pravnicka_osoba: pravnickaOsoba,
                sek: sek,
                body: body,
                bodovy_system: bodovySystem
            }
        }
        if (props.typ == 11 || props.typ == 12) {
            // DOVOD PREDVEDENIA, OSTATNE CINNOSTI
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                label: label,
                points: points
            }
        }
        if (props.typ == 13) {
            // TRESTNE CINY
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                label: label,
                points: points,
                paragraf: paragraf
            }
        }
        if (props.typ == 14) {
            // OBVODY
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                nazov: label,
                kod: kod
            }
        }
        if (props.typ == 15) {
            // ULICE
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                mesto: mesto,
                ulica: ulica,
                lokalita_id: lokalitaID,
                lokalita: lokalita
            }
        }
        if (props.typ == 16) {
            // MESTA
            var data = {
                id: editing == true ? itemsSelected.id : 0,
                department_id: props.department.id,
                enabled: enabled,
                mesto: mesto,
                okres: okres,
                psc: psc,
                kod_okresu: kodOkres,
                kraj: kraj,
            }
        }
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'ciselnik_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    typ: props.typ,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (editing == false) {
                    db_get();
                    setShowDialog(false);
                } else {
                    ChangeEditedRow();
                    setShowDialog(false);
                }
            } else {
                setError(lang.db_error);
            }
        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const db_cell_update = async (id, key, value) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'ciselnik_cell_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: props.typ,
                    id: id,
                    key: key,
                    value: value,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
            }

        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }

    const db_export = async (note) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'ciselnik_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    title: props.label,
                    grid_columns: columns,
                    grid_data: itemsFiltered,
                    token: params.apptoken,
                    note: note,
                    user: props.user,
                    decode_key: props.decodeKey,

                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {

            } else {
                setShowInfo(true);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const AddNew = () => {
        // pridanie nového záznamu

        // ------- ZMENIT DATA --------  
        setUlicaErr(false);
        setMestoErr(false);
        setOkresErr(false);
        setPSCErr(false);
        setKodOkresErr(false);
        setKrajErr(false);

        setEnabled(true);
        setPoradie('');
        setVzn('');
        setLabel('');
        setLabelMobil('');
        setLabelErr(false);
        setZakon('');
        setParagraf('');
        setOdstavec('');
        setPismeno('');

        setPravnickaOsoba(false);
        setZakon2('');
        setParagraf2('');
        setOdstavec2('');
        setPismeno2('');

        setHotovost(false);
        setSek(false);
        setPokuta(false);
        setBodovySystem(false);
        setBody('');
        setPoints('');
        setKod('');

        setMesto(props.department.town);
        setUlica('');
        setPSC('');
        setCislo('');

        setOkres('');
        setKodOkres('');
        setKraj('');
        setExporting(false);

        setLokalita('');
        setLokalitaID(0);
        // ----------------------------

        setError('');
        setEditing(false);
        setShowDialog(true);
        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);
    }

    const EditLine = () => {
        // editácia existujúceho záznamu
        var result = items.filter(x => x.id == index);
        if (result.length == 1) {
            var data = result[0];
            if (parseInt(data.department_id) > 0 || CheckPermision(props.permissions, global.permission_system_admin) == true) {

                // CISELNIK UDALOSTI
                if (defaultTypes.includes(props.typ)) {
                    setEnabled(data.enabled == true);
                    setLabel(data.label);
                    setLabelErr(false);
                }
                if (props.typ == 8) {
                    // DRUH PRIESTUPKU      
                    setPoradie(data.poradie);
                    setLabel(data.label);
                    setLabelMobil(data.label_mobil);
                    setLabelErr(false);
                    setZakon(data.zakon);
                    setParagraf(data.paragraf);
                    setOdstavec(data.odstavec);
                    setPismeno(data.pismeno);
                    setExporting(data.export == 0 ? false : true);
                    setBody(data.body);
                }

                if (props.typ == 9) {
                    // VZN     
                    setPoradie(data.poradie);
                    setVzn(data.vzn)
                    setLabel(data.label);
                    setLabelMobil(data.label_mobil);
                    setLabelErr(false);

                    setZakon(data.zakon);
                    setParagraf(data.paragraf);
                    setOdstavec(data.odstavec);
                    setPismeno(data.pismeno);

                    setZakon2(data.zakon2);
                    setParagraf2(data.paragraf2);
                    setOdstavec2(data.odstavec2);
                    setPismeno2(data.pismeno2);
                    setBody(data.body);

                    setExporting(data.export == 0 ? false : true);
                }

                if (props.typ == 10) {
                    // SPOSOB RISENIA
                    setLabel(data.label);
                    setLabelErr(false);

                    setZakon(data.zakon);
                    setParagraf(data.paragraf);
                    setOdstavec(data.odstavec);
                    setPismeno(data.pismeno);

                    setHotovost(data.hotovost == true ? true : false);
                    setSek(data.sek == true ? true : false);
                    setPokuta(data.pokuta == true ? true : false);
                    setPravnickaOsoba(data.pravnicka_osoba == true ? true : false);
                    setBodovySystem(data.bodovy_system == true ? true : false);
                    setBody(data.body);
                }

                if (props.typ == 11 || props.typ == 12) {
                    // dovod predvedenia, ostatne
                    setEnabled(data.enabled == true);
                    setLabel(data.label);
                    setPoints(data.points);
                    setLabelErr(false);
                }
                if (props.typ == 13) {
                    // trestné činy
                    setEnabled(data.enabled == true);
                    setLabel(data.label);
                    setPoints(data.points);
                    setParagraf(data.paragraf);
                    setLabelErr(false);
                }
                if (props.typ == 14) {
                    // OBVODY
                    setEnabled(data.enabled == true ? true : false);
                    setLabel(data.nazov);
                    setKod(data.kod);
                    setKodErr(false);
                    setLabelErr(false);
                }
                if (props.typ == 15) {
                    // ULICE
                    setEnabled(data.enabled == true ? true : false);
                    setMesto(data.mesto);
                    setUlica(data.ulica);
                    setLabelErr(false);
                    setLokalita(data.lokalita);
                    setLokalitaID(data.lokalita_id);
                }

                if (props.typ == 16) {
                    // MESTA                    
                    setMesto(data.mesto);
                    setOkres(data.okres);
                    setPSC(data.psc);
                    setKodOkres(data.kod_okresu);
                    setKraj(data.kraj);
                }

                setError('')
                setItemsSelected(data);
                setEditing(true);
                setShowDialog(true);
                timer.current = setTimeout(() => {
                    inputRef.current.focus();
                }, 300);
            }
        }
    }


    const DialogSave = () => {
        if (CheckData() == false) {
            db_update();
        }
    }


    const CellSave = (params) => {
        // pri zmene bunky - nahrať do DB
        var result = items.filter(x => x.id == index);
        if (result.length == 1 && (CheckPermision(props.permissions, global.permission_system_admin) == true || parseInt(result[0].department_id) != 0)) {
            var data = result[0];
            data[params.field] = params.value;
            db_cell_update(parseInt(params.id), params.field, params.value);
        } else {
            db_get();
        }
    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemsSelected(data);
        setIndex(id);
    }

    const ChangeSearch = (_str) => {
        setSearch(_str);
        var str = _str.trim();
        var data = [];

        // UDALOST
        if (defaultTypes.includes(props.typ)) {
            data = items.filter(x => x.label.toLowerCase().includes(str.toLowerCase()));
        }
        if (props.typ > 7 && props.typ < 15) {
            data = items.filter(x => x.label.toLowerCase().includes(str.toLowerCase()));
        }
        if (props.typ == 15) {
            data = items.filter(x => x.ulica.toLowerCase().includes(str.toLowerCase()));
        }
        if (props.typ == 16) {
            data = items.filter(x => x.mesto.toLowerCase().includes(str.toLowerCase()));
        }
        setItemsFiltered(data);
    }

    const ChangeEditedRow = () => {
        // zmenit editovaný riadok v tabulke
        var data = itemsSelected;

        // UDALOST
        if (defaultTypes.includes(props.typ)) {
            data.label = label;
            data.enabled = enabled
        }
        if (props.typ == 8) {
            // DRUH PRIESTUPKU
            data.label = label;
            data.zakon = zakon;
            data.paragraf = paragraf;
            data.odstavec = odstavec;
            data.pismeno = pismeno;
            data.export = exporting;
            data.poradie = poradie;
            data.body = body;
            data.label_mobil = labelMobil;
        }
        if (props.typ == 9) {
            // VZN
            data.vzn = vzn
            data.label = label;
            data.zakon = zakon;
            data.paragraf = paragraf;
            data.odstavec = odstavec;
            data.pismeno = pismeno;
            data.zakon2 = zakon2;
            data.paragraf2 = paragraf2;
            data.odstavec2 = odstavec2;
            data.pismeno2 = pismeno2;
            data.export = exporting;
            data.poradie = poradie;
            data.body = body;
            data.label_mobil = labelMobil;
        }
        if (props.typ == 10) {
            // SPOSOB RIESENIA
            data.label = label;
            data.zakon = zakon;
            data.paragraf = paragraf;
            data.odstavec = odstavec;
            data.pismeno = pismeno;
            data.hotovost = hotovost;
            data.pokuta = pokuta;
            data.sek = sek;
            data.pravnicka_osoba = pravnickaOsoba;
            data.bodovy_system = bodovySystem;
            data.body = body;
        }
        if (props.typ == 11 || props.typ == 12) {
            // DOVOD PREDVEDENIA, OSTATNE CINNOSTI
            data.label = label;
            data.points = points;
        }
        if (props.typ == 13) {
            // TRESTNE CINY
            data.label = label;
            data.points = points;
            data.paragraf = paragraf;
        }
        if (props.typ == 14) {
            // OBVODY
            data.label = label;
            data.enabled = enabled;
            data.kod = kod;
        }
        if (props.typ == 15) {
            // ULICE
            data.mesto = mesto;
            data.ulica = ulica;
            data.lokalita = lokalita;
            data.lokalita_id = lokalitaID;
        }
        if (props.typ == 16) {
            // MESTA
            data.mesto = mesto;
            data.okres = okres;
            data.psc = psc;
            data.kod_okresu = kodOkres;
            data.kraj = kraj;
        }
    }

    const CheckData = () => {
        var err = false;
        setLabelErr(false);
        setError('');
        setUlicaErr(false);

        // ------- ZMENIT DATA --------
        if (props.typ < 15) {
            if (label.trim() == '') {
                err = true;
                setError(lang.required);
                setLabelErr(true);
            }
        }
        if (props.typ == 15) {
            // ulice
            if (ulica.trim() == '') {
                err = true;
                setError(lang.required);
                setUlicaErr(true);
            }
        }
        if (props.typ == 16) {
            // mesta
            if (mesto.trim() == '') {
                err = true;
                setError(lang.required);
                setMestoErr(true);
            }
        }
        // ----------------------------

        return err;
    }

    const DialogEnterNoteResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            db_export(note);
        }

    }

    const DialogInfoResult = () => {
        setShowInfo(false);
    }

    const SetLokalita = (value, key) => {

        if (value != null) {
            setLokalita(value.label);
            setLokalitaID(value.id);
        }
    }

    return (
        <div style={{ ...styles.Block, paddingTop: 20 }}>
            <div style={{ ...styles.Block, width: '100%' }}>
                <div style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ ...styles.BlockLeft, width: 250 }}>
                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                        <TextField size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={search} onInput={e => ChangeSearch(e.target.value)} />
                    </div>
                    <div style={{ ...styles.Block, width: 50, paddingTop: 25 }}>
                        <IconButton onClick={() => ChangeSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>
                <Box sx={{
                    height: 410,
                    width: 1,
                    '& .super-app-theme--header': {
                        backgroundColor: global.theme_table_header_color,
                    },
                    '& .super-app-theme--normal': {
                        backgroundColor: global.theme_white,
                        color: '#000000',
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                    '& .super-app-theme--disabled': {
                        backgroundColor: global.theme_white,
                        color: global.theme_protected,
                        fontWeight: 'normal',
                        '&:hover': {
                            bgcolor: (theme) => (global.theme_gray),
                        },
                    },
                }}>
                    <DataGrid
                        sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                        rows={itemsFiltered}
                        columns={columns}
                        //pageSize={global.table_rows}
                        //rowsPerPageOptions={[global.table_rows]}
                        //checkboxSelection
                        //disableSelectionOnClick  
                        rowHeight={30}
                        onRowClick={(params) => { ChangeIndex(params.id) }}
                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                        getRowClassName={(params) => (params.row.department_id > 0 ? `super-app-theme--normal` : `super-app-theme--disabled`)}
                        hideFooterRowCount={true}
                        hideFooterSelectedRowCount={true}
                        onCellEditCommit={(params) => { CellSave(params) }}
                    />
                </Box>
            </div>

            <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRow, width: '50%' }}>
                    {CheckPermision(props.permissions, global.permission_ciselníky) == true ?
                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonNew, marginRight: 20 }}>
                            <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPlus} />
                            <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.new}</p>
                        </Button>
                        : null}
                    {(CheckPermision(props.permissions, global.permission_ciselníky) && index > 0 && itemsSelected.department_id > 0) || (index > 0 && itemsSelected.department_id == 0 && CheckPermision(props.permissions, global.permission_system_admin)) ?
                        <Button onClick={() => EditLine()} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                            <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                            <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                        </Button>
                        : null}

                </div>
                <div style={{ ...styles.BlockRight, width: '50%' }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {CheckPermision(props.permissions, global.permission_print) == true && itemsFiltered.length > 0 ?
                            <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faFileExport} />
                                <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.exporting}</p>
                            </Button>
                            : null}
                        <IconButton onClick={() => db_get()}>
                            <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                        </IconButton>
                    </div>
                </div>
            </div>

            {/* ****************************************************************************************
                DIALOG - NOVY ZÁZNAM / UPRAVIT ZAZNAM
             **************************************************************************************** */}
            <Dialog open={showDialog} maxWidth={'xl'} style={{ backgroundColor: '#00000000' }} >

                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                    <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                        <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                        <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.record_edit : lang.new_record}</p>
                    </div>
                    <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                        <IconButton onClick={() => setShowDialog(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                        </IconButton>
                    </div>
                </div>

                {defaultTypes.includes(props.typ) ?
                    // CISELNIK UDALOSTI, FUNKCIE
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 700 }}>
                        {/* NAZOV */}
                        <div style={{ ...styles.BlockRow, width: '95%' }}>
                            <div style={{ margin: 10, width: '90%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, marginLeft: 20, width: 100, paddingTop: 15 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.active}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 8 ?
                    // DRUH PRIESTUPKU
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 0, width: 1200 }}>
                        {/* NAZOV, ZAKON, PARAGRAF, ODSTAVEC, PISMENO */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '12%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.zakon}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={zakon} onInput={e => setZakon(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '12%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.paragraf_}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={paragraf} onInput={e => setParagraf(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '12%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.odstavec_}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={odstavec} onInput={e => setOdstavec(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '12%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.pismeno_}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pismeno} onInput={e => setPismeno(e.target.value)} />
                            </div>
                        </div>
                        {/* BODOVY SYSTEM, PORADIE */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: 100 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.poradie}</p>
                                <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={poradie} onInput={e => setPoradie(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: 100 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.points}</p>
                                <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={body} onInput={e => setBody(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, marginLeft: 20, paddingTop: 15 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.export_pz_sr_}</p>}
                                    control={<Checkbox checked={exporting} size={'small'} onChange={() => setExporting(!exporting)} />}
                                />
                            </div>
                        </div>
                        {/* MOBILNY NAZOV */}
                        <div style={{ ...styles.Block, backgroundColor: global.theme_lighter, paddingTop: 20, paddingBottom: 20, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.mobilna_aplikacia}</p>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: 10, width: 400 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.nazov_mobil}</p>
                                    <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={labelMobil} onInput={e => setLabelMobil(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 9 ?
                    // VZN
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 0, width: 800 }}>
                        {/* NAZOV */}
                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                            <div style={{ width: '20%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.vzn}</p>
                                <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={vzn} onInput={e => setVzn(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '80%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                        </div>

                        {/* ZAKON */}
                        <div style={{ ...styles.BlockRow, width: '98%', marginTop: 20 }}>
                            <div style={{ width: '50%' }}>
                            </div>
                            <div style={{ width: '20%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.zakon}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={zakon} onInput={e => setZakon(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.paragraf}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={paragraf} onInput={e => setParagraf(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.odstavec}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={odstavec} onInput={e => setOdstavec(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.pismeno}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pismeno} onInput={e => setPismeno(e.target.value)} />
                            </div>
                        </div>

                        {/* ZAKON */}
                        <div style={{ ...styles.BlockRow, width: '98%', marginTop: 20 }}>
                            <div style={{ ...styles.BlockRight, width: '50%', paddingTop: 25 }}>
                                <p style={{ ...styles.TextTiny, marginRight: 10 }}>S poukázaním na zákon</p>
                            </div>
                            <div style={{ width: '20%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.zakon}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={zakon2} onInput={e => setZakon2(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.paragraf}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={paragraf2} onInput={e => setParagraf2(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.odstavec}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={odstavec2} onInput={e => setOdstavec2(e.target.value)} />
                            </div>
                            <div style={{ marginLeft: 10, width: '10%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.pismeno}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pismeno2} onInput={e => setPismeno2(e.target.value)} />
                            </div>
                        </div>
                        {/* BODOVY SYSTEM, PORADIE */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 50, borderTop: '1px solid ' + global.theme_medium_gray, paddingTop: 10 }}>
                            <div style={{ margin: 10, width: 100 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.poradie}</p>
                                <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={poradie} onInput={e => setPoradie(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: 100 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.points}</p>
                                <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={body} onInput={e => setBody(e.target.value)} />
                            </div>
                            {/*
                            <div style={{ margin: 10, marginLeft: 20, paddingTop: 15 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.export_pz_sr_}</p>}
                                    control={<Checkbox checked={exporting} size={'small'} onChange={() => setExporting(!exporting)} />}
                                />
                            </div>
                            */}
                        </div>
                        {/* MOBILNY NAZOV */}
                        <div style={{ ...styles.Block, backgroundColor: global.theme_lighter, paddingTop: 20, paddingBottom: 20, marginTop: 20 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.mobilna_aplikacia}</p>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: 10, width: 400 }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.nazov_mobil}</p>
                                    <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={labelMobil} onInput={e => setLabelMobil(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 10 ?
                    // SPOSOB RIESENIA
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 1400 }}>
                        {/* NAZOV */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: 10, width: '18%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.zakon}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={zakon} onInput={e => setZakon(e.target.value)} />
                                </div>
                                <div style={{ margin: 10, width: '18%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.paragraf}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={paragraf} onInput={e => setParagraf(e.target.value)} />
                                </div>
                                <div style={{ margin: 10, width: '18%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.odstavec}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={odstavec} onInput={e => setOdstavec(e.target.value)} />
                                </div>
                                <div style={{ margin: 10, width: '18%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.pismeno}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pismeno} onInput={e => setPismeno(e.target.value)} />
                                </div>
                                <div style={{ margin: 10, marginLeft: 70, width: '20%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.body}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={body} onInput={e => setBody(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 40 }}>
                            <div style={{ ...styles.BlockRow, width: '50%' }}>
                                <div style={{ ...styles.BlockLeft, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextInput, fontWeight: 'normal', marginLeft: 10 }}>{lang.sposob_riesenia}</p>
                                    <div style={{ ...styles.BlockRow, width: '90%', marginTop: 15 }}>
                                        <div style={{ margin: 0, width: '25%' }}>
                                            <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0, marginLeft: 20 }} checked={hotovost} onChange={(event) => setHotovost(event.target.checked)} />} label={lang.hotovost} />
                                        </div>
                                        <div style={{ margin: 0, width: '20%', marginLeft: 10 }}>
                                            <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0, marginLeft: 20 }} checked={sek} onChange={(event) => setSek(event.target.checked)} />} label={lang.sek} />
                                        </div>
                                        <div style={{ margin: 0, width: '25%', marginLeft: 10 }}>
                                            <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0, marginLeft: 20 }} checked={pokuta} onChange={(event) => setPokuta(event.target.checked)} />} label={lang.pokuta} />
                                        </div>
                                        <div style={{ margin: 0, width: '30%', marginLeft: 10 }}>
                                            <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0, marginLeft: 20 }} checked={pravnickaOsoba} onChange={(event) => setPravnickaOsoba(event.target.checked)} />} label={lang.pravnicka_osoba} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}

                {props.typ == 11 || props.typ == 12 ?
                    // DOVOD PREDVEDENIA, OSTATNE CINNOSTI
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 700 }}>
                        {/* ENABLED, NAZOV */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '25%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.points}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={points} onInput={e => setPoints(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 13 ?
                    // TRESTNE CINY
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 800 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '80%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField inputRef={inputRef} size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '20%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.paragraf_}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={paragraf} onInput={e => setParagraf(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '20%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.points}</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={points} onInput={e => setPoints(e.target.value)} />
                            </div>

                        </div>
                    </div>
                    : null}


                {props.typ == 14 ?
                    // OBVODY
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 800 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '25%', paddingTop: 15 }}>
                                <FormControlLabel style={{ marginLeft: 20 }} control={<Checkbox checked={enabled} onChange={() => setEnabled(!enabled)} />} label={lang.active} />
                            </div>
                            <div style={{ margin: 10, width: '15%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.code}</p>
                                <TextField inputRef={inputRef} size={'small'} error={kodErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={kod} onInput={e => setKod(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '60%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.label}</p>
                                <TextField size={'small'} error={labelErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={label} onInput={e => setLabel(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 15 ?
                    // ULICE
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 1000 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '33%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.town}</p>
                                <TextField inputRef={inputRef} size={'small'} error={mestoErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={mesto} onInput={e => setMesto(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '33%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.street}</p>
                                <TextField size={'small'} error={ulicaErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={ulica} onInput={e => setUlica(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '33%' }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={25} lang={lang} label={lang.locality} width={'100%'} value={lokalita} value_id={lokalitaID} func={SetLokalita.bind(this)} />
                            </div>
                        </div>
                    </div>
                    : null}

                {props.typ == 16 ?
                    // MESTA
                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20, width: 800 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.town}</p>
                                <TextField size={'small'} error={mestoErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={mesto} onInput={e => setMesto(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.district}</p>
                                <TextField size={'small'} error={okresErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={okres} onInput={e => setOkres(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.psc}</p>
                                <TextField size={'small'} error={pscErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={psc} onInput={e => setPSC(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.district_code}</p>
                                <TextField size={'small'} error={kodOkresErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={kodOkres} onInput={e => setKodOkres(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.country}</p>
                                <TextField size={'small'} error={krajErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={kraj} onInput={e => setKraj(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    : null}


                <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}

                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                    <Button onClick={() => DialogSave()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                    <Button onClick={() => setShowDialog(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                </div>
            </Dialog >

            {/* INFO */}
            {showInfo == true ?
                <DialogInfo label={lang.error} text={lang.stats_export_error} error={true} func={DialogInfoResult.bind(this)} />
                : null}

            {/* EXPORT */}
            {showExport == true ?
                <DialogEnterNote disable_choose={true} func={DialogEnterNoteResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div>
    )
}
