import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, CopyData, PriestupokSearch, Capitalize, GetDate2, GetDate } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';

import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';


export const Vozidla = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectionModel, setSelectionModel] = useState();
    const [redraw, setRedraw] = useState(false);
    const [tab, setTab] = useState(0);
    const [indexPriestupky, setIndexPriestupky] = useState(0);
    const [priestupky, setPriestupky] = useState([]);
    const [priestupokSelected, setPriestupokSelected] = useState([]);
    const [priestupkyFiltered, setPriestupkyFiltered] = useState([]);
    const [itemPriestupok, setItemPriestupok] = useState([]);
    const [showPriestupok, setShowPriestupok] = useState(false);
    const [searchDefault, setSearchDefault] = useState(props.obvod != undefined ? { datum: props.actualYear, obvod: props.obvod.kod, search: false } : { datum: props.actualYear, search: false });

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    useEffect(() => {

        db_get(searchDefault);

        return () => {
        };

    }, []);

    const db_get = async (data) => {
        setBusy(true);
        console.log(data);
        try {
            const response = await fetch(
                global.db_url + 'vozidla', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);
                setIndex(0);
                setSelectionModel(0); // DESELECT LINE

                if (data.search == true) {
                    if (data.text != undefined) {
                        setSearch(data.text);
                    } else {
                        var spz = '';
                        if (data.spz != undefined) {
                            spz = data.spz.trim();
                        }
                        var text = spz;
                        setSearch(text.trim());
                    }
                } else {
                    setSearch('');
                }

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_vozidlo_priestupky = async (spz) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'vozidlo_priestupky', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    spz: spz,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setPriestupky(tmp);
                setPriestupkyFiltered(tmp);
                setIndexPriestupky(0);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_priestupok = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var data = json.item;
                setItemPriestupok(data);
                setShowPriestupok(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const columns = [
        {
            field: 'spz',
            headerName: lang.auto_spz,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 140,
            editable: false,
            hide: false
        },
        {
            field: 'auto_typ',
            headerName: lang.auto_typ,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 150,
            editable: false,
            sortable: true,
            filterable: true,
            align: 'left'
        },
        {
            field: 'auto_druh',
            headerName: lang.auto_druh,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 300,
            editable: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'auto_farba',
            headerName: lang.auto_farba,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 250,
            editable: false,
            filterable: true,
            align: 'left'
        },
    ];

    const columns_priestupky = [
        {
            field: 'datum',
            headerName: lang.date,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            width: 120,
            editable: false,
            hide: false,
            align: 'right'
        },
        {
            field: 'cas_od',
            headerName: lang.time,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: false,
            sortable: true,
            filterable: true,
            align: 'center'
        },
        {
            field: 'priestupok_text',
            headerName: lang.druh_priestupku,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 300,
            editable: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'miesto_priestupku',
            headerName: lang.miesto_priestupku,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 250,
            editable: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'stav_riesenia',
            headerName: lang.stav_riesenia,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'sposob_riesenia',
            headerName: lang.sposob_riesenia,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 250,
            editable: false,
            filterable: true,
            align: 'left'
        },
    ];


    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemSelected(data);
        setIndex(id);
    }

    const ChangePriestupokIndex = (id) => {
        var data = priestupky.find(x => x.id == id);
        setPriestupokSelected(data);
        setIndexPriestupky(id);
    }

    const ShowPreview = () => {
        setShowPreview(true);
    }

    const ChangeTab = (event, value) => {
        setPriestupky([]);
        setPriestupkyFiltered([]);
        setTab(value);
        if (value == 1) {
            var spz = itemSelected.spz;
            db_vozidlo_priestupky(spz);
        }
    }

    const PriestupokPreviewResult = () => {
        setShowPriestupok(false);
    }

    const ShowPriestupok = () => {
        var id = priestupokSelected.id;
        db_priestupok(id);
    }

    const ShowSearch = () => {
        setShowSearch(true);
    }

    const PriestupokSearchResult = (typ, value) => {
        setShowSearch(false);
        console.log(value);
        if (typ == 1) {
            if (value != null) {
                db_get(value);
            }
        }
    }

    const CustomColumnMenuComponent = (props) => {
        const [text, setText] = useState('');
        const { hideMenu, currentColumn, color, ...other } = props;
        const textRef = useRef(null);
        const timer = useRef();

        useEffect(() => {

            if (currentColumn.filterable == true) {
                textRef.current.focus();
            }

            return () => {
            };

        }, []);

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                ownerState={{ color }}
                {...other}
            >
                {/*
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                */}
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, margin: 10 }}>
                        {currentColumn.filterable == true ?
                            <TextField
                                inputRef={textRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: 200 }}
                                label={''} variant="outlined"
                                value={text}
                                placeholder={currentColumn.headerName}
                                onInput={e => setText(e.target.value)}
                                onBlur={hideMenu}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (text.trim() != '') {

                                            var data = {
                                                text: text,
                                                column: currentColumn.field,
                                                search: true
                                            }
                                            db_get(data);
                                        }
                                    }

                                }}
                            />
                            :
                            <p style={{ ...styles.TextTiny, marginTop: 9 }}>{currentColumn.headerName}</p>
                        }
                    </div>
                    <IconButton onClick={hideMenu} style={{ ...styles.Block, height: 36, width: 36, marginTop: 10, marginRight: 10 }}>
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 12 }} icon={faXmark} />
                    </IconButton>
                </div>
            </GridColumnMenuContainer>
        );
    }

    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>
            <Tabs
                value={tab}
                onChange={ChangeTab}
                TabIndicatorProps={{
                    style: {
                        color: 'red',
                        backgroundColor: global.theme_light
                    }
                }}
                style={{ width: 400 }}
            >
                <Tab value={0} style={{ width: 200 }} label={lang.auto_zoznam} />
                <Tab value={1} style={{ width: 200 }} label={lang.auto_prietupky} disabled={index > 0 ? false : true} />

            </Tabs>

            {tab == 0 ?
                // *************************************************************************************
                //    ZOZNAM VOZIDIEL
                // *************************************************************************************

                <div style={{ ...styles.Block, width: '100%' }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        {search != '' ?
                            <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.search_text}:</p>
                                <Chip
                                    label={search}
                                    onDelete={() => db_get(searchDefault)}
                                    style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                                />
                            </div>
                            : null}
                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={itemsFiltered}
                                columns={columns}

                                // vlastná menu po kliknuti na header menu
                                components={{
                                    ColumnMenu: CustomColumnMenuComponent,
                                }}


                                rowHeight={30}
                                onRowClick={(params) => { ChangeIndex(params.id) }}
                                //onCellKeyDown={(params,event) => { console.log(event) }}

                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (`super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '70%' }}>
                            {
                                props.permissions.toString().substr(global.permission_read, 1) == '1' ?
                                    <div style={{ ...styles.BlockLeft, width: 50 }}>
                                        <IconButton onClick={() => ShowSearch()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_dark }}>
                                            <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faSearch} />
                                        </IconButton>
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={{ ...styles.BlockRight, width: '30%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get(searchDefault)}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {tab == 1 ?
                // *************************************************************************************
                //    SPACHANE PRISTUPKY DANYM VOZIDLOM
                // *************************************************************************************
                <div style={{ ...styles.Block, width: '100%' }}>

                    <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                        <p style={{ ...styles.TextNormal }}>{lang.auto_spz}: {itemSelected.spz}</p>
                    </div>

                    <div style={{ ...styles.Block, width: '100%' }}>

                        <Box sx={{
                            height: 410,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--red': {
                                backgroundColor: global.theme_white,
                                color: global.theme_dark_red,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--blue': {
                                backgroundColor: global.theme_white,
                                color: global.theme_blue,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={priestupkyFiltered}
                                columns={columns_priestupky}

                                // vlastná menu po kliknuti na header menu
                                components={{
                                    ColumnMenu: CustomColumnMenuComponent,
                                }}

                                rowHeight={30}
                                onRowClick={(params) => { ChangePriestupokIndex(params.id) }}

                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (`super-app-theme--normal`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                        </Box>

                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '70%' }}>
                            {/*
                            props.permissions.toString().substr(global.permission_read, 1) == '1' ?
                            <div style={{ ...styles.BlockLeft, width: 50 }}>
                                <IconButton onClick={() => ShowSearch()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faSearch} />
                                </IconButton>
                            </div>
                            : null
                        */}
                        </div>
                        <div style={{ ...styles.BlockRight, width: '30%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>

                                {indexPriestupky > 0 && showPriestupok == false ?
                                    <div style={{ width: 50 }}>
                                        <IconButton onClick={() => ShowPriestupok()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_lighter }}>
                                            <FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faInfo} />
                                        </IconButton>
                                    </div>
                                    : null}

                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => db_get(searchDefault)}>
                                        <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {showSearch == true ?
                <PriestupokSearch typ={2} decodeKey={props.decodeKey} department={props.department} lang={lang} func={PriestupokSearchResult.bind(this)} />
                : null}

            {showPriestupok == true ?
                <PriestupokPreview decodeKey={props.decodeKey} dialog={true} item={itemPriestupok} isBusy={isBusy} func={PriestupokPreviewResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div >
    )
};

