import React, { useEffect, useState, useRef, useCallback } from 'react';
//import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faXmark, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, IconButton, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styles } from './styles.js';
import { GetDate, Loading } from './items.js';


export const MobileSearch = (props) => {
    /*
            USAGE:
            <MobileSearch lang={lang} editing={editing} func={MobileSearchResult.bind(this)} />

            const MobileSearchResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [searchOsoba, setSearchOsoba] = useState('');
    const [searchAuto, setSearchAuto] = useState('');
    const [searchNone, setSearchNone] = useState(false);
    const [osoba, setOsoba] = useState([]);
    const [auto, setAuto] = useState([]);
    const [items, setItems] = useState([]);

    const headerHeight = 50;
    const footerHeight = 40;
    const osobaHeight = 220;
    const autoHeight = 150;

    let { func } = props;
    var lang = sk;

    useEffect(() => {


        return () => {
        };

    }, []);

    const db_find_osoba = async (rodne_cislo) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'mobile_search_osoba', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    user_id: props.user.id,
                    rodne_cislo: rodne_cislo,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                if (json.osoba == false) {
                    setSearchNone(true);
                } else {
                    setSearchNone(false);
                    setOsoba(json.osoba);
                    if (json.priestupky != false) {
                        setItems(json.priestupky);
                    } else {
                        setItems([]);
                    }

                }
                setIndex(1);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_find_auto = async (spz) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'mobile_search_auto', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    user_id: props.user.id,
                    spz: spz,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                if (json.spz == false) {
                    setSearchNone(true);
                } else {
                    setSearchNone(false);
                    setAuto(json.auto);
                    if (json.priestupky != false) {
                        setItems(json.priestupky);
                    } else {
                        setItems([]);
                    }

                }
                setIndex(2);
            }
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const Search = () => {
        var rodne_cislo = searchOsoba.trim();
        var spz = searchAuto.trim();
        if (rodne_cislo != '') {
            rodne_cislo = rodne_cislo.replace('/', '');
            rodne_cislo = rodne_cislo.replaceAll(' ', '');
        }

        if (spz != '') {
            spz = spz.replaceAll(' ', '');
            spz = spz.toUpperCase();
        }

        if (rodne_cislo != '') {
            // HLADANIE OSOBY Z RODNYM CISLO
            db_find_osoba(rodne_cislo);

        } else if (spz != '') {
            // HLADANIE VOZIDLA S SPZ
            db_find_auto(spz);
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back, height: props.height }}>

            <Paper elevation={5} style={{ ...styles.Block, height: headerHeight, backgroundColor: global.theme_darker, borderRadius: 0 }}>
                <div style={{ ...styles.BlockRow, marginTop: 0 }}>
                    <div style={{ ...styles.Block, width: 50 }}>
                        {index > 0 ?
                            <IconButton onClick={() => setIndex(0)} style={{ width: 34, height: 34, backgroundColor: global.theme_light }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faAngleLeft} />
                            </IconButton>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <p style={{ ...styles.TextLarge, color: global.theme_white, marginLeft: 10, marginTop: 6, fontWeight: '300' }}><b>{index == 0 ? lang.mobile_search : lang.search_result}</b></p>
                    </div>
                    <div style={{ ...styles.Block, width: 50 }}></div>
                </div>
            </Paper>
            {index == 0 ?
                <div style={{ ...styles.BlockCenter, height: props.height - headerHeight }}>
                    <div style={{ ...styles.Block, width: '70%', marginTop: 20 }}>

                        <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.mobile_search_osoba}</p>
                        <TextField
                            value={searchOsoba}
                            onInput={e => setSearchOsoba(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Search()
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchOsoba('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white },
                            }}
                            inputProps={{
                                inputMode: 'decimal'
                            }}
                            variant="outlined"
                        />

                        <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2, marginTop: 20 }}>{lang.mobile_search_auto}</p>
                        <TextField
                            value={searchAuto}
                            onInput={e => setSearchAuto(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    Search()
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchAuto('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white }
                            }}
                            variant="outlined"
                        />

                        <Button onClick={() => Search()} style={{ ...styles.ButtonMobile, marginTop: 40 }}>
                            <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faSearch} />
                            {lang.search}
                        </Button>
                        <div style={{ ...styles.Block }}>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonMobileCancel, marginTop: 40 }}>
                                <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faXmark} />
                                {lang.cancel}
                            </Button>
                        </div>
                    </div>

                </div>
                : null}

            {index == 1 ?
                // *********************************************************************************************************
                // VYSLEDOK HLADANIA OSOBY
                // *********************************************************************************************************
                <div style={{ ...styles.BlockCenter, height: props.height - headerHeight }}>

                    {searchNone == true ?
                        <div style={{ ...styles.Block, width: '90%', height: props.height - headerHeight }}>
                            <p style={{ ...styles.TextTiny }}>{lang.search_result_none}</p>
                        </div>
                        :
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockCenter, height: osobaHeight - 15, backgroundColor: global.theme_lighter }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.rodne_cislo}: {osoba.rodne_cislo}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.name_surname}: {osoba.meno} {osoba.priezvisko}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.birth_date}: {GetDate(osoba.narodeny)}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.prislusnost}: {osoba.narodnost}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.address}:</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{osoba.ulica} {osoba.ulica_cislo}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{osoba.psc} {osoba.mesto} {osoba.stat}</p>
                            </div>

                            <div style={{ ...styles.BlockCenter, marginTop: 15, height: props.height - headerHeight - osobaHeight - 30, overflowY: 'scroll' }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.osoba_priestupky}</p>
                                {items.map((item, index) => (
                                    <Paper elevation={3} key={item.id} style={{ ...styles.Block, height: 90, marginTop: 8, marginBottom: 5, backgroundColor: global.theme_white }}>
                                        <div style={{ ...styles.Block, width: '95%' }}>
                                            <p style={{ ...styles.TextTiny }}><b>{GetDate(item.datum)}</b><span style={{ marginLeft: 15 }}>{item.cas_od} hod.</span></p>
                                            <p style={{ ...styles.TextXTiny, marginTop: 5 }}>{item.priestupok_text}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.sposob_riesenia} {parseInt(item.hotovost_suma) > 0 ? item.hotovost_suma + ' €' : parseInt(item.sek_sum) > 0 ? item.sek_sum + ' €' : ''}</p>
                                        </div>
                                    </Paper>
                                ))}
                            </div>
                        </div>
                    }

                </div>
                : null}

            {index == 2 ?
                // *********************************************************************************************************
                // VYSLEDOK HLADANIA AUTA
                // *********************************************************************************************************
                <div style={{ ...styles.BlockCenter, height: props.height - headerHeight }}>

                    {searchNone == true ?
                        <div style={{ ...styles.Block, width: '90%', height: props.height - headerHeight }}>
                            <p style={{ ...styles.TextTiny }}>{lang.search_result_none}</p>
                        </div>
                        :
                        <div style={{ ...styles.Block }}>
                            <div style={{ ...styles.BlockCenter, height: autoHeight - 15, backgroundColor: global.theme_lighter }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.auto_spz}: {auto.spz}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.auto_typ}: {auto.auto_typ}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.auto_druh}: {auto.auto_druh}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.auto_farba}: {auto.auto_farba}</p>
                            </div>

                            <div style={{ ...styles.BlockCenter, marginTop: 15, height: props.height - headerHeight - autoHeight - 30, overflowY: 'scroll' }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.osoba_priestupky}</p>
                                {items.map((item, index) => (
                                    <Paper elevation={3} key={item.id} style={{ ...styles.Block, height: 100, marginTop: 8, marginBottom: 5, backgroundColor: global.theme_white }}>
                                        <div style={{ ...styles.Block, width: '95%' }}>
                                            <p style={{ ...styles.TextTiny }}><b>{GetDate(item.datum)}</b><span style={{ marginLeft: 15 }}>{item.cas_od} hod.</span></p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.meno} {item.priezvisko}{item.rodne_cislo != '' ? ', rč: ' + item.rodne_cislo : ''}</p>
                                            <p style={{ ...styles.TextXTiny, marginTop: 5 }}>{item.priestupok_text}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.sposob_riesenia} {parseInt(item.hotovost_suma) > 0 ? item.hotovost_suma + ' €' : parseInt(item.sek_sum) > 0 ? item.sek_sum + ' €' : ''}</p>
                                        </div>
                                    </Paper>
                                ))}
                            </div>
                        </div>
                    }
                </div>
                : null}

            {
                isBusy ?
                    <Loading center={true} />
                    : null
            }
        </div >
    );
}


