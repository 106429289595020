import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, IsEmpty, Loading, notNumber } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSave, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { useHotkeys } from 'react-hotkeys-hook';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { set } from 'date-fns';
import { useGridPreProcessing } from '@mui/x-data-grid/hooks/core/preProcessing/useGridPreProcessing.js';


export const VybaveniePolicie = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [pocetKamier, setPocetKamier] = useState('');
    const [pocetKamierErr, setPocetKamierErr] = useState(false);
    const [pocetObjektov, setPocetObjektov] = useState('');
    const [pocetObjektovErr, setPocetObjektovErr] = useState(false);
    const [pocetDielni, setPocetDielni] = useState('');
    const [pocetDielniErr, setPocetDielniErr] = useState(false);
    const [pocetVozidiel, setPocetVozidiel] = useState('');
    const [pocetVozidielErr, setPocetVozidielErr] = useState(false);
    const [pocetPsov, setPocetPsov] = useState('');
    const [pocetPsovErr, setPocetPsovErr] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;
    let { func } = props;
    let params = useParams();
    const rowOffset = 10;

    useEffect(() => {

        db_get(false);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'vybavenie', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                var data = json.department;
                setPocetKamier(data.pocet_kamier);
                setPocetObjektov(data.pocet_objektov);
                setPocetDielni(data.pocet_dielni);
                setPocetVozidiel(data.pocet_vozidiel);
                setPocetPsov(data.pocet_psov);
            }


        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const db_update = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'vybavenie_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
console.log(json);
            if (json.ok > 0) {
                db_get(true);
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const Check = () => {
        var error = false;
        setError('');

        error = notNumber(pocetKamier) == true ? setPocetKamierErr(true) ?? true : setPocetKamierErr(false) ?? false;
        error += notNumber(pocetObjektov) == true ? setPocetObjektovErr(true) ?? true : setPocetObjektovErr(false) ?? false;
        error += notNumber(pocetDielni) == true ? setPocetDielniErr(true) ?? true : setPocetDielniErr(false) ?? false;
        error += notNumber(pocetVozidiel) == true ? setPocetVozidielErr(true) ?? true : setPocetVozidielErr(false) ?? false;
        error += notNumber(pocetPsov) == true ? setPocetPsovErr(true) ?? true : setPocetPsovErr(false) ?? false;

        return error;
    }

    const Save = () => {
        if (Check() == false) {
            var data = {};
            data.department_id = props.department.id;
            data.pocet_kamier = pocetKamier;
            data.pocet_objektov = pocetObjektov;
            data.pocet_dielni = pocetDielni;
            data.pocet_vozidiel = pocetVozidiel;
            data.pocet_psov = pocetPsov;
            
            db_update(data);
        } else {
            setError(lang.required);
        }
    }


    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%', paddingTop: 20 }}>
            <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, paddingBottom: 40, border: '1px solid ' + global.theme_light_gray }}>
                <p style={{ ...styles.TextLarge, fontWeight: 'bold' }}>{lang.vybavenie_policie}</p>
                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.vybavenie_policie_}</p>
                <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 20, paddingBottom: 0 }}>

                    <div style={{ ...styles.Block, paddingTop: 40, paddingBottom: 40 }}>

                        {/* POCET KAMIER */}
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockRight, width: '52%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 8 }}>{lang.pocet_kamier}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '48%', marginLeft: 20 }}>
                                <TextField size={'small'} error={pocetKamierErr} style={{ width: 100 }} variant="outlined" value={pocetKamier} onInput={e => setPocetKamier(e.target.value)} />
                            </div>
                        </div>

                        {/* POCET OBJEKTOV */}
                        <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                            <div style={{ ...styles.BlockRight, width: '52%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 8 }}>{lang.pocet_objektov}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '48%', marginLeft: 20 }}>
                                <TextField size={'small'} error={pocetObjektovErr} style={{ width: 100 }} variant="outlined" value={pocetObjektov} onInput={e => setPocetObjektov(e.target.value)} />
                            </div>
                        </div>

                        {/* POCET ZAMESTNANCOV DIELNI */}
                        <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                            <div style={{ ...styles.BlockRight, width: '52%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 8 }}>{lang.pocet_dielni}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '48%', marginLeft: 20 }}>
                                <TextField size={'small'} error={pocetDielniErr} style={{ width: 100 }} variant="outlined" value={pocetDielni} onInput={e => setPocetDielni(e.target.value)} />
                            </div>
                        </div>

                        {/* POCET VOZIDIEL */}
                        <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                            <div style={{ ...styles.BlockRight, width: '52%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 8 }}>{lang.pocet_vozidiel}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '48%', marginLeft: 20 }}>
                                <TextField size={'small'} error={pocetVozidielErr} style={{ width: 100 }} variant="outlined" value={pocetVozidiel} onInput={e => setPocetVozidiel(e.target.value)} />
                            </div>
                        </div>

                        {/* POCET PSOV */}
                        <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                            <div style={{ ...styles.BlockRight, width: '52%' }}>
                                <p style={{ ...styles.TextSmall, marginTop: 8 }}>{lang.pocet_psov}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '48%', marginLeft: 20 }}>
                                <TextField size={'small'} error={pocetPsovErr} style={{ width: 100 }} variant="outlined" value={pocetPsov} onInput={e => setPocetPsov(e.target.value)} />
                            </div>
                        </div>

                    </div>

                </div>

                <div style={{ ...styles.Block, height: 40 }}>
                    {error != '' ?
                        <Alert severity="error">{error}</Alert>
                        : null}
                </div>
            </div>

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                {props.permissions.toString().substr(global.permission_admin, 1) == '1' ?
                    <Button onClick={() => Save()} style={{ ...styles.ButtonNew }}>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.save}</p>
                    </Button>
                    : null}
            </div>

            {isLoading ?
                <Loading />
                : null}

        </div >
    )
};

