import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from '../globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { Alert, Button, IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, skSK } from '@mui/x-data-grid';
import { Debug, Loading, CheckPermision, NoPermission } from '../items.js';
import Dialog from '@mui/material/Dialog';
import { faClose, faUser } from '@fortawesome/free-solid-svg-icons'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../styles.js';



export const Countries = (props) => {
    /*    
            USAGE:
            <Users func={UsersPress.bind(this)} />
    
            const UsersPress = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [index, setIndex] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');

    // ----------------------------
    const [stat, setStat] = useState('');
    const [statErr, setStatErr] = useState(false);
    const [code, setCode] = useState('');
    const [code2, setCode2] = useState('');
    const [foreiner, setForeiner] = useState(false);
    // ----------------------------

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;


    useEffect(() => {
        db_get();

        return () => {
        };

    }, []);



    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'states', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setItems(json.states);
                setItemsFiltered(json.states);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_update = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'states_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.is,
                    editing: editing,
                    index: index,
                    stat: stat,
                    short1: code,
                    short2: code2,
                    cudzinec: foreiner,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (editing == false) {
                    db_get();
                    setShowDialog(false);
                } else {
                    ChangeEditedRow();
                    setShowDialog(false);
                }
            } else {
                setError(lang.db_error);
            }
        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const db_cell_update = async (id, key, value) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'states_cell_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    key: key,
                    value: value,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
            }

        } catch (error) {
            Debug(error);
            setError(lang.db_error);
            setBusy(false);
        }
    }


    const columns = [
        {
            field: 'stat',
            headerName: lang.state,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: CheckPermision(props.permissions, global.permission_system_admin),
        },
        {
            field: 'short1',
            headerName: lang.code,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: CheckPermision(props.permissions, global.permission_system_admin),
        },
        {
            field: 'short2',
            headerName: lang.code,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: CheckPermision(props.permissions, global.permission_system_admin),
        },
        {
            field: 'cudzinec',
            headerName: lang.foreiner,
            type: 'boolean',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: CheckPermision(props.permissions, global.permission_system_admin),
        },
    ];


    const AddNew = () => {
        // pridanie nového záznamu

        // ------- ZMENIT DATA --------
        setStat('');
        setCode('');
        setCode2('');
        setForeiner(false);
        setStatErr(false);

        // ----------------------------

        setError('');
        setEditing(false);
        setShowDialog(true);
    }

    const EditLine = () => {
        // editácia existujúceho záznamu
        var result = items.filter(x => x.id == index);
        if (result.length == 1) {
            var data = result[0];

            // ------- ZMENIT DATA --------
            setStatErr(false);

            setStat(data.stat);
            setCode(data.short1);
            setCode2(data.short2);
            setForeiner(data.cudzinec == 0 ? false : true);
            // ----------------------------

            setError('')
            setItemsSelected(data);
            setEditing(true);
            setShowDialog(true);
        }
    }

    const CheckData = () => {
        var err = false;
        setStatErr(false);
        setError('');

        // ------- ZMENIT DATA --------
        if (stat.trim() == '') {
            err = true;
            setError(lang.required);
            setStatErr(true);
        }
        // ----------------------------

        return err;
    }

    const DialogSave = () => {
        if (CheckData() == false) {
            db_update();
        }
    }


    const ChangeEditedRow = () => {
        // zmenit editovaný riadok v tabulke
        var data = itemsSelected;

        // ------- ZMENIT DATA --------
        data.stat = stat;
        data.short1 = code;
        data.short2 = code2;
        data.cudzinec = foreiner;
        // ----------------------------
    }

    const CellSave = (params) => {
        // pri zmene bunky - nahrať do DB
        var result = items.filter(x => x.id == index);
        if (result.length == 1) {
            var data = result[0];
            data[params.field] = params.value;
            db_cell_update(parseInt(params.id), params.field, params.value);
        }
    }

    const ChangeSearch = (_str) => {
        setSearch(_str);
        var str = _str.trim();
        var data = items.filter(x => x.stat.toLowerCase().includes(str.toLowerCase()) || x.short1.includes(str));
        setItemsFiltered(data);
    }

    const ChangeForeiner = (event) => {
        setForeiner(event.target.checked);
    }

    return (
        CheckPermision(props.permissions, global.permission_read) == false ?
            <NoPermission lang={lang} />
            :
            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.Block, width: '100%' }}>

                    <div style={{ ...styles.Block, backgroundColor: global.theme_white, borderRadius: global.radius }}>
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10, width: 200 }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.search}</p>
                                <TextField size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" value={search} onInput={e => ChangeSearch(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 35 }}>
                                <IconButton onClick={() => ChangeSearch('')} style={{ display: 'flex', height: 20, justifyContent: 'center', alignItems: 'center', width: 20, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 10 }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>
                        <Box sx={{
                            height: global.table_height,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                backgroundColor: global.theme_white,
                                color: '#000000',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--disabled': {
                                backgroundColor: global.theme_white,
                                color: global.theme_protected,
                                fontWeight: 'normal',
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={itemsFiltered}
                                columns={columns}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { setIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.department_id > 0 ? `super-app-theme--normal` : `super-app-theme--disabled`)}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                                onCellEditCommit={(params) => { CellSave(params) }}
                            />
                        </Box>
                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '50%' }}>
                            {CheckPermision(props.permissions, global.permission_system_admin) == true ?
                                <Button onClick={() => AddNew()} style={{ ...styles.ButtonNew, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPlus} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.new}</p>
                                </Button>
                                : null}
                            {CheckPermision(props.permissions, global.permission_system_admin) == true ? index > 0 ?
                                <Button onClick={() => EditLine()} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                                </Button>
                                : null : null}
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                            <IconButton onClick={() => db_get()}>
                                <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                            </IconButton>
                        </div>
                    </div>
                </div>


                {/* ****************************************************************************************
                DIALOG - NOVY ZÁZNAM / UPRAVIT ZAZNAM
             **************************************************************************************** */}
                <Dialog open={showDialog} maxWidth={'md'} fullWidth={true} style={{ backgroundColor: '#00000000' }} >

                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                        <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                            <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                            <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.register_state_edit : lang.register_state}</p>
                        </div>
                        <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                            <IconButton onClick={() => setShowDialog(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                        {/* STAT, KOD */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.state}</p>
                                <TextField size={'small'} error={statErr} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={stat} onInput={e => setStat(e.target.value)} />
                            </div>

                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.code} (2 znaky)</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={code} onInput={e => setCode(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '50%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.code} (3 znaky)</p>
                                <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={code2} onInput={e => setCode2(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '50%', paddingTop: 10 }}>
                                <FormControlLabel control={<Checkbox checked={foreiner} onChange={ChangeForeiner} />} label={lang.foreiner} />
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 10, paddingBottom: 10, height: 50 }}>
                        {error != '' ?
                            <Alert severity="error">{error}</Alert>
                            : null}

                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                        <Button onClick={() => DialogSave()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                        <Button onClick={() => setShowDialog(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                    </div>
                </Dialog >

                {isBusy ?
                    <Loading />
                    : null}

            </div>
    )
}
