import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faMinus, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { width } from '@mui/system';


export const EvidenciaPsovNew = (props) => {

    /*
            USAGE:
            <EvidenciaPsovNew editing={true} department={department} obvod={obvod} user={user} func={EvidenciaPsovNewResult.bind(this)} />
 
            const EvidenciaPsovNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');
    const [tab, setTab] = useState(0);

    // --------------------------------------------------------
    // POLOZKY DIALOGU
    const [cislo, setCislo] = useState('');
    const [datum, setDatum] = useState('');
    const [datumPrihlasenia, setDatumPrihlasenia] = useState('');
    const [plemeno, setPlemeno] = useState('');
    const [plemenoErr, setPlemenoErr] = useState(false);
    const [farba, setFarba] = useState('');
    const [cisloZnamky, setCisloZnamky] = useState('');
    const [cisloCipu, setCisloCipu] = useState('');
    const [pohlavie, setPohlavie] = useState('');
    const [vyska, setVyska] = useState('');
    const [poznamka, setPoznamka] = useState('');

    // --------------------------------------------------------
    // UDAJE O OSOBE
    const [personID, setPersonID] = useState(0);
    const [meno, setMeno] = useState('');
    const [menoErr, setMenoErr] = useState(false);
    const [priezvisko, setPriezvisko] = useState('');
    const [priezviskoErr, setPriezviskoErr] = useState(false);
    const [ulica, setUlica] = useState('');
    const [ulicaCislo, setUlicaCislo] = useState('');
    const [mesto, setMesto] = useState('');
    const [poznamka2, setPoznamka2] = useState('');
    // --------------------------------------------------------


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const offset = 15;

    useEffect(() => {

        if (props.editing == true) {

            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();

        } else {

            // NOVY ZAZNAM
            setLabel(lang.new_record);
            setDatum(Today());
        }

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'psi_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;

                setCislo(data.cislo);
                setDatum(data.datum)
                setDatumPrihlasenia(data.datum_prihlasenia);
                setPlemeno(data.plemeno);
                setFarba(data.farba);
                setCisloZnamky(data.cislo_znamky);
                setCisloCipu(data.cislo_cipu);
                setPohlavie(data.pohlavie);
                setVyska(data.vyska);
                setPoznamka(data.poznamka);
                setMeno(data.meno);
                setPriezvisko(data.priezvisko);
                setUlica(data.ulica);
                setUlicaCislo(data.ulica_cislo);
                setMesto(data.mesto);
                setPoznamka2(data.poznamka_2);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'psi_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data);
                } else {
                    Press(2, data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        setError('');

        if (plemeno.trim() == '') {
            error = true;
            setPlemenoErr(true);
        }
        if (error == true) {
            setError(lang.required);
        } else {
            var data = {
                id: props.editing == true ? props.item_id : 0,
                updated_user_id: props.user.id,
                department_id: props.department.id,
                obvod: props.obvod.kod,
                obvod_id: props.obvod.id,
                cislo: props.editing == true ? cislo : '',
                rok: props.actualYear,
                datum: datum,
                datum_prihlasenia: datumPrihlasenia,
                plemeno: plemeno,
                farba: farba,
                cislo_znamky: cisloZnamky,
                cislo_cipu: cisloCipu,
                pohlavie: pohlavie,
                vyska: vyska,
                poznamka: poznamka,
                meno: meno,
                priezvisko: priezvisko,
                ulica: ulica,
                ulica_cislo: ulicaCislo,
                mesto: mesto,
                poznamka_2: poznamka2,
            }
            if (props.editing == false) {
                data.created_user_id = props.user.id;
            }

            db_update(data);

        }
    }


    /*
    const SetRiesitel1 = (value, key) => {
        if (value != null) {
            setUser1(value.label);
            setUser1ID(value.id);
        }
    }
    */

    const SetPlemeno = (value, key) => {
        if (value != null) {
            setPlemeno(value.label);
        }
    }

    const ChangeTab = (event, value) => {
        setTab(value);
        if (value == 1) {
            timer.current = setTimeout(() => {
                inputRef2.current.focus();
            }, 300);
        }
    }


    const ChangePohlavie = (event) => {
        var value = event.target.value;
        setPohlavie(value);
    }


    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
        }
    }

    const SetMesto = (value, key) => {
        if (value != null) {
            setMesto(value.label);
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 0, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 10, paddingBottom: 15 }}>
                    {/* DATUM*/}
                    <div style={{ width: '95%', marginTop: offset }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date}</p>
                        <TextField inputRef={inputRef} type={'date'} value={datum} size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" onInput={e => setDatum(e.target.value)} />
                    </div>
                </div>

                <Tabs
                    value={tab}
                    onChange={ChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: global.theme_light
                        }
                    }}
                    style={{ width: 400 }}
                >
                    <Tab value={0} style={{ width: 200, fontWeight: tab == 0 ? 'bold' : 'normal' }} label={lang.pes_udaje} />
                    <Tab value={1} style={{ width: 200, fontWeight: tab == 1 ? 'bold' : 'normal' }} label={lang.pes_osoba_udaje} />

                </Tabs>

                {tab == 0 ?
                    <div style={{ ...styles.Block, marginTop: offset, height: 370 }}>

                        {/* datum prihlasenia */}
                        <div style={{ width: '95%', marginTop: offset }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.datum_prihlasenia}</p>
                            <TextField value={datumPrihlasenia} type={'date'} size="small" style={{ backgroundColor: global.theme_white, width: 200 }} label={''} variant="outlined" onInput={e => setDatumPrihlasenia(e.target.value)} />
                        </div>

                        {/* plemeno */}
                        <div style={{ width: '95%', marginTop: offset }}>
                            <AutocompleteText inputRef={null} error={plemenoErr} allowdelete={true} allowedit={true} decodeKey={props.decodeKey} check={false} row={false} new_record={true} department={props.department} db={27} lang={lang} label={lang.pes_plemeno} width={'100%'} value={plemeno} value_id={0} func={SetPlemeno.bind(this)} />
                        </div>

                        <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                            {/* farba */}
                            <div style={{ width: '33%' }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.pes_farba}</p>
                                <TextField value={farba} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setFarba(e.target.value)} />
                            </div>

                            {/* Vyska */}
                            <div style={{ width: '33%', marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.pes_vyska}</p>
                                <TextField value={vyska} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setVyska(e.target.value)} />
                            </div>

                            {/* pohlavie */}
                            <div style={{ width: '33%', marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.sex}</p>
                                <Select
                                    value={pohlavie}
                                    onChange={ChangePohlavie}
                                    size='small'
                                    variant={"outlined"}
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    <MenuItem value={0}>{lang.pes_types[0]}</MenuItem>
                                    <MenuItem value={1}>{lang.pes_types[1]}</MenuItem>
                                    <MenuItem value={2}>{lang.pes_types[2]}</MenuItem>
                                </Select>
                            </div>
                        </div>

                        {/* Vyska */}
                        <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                            <div style={{ width: '50%' }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.cislo_znamky}</p>
                                <TextField value={cisloZnamky} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCisloZnamky(e.target.value)} />
                            </div>
                            <div style={{ width: '50%', marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.cislo_cipu}</p>
                                <TextField value={cisloCipu} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCisloCipu(e.target.value)} />
                            </div>

                        </div>

                        {/* POZNAMKA */}
                        <div style={{ width: '95%', marginTop: offset }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                            <TextField value={poznamka} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka(e.target.value)} />
                        </div>
                    </div>
                    : null}

                {tab == 1 ?
                    <div style={{ ...styles.BlockCenter, marginTop: offset, height: 370 }}>
                        {/* MENO A PRIEZVISKO */}
                        <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                            <div style={{ width: '50%' }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.name}</p>
                                <TextField error={menoErr} inputRef={inputRef2}
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={meno} onInput={e => setMeno(e.target.value)} />
                            </div>
                            <div style={{ width: '50%', marginLeft: 15 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.surname}</p>
                                <TextField error={priezviskoErr}
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={priezvisko} onInput={e => setPriezvisko(e.target.value)} />
                            </div>
                        </div>

                        {/*MESTO, ULICA */}
                        <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                            <div style={{ width: 210 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} new_record={false} row={false} check={false} department={props.department} db={3} lang={lang} label={lang.town} width={'100%'} value={mesto} func={SetMesto.bind(this)} />
                            </div>
                            <div style={{ width: 210, marginLeft: 20 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} new_record={false} row={false} check={false} department={props.department} db={2} lang={lang} label={lang.street} width={'100%'} value={ulica} keys={mesto} func={SetUlica.bind(this)} />
                            </div>
                            <div style={{ width: 120, marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.street_number}</p>
                                <TextField
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={ulicaCislo} onInput={e => setUlicaCislo(e.target.value)} />
                            </div>
                        </div>

                        {/* POZNAMKA */}
                        <div style={{ width: '95%', marginTop: offset }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                            <TextField value={poznamka2} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka2(e.target.value)} />
                        </div>

                    </div>
                    : null
                }
            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {
                isLoading ?
                    <Loading center></Loading>
                    : null
            }
        </Dialog >
    );
}

export const EvidenciaPsovReview = (props) => {

    /*
            USAGE:
            <EvidenciaPsovReview item_id={} func={EvidenciaPsovReviewResult.bind(this)} />
 
            const EvidenciaPsovReviewResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'psi_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{lang.pes_evidencia}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ ...styles.BlockRow, paddingTop: 15 }}>
                                <TextField value={data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                <TextField value={data.datum} label={lang.date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 300 }}>
                                    <TextField value={data.cislo_znamky} label={lang.cislo_znamky} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 300, marginLeft: 20 }}>
                                    <TextField value={data.cislo_cipu} label={lang.cislo_cipu} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 150, marginLeft: 20 }}>
                                    <TextField value={data.plemeno} label={lang.pes_plemeno} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 150, marginLeft: 20 }}>
                                    <TextField value={data.farba} label={lang.pes_farba} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 150, marginLeft: 20 }}>
                                    <TextField value={data.vyska} label={lang.pes_vyska} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 150, marginLeft: 20 }}>
                                    <TextField value={lang.pes_types[data.pohlavie]} label={lang.sex} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 300 }}>
                                    <TextField value={data.meno} label={lang.pes_majitel_name} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 300, marginLeft: 20 }}>
                                    <TextField value={data.priezvisko} label={lang.pes_majitel_surname} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 660, marginLeft: 20 }}>
                                    <TextField value={data.ulica + ' ' + data.ulica_cislo + ' ' + data.mesto} label={lang.address} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 620 }}>
                                    <TextField value={data.poznamka} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 660, marginLeft: 20 }}>
                                    <TextField value={data.poznamka2} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                                </div>
                                {data.updated_meno != '' ?
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div >
    );
}
