import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from '../globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus, faClose, faUser, faRotateLeft, faFileExport } from '@fortawesome/free-solid-svg-icons'
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { DataGrid, skSK } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { CheckPermision, Debug, Loading, NoPermission, DialogEnterNote, DialogInfo, Today, nonEmail, notNumber } from '../items.js';
import Box from '@mui/material/Box';
import { styles } from '../styles.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const Users = (props) => {
    /*    
            USAGE:
            <Users func={UsersPress.bind(this)} />
    
            const UsersPress = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [userTyp, setUserTyp] = useState(0);
    const [tab, setTab] = useState(0);

    const [showUser, setShowUser] = useState(false);

    const [permissions, setPermissions] = useState('');
    const [editing, setEditing] = useState(false);
    const [index, setIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [showExport, setShowExport] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);


    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState(false);
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [badge, setBadge] = useState('');
    const [rank, setRank] = useState(0);
    const [rankLabel, setRankLabel] = useState('');
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [username, setUsername] = useState('');
    const [usernameErr, setUsernameErr] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState(false);
    const [pin, setPin] = useState('');
    const [pinErr, setPinErr] = useState(false);
    const [error, setError] = useState('');
    const [stats, setStats] = useState(false);
    const [odsudeny, setOdsudeny] = useState(false);

    const [permissionAdmin, setPermissionAdmin] = useState(false);
    const [permissionRead, setPermissionRead] = useState(false);
    const [permissionWrite, setPermissionWrite] = useState(false);
    const [permissionEdit, setPermissionEdit] = useState(false);
    const [permissionDelete, setPermissionDelete] = useState(false);
    const [permissionPrint, setPermissionPrint] = useState(false);
    const [permissionBloky, setPermissionBloky] = useState(false);
    const [permissionCiselniky, setPermissionCiselniky] = useState(false);
    const [permissionUnlock, setPermissionUnlock] = useState(false);

    const [active, setActive] = useState(false);
    const [certificate, setCertificate] = useState(false);
    const [certificateDate, setCertificateDate] = useState(null);
    const [certificateNumber, setCertificateNumber] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    let params = useParams();

    const inputRef = useRef(null);

    useEffect(() => {
        // AKY TYP UCTU - KTO JE PRIHLASENY
        setUserTyp(props.userTyp);

        //setItems(rows);
        db_users();
        //setRanks(rankList);
        return () => {
        };

    }, []);

    const db_users = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'users', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setRanks(json.ranks);
                setItems(json.users);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_user_register = async () => {
        setBusy(true);

        var _permissions = ChangePermissions();

        try {
            const response = await fetch(
                global.db_url + 'user_register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    active: active,
                    username: username,
                    password: password,
                    employee_number: employeeNumber,
                    name: name,
                    surname: surname,
                    certificate: certificate,
                    rank_id: rank,
                    badge: badge,
                    date_start: dateStart,
                    date_end: dateEnd,
                    pin: pin.trim(),
                    type: 1,
                    permissions: _permissions,
                    stats: stats,
                    odsudeny: odsudeny,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setShowUser(false);
                db_users();
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_user_update = async () => {
        setBusy(true);

        var _permissions = ChangePermissions();

        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    index: index,
                    department_id: props.department.id,
                    active: active,
                    username: username,
                    password: password,
                    employee_number: employeeNumber,
                    name: name,
                    surname: surname,
                    certificate: certificate,
                    rank_id: rank,
                    badge: badge,
                    date_start: dateStart,
                    date_end: dateEnd,
                    pin: pin.trim(),
                    certificate_date: certificateDate,
                    certificate_number: certificateNumber,
                    type: 1,
                    permissions: _permissions,
                    stats: stats,
                    odsudeny: odsudeny,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setShowUser(false);

            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_user_check = async () => {
        setBusy(true);
        setError('');
        try {
            const response = await fetch(
                global.db_url + 'user_register_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    id: editing == true ? itemsSelected.id : 0,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (editing == true) {
                    if (json.count == 0) {
                        db_user_update();
                    } else {
                        // CHYBA - USERNAME UZ EXISTUJE
                        setError(lang.username_exists);
                        setUsernameErr(true);
                    }
                } else {
                    if (json.count == 0) {
                        db_user_register();
                    } else {
                        // CHYBA - USERNAME UZ EXISTUJE
                        setError(lang.username_exists);
                        setUsernameErr(true);
                    }
                }
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_export = async (note) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'user_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    title: lang.user_export,
                    token: params.apptoken,
                    note: note,
                    user: itemSelected,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {

            } else {
                setShowInfo(true);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const columns = [
        {
            field: 'active',
            headerName: lang.active,
            headerClassName: 'super-app-theme--header',
            width: 65,
            type: 'boolean',
            editable: false,
            sortable: true,
        },
        {
            field: 'employee_number',
            headerName: lang.number,
            headerClassName: 'super-app-theme--header',
            width: 65,
            editable: false,
            sortable: true
        },
        {
            field: 'name',
            headerName: lang.name,
            headerClassName: 'super-app-theme--header',
            width: 160,
            editable: false,
            sortable: true
        },
        {
            field: 'surname',
            headerName: lang.surname,
            headerClassName: 'super-app-theme--header',
            width: 160,
            editable: false,
        },
        {
            field: 'badge',
            headerName: lang.badge,
            headerClassName: 'super-app-theme--header',
            //type: 'boolean',
            width: 100,
            editable: false,
        },
        {
            field: 'rank_label',
            headerName: lang.rank,
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: false,
        },
        {
            field: 'certificate',
            headerName: lang.certificate,
            headerClassName: 'super-app-theme--header',
            width: 100,
            type: 'boolean',
            editable: false,
        },
        {
            field: 'date_start',
            headerName: lang.date_start,
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 120,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
        },
        {
            field: 'date_end',
            headerName: lang.date_end,
            type: 'date',
            headerClassName: 'super-app-theme--header',
            width: 120,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
        },
    ];

    const AddNewUser = () => {
        var today = Today();
        setNameErr(false);
        setSurnameErr(false);
        setUsernameErr(false);
        setPasswordErr(false);
        setError('');
        setName('');
        setSurname('')
        setEmployeeNumber('');
        setBadge('');
        setRank(0);
        setRankLabel('');
        setDateStart(today);
        setDateEnd(today);
        setCertificate(false);
        setActive(true);
        setUsername('');
        setPassword('');
        setPin('');
        setCertificateDate(today);
        setCertificateNumber('');
        setStats(false);
        setOdsudeny(false);
        setTab(0);
        setPermissions(global.permisions_default);
        setEditing(false);
        setShowUser(true);
    }

    const EditUser = () => {
        var data = items.find(x => x.id == index);

        if (data != null) {
            setItemsSelected(data);
            setNameErr(false);
            setSurnameErr(false);
            setUsernameErr(false);
            setPasswordErr(false);
            setError('');
            setName(data.name);
            setSurname(data.surname)
            setEmployeeNumber(data.employee_number);
            setBadge(data.badge);
            setRank(data.rank_id);
            var list = ranks.filter(x => x.id == data.rank_id);
            if (list.length > 0) {
                setRankLabel(list[0].label);
            } else {
                setRankLabel('');
            }
            setDateStart(data.date_start);
            setDateEnd(data.date_end);
            setCertificateDate(data.certificate_date);
            setCertificateNumber(data.certificate_number == null ? '' : data.certificate_number);
            setCertificate(data.certificate == 0 ? false : true);
            setActive(data.enabled == 0 ? false : true);
            setUsername(data.username);
            /*
            if (data.username == '') {
                setUsernameEnabled(false);
                setRegister(true);
            } else {
                setUsernameEnabled(true);
                setRegister(false);
            }
            */
            setPassword(data.password);
            setPin(data.pin);
            setStats(data.stats == false ? false : true);
            setOdsudeny(data.odsudeny == false ? false : true);

            setPermissions(data.permissions);
            setPermissionAdmin(CheckPermision(data.permissions, global.permission_admin));
            setPermissionRead(CheckPermision(data.permissions, global.permission_read));
            setPermissionWrite(CheckPermision(data.permissions, global.permission_write));
            setPermissionEdit(CheckPermision(data.permissions, global.permission_edit));
            setPermissionDelete(CheckPermision(data.permissions, global.permission_delete));
            setPermissionPrint(CheckPermision(data.permissions, global.permission_print));
            setPermissionBloky(CheckPermision(data.permissions, global.permission_bloky));
            setPermissionCiselniky(CheckPermision(data.permissions, global.permission_ciselniky));
            setPermissionUnlock(CheckPermision(data.permissions, global.permission_unlock));

            setEditing(true);
            setTab(0);
            setShowUser(true);
        }
    }

    const EnterPin = (text) => {
        if (/^\d+$/.test(text)) {
            setPin(text);
        }
        if (text == '') {
            setPin(text);
        }
    }

    const EnterRank = (value) => {
        if (value == null) {
            setRank(0);
        } else {
            setRank(parseInt(value.id));
        }
    }


    const UserSave = () => {

        var err = false;

        setError('');
        setNameErr(false);
        setSurnameErr(false);
        setPasswordErr(false);
        setUsernameErr(false);
        setPinErr(false);
        
        if (name.trim() == '') {
            err = true;
            setNameErr(true);
            setError(lang.required);
        }
        if (surname.trim() == '') {
            err = true;
            setSurnameErr(true);
            setError(lang.required);
        }
        if (pin.trim() != '') {
            if (pin.length != 6) {
                err = true;
                setPinErr(true);
                setError(lang.pin_error_);
            } else {
                if (notNumber(pin) == true) {
                    err = true;
                    setPinErr(true);
                    setError(lang.pin_error_);
                }
            }
        }

        if (err == false) {
            if (username.trim() != '') {
                if (password.trim().length < 8) {
                    err = true;
                    setPasswordErr(true);
                    setError(lang.password_min_len);
                }
                if (nonEmail(username) == true) {
                    setUsernameErr(true);
                    setError(lang.loginname_error);
                }
            }
            if (password.trim() != '') {
                if (username.trim() == '') {
                    err = true;
                    setUsernameErr(true);
                    setError(lang.required);
                }
            }
        }

        if (err == false) {
            var data = itemsSelected;
            data.name = name;
            data.surname = surname;
            data.employee_number = employeeNumber;
            data.badge = badge;
            data.rank_id = rank;

            var list = ranks.filter(x => x.id == rank);
            if (list.length > 0) {
                data.rank_label = (list[0].label);
            } else {
                data.rank_label = '';
            }

            // OSVEDCENIE
            data.certificate = certificate;
            data.date_start = dateStart;
            data.date_end = dateEnd;
            data.active = active;
            data.username = username;
            data.password = password;
            data.pin = pin.trim();
            data.certificate_date = certificateDate;
            data.certificate_number = certificateNumber;
            data.stats = stats;
            data.odsudeny = odsudeny;

            data.permissions = ChangePermissions();
            //

            setItemsSelected(data);
            if (editing == true) {
                if (username == '') {
                    db_user_update();
                } else {
                    db_user_check();
                }
            } else {
                if (username == '') {
                    // bez registrácie
                    db_user_register();
                } else {
                    db_user_check();
                }

            }
        }
    }

    const ChangeCertificate = (event) => {
        setCertificate(event.target.checked);
    }

    const ChangeActive = (event) => {
        setActive(event.target.checked);
    }


    const ChangeStats = (event) => {
        setStats(event.target.checked);
    }

    const ChangeOdsudeny = (event) => {
        setOdsudeny(event.target.checked);
    }

    const ChangeTab = (event, value) => {
        //var lastTab = tab;
        setTab(value);
    }

    function setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    const DialogEnterNoteResult = (typ, note) => {
        setShowExport(false);

        if (typ == true) {
            db_export(note);
        }

    }

    const DialogInfoResult = () => {
        setShowInfo(false);
    }

    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemSelected(data);
        setIndex(id);
    }

    function ChangePermissions() {
        // POVOLENIA
        var _permissions = permissions;
        _permissions = setCharAt(_permissions, global.permission_admin, permissionAdmin == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_bloky, permissionBloky == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_delete, permissionDelete == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_edit, permissionEdit == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_print, permissionPrint == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_read, permissionRead == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_write, permissionWrite == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_ciselniky, permissionCiselniky == true ? '1' : '0')
        _permissions = setCharAt(_permissions, global.permission_unlock, permissionUnlock == true ? '1' : '0')
        return _permissions;
    }

    const ChangeRank = (event) => {
        var value = event.target.value;
        setRank(value);
    }

    return (
        CheckPermision(props.permissions, global.permission_read) == false ?
            <NoPermission lang={lang} />
            :
            <div style={{ ...styles.Block, paddingTop: 20 }}>
                <div style={{ ...styles.Block, width: '100%' }}>

                    <div style={{ ...styles.Block, backgroundColor: global.theme_white, borderRadius: global.radius }}>
                        <Box sx={{
                            height: global.table_height,
                            width: 1,
                            '& .super-app-theme--header': {
                                backgroundColor: global.theme_table_header_color,
                            },
                            '& .super-app-theme--normal': {
                                bgcolor: (theme) => (global.theme_white),
                                color: (theme) => (global.theme_black),
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray),
                                },
                            },
                            '& .super-app-theme--disabled': {
                                bgcolor: (theme) => (global.theme_white),
                                color: (theme) => (global.theme_medium_gray),
                                '&:hover': {
                                    bgcolor: (theme) => (global.theme_gray)
                                },
                            },
                        }}>
                            <DataGrid
                                sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                                rows={items}
                                columns={columns}
                                //pageSize={global.table_rows}
                                //rowsPerPageOptions={[global.table_rows]}
                                //checkboxSelection
                                //disableSelectionOnClick  
                                rowHeight={30}
                                onRowClick={(params) => { ChangeIndex(params.id) }}
                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                getRowClassName={(params) => (params.row.active ? `super-app-theme--normal` : `super-app-theme--disabled`)}
                            />
                        </Box>
                    </div>

                    <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '50%' }}>
                            {CheckPermision(props.permissions, global.permission_ciselniky) == true ?
                                <Button onClick={() => AddNewUser()} style={{ ...styles.ButtonNew, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPlus} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.new}</p>
                                </Button>
                                : null}
                            {CheckPermision(props.permissions, global.permission_ciselniky) == true ? index > 0 ?
                                <Button onClick={() => EditUser()} style={{ ...styles.ButtonEdit, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                                    <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                                </Button>
                                : null : null}

                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {CheckPermision(props.permissions, global.permission_print) == true && index > 0 ?
                                    <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonEdit, marginRight: 20, width: 200 }}>
                                        <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faFileExport} />
                                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.export_record}</p>
                                    </Button>
                                    : null}
                                <IconButton onClick={() => db_users()}>
                                    <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ****************************************************************************************
                DIALOG - ZAMESTANEC
                **************************************************************************************** */}
                <Dialog open={showUser} maxWidth={'md'} fullWidth={true} style={{ backgroundColor: '#00000000' }} >
                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: global.dialog_title_height }}>
                        <div style={{ ...styles.BlockRow, alignItems: 'center', height: global.dialog_title_height }}>
                            <FontAwesomeIcon style={{ marginLeft: 15, marginRight: 10, color: global.theme_light }} icon={faUser} />
                            <p style={{ ...styles.TextNormal, color: global.theme_white }}>{editing ? lang.register_user_edit : lang.register_user}</p>
                        </div>
                        <div style={{ ...styles.Block, height: global.dialog_title_height, width: 70 }}>
                            <IconButton onClick={() => setShowUser(false)} style={{ display: 'flex', height: 34, justifyContent: 'center', alignItems: 'center', width: 34, borderRadius: '50%', backgroundColor: global.theme_dark }}>
                                <FontAwesomeIcon style={{ color: global.theme_white, marginRight: 0, width: 16 }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: global.theme_gray, width: '100%', paddingTop: 20 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 0, width: '25%', paddingLeft: 10 }}>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, width: '50%' }}>
                                <p style={{ margin: 0, marginBottom: 10, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium }}>{lang.user_data}</p>
                            </div>
                            <div style={{ margin: 0, width: '25%' }}></div>
                        </div>

                        {/* MENO, PRIEZVISKO */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.name + ' (*)'}</p>
                                <TextField inputRef={inputRef} error={nameErr} style={{ backgroundColor: global.theme_white, width: '100%' }} size={'small'} label={''} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.surname + ' (*)'}</p>
                                <TextField error={surnameErr} style={{ backgroundColor: global.theme_white, width: '100%' }} size={'small'} label={''} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                            </div>
                        </div>

                        {/* Číslo, odznak, hodnost */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ margin: 10, width: '100%' }}><p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.employee_number}</p>
                                <TextField style={{ backgroundColor: global.theme_white, width: '100%' }} size={'small'} label={''} variant="outlined" value={employeeNumber} onInput={e => setEmployeeNumber(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '100%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.badge_number}</p>
                                <TextField style={{ backgroundColor: global.theme_white, width: '100%' }} size={'small'} label={''} variant="outlined" value={badge} onInput={e => setBadge(e.target.value)} />
                            </div>
                            <div style={{ margin: 10, width: '210%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.rank}</p>
                                <Select
                                    value={rank}
                                    onChange={ChangeRank}
                                    size='small'
                                    variant={'outlined'}
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    {ranks.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/* osvedcenie */}
                        <div style={{ ...styles.BlockRow, backgroundColor: global.theme_light_gray, paddingTop: 5, paddingBottom: 10, marginTop: 10, marginBottom: 10 }}>
                            <div style={{ margin: 10, width: '50%', paddingTop: 13 }}>
                                <FormControlLabel control={<Checkbox checked={certificate} onChange={ChangeCertificate} />} label={lang.certificate_owner} />
                            </div>
                            {certificate == true ?
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ margin: 10, width: '50%' }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.osvedcenie_datum}</p>
                                        <TextField size={'small'} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={certificateDate} onInput={e => setCertificateDate(e.target.value)} />
                                    </div>
                                    <div style={{ margin: 10, width: '50%' }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.osvedcenie_cislo}</p>
                                        <TextField style={{ backgroundColor: global.theme_white, width: '100%' }} size={'small'} label={''} variant="outlined" value={certificateNumber} onInput={e => setCertificateNumber(e.target.value)} />
                                    </div>
                                </div>
                                : null}
                        </div>

                        {/* dátum nástupu a dátum ukončenia, aktívny */}
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ margin: 10, width: '25%' }}>
                                <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.date_start_}</p>
                                <TextField size={'small'} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={dateStart} onInput={e => setDateStart(e.target.value)} />
                            </div>
                            <div style={{ ...styles.Block, margin: 10, width: '25%', paddingTop: 13 }}>
                                <FormControlLabel control={<Checkbox checked={active} onChange={ChangeActive} />} label={lang.active} />
                            </div>
                            {active == false ?
                                <div style={{ margin: 10, width: '25%' }}>
                                    <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.date_end_}</p>
                                    <TextField size={'small'} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={dateEnd} onInput={e => setDateEnd(e.target.value)} />
                                </div>
                                :
                                <div style={{ margin: 10, width: '25%' }}></div>
                            }
                            <div style={{ ...styles.Block, margin: 10, width: '25%', paddingTop: 13 }}>
                                <FormControlLabel control={<Checkbox checked={stats} onChange={ChangeStats} />} label={lang.stats} />
                            </div>

                        </div>

                        {/* PRAVOPLATNE ODSUDENY */}
                        <div style={{ ...styles.BlockLeft }}>
                            <div style={{ margin: 10 }}>
                                <FormControlLabel control={<Checkbox checked={odsudeny} onChange={ChangeOdsudeny} />} label={lang.pravoplatne_odsudeny} />
                            </div>
                        </div>

                        <Tabs
                            value={tab}
                            onChange={ChangeTab}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: global.theme_light
                                }
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <Tab value={0} style={{ width: 220 }} label={lang.login_data} />
                            {CheckPermision(props.permissions, global.permission_admin) == true ?
                                <Tab value={1} style={{ width: 220 }} label={lang.permissions} />
                                : null}
                        </Tabs>

                        {/* prihlasovacie meno a pin */}
                        {tab == 0 ?
                            <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 20, paddingBottom: 10, height: 120 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_medium }}>{lang.register_user_login_text}</p>
                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                    <div style={{ margin: 10, width: '37%' }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.loginname}</p>
                                        <TextField error={usernameErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                                    </div>
                                    <div style={{ margin: 10, width: '37%' }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.password_}</p>
                                        <TextField error={passwordErr} size={'small'} type='password' style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={password} onInput={e => setPassword(e.target.value)} />
                                    </div>
                                    <div style={{ margin: 10, width: '26%' }}>
                                        <p style={{ paddingLeft: 5, margin: 0, fontSize: global.font_textlabel }}>{lang.pin}</p>
                                        <TextField error={pinErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" value={pin} type='password' onInput={e => EnterPin(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            : null}

                        {/* POVOLENIA */}
                        {tab == 1 ?
                            <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, paddingTop: 20, paddingBottom: 10, height: 120 }}>
                                {/*
                                    <p style={{ margin: 0, marginBottom: 10, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium }}>{lang.permissions}</p>
                                    */}
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 280 }}>
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionRead} onChange={(event) => setPermissionRead(event.target.checked)} />} label={lang.permission_read} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionWrite} onChange={(event) => setPermissionWrite(event.target.checked)} />} label={lang.permission_write} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionEdit} onChange={(event) => setPermissionEdit(event.target.checked)} />} label={lang.permission_edit} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 280 }}>
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionDelete} onChange={(event) => setPermissionDelete(event.target.checked)} />} label={lang.permission_edit_my} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionBloky} onChange={(event) => setPermissionBloky(event.target.checked)} />} label={lang.permission_bloky} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionPrint} onChange={(event) => setPermissionPrint(event.target.checked)} />} label={lang.permission_print} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 280 }}>
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionCiselniky} onChange={(event) => setPermissionCiselniky(event.target.checked)} />} label={lang.permission_ciselniky} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionUnlock} onChange={(event) => setPermissionUnlock(event.target.checked)} />} label={lang.permission_unlock} />
                                        <FormControlLabel control={<Checkbox style={{ margin: 4, padding: 0 }} checked={permissionAdmin} onChange={(event) => setPermissionAdmin(event.target.checked)} />} label={lang.permission_admin} />
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>

                    <div style={{ ...styles.Block, height: 50, flexDirection: 'column', backgroundColor: global.theme_light_gray }}>
                        {error != '' ?
                            <Alert severity="error">{error}</Alert>
                            : null}

                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, flexDirection: 'row', backgroundColor: global.theme_light_gray }}>
                        <Button onClick={() => UserSave()} style={{ ...styles.ButtonSave, margin: 15 }} variant="contained">{lang.save}</Button>
                        <Button onClick={() => setShowUser(false)} style={{ ...styles.ButtonClose, margin: 15 }} variant="contained">{lang.cancel}</Button>
                    </div>
                </Dialog >

                {/* INFO */}
                {showInfo == true ?
                    <DialogInfo label={lang.error} text={lang.stats_export_error} error={true} func={DialogInfoResult.bind(this)} />
                    : null}

                {/* EXPORT */}
                {showExport == true ?
                    <DialogEnterNote disable_choose={true} func={DialogEnterNoteResult.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading />
                    : null}

            </div >
    )
}
