import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, GetDefaultFilter, PriestupokSearch, Capitalize, GetDate2, GetDate, CopyData, GetSearchText, StatsSearch } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faFilter, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { TrestnyCinNew, TrestnyCinReview } from './trestny_cin_dialog'


export const Trestneciny = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [index, setIndex] = useState(0);

    const [search, setSearch] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectionModel, setSelectionModel] = useState();

    const [editing, setEditing] = useState(false);
    const [showAddnew, setShowAddnew] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [searchDefault, setSearchDefault] = useState(null);
    const [showNevybavene, setShowNevybavene] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    const search_default = GetDefaultFilter(props.obvod);

    useEffect(() => {

        setSearch(search_default);
        setSearchText(GetSearchText(search_default));
        setSearchDefault(search_default);

        db_get(search_default);

        return () => {
        };

    }, []);

    const db_get = async (filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'trestne_ciny', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    filter: filter,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                setItemsFiltered(tmp);
                setIndex(0);
                setSelectionModel(0); // DESELECT LINE

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const columns_prijate = [
        {
            field: 'cislo',
            headerName: lang.number,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 140,
            editable: false,
            filterable: true,
            hide: false
        },
        {
            field: 'datum',
            headerName: lang.date,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 130,
            editable: false,
            filterable: true,
            hide: false
        },
        {
            field: 'cas',
            headerName: lang.time,
            type: 'string',
            width: 150,
            editable: false,
            hide: false,
            filterable: true,
            align: 'center'
        },
        {
            field: 'trestny_cin',
            headerName: lang.trestny_cin,
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 450,
            editable: false,
            filterable: true,
            align: 'left'
        },
        {
            field: 'poznamka',
            headerName: lang.note,
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 450,
            editable: false,
            hide: false,
            filterable: true,
            align: 'left'
        }
    ];



    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemSelected(data);
        setIndex(id);
    }

    const ShowPreview = () => {
        setShowPreview(true);
    }

    const ShowSearch = () => {
        setShowSearch(true);
    }

    const AddNew = () => {
        setEditing(false);
        setShowAddnew(true);
    }

    const Edit = () => {
        setEditing(true);
        setShowPreview(false);
        setShowAddnew(true);
    }

    const TrestnyCinNewResult = (typ, data) => {
        setShowAddnew(false);
        if (typ == 1) {
            // UPDATE
            CopyData(data, itemSelected, ['id']);
            itemSelected.datum = GetDate(data.datum);
            itemSelected.cislo = 'TC-' + data.cislo;
        }
        if (typ == 2) {
            // novy zaznam
            db_get(search);
        }
    }

    const SearchResult = (typ, data) => {

        setShowSearch(false);
        if (typ == 1) {
            setSearch(data);
            setSearchText(GetSearchText(data));
            db_get(data);
        }
    }


    const RestoreSearch = () => {
        var tmp = search_default;
        setSearch(tmp);
        setSearchText(GetSearchText(tmp));
        db_get(tmp);
    }

    const TrestnyCinReviewResult = () => {
        setShowPreview(false)
    }


    const CustomColumnMenuComponent = (props) => {
        const [text, setText] = useState('');
        const { hideMenu, currentColumn, color, ...other } = props;
        const textRef = useRef(null);
        const timer = useRef();

        useEffect(() => {

            if (currentColumn.filterable == true) {
                textRef.current.focus();
            }

            return () => {
            };

        }, []);

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                ownerState={{ color }}
                {...other}
            >
                {/*
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                */}
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, margin: 10 }}>
                        {currentColumn.filterable == true ?
                            <TextField
                                inputRef={textRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: 200 }}
                                label={''} variant="outlined"
                                value={text}
                                placeholder={currentColumn.headerName}
                                onInput={e => setText(e.target.value)}
                                onBlur={hideMenu}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (text.trim() != '') {
                                            var data = search;
                                            data.stlpec = currentColumn.field;
                                            data.text = text;
                                            data.default = false;
                                            setSearchText(GetSearchText(data));
                                            setSearch(data);
                                            db_get(data);
                                        }
                                    }

                                }}
                            />
                            :
                            <p style={{ ...styles.TextTiny, marginTop: 9 }}>{currentColumn.headerName}</p>
                        }
                    </div>
                    <IconButton onClick={hideMenu} style={{ ...styles.Block, height: 36, width: 36, marginTop: 10, marginRight: 10 }}>
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 12 }} icon={faXmark} />
                    </IconButton>
                </div>
            </GridColumnMenuContainer>
        );
    }

    // **************************************************************************************************************
    // **************************************************************************************************************
    //
    // MAIN RENDER
    //
    // **************************************************************************************************************
    // **************************************************************************************************************
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>
            <div style={{ ...styles.Block }}>
                {searchText != '' ?
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                        <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.search_text}:</p>
                        {search.default == true ?
                            <Chip
                                label={searchText}
                                style={{ marginLeft: 10, marginTop: 10 }}
                            />
                            :
                            <Chip
                                label={searchText}
                                onDelete={() => RestoreSearch()}
                                style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                            />
                        }
                        <p style={{ ...styles.TextTiny, marginTop: 17, marginLeft: 30 }}>{lang.pocet_zaznamov}: {itemsFiltered.length}</p>
                    </div>
                    : null}
            </div>

            {
                // *************************************************************************************
                //    ZOZNAM PRIJATEJ POSTY
                // *************************************************************************************
            }
            <div style={{ ...styles.Block, width: '100%' }}>
                <div style={{ ...styles.Block, width: '100%' }}>
                    <Box sx={{
                        height: 410,
                        width: 1,
                        '& .super-app-theme--header': {
                            backgroundColor: global.theme_table_header_color,
                        },
                        '& .super-app-theme--normal': {
                            backgroundColor: global.theme_white,
                            color: '#000000',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--red': {
                            backgroundColor: global.theme_white,
                            color: global.theme_dark_red,
                            fontWeight: 'normal',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--blue': {
                            backgroundColor: global.theme_white,
                            color: global.theme_blue,
                            fontWeight: 'normal',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                    }}>
                        <DataGrid
                            sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                            rows={itemsFiltered}
                            columns={columns_prijate}

                            // vlastná menu po kliknuti na header menu
                            components={{
                                ColumnMenu: CustomColumnMenuComponent,
                            }}

                            rowHeight={30}
                            onRowClick={(params) => { ChangeIndex(params.id) }}
                            //onCellKeyDown={(params,event) => { console.log(event) }}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => (`super-app-theme--normal`)}
                            hideFooterRowCount={true}
                            hideFooterSelectedRowCount={true}
                        />
                    </Box>

                </div>

            </div>



            {showPreview == true ?
                <TrestnyCinReview item_id={itemSelected.id} decodeKey={props.decodeKey} func={TrestnyCinReviewResult.bind(this)} />
                : null}

            <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                <div style={{ ...styles.BlockRow, width: '70%' }}>
                    {
                        props.permissions.toString().substr(global.permission_read, 1) == '1' ?
                            <Button onClick={() => ShowSearch()} style={{ ...styles.ButtonAction }}>
                                <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faFilter} />
                                <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_white }}>{lang.filter}</p>
                            </Button>
                            : null
                    }
                    {props.permissions.toString().substr(global.permission_write, 1) == '1' ?
                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonNew, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPlus} />
                            <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.new}</p>
                        </Button>
                        : null}
                    {props.permissions.toString().substr(global.permission_edit, 1) == '1' && index > 0 ?
                        <Button onClick={() => Edit()} style={{ ...styles.ButtonAction, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                            <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                        </Button>
                        : null}
                </div>
                <div style={{ ...styles.BlockRight, width: '30%' }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {index > 0 && showPreview == false ?
                            <div style={{ width: 50 }}>
                                <IconButton onClick={() => ShowPreview()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_lighter }}>
                                    <FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faInfo} />
                                </IconButton>
                            </div>
                            : null}
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => db_get(searchDefault)}>
                                <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

            {showAddnew == true ?
                <TrestnyCinNew editing={editing} decodeKey={props.decodeKey} item_id={itemSelected.id} actualYear={props.actualYear} department={props.department} obvod={props.obvod} user={props.user} settings={props.settings} func={TrestnyCinNewResult.bind(this)} />
                : null}

            {/* VYHLADAVANIE */}
            {showSearch == true ?
                <StatsSearch typ={0} decodeKey={props.decodeKey} data={search} department={props.department} lang={lang} func={SearchResult.bind(this)} />
                : null}


            {isBusy ?
                <Loading />
                : null}

        </div >
    )
};

