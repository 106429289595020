import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, GetDateYear, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faMinus, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { width } from '@mui/system';


export const OstatneCinnostiNew = (props) => {

    /*
            USAGE:
            <OstatneCinnostiNew editing={true} department={department} obvod={obvod} user={user} func={OstatneCinnostiNewResult.bind(this)} />
 
            const OstatneCinnostiNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');

    // --------------------------------------------------------
    // POLOZKY DIALOGU
    const [cislo, setCislo] = useState('');
    const [datumOd, setDatumOd] = useState('');
    const [datumDo, setDatumDo] = useState('');
    const [casOd, setCasOd] = useState('07:00');
    const [casDo, setCasDo] = useState('07:30');
    const [cinnost, setCinnost] = useState('');
    const [cinnostID, setCinnostID] = useState(0);
    const [cinnostErr, setCinnostErr] = useState(false);
    const [pocetOsob, setPocetOsob] = useState('0');
    const [poznamka, setPoznamka] = useState('');
    const [ulica, setUlica] = useState('');
    const [ulicaCislo, setUlicaCislo] = useState('');
    const [popis, setPopis] = useState('');
    const [user1, setUser1] = useState('');
    const [user1ID, setUser1ID] = useState(0);
    const [user2, setUser2] = useState('');
    const [user2ID, setUser2ID] = useState(0);
    // --------------------------------------------------------


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const offset = 15;

    useEffect(() => {

        if (props.editing == true) {

            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();

        } else {

            // NOVY ZAZNAM
            setLabel(lang.new_record);
            setDatumOd(Today());
            setDatumDo(Today());
        }

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'ostatne_cinnosti_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;

                setCislo(data.cislo);
                setDatumOd(data.datum_od)
                setCasOd(data.cas_od);
                setDatumDo(data.datum_do)
                setCasDo(data.cas_do);
                setCinnost(data.ostatne);
                setCinnostID(data.ostatne_id);
                setPocetOsob(data.pocet_osob);
                setPoznamka(data.poznamka);
                setPopis(data.popis);
                setUlica(data.ulica);
                setUlicaCislo(data.ulica_cislo);
                setUser1(data.user1);
                setUser1ID(data.user1_id);
                setUser2(data.user2);
                setUser2ID(data.user2_id);

            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);
        console.log(data);
        try {
            const response = await fetch(
                global.db_url + 'ostatne_cinnosti_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data);
                } else {
                    Press(2, data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        setError('');
        setCinnostErr('');

        if (cinnostID == 0) {
            setCinnostErr(true);
            error = true;
        }
        if (error == true) {
            setError(lang.required);
        } else {
            var data = {
                id: props.editing == true ? props.item_id : 0,
                updated_user_id: props.user.id,
                department_id: props.department.id,
                obvod: props.obvod.kod,
                obvod_id: props.obvod.id,
                cislo: props.editing == true ? cislo : '',
                rok: GetDateYear(datumOd),
                datum_od: datumOd,
                datum_do: datumDo,
                cas_od: casOd,
                cas_do: casDo,
                ostatne: cinnost,
                ostatne_id: cinnostID,
                pocet_osob: pocetOsob.trim() == '' ? 0 : parseInt(pocetOsob),
                ulica: ulica,
                ulica_cislo: ulicaCislo,
                poznamka: poznamka,
                popis: popis,
                user1: user1,
                user1_id: user1ID,
                user2: user2,
                user2_id: user2ID,
            }
            if (props.editing == false) {
                data.created_user_id = props.user.id;
            }

            db_update(data);

        }
    }

    const SetCinnost = (value, key) => {
        if (value != null) {
            setCinnost(value.label);
            setCinnostID(value.id);
        }
    }

    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
        }
    }


    const SetRiesitel1 = (value, key) => {
        if (value != null) {
            setUser1(value.label);
            setUser1ID(value.id);
        }
    }

    const SetRiesitel2 = (value, key) => {
        if (value != null) {
            setUser2(value.label);
            setUser2ID(value.id);
        }
    }

    const CasOd = () => {
        if (props.editing == false) {
            var tmp = casOd.split(':');
            var h = parseInt(tmp[0]);
            var m = parseInt(tmp[1]);

            m = m + global.CasAdd;
            if (m > 59) {
                m = m - 60;
                h = h + 1;
                if (h > 23) {
                    h = h - 24;
                }
            }

            setCasDo(h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0'))
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 0, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 10, paddingBottom: 15 }}>

                    {/* DATUM - CAS OD - DO */}
                    <div style={{ ...styles.BlockRowRaw, marginTop: offset }}>
                        <div style={{ width: 150, marginRight: 10 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date_from}</p>
                            <TextField inputRef={inputRef} type={'date'} value={datumOd} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumOd(e.target.value)} />
                        </div>
                        <div style={{ width: 110 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time}</p>
                            <TextField onBlur={() => CasOd()} type={'time'} value={casOd} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasOd(e.target.value)} />
                        </div>
                        <div style={{ width: 15, marginLeft: 8, marginRight: 7, paddingTop: 30 }}>
                            <FontAwesomeIcon style={{ width: 15, height: 15, color: global.theme_black }} icon={faMinus} />
                        </div>
                        <div style={{ width: 150, marginRight: 10 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date_to}</p>
                            <TextField type={'date'} value={datumDo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumDo(e.target.value)} />
                        </div>
                        <div style={{ width: 110, marginRight: 0 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time}</p>
                            <TextField type={'time'} value={casDo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasDo(e.target.value)} />
                        </div>
                    </div>
                </div>

                {/* CINNOST, POCET OSOB */}
                <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                    <div style={{ width: '100%' }}>
                        <AutocompleteText error={cinnostErr} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={23} lang={lang} label={lang.cinnost} width={'100%'} value={cinnost} value_id={cinnostID} func={SetCinnost.bind(this)} />
                    </div>
                </div>

                {/* CINNOST, POCET OSOB - OBMEDZENIE OSOBNEJ SLOBODY */}
                <div style={{ ...styles.Block, width: '95%', marginTop: offset }}>
                    <div style={{ ...styles.Block, width: 400 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.pocet_osob_sloboda}</p>
                        <TextField value={pocetOsob} size="small" style={{ backgroundColor: global.theme_white, width: 100 }} label={''} variant="outlined" onInput={e => setPocetOsob(e.target.value)} />
                    </div>
                </div>

                {/* ULICA, CISLO */}
                <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                    <div style={{ width: 450 }}>
                        <AutocompleteText check={false} decodeKey={props.decodeKey} row={false} new_record={true} department={props.department} db={1} lang={lang} label={lang.street} width={'100%'} value={ulica} func={SetUlica.bind(this)} />
                    </div>
                    <div style={{ width: 130, marginLeft: 20 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.number}</p>
                        <TextField value={ulicaCislo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setUlicaCislo(e.target.value)} />
                    </div>
                </div>

                {/* POZNAMKA */}
                <div style={{ width: '95%', marginTop: offset }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                    <TextField value={poznamka} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka(e.target.value)} />
                </div>


                {/* POPIS */}
                <div style={{ width: '95%', marginTop: offset }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.popis_cinnosti}</p>
                    <TextField value={popis} size="small" multiline={true} rows={6} style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPopis(e.target.value)} />
                </div>

                {/* RIESITEL 1/2 */}
                <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                    <div style={{ width: '50%' }}>
                        <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 1'} width={'100%'} value={user1} value_id={user1ID} func={SetRiesitel1.bind(this)} />
                    </div>
                    <div style={{ width: '50%', marginLeft: 20 }}>
                        <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 2'} width={'100%'} value={user2} value_id={user2ID} func={SetRiesitel2.bind(this)} />
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {
                isLoading ?
                    <Loading center></Loading>
                    : null
            }
        </Dialog >
    );
}

export const OstatneCinnostiReview = (props) => {

    /*
            USAGE:
            <OstatneCinnostiReview item_id={} func={OstatneCinnostiReviewResult.bind(this)} />
 
            const OstatneCinnostiReviewResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'ostatne_cinnosti_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{lang.cinnost}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ width: 900 }}>
                            <div style={{ ...styles.BlockRow, paddingTop: 15 }}>
                                <TextField value={'OC-' + data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                <TextField value={data.datum_od} label={lang.date_from} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                <TextField value={data.cas_od} label={lang.time} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                <div style={{ paddingTop: 12, marginLeft: 8, marginRight: 8 }}>
                                    <FontAwesomeIcon style={{ width: 15, height: 15, color: global.theme_black }} icon={faMinus} />
                                </div>
                                <TextField value={data.datum_od} label={lang.date_from} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                <TextField value={data.cas_od} label={lang.time} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />

                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 700 }}>
                                    <TextField value={data.ostatne} label={lang.cinnost} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 200, marginLeft: 31 }}>
                                    <TextField value={data.pocet_osob} label={lang.obmedzenie_slobody} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 450 }}>
                                    <TextField value={data.ulica + ' ' + data.ulica_cislo} label={lang.street} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 450, marginLeft: 31 }}>
                                    <TextField value={data.poznamka} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: 450 }}>
                                    <TextField value={data.user1} label={lang.riesitel + ' 1'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: 450, marginLeft: 31 }}>
                                    <TextField value={data.user2} label={lang.riesitel + ' 2'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                                </div>
                                {data.updated_meno != '' ?
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div style={{ width: 450, paddingTop: 15, marginLeft: 20 }}>
                            <TextField value={data.popis} multiline={true} rows={6} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                        </div>
                    </div>

                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div >
    );
}
