import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, GetTodayTime, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faMinus, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { width } from '@mui/system';


export const UdalostiNew = (props) => {

    /*
            USAGE:
            <EvidenciaPsovNew editing={true} department={department} obvod={obvod} user={user} func={EvidenciaPsovNewResult.bind(this)} />
 
            const EvidenciaPsovNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');
    const [tab, setTab] = useState(0);

    // --------------------------------------------------------
    // POLOZKY DIALOGU
    const [obvod, setObvod] = useState('');
    const [obvodID, setObvodID] = useState('');
    const [cislo, setCislo] = useState('');
    const [datum, setDatum] = useState('');
    const [cas, setCas] = useState('07:00');
    const [oznamovatel, setOznamovatel] = useState('');
    const [oznamovatelErr, setOznamovatelErr] = useState(false);
    const [udalost, setUdalost] = useState('');
    const [udalostID, setUdalostID] = useState(0);
    const [popis, setPopis] = useState('');
    const [sposobRiesenia, setSposobRiesenia] = useState('');
    const [stavRiesenia, setStavRiesenia] = useState('');
    const [stavRieseniaErr, setStavRieseniaErr] = useState(false);
    const [stavRieseniaID, setStavRieseniaID] = useState(1);
    const [ulica, setUlica] = useState('');
    const [ulicaID, setUlicaID] = useState(0);
    const [ulicaCislo, setUlicaCislo] = useState('');
    const [opravnene, setOpravnene] = useState(true);
    const [riesitel, setRiesitel] = useState('');
    const [riesitelID, setRiesitelID] = useState(0);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const offset = 15;

    useEffect(() => {

        if (props.editing == true) {

            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();

        } else {

            // NOVY ZAZNAM
            setCas(GetTodayTime());
            setLabel(lang.new_record);
            setDatum(Today());
        }

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'udalosti_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;

                setObvod(data.obvod);
                setObvodID(data.obvod_id);
                setCislo(data.cislo);
                setDatum(data.datum)
                setCas(data.cas);
                setOznamovatel(data.oznamovatel);
                setUdalost(data.udalost);
                setUdalostID(data.udalost_id);
                setUlica(data.ulica);
                setUlicaID(data.ulicaID);
                setUlicaCislo(data.ulica_cislo);
                setPopis(data.popis);
                setSposobRiesenia(data.sposob_riesenia);
                setOpravnene(data.opravnene == 0 ? false : true);
                setStavRiesenia(data.stav_riesenia);
                setStavRieseniaID(data.stav_riesenia_id);
                setRiesitel(data.user1);
                setRiesitelID(data.user1_id);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'udalosti_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data);
                } else {
                    Press(2, data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        setError('');
        setOznamovatelErr(false);
        setStavRieseniaErr(false);

        if (oznamovatel.trim() == '') {
            error = true;
            setOznamovatelErr(true);
        }
        /*
        if (stavRieseniaID.trim() == 0) {
            error = true;
            setStavRieseniaErr(true);
        }
        */
        if (error == true) {
            setError(lang.required);
        } else {
            var data = {
                id: props.editing == true ? props.item_id : 0,
                updated_user_id: props.user.id,
                department_id: props.department.id,
                obvod: props.editing == true ? obvod : props.obvod.kod,
                obvod_id: props.editing == true ? obvodID : props.obvod.id,
                cislo: props.editing == true ? cislo : '',
                rok: props.actualYear,
                datum: datum,
                cas: cas,
                oznamovatel: oznamovatel,
                udalost: udalost,
                udalost_id: udalostID,
                ulica: ulica,
                ulica_id: ulicaID,
                ulica_cislo: ulicaCislo,
                popis: popis,
                sposob_riesenia: sposobRiesenia,
                //stav_riesenia: stavRiesenia,
                stav_riesenia: 'Doriešené',
                //stav_riesenia_id: stavRieseniaID,
                stav_riesenia_id: 1,
                opravnene: opravnene,
                user1: riesitel,
                user1_id: riesitelID
            }
            if (props.editing == false) {
                data.created_user_id = props.user.id;
            }

            db_update(data);

        }
    }


    const SetRiesitel = (value, key) => {
        if (value != null) {
            setRiesitel(value.label);
            setRiesitelID(value.id);
        }
    }

    const SetStavRiesenia = (value, key) => {
        if (value != null) {
            setStavRiesenia(value.label);
            setStavRieseniaID(value.id);
        }
    }

    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
            setUlicaID(value.id);
        }
    }

    const SetUdalost = (value, key) => {
        if (value != null) {
            setUdalost(value.label);
            setUdalostID(value.id);
        }
    }

    const SetSposobRiesenia = (value, key) => {
        if (value != null) {
            setSposobRiesenia(value.label);
        }
    }

    const ChangeTab = (event, value) => {
        setTab(value);
        if (value == 1) {
            timer.current = setTimeout(() => {
                inputRef2.current.focus();
            }, 300);
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 0, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 0, paddingBottom: 15 }}>
                    {/* DATUM - CAS */}
                    <div style={{ ...styles.BlockRowRaw, marginTop: offset }}>
                        <div style={{ width: 200, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date}</p>
                            <TextField inputRef={inputRef} type={'date'} value={datum} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatum(e.target.value)} />
                        </div>
                        <div style={{ width: 120, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time}</p>
                            <TextField type={'time'} value={cas} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCas(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div style={{ ...styles.Block, marginTop: offset }}>

                    {/* oznamovatel */}
                    <div style={{ width: '95%' }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.oznamovatel}</p>
                        <TextField error={oznamovatelErr} value={oznamovatel} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setOznamovatel(e.target.value)} />
                    </div>

                    {/* udalost */}
                    <div style={{ width: '95%', marginTop: offset }}>
                        <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={true} department={props.department} db={24} lang={lang} label={lang.udalost} width={'100%'} value={udalost} value_id={udalostID} func={SetUdalost.bind(this)} />
                    </div>

                    {/* ulica, cislo */}
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        <div style={{ ...styles.BlockLeft, width: '75%' }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={true} department={props.department} db={1} lang={lang} label={lang.street} width={'100%'} value={ulica} value_id={ulicaID} func={SetUlica.bind(this)} />
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '25%', marginLeft: 20 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.street_number}</p>
                            <TextField value={ulicaCislo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setUlicaCislo(e.target.value)} />
                        </div>
                    </div>

                    {/* popis udalosti */}
                    <div style={{ width: '95%', marginTop: offset }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.udalost_popis}</p>
                        <TextField value={popis} multiline={true} rows={5} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPopis(e.target.value)} />
                    </div>

                    {/* spôsob riešenia, opravnené */}
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        <div style={{ ...styles.BlockLeft, width: '75%' }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} allowdelete={true} allowedit={true} check={false} row={false} new_record={true} department={props.department} db={26} lang={lang} label={lang.sposob_riesenia} width={'100%'} value={sposobRiesenia} value_id={0} func={SetSposobRiesenia.bind(this)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '25%', paddingTop: 18 }}>
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.opravnene}</p>}
                                control={<Checkbox checked={opravnene} size={'small'} onChange={() => setOpravnene(!opravnene)} />}
                            />
                        </div>
                    </div>

                    {/* RIESITEL, stav riesenia */}
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: offset }}>
                        <div style={{ width: '100%' }}>
                            <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel} width={'100%'} value={riesitel} value_id={riesitelID} func={SetRiesitel.bind(this)} />
                        </div>
                        {/*
                        <div style={{ width: '50%', marginLeft: 20 }}>
                            <AutocompleteText error={stavRieseniaErr} inputRef={null} check={true} row={false} new_record={false} department={props.department} db={10} lang={lang} label={lang.stav_riesenia} width={'100%'} value={stavRiesenia} value_id={stavRieseniaID} func={SetStavRiesenia.bind(this)} />
                        </div>
                        */}
                    </div>


                </div>

            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {
                isLoading ?
                    <Loading center></Loading>
                    : null
            }
        </Dialog >
    );
}

export const UdalostiReview = (props) => {

    /*
            USAGE:
            <EvidenciaPsovReview item_id={} func={EvidenciaPsovReviewResult.bind(this)} />
 
            const EvidenciaPsovReviewResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'udalosti_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{lang.udalost}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ width: '65%' }}>
                            <div style={{ ...styles.BlockRow, width: '100%', paddingTop: 15 }}>
                                <div style={{ width: '20%' }}>
                                    <TextField value={'U-' + data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '20%', marginLeft: 20 }}>
                                    <TextField value={data.datum} label={lang.date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '10%', marginLeft: 20 }}>
                                    <TextField value={data.cas} label={lang.time} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '57%', marginLeft: 20 }}>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: '50%' }}>
                                    <TextField value={data.oznamovatel} label={lang.oznamovatel} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '40%', marginLeft: 20 }}>
                                    <TextField value={data.ulica} label={lang.street} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '10%', marginLeft: 20 }}>
                                    <TextField value={data.ulica_cislo} label={lang.street_number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: '50%' }}>
                                    <TextField value={data.udalost} label={lang.udalost} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>

                                <div style={{ width: '40%', marginLeft: 20 }}>
                                    <TextField value={data.user1} label={lang.riesitel} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                                <div style={{ width: '10%', marginLeft: 20 }}>
                                    <TextField value={data.opravnene == true ? 'Áno' : 'Nie'} label={lang.opravnene} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                <div style={{ width: '100%' }}>
                                    <TextField value={data.sposob_riesenia} label={lang.sposob_riesenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                                </div>
                                {data.updated_meno != '' ?
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div style={{ width: '35%', marginLeft: 20 }}>
                            <div style={{ width: '100%', paddingTop: 15 }}>
                                <TextField value={data.popis} multiline={true} rows={9} label={lang.udalost_popis} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                            </div>
                        </div>
                    </div>

                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div >
    );
}
