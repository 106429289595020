import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, LoadLogin, LoadUser, LoadLanguage, SaveUser, SaveLogin, SaveDepartment, SaveObvod } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { ForgotPasswordDialog } from './forgot_password.js';
import CircularProgress from '@mui/material/CircularProgress';


export const Login = (props) => {
    const navigate = useNavigate();
    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    //APP
    const [isBusy, setBusy] = useState(true);

    const [username, setUsername] = useState(global.testing == true ? 'evid@vgvstudio.sk' : '');
    const [password, setPassword] = useState(global.testing == true ? '12345678' : '');
    const [error, setError] = useState('');
    const [showForgot, setShowForgot] = useState(false);
    const [obvody, setObvody] = useState([]);
    const [obvod, setObvod] = useState(-1);
    const [obvodSelected, setObvodSelected] = useState([]);
    const [logged, setLogged] = useState(false);
    const [token, setToken] = useState('');
    const [tokenID, setTokenID] = useState(0);
    const [expire, setExpire] = useState('');
    const [loginEnable, setLoginEnable] = useState(false);
    const [appRegistred, setAppRegistred] = useState(true);
    const [departmentID, setDepartmentID] = useState(0);
    const [demo, setDemo] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const radius = 10;
    const boxHeight = 420;
    const boxWidth = 650;

    var lang = language;

    let params = useParams();

    const timer = useRef();
    const homeRef = useRef(null)
    const inputRef = useRef(null);

    /*
    const logo = require('./react/app/logo_white.png');
    const logo_color = require('./react/app/logo.png');
    */

    useEffect(() => {

        // LANGUAGE        
        setLanguage(LoadLanguage());

        // RESETOVANIE PRIHLASENIA
        var data = { token: '', expire: 0 };
        SaveLogin(data);
        SaveUser([]);

        // KONTROLA PRIHLASENIA
        /*
        var data = LoadLogin();
        if (data != null) {
            if (data.token != '') {
                Debug('Prihlásený admin');
                navigate('/main');
            } else {
                Debug('Neprihlasený');
            }
        }
        */

        // KONTROLA TOKENU Z PALIKACIE
        db_token(params.apptoken);

        setBusy(false);

        return () => {
        };

    }, []);




    // *****************************************************************************
    // KONTROLA TOKENU APLIKACIE
    // *****************************************************************************
    const db_token = async (token) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'token_app_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    decode_key: params.decode_key,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (json.exists == true) {
                    setAppRegistred(true);
                    setDepartmentID(json.id);
                    setLoginEnable(true);
                    timer.current = setTimeout(() => {
                        inputRef.current.focus();
                    }, 800);

                } else {
                    setAppRegistred(false);
                }
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_login = async () => {
        setBusy(true);

        // expiracia prihlásenia
        var expire = new Date() / 1000 + global.login_expire;

        try {
            const response = await fetch(
                global.db_url + 'login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    username: username,
                    password: password,
                    department_id: departmentID,
                    expire: expire,
                    decode_key: params.decode_key,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setError('');

                setToken(json.token);
                setTokenID(json.token_id);

                var obv = json.obvody;
                setObvody(obv);

                setExpire(json.expire);

                setLogged(true);

                if (obv.length < 2) {
                    // LEN JEDEN OBVOD - nastavit a prejst do aplikácie

                    var data = { token: json.token, expire: json.expire };
                    SaveLogin(data);
                    navigate('/main/' + params.apptoken);
                }

            } else {
                setError(lang.login_error);
                setLogged(false);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_obvod = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'login_obvod', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: tokenID,
                    obvod_id: obvodSelected.id,
                    decode_key: params.decode_key,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var data = { token: token, expire: expire };
                SaveLogin(data);
                navigate('/main/' + params.apptoken);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const LoginProccess = () => {
        setError('');
        db_login();
    }

    const ForgotPassword = () => {
        setShowForgot(true);
    }

    const ForgotPasswordPress = (typ, value) => {
        if (typ == false) {
            setShowForgot(false);
        }
    }

    const ChangeObvod = (event) => {
        var value = event.target.value;
        setObvod(value);

        var tmp = obvody.find(x => x.id == value);
        setObvodSelected(tmp);
    }

    const Enter = (_obvod, _token, _expire) => {
        if (obvod == 0) {
            var data = { token: token, expire: expire };
            SaveLogin(data);
            navigate('/main/' + params.apptoken);
        } else {
            db_obvod(_token, _expire);
        }
    }

    const Demo = () => {
        if (demo > 1) {
            setUsername('evid@vgvstudio.sk');
            setPassword('12345678');
        } else {
            setDemo(demo + 1);
        }
    }
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_black, height: isSmall ? undefined : '100vh', minHeight: isSmall ? undefined : '100vh' }}>

            <Paper elevation={6} variant={'elevation'} style={{ ...styles.BlockRow, backgroundColor: global.theme_gray, borderRadius: radius, width: isSmall ? '100%' : boxWidth, height: boxHeight }}>
                <div style={{ ...styles.Block, width: '50%', backgroundColor: global.theme_darker, height: '100%', borderTopLeftRadius: radius, borderBottomLeftRadius: radius, height: boxHeight }}>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextXXLarge, color: global.theme_white }}>{lang.login}</p>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 25, marginBottom: 25 }}>
                        <img onDoubleClick={() => Demo()} src={global.images + 'app/logo_circle.png'} width={160} />
                    </div>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextNormal, color: global.theme_white, fontWeight: 500 }}>{lang.app_label}</p>
                        <p style={{ ...styles.TextXXTiny, color: global.theme_medium, fontWeight: 400, marginTop: 5 }}>{global.version}</p>
                    </div>
                </div>
                {loginEnable == true ?
                    <div style={{ ...styles.Block, width: '50%' }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                <div style={{ ...styles.BlockLeft }} >
                                    <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: 400, marginBottom: 5 }}>{lang.username + ' / ' + lang.email}</p>
                                    <TextField inputRef={inputRef} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, marginTop: 20 }} >
                                    <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: 400, marginBottom: 5 }}>{lang.password}</p>
                                    <TextField size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" type="password" value={password}
                                        onInput={e => setPassword(e.target.value)}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                LoginProccess();
                                            }
                                        }}
                                    />
                                </div>

                                <div style={{ ...styles.Block, marginTop: 50, height: 30 }}>
                                    {logged == true ?
                                        <div style={{ ...styles.BlockLeft }} >
                                            <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: 400, marginBottom: 5 }}>{lang.obvod}</p>
                                            <Select
                                                value={obvod}
                                                onChange={ChangeObvod}
                                                size='small'
                                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                                            >
                                                <MenuItem value={0}>
                                                    {lang.centrala}
                                                </MenuItem>
                                                {obvody.map((item) => (
                                                    <MenuItem key={item.id} value={item.id} >
                                                        {item.kod}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            {error != '' ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}>
                                    <Alert severity="error">{error}</Alert>
                                </div>
                                : null}
                            {error == '' ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, height: 40 }}>
                                    {isBusy == true ?
                                        <CircularProgress />
                                        : null}
                                </div>
                                : null}
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                            {logged == false ?
                                <Button onClick={() => LoginProccess()} style={{ backgroundColor: global.theme_darker_green, width: 240 }} variant="contained">{lang.login}</Button>
                                :
                                obvod >= 0 ?
                                    <Button onClick={() => Enter()} style={{ backgroundColor: global.theme_darker_green, width: 240 }} variant="contained">{lang.app_enter}</Button>
                                    : null
                            }
                            {logged == false ?
                                <Button onClick={() => ForgotPassword()} style={{ color: global.theme_dark_gray, marginTop: 20, textDecorationLine: 'underline', textTransform: 'none', fontWeight: 'normal' }} variant="text">{lang.lost_password_button}</Button>
                                : null}
                        </div>
                    </div>
                    :
                    <div style={{ ...styles.Block, width: '50%', height: boxHeight }}>
                        {isBusy == true ?
                            <CircularProgress />
                            : null}
                        {appRegistred == false ?
                            <p style={{ ...styles.TextSmall }}>{lang.app_not_registered}</p>
                            : null}
                    </div>
                }
            </Paper>


            {
                showForgot == true ?
                    <ForgotPasswordDialog func={ForgotPasswordPress.bind(this)} />
                    : null
            }

        </div >
    )
};

