import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, IconButton, Box, GlobalStyles } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, CopyData, PriestupokSearch, Capitalize, GetDate2, GetDate, CheckPermision, DialogInfo, DialogEnterNote, GetSearchText, StatsSearch, GetDefaultFilter, LogsDialog, DialogUsepError } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark, faFilter, faPaperPlane, faLock, faUnlock, faEye, faStamp, faLink, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { useHotkeys } from 'react-hotkeys-hook';

import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { PredvedeneOsobyNew } from './predvedene_osoby_dialog'

export const Usep = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [index, setIndex] = useState(0);
    const [editing, setEditing] = useState(false);
    const [showPredvedeneOsoby, setShowPredvedeneOsoby] = useState(false);

    const search_default = GetDefaultFilter(props.obvod);
    const [search, setSearch] = useState(search_default);

    const [showNew, setShowNew] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectionModel, setSelectionModel] = useState();
    const [redraw, setRedraw] = useState(false);
    const [itemPriestupok, setItemPriestupok] = useState([]);
    const [showExport, setShowExport] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [preview, setPreview] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [showUsepError, setShowUsepError] = useState(false);
    const [usepID, setUsepID] = useState(0);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxWidth = 800;
    const boxHeight = 25;

    var language = 0;
    var lang = sk;

    let params = useParams();

    // KLAVESOVE SKRATKY
    useHotkeys('F12', (key) => handleKey(key), true)



    useEffect(() => {

        if (props.subBody == true) {
            // OKNO - NOVY PRIESTUPOK
            AddNew();
        }

        setSearch(search_default);
        setSearchText(GetSearchText(search_default));
        db_get(search_default);

        return () => {
        };

    }, []);

    const handleKey = (key) => {
        if (key.key == 'F12') {
            if (props.permissions.toString().substr(global.permission_write, 1) == '1') {
                AddNew()
            }
        }
    }


    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'usep', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    decode_key: props.decodeKey
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);
                setIndex(0);
                setSelectionModel(0); // DESELECT LINE

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_unlock = async (id) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_unlock', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    id: id,
                    decode_key: props.decodeKey,
                    user_id: props.user.id,
                    log: global.log_unlock
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                itemSelected.locked = false;
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }



    const db_send = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'usep_send_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    token: params.apptoken,
                    priestupok_id: itemSelected.id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get();
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_get_priestupok = async (id) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                    department_id: props.department.id,
                    user_id: props.user.id,
                    /* zatial vypnute
                    log: global.log_preview
                    */
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.item;
                setItemPriestupok(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }



    const columns = [
        {
            field: 'locked',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 30,
            renderCell: (params) => (params.value == false ? '' : <FontAwesomeIcon style={{ width: 12, height: 12, color: global.theme_black }} icon={faLock} />),
            editable: false,
            hide: false,
            align: 'center'
        },
        {
            field: 'jednacie_cislo',
            headerName: lang.number.toLowerCase(),
            headerClassName: 'super-app-theme--header',
            type: 'string',
            width: 110,
            editable: false,
            filterable: false,
            hide: false
        },
        {
            field: 'priezvisko',
            headerName: lang.surname.toLowerCase(),
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: false,
            sortable: true,
            filterable: false,
            align: 'left'
        },
        {
            field: 'meno',
            headerName: lang.name.toLowerCase(),
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 200,
            editable: false,
            filterable: false,
            align: 'left'
        },
        {
            field: 'narodeny',
            headerName: lang.narodeny.toLowerCase(),
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 120,
            valueGetter: ({ value }) => value != null ? value && new Date(value) : '',
            editable: false,
            hide: false,
            filterable: false,
            align: 'right'
        },
        {
            field: 'rodne_cislo',
            headerName: lang.rodne_cislo.toLowerCase(),
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 120,
            editable: false,
            filterable: false,
            align: 'center'
        },
        {
            field: 'datum',
            headerName: lang.date.toLowerCase(),
            headerClassName: 'super-app-theme--header',
            type: 'date',
            width: 120,
            valueGetter: ({ value }) => value && new Date(value),
            editable: false,
            hide: false,
            filterable: false,
            align: 'right'
        },
        {
            field: 'hotovost_suma',
            headerName: 'pokuta €',
            type: 'string',
            headerClassName: 'super-app-theme--header',
            width: 100,
            editable: false,
            filterable: true,
            align: 'right'
        },
        {
            field: 'riesitel_1',
            headerName: lang.riesitel.toLowerCase(),
            type: 'string',
            width: 250,
            editable: false,
            hide: false,
            filterable: false,
            align: 'left'
        },
        {
            field: 'usep',
            headerName: 'usep',
            type: 'string',
            width: 50,
            editable: false,
            hide: false,
            filterable: false,
            align: 'center',
            renderCell: (params) => (params.value == 0 ? params.row.locked == true ? <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_light_gray }} icon={faPaperPlane} /> : '' : params.value == 1 ? <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_darker_green }} icon={faCheck} /> : <IconButton onClick={() => UsepError(params)} style={{ width: 34, height: 34 }}><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_dark_red }} icon={faXmark} /></IconButton>),
        },
    ];


    const AddNew = () => {
        setPreview(false);
        setEditing(false);
        setShowNew(true);
    }


    const ChangeIndex = (id) => {
        var data = items.find(x => x.id == id);
        setItemSelected(data);
        setIndex(id);
        if (showPreview == true) {
            db_get_priestupok(id);
        }
    }

    const PriestupokPreviewResult = (typ) => {
        if (typ == 0) {
            setShowPreview(false);
        }
    }

    const Edit = () => {
        setPreview(false);
        setEditing(true);
        setShowNew(true);
    }


    const RestoreSearch = () => {
        setSearch(search_default);
        setSearchText(GetSearchText(search_default));
        db_get(search_default);
    }

    const Unlock = () => {
        if (itemSelected.locked == true) {
            db_unlock(itemSelected.id);
        }
    }

    const UsepError = (data) => {
        setUsepID(data.id);
        setShowUsepError(true);
    }

    const DialogUsepErrorResult = () => {
        setShowUsepError(false);
    }

    const PriestupokResult = (typ, data) => {
        setShowNew(false);
        if (typ == 1) {
            if (editing == true) {
                CopyData(data, itemSelected, ['id']);
                itemSelected.priestupok_text = data.priestupok_id == 1 ? data.vzn : data.priestupok;
                itemSelected.suma = 0;
                if (data.hotovost_suma > 0) {
                    itemSelected.suma = data.hotovost_suma;
                }
                if (data.sek_sum > 0) {
                    itemSelected.suma = data.sek_sum;
                }
                if (data.pokuta > 0) {
                    itemSelected.suma = data.pokuta;
                }

                setRedraw(!redraw);
            } else {
                db_get(search_default);
            }
        }
    }

    const SendUSEP = () => {
        db_send();
    }

    const CustomColumnMenuComponent = (props) => {
        const [text, setText] = useState('');
        const { hideMenu, currentColumn, color, ...other } = props;
        const textRef = useRef(null);
        const timer = useRef();

        useEffect(() => {

            if (currentColumn.filterable == true) {
                textRef.current.focus();
            }

            return () => {
            };

        }, []);

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                ownerState={{ color }}
                {...other}
            >
                {/*
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                */}
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockLeft, margin: 10 }}>
                        {currentColumn.filterable == true ?
                            <TextField
                                inputRef={textRef}
                                size="small"
                                style={{ backgroundColor: global.theme_white, width: 200 }}
                                label={''} variant="outlined"
                                value={text}
                                placeholder={currentColumn.headerName}
                                onInput={e => setText(e.target.value)}
                                onBlur={hideMenu}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        if (text.trim() != '') {

                                            var data = {
                                                text: currentColumn.field == 'datum' ? GetDate2(text) : Capitalize(text.trim()),
                                                column: currentColumn.field,
                                                search: true
                                            }
                                            db_get(data);
                                        }
                                    }

                                }}
                            />
                            :
                            <p style={{ ...styles.TextTiny, marginTop: 9 }}>{currentColumn.headerName}</p>
                        }
                    </div>
                    <IconButton onClick={hideMenu} style={{ ...styles.Block, height: 36, width: 36, marginTop: 10, marginRight: 10 }}>
                        <FontAwesomeIcon style={{ color: global.theme_black, width: 12 }} icon={faXmark} />
                    </IconButton>
                </div>
            </GridColumnMenuContainer>
        );
    }

    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%' }}>
            <div style={{ ...styles.Block, width: '100%' }}>
                <div style={{ ...styles.Block, width: '100%' }}>

                    {/* FILTER */}
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 10 }}>
                        {/*
                        <p style={{ ...styles.TextSmall, marginTop: 15 }}>{lang.filtered}:</p>
                        {search.default == true ?
                            <Chip
                                label={searchText}
                                style={{ marginLeft: 10, marginTop: 10 }}
                            />
                            :
                            <Chip
                                label={searchText}
                                onDelete={() => RestoreSearch()}
                                style={{ marginLeft: 10, marginTop: 10, backgroundColor: global.theme_filter }}
                            />
                        }
                        */}
                        <p style={{ ...styles.TextTiny, marginTop: 17, marginLeft: 0, fontWeight: 'bold' }}>{lang.usep_list1} <span style={{ marginLeft: 10, fontWeight: '400' }}>{lang.usep_list2} {itemsFiltered.length}</span></p>
                    </div>

                    <Box sx={{
                        height: 410,
                        width: 1,
                        '& .super-app-theme--header': {
                            backgroundColor: global.theme_table_header_color,
                        },
                        '& .super-app-theme--normal': {
                            backgroundColor: global.theme_white,
                            color: '#000000',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--red': {
                            backgroundColor: global.theme_white,
                            color: global.theme_dark_red,
                            fontWeight: 'normal',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                        '& .super-app-theme--blue': {
                            backgroundColor: global.theme_white,
                            color: global.theme_blue,
                            fontWeight: 'normal',
                            '&:hover': {
                                bgcolor: (theme) => (global.theme_gray),
                            },
                        },
                    }}>
                        <DataGrid
                            sx={{ fontSize: global.font_table, border: global.theme_table_border_width, borderColor: global.theme_table_border_color }}
                            rows={itemsFiltered}
                            columns={columns}

                            //pageSize={global.table_rows}
                            //rowsPerPageOptions={[global.table_rows]}
                            //checkboxSelection
                            //disableSelectionOnClick

                            // MENU - vypnutie menu
                            //disableColumnMenu={true}

                            // kliknutie na hlavicku tabulky
                            //onColumnHeaderClick={handleClick}

                            // vlastná menu po kliknuti na header menu
                            components={{
                                ColumnMenu: CustomColumnMenuComponent,
                            }}

                            // CLEAR SELECTED ROW
                            selectionModel={selectionModel}
                            onSelectionModelChange={({ selectionModel }) => setSelectionModel(selectionModel)}

                            rowHeight={30}
                            onRowClick={(params) => { ChangeIndex(params.id) }}
                            //onCellKeyDown={(params,event) => { console.log(event) }}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => (params.row.stav_riesenia_id == 1 ? `super-app-theme--normal` : `super-app-theme--red`)}
                            hideFooterRowCount={true}
                            hideFooterSelectedRowCount={true}
                        //onCellEditCommit={(params) => { CellSave(params) }}
                        />
                    </Box>

                </div>

                {index > 0 && showPreview == true ?
                    <PriestupokPreview decodeKey={props.decodeKey} dialog={false} item={itemPriestupok} isBusy={isBusy} func={PriestupokPreviewResult.bind(this)} />
                    : null}

                <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 20 }}>
                    <div style={{ ...styles.BlockRow, width: '60%' }}>
                        {CheckPermision(props.permissions, global.permission_unlock) == true && itemSelected.locked == true ?
                            <div style={{ ...styles.BlockLeft, width: 50 }}>
                                <IconButton onClick={() => Unlock()} style={{ ...styles.ButtonRound, backgroundColor: global.theme_dark_red }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white, width: 14, height: 14 }} icon={faUnlock} />
                                </IconButton>
                            </div>
                            : null}
                        {(CheckPermision(props.permissions, global.permission_edit) == true || (CheckPermision(props.permissions, global.permission_edit_my) == true && (itemSelected.riesitel_id_1 == props.user.id || itemSelected.riesitel_id_2 == props.user.id))) && index > 0 && itemSelected.locked == false ?
                            <Button onClick={() => Edit()} style={{ ...styles.ButtonAction, marginRight: 20 }}>
                                <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPen} />
                                <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.edit}</p>
                            </Button>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRight, width: '40%' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {props.department.usep == true ?
                                ((CheckPermision(props.permissions, global.permission_edit) == true && itemSelected.usep == 0) || (CheckPermision(props.permissions, global.permission_edit_my) == true && (itemSelected.usep == 0))) && index > 0 && itemSelected.locked == true ?
                                    <Button onClick={() => SendUSEP()} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, width: 300, marginRight: 20 }}>
                                        <FontAwesomeIcon style={{ paddingRight: 8, color: global.theme_white, marginRight: 0, width: 16 }} icon={faPaperPlane} />
                                        <p style={{ margin: 0, fontSize: global.font_tiny, color: global.theme_white }}>{lang.usep_send}</p>
                                    </Button>
                                    : null : null}
                            <div style={{ width: 50 }}>
                                <IconButton onClick={() => db_get(search_default)}>
                                    <FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faRotateLeft} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
                {props.department.usep == true ?
                    <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>Systém USEP:</p>
                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>Do systému USEP sa odosielajú len priestupky ukončené a označené znakom <span><FontAwesomeIcon style={{ color: global.theme_black, width: 16, height: 16 }} icon={faLock} /></span></p>
                        <p style={{ ...styles.TextSmall, marginTop: 5 }}><span><FontAwesomeIcon style={{ color: global.theme_dark_red, width: 16, height: 16 }} icon={faXmark} /></span> Symbol označuje priestupok, ktorý sa nazapísal do USEP. Kliknutím na symbol sa zobrazí popis chyby. Je potrebné chyby opraviť a priestupok opäť uložiť ako ukončený.</p>
                        <p style={{ ...styles.TextSmall, marginTop: 5 }}><span><FontAwesomeIcon style={{ color: global.theme_medium_gray, width: 16, height: 16 }} icon={faPaperPlane} /></span> Daný priestupok je pripravený na odoslanie do systému USEP.</p>
                        <p style={{ ...styles.TextSmall, marginTop: 30, fontWeight: '600' }}>Poznámka:</p>
                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>Priestupky sú odosielané do systému USEP automaticky a nie je potrebné ich odosielať po jednom. V prípade chyby môžete priestupok opraviť a odoslať manuálne.</p>
                    </div>
                    :
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.Block, width: 400, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_dark_red }}>
                            <p style={{ ...styles.TextLarge, fontWeight: '600', color: global.theme_white }}>Systém USEP nemáte aktivovaný!</p>
                            <p style={{ ...styles.TextNormal, fontWeight: '400', color: global.theme_white, marginTop: 10 }}>Priestupky sa neodosielajú.</p>
                        </div>
                    </div>

                }
            </div>

            {/* NOVY PRIESTUPOK */}
            {showNew == true ?
                <Priestupok preview={preview} decodeKey={props.decodeKey} actualYear={props.actualYear} lang={lang} department={props.department} obvod={props.obvod} editing={editing} user={props.user} permissions={props.permissions} item={itemSelected} func={PriestupokResult.bind(this)} />
                : null}

            {/* ZOBRAZENIE CHYBY USEP */}
            {showUsepError == true ?
                <DialogUsepError decodeKey={props.decodeKey} department={props.department} lang={lang} id={usepID} func={DialogUsepErrorResult.bind(this)} />
                : null}

            {isBusy ?
                <Loading />
                : null}

        </div >
    )
};

