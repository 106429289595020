// **********************************************************************

// DATABAZA: TZ031101db

// **********************************************************************

import { faChartBar, faCogs, faList, faTable } from "@fortawesome/free-solid-svg-icons";

global.testing = process.env.REACT_APP_EVID_TEST == 0 ? false : true;
global.version = 'verzia 0.2.2';
global.version_mobile = 'verzia 0.2.0';

// expirácia prihlásenia v sekundách
global.login_expire = (8 * 3600); // (1 hod * 3600 sec)
global.login_mobile_expire = (2 * 3600); // (1 hod * 3600 sec)

global.expire_check = true; // zapnut / vypnut kontrolu expiracie prihlásenia

global.centrala_id = 0;
global.centrala_kod = 'CEN';

global.max_screen = 1600;

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = global.testing == true ? 'https://evid.datadream-test.sk' : 'https://app.evid.sk';
global.db_url = global.web + '/app_web/';
global.images = global.web + '/public/images/';
global.pdf = global.web + '/public/pdf/';
global.excel = global.web + '/public/excel/';
global.www = 'app.esthedermsalon.com';

global.local_user = 'app_evid_user';
global.local_department = 'app_evid_department';
global.local_obvod = 'app_evid_obvod';
global.local_login = 'app_evid_login';
global.local_unique_id = 'app_evid_uid';
global.local_settings = 'app_evid_settings';
global.local_language = 'app_evid_language';

// **********************************************************************
// BLOKY
// **********************************************************************
global.bloky_free = 0;
global.bloky_archived = 1;
global.bloky_canceled = 2;

// **********************************************************************
global.debugging = global.testing;
global.items_max = 20; // pagination - max.items na stránke
global.tabsize = 40;

global.CasAdd = 15; // CAS PRIESTUPKU, kolko pripočítať k casu od
global.CasAddPredvolanie = 30; // Predvolanie osoby, kolko pripočítať k casu od

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_lighter = '#BBD6FD';
global.theme_light = '#95B2DC';
global.theme_medium = '#5F7AA1';
global.theme_dark = '#3D5273';
global.theme_darker = '#29364A';

global.theme_lighter_blue = '#A5ECF7';
global.theme_light_blue = '#22D3EE';
global.theme_blue = '#209FB6';
global.theme_dark_blue = '#1C6A78';

global.theme_lighter_red = '#F99891';
global.theme_light_red = '#F77268';
global.theme_red = '#F44336';
global.theme_dark_red = '#C3170B';

global.theme_light_green = '#A6FA9D';
global.theme_green = '#46F436';
global.theme_dark_green = '#18BB18';
global.theme_darker_green = '#15A115';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xgray = '#CCCCCC';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu = '#000000';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu1 = '#aabbcc';
global.theme_submenu2 = '#ddbbbb';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_protected = '#1C6A78';
global.theme_filter = '#FFBAB5';


// DIALOG
global.theme_dialog_back = 'linear-gradient(to right,#95B2DC,#BBD6FD)';
global.theme_dialog_label = '#000000';
global.theme_dialog_back_red = 'linear-gradient(to right,#AA0000,#FF8888)';

global.theme_gradient = 'linear-gradient(to right,#FFFFFF,#A5ECF7)';
global.theme_gradient_dark = 'linear-gradient(#7B614D,#DAC7B8)';
global.theme_menu_top_gradient = 'linear-gradient(to right,#3D5273,#3D5273)';

global.theme_mobile_background = 'linear-gradient(to bottom,#3D5273,#19263A)';
global.theme_mobile_background_white = 'linear-gradient(to bottom,#FFFFFF,#CCCCCC)';
// **********************************************************************
// LOGS - PRIESTUPKY
// **********************************************************************
global.log_none = 0;
global.log_new = 1;
global.log_view = 2;
global.log_update = 3;
global.log_open = 4;
global.log_unlock = 5;
global.log_new_lock = 6;
global.log_update_lock = 7;
global.log_export = 8;
global.log_preview = 9;
global.log_image_delete = 10;
global.log_lustrovanie = 11;
global.log_uhrada = 16;

// **********************************************************************
// DIALOG
// **********************************************************************
global.dialog_title_height = 40;
global.table_height = 500;

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 1050;// 992;
global.screen_max = 1050; //950

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;
global.font_blog = 20;
// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;

// **********************************************************************
// MENU INDEX
// **********************************************************************
global.menu_padding = 40;
global.menu_button_width = 130;

global.menu_main = 0;
global.menu_width = 280;

global.menu_action = 1004;
global.menu_subaction = 9377;

global.creator = 'Datadream s.r.o.';
global.creator_web = 'https://datadream.sk';

// **********************************************************************
// IMAGE TYPS - upload image
// **********************************************************************
global.image_logo = 0;


// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.colors = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#444444' },
    { id: 2, color: '#777777' },
    { id: 3, color: '#AAAAAA' },
    { id: 4, color: '#CCCCCC' },
    { id: 5, color: '#E3E5F0' },
    { id: 6, color: '#F4F5FA' },
    { id: 7, color: '#FFFFFF' },

    { id: 13, color: '#0000AA' },
    { id: 8, color: '#0000FF' },
    { id: 14, color: '#509DF4' },
    { id: 15, color: '#ADCCEF' },
    { id: 10, color: '#00FFFF' },
    { id: 12, color: '#8957F4' },
    { id: 9, color: '#FF00FF' },
    { id: 26, color: '#FFAAFF' },


    { id: 11, color: '#FF0000' },
    { id: 21, color: '#F44336' },
    { id: 25, color: '#D8532B' },
    { id: 24, color: '#F77B72' },
    { id: 16, color: '#FFDDDD' },
    { id: 12, color: '#FFAA77' },
    { id: 17, color: '#FFFF00' },
    { id: 18, color: '#FFFF77' },

    { id: 19, color: '#004400' },
    { id: 20, color: '#4D7B4D' },
    { id: 23, color: '#00AA00' },
    { id: 22, color: '#00FF00' },
    { id: 17, color: '#C0E0B8' },
]


// **********************************************************************
// POVOLENIA - retazec RWECDPABRR  R-reserve
// **********************************************************************
global.permisions_default = '000000000000';
global.permisions_admin_default = '111111011100';
global.permission_read = 0;
global.permission_write = 1;
global.permission_edit = 2;
global.permission_admin = 3; // uprava povoleni - local admin
global.permission_edit_my = 4;
global.permission_print = 5;
global.permission_system_admin = 6; // celkovy systemovy administrátor - Datadream
global.permission_bloky = 7; // pokutove bloky / seky - vytvarat a editovat
global.permission_ciselniky = 8; // uprava ciselnikov
global.permission_unlock = 9; // odomykanie priestupkov k editácii

// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    dph_coeficient: 1.2,
    title: 'Evid',
    app_label: 'Evid SK',
    description: 'Evidencia MSP',
    home: 'Domov',
    web: 'app.evid.sk',
    admin: 'administrátor',


    // **********************************************************************
    // STRUKTURA MENU
    // **********************************************************************
    menuitems: [
        {
            id: '3',
            enabled: true,
            name: 'Agenda',
            label: 'Agenda',
            icon: faList,
            color: 'orange',
            admin: false,
            children: [
                {
                    id: '1',
                    enabled: true,
                    admin: false,
                    name: 'Priestupky',
                    label: 'Evidencia priestupkov',
                },
                {
                    id: '12',
                    enabled: true,
                    admin: false,
                    name: 'Udalosti',
                    label: 'Evidencia udalostí',
                },
                {
                    id: '5',
                    enabled: true,
                    admin: false,
                    name: 'Osoby',
                    label: 'Evidencia osôb',
                },
                {
                    id: '4',
                    enabled: true,
                    admin: false,
                    name: 'Vozidlá',
                    label: 'Evidencia vozidiel',
                },
                {
                    id: '2',
                    enabled: true,
                    admin: false,
                    name: 'Pokutové bloky',
                    label: 'Evidencia pokutových blokov',
                },
                {
                    id: '3',
                    enabled: true,
                    admin: false,
                    name: 'Pokutové šeky',
                    label: 'Evidencia pokutových šekov',
                },
                {
                    id: '6',
                    enabled: true,
                    admin: false,
                    name: 'Pošta',
                    label: 'Evidencia doručenej a odoslanej pošty',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: '',
                    label: '',
                },
                {
                    id: '7',
                    enabled: true,
                    admin: false,
                    name: 'Trestné činy',
                    label: 'Evidencia trestných činov',
                },
                {
                    id: '8',
                    enabled: true,
                    admin: false,
                    name: 'Predvedené osoby',
                    label: 'Evidencia predvedených osôb',
                },
                {
                    id: '9',
                    enabled: true,
                    admin: false,
                    name: 'Evidencia psov',
                    label: 'Evidencia psov',
                },
                {
                    id: '13',
                    enabled: true,
                    admin: false,
                    name: 'Kamerové záznamy',
                    label: 'Evidenia žiadostí o kamerový záznam',
                },
                {
                    id: '10',
                    enabled: true,
                    admin: false,
                    name: 'Ostatné činnosti',
                    label: 'Evidencia ostaných činností',
                },
                {
                    id: '14',
                    enabled: true,
                    admin: false,
                    name: 'Správne konanie',
                    label: 'Evidencia úhrad pokút v správnom konaní',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: '',
                    label: '',
                },
                {
                    id: '11',
                    enabled: true,
                    admin: false,
                    name: 'Kniha priestupkov',
                    label: 'Kniha priestupkov',
                },
            ]
        },
        {
            id: '4',
            enabled: true,
            name: 'Štatistika',
            label: 'Štiatistika',
            admin: false,
            icon: faChartBar,
            color: global.theme_dark_red,
            children: [
                {
                    id: '1',
                    enabled: true,
                    admin: false,
                    name: 'Riešitelia',
                    label: 'Štatistika riešiteľov',
                },
                {
                    id: '2',
                    enabled: true,
                    admin: false,
                    name: 'Druhy priestupkov',
                    label: 'Štatistika druhov priestupkov',
                },
                {
                    id: '3',
                    enabled: true,
                    admin: false,
                    name: 'Priestupky VZN',
                    label: 'Štatistika priestupkov VZN',
                },
                {
                    id: '4',
                    enabled: true,
                    admin: false,
                    name: 'Miesta priestupkov',
                    label: 'Štatistika podľa miesta priestupkov',
                },
                {
                    id: '5',
                    enabled: true,
                    admin: false,
                    name: 'Spôsob zistenia',
                    label: 'Štatistika podľa spôsobu zistenia priestupkov',
                },
                {
                    id: '6',
                    enabled: true,
                    admin: false,
                    name: 'Spôsob riešenia',
                    label: 'Štatistika podľa spôsobov riešenia priestupkov',
                },
                {
                    id: '7',
                    enabled: true,
                    admin: false,
                    name: 'Mesiace v roku',
                    label: 'Štatistika podľa mesiacov v roku',
                },
                {
                    id: '8',
                    enabled: true,
                    admin: false,
                    name: 'Čas spáchania',
                    label: 'Štatistika podľa času spáchania priestupkov',
                },
                {
                    id: '9',
                    enabled: true,
                    admin: false,
                    name: 'Doručená pošta',
                    label: 'Štatistika doručenej pošty',
                },
                {
                    id: '12',
                    enabled: true,
                    admin: false,
                    name: 'Udalosti',
                    label: 'Štatistika udalostí',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: '',
                    label: '',
                },
                {
                    id: '10',
                    enabled: true,
                    admin: false,
                    name: 'Správa o činnosti',
                    label: 'Správa o činnosti obecnej / mestskej polície',
                },
                {
                    id: '11',
                    enabled: true,
                    admin: false,
                    name: 'Export pre PZ SR',
                    label: 'Export údajov pre Policajný zbor SR',
                },
            ]
        },
        {
            id: '1',
            enabled: true,
            name: 'Číselníky',
            label: 'Číselníky',
            icon: faTable,
            admin: true,
            color: global.theme_blue,
            children: [
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Evidencia zamestnancov',
                    label: '',
                },
                {
                    id: '1',
                    enabled: true,
                    admin: false,
                    name: 'Zamestnanci',
                    label: 'Evidencia zamestnancov',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Priestupky',
                    label: '',
                },
                {
                    id: '10',
                    enabled: true,
                    admin: false,
                    name: 'Preukaz totožnosti',
                    label: 'Zoznam preukazov totožnosti',
                },
                {
                    id: '15',
                    enabled: true,
                    admin: false,
                    name: 'Druh priestupku',
                    label: 'Druhy priestupkov',
                },
                {
                    id: '16',
                    enabled: true,
                    admin: false,
                    name: 'VZN',
                    label: 'Všeobecne záväzné nariadenia',
                },
                {
                    id: '11',
                    enabled: true,
                    admin: false,
                    name: 'Spôsob zistenia',
                    label: 'Zoznam spôsob zistenia',
                },
                {
                    id: '12',
                    enabled: true,
                    admin: false,
                    name: 'Stav riešenia',
                    label: 'Stavy riešenia',
                },
                {
                    id: '13',
                    enabled: true,
                    admin: false,
                    name: 'Spôsob riešenia',
                    label: 'Spôsoby riešenia',
                },
                {
                    id: '9',
                    enabled: true,
                    admin: false,
                    name: 'Druh vozidla',
                    label: 'Zoznam druhov vozidiel - továrenské značky',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Predvedenie osôb',
                    label: '',
                },
                {
                    id: '6',
                    enabled: true,
                    admin: false,
                    name: 'Dôvod predvedenia',
                    label: 'Dôvod predvedenia osoby',
                },
                {
                    id: '7',
                    enabled: true,
                    admin: false,
                    name: 'Miesto predvedenia',
                    label: 'Miesto predvedenia osoby',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Trestné činy',
                    label: '',
                },
                {
                    id: '8',
                    enabled: true,
                    admin: false,
                    name: 'Trestné činy',
                    label: 'Zoznam trestných činov',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Pošta',
                    label: '',
                },
                {
                    id: '18',
                    enabled: true,
                    admin: false,
                    name: 'Odosielateľ pošty',
                    label: 'Odosielateľ poštovej zásielky',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Ostatné číselníky',
                    label: '',
                },
                {
                    id: '19',
                    enabled: true,
                    admin: false,
                    name: 'Ostatné činnosti',
                    label: 'Ostatné činnosti',
                },
                {
                    id: '20',
                    enabled: true,
                    admin: false,
                    name: 'Udalosti',
                    label: 'Udalosti',
                },
                {
                    id: '0',
                    enabled: true,
                    admin: false,
                    name: 'Iné číselníky',
                    label: '',
                },
                {
                    id: '17',
                    enabled: true,
                    admin: false,
                    name: 'Obvody',
                    label: 'Obvody oddelenia',
                },
                {
                    id: '2',
                    enabled: true,
                    admin: false,
                    name: 'Funkcie',
                    label: 'Zoznam funkcii',
                },
                {
                    id: '3',
                    enabled: true,
                    admin: false,
                    name: 'Ulice',
                    label: 'Zoznam ulíc',
                },
                {
                    id: '21',
                    enabled: true,
                    admin: false,
                    name: 'Lokality',
                    label: 'Zoznam lokalít',
                },
                {
                    id: '4',
                    enabled: true,
                    admin: true,
                    name: 'Mestá',
                    label: 'Zoznam miest',
                },
                {
                    id: '5',
                    enabled: true,
                    admin: true,
                    name: 'Štáty',
                    label: 'Zoznam štátov',
                },
            ],
        },
        {
            id: '2',
            enabled: true,
            name: 'Nastavenia',
            label: 'Nastavenia webovej aplikácie',
            icon: faCogs,
            admin: false,
            color: '#007700',
            children: [
                {
                    id: '10',
                    enabled: true,
                    admin: false,
                    name: 'Záznam prihlásení',
                    label: 'Záznam prihlásení do systému',
                },
                {
                    id: '11',
                    enabled: true,
                    admin: false,
                    name: 'Aplikácia',
                    label: 'Nastavenie aplikácie',
                },
                {
                    id: '13',
                    enabled: true,
                    admin: false,
                    name: 'Vybavenie polície',
                    label: 'Vybavenie obecnej / mestskej polície',
                },
                {
                    id: '12',
                    enabled: true,
                    admin: false,
                    name: 'USEP',
                    label: 'Systém USEP',
                },
            ],
        },
    ],

    zakroky: [
        { id: 10, label: 'Použitá zbraň', sub: false },
        { id: 11, label: 'Neoprávnene', sub: true, root: 10 },
        { id: 12, label: 'Použitý varovný výstrel do vzduchu', sub: false },
        { id: 13, label: 'Neoprávnene', sub: true, root: 12 },
        { id: 14, label: 'Použité donucovacie prostriedky', sub: false },
        { id: 15, label: 'Neoprávnene', sub: true, root: 14 },
        { id: 16, label: 'Došlo k zraneniu osoby, proti ktorej zákrok smeroval', sub: false, root: 0 },
        { id: 17, label: 'Došlo k usmrteniu osoby, proti ktorej zákrok smeroval (do 24 hod.)', sub: false, root: 0 },
        { id: 18, label: 'Došlo pri zákroku k zraneniu nezúčastnenej osoby', sub: false, root: 0 },
        { id: 19, label: 'Došlo pri zákroku k usmrteniu nezúčastnenej osoby (do 24 hod.)', sub: false, root: 0 },
        { id: 20, label: 'Došlo k spôsobeniu škody na majetku', sub: false, root: 0 },
        { id: 21, label: 'Z toho so spôsobením na majetku obce', sub: true, root: 20 },
        { id: 22, label: 'Došlo k útoku na príslušníka OP (pri plnení úloh OP, alebo v súvislosti s ich plnením)', sub: false, root: 0 },
        { id: 23, label: 'So zranením príslušníka OP', sub: true, root: 22 },
    ],


    sprava_o_cinnosti: [
        { id: 1, cislo: '1', ods: 'I.', label: 'Plánovaný počet príslušníkov obecnej polície' },
        { id: 2, cislo: '1a', ods: 'I.', label: 'Skutočný počet príslušníkov obecnej polície' },
        { id: 3, cislo: '2', ods: 'I.', label: 'Počet prijatých príslušníkov obecnej polície' },
        { id: 4, cislo: '3', ods: 'I.', label: 'Počet prepustených príslušníkov obecnej polície' },
        { id: 5, cislo: '4', ods: 'I.', label: 'Počet príslušníkov obecnej polície, ktorí boli právoplatne odsúdený za spáchanie úmyselného trestného činu' },
        { id: 6, cislo: '5', ods: 'I.', label: 'Počet príslušníkov obecnej polície, ktorí sú držiteľmi osvedčenia o odbornej spôsobilosti podľa §25 zákona SNR č. 564/1991 Zb. o obecnej polícií v znení neskorších predpisov' },

        { id: 7, cislo: '1', ods: 'II.', label: 'Počet opodstatnených sťažností na príslušníkov obecnej polície' },
        { id: 8, cislo: '2', ods: 'II.', label: 'Počet neopodstatnených sťažností na príslušníkov obecnej polície' },

        { id: 9, cislo: '1', ods: 'III.', label: 'Počet vykonaných zákrokov' },
        { id: 10, cislo: '2', ods: 'III.', label: 'Počet prípadov použitia zbrane' },
        { id: 11, cislo: '2a', ods: 'III.', label: 'Z toho neoprávnených' },
        { id: 12, cislo: '3', ods: 'III.', label: 'Počet prípadov použitia varovného výstrelu do vzduchu' },
        { id: 13, cislo: '3a', ods: 'III.', label: 'Z toho neoprávnených' },
        { id: 14, cislo: '4', ods: 'III.', label: 'Počet prípadov použitia donucovacích prostriedkov' },
        { id: 15, cislo: '4a', ods: 'III.', label: 'Z toho neoprávnených' },
        { id: 16, cislo: '5', ods: 'III.', label: 'Počet prípadov, v ktorých došlo k zraneniu osoby, proti ktorej zákrok smeroval' },
        { id: 17, cislo: '6', ods: 'III.', label: 'Počet prípadov, v ktorých došlo k zraneniu osoby, proti ktorej zákrok smeroval (do 24 hod.)' },
        { id: 18, cislo: '7', ods: 'III.', label: 'Počet prípadov, v ktorých došlo pri zákroku k zraneniu nezúčastnenej osoby' },
        { id: 19, cislo: '8', ods: 'III.', label: 'Počet prípadov, v ktorých došlo pri zákroku k usmrteniu nezúčastnenej osoby (do 24 hod.)' },
        { id: 20, cislo: '9', ods: 'III.', label: 'Počet prípadov, v ktorých došlo pri zákroku k spôsobeniu škody na majetku' },
        { id: 21, cislo: '9a', ods: 'III.', label: 'Z toho so spôsobením škody na majetku' },
        { id: 22, cislo: '10', ods: 'III.', label: 'Počet útokov na príslušníkov obecnej polície (pri plnení úloh obecnej polície, alebo v súvislosti s ich plnením)' },
        { id: 23, cislo: '10a', ods: 'III.', label: 'Z toho so zranením príslušníka obecnej polície' },

        { id: 24, cislo: '1', ods: 'IV.', label: 'Počet osôb, ktorým bola obmedzená osobná sloboda zákrokom príslušníka obecnej polície' },
        { id: 25, cislo: '2', ods: 'IV.', label: 'Počet osôb predvedených na útvar obecnej polície' },
        { id: 26, cislo: '3', ods: 'IV.', label: 'Počet osôb predvedených na útvary Policajného zboru' },
        { id: 27, cislo: '4', ods: 'IV.', label: 'Počet nájdených osôb, po ktorých bolo vyhlásené pátranie' },
        { id: 28, cislo: '5', ods: 'IV.', label: 'Počet nájdených vecí, po ktorých bolo vyhlásené pátranie' },
        { id: 29, cislo: '6', ods: 'IV.', label: 'Počet nájdených motorových vozidiel, po ktorých bolo vyhlásené pátranie' },

        { id: 30, cislo: '1', ods: 'V.', label: 'Celkový počet zistených priestupkov vlastnou činnosťou' },
        { id: 31, cislo: '2', ods: 'V.', label: 'Celkový počet oznámených priestupkov na útvar obecnej polície' },
        { id: 32, cislo: '3', ods: 'V.', label: 'Celkový počet uložených priestupkov' },
        { id: 33, cislo: '4', ods: 'V.', label: 'Celkový počet odložených priestupkov' },
        { id: 34, cislo: '5', ods: 'V.', label: 'Celkový počet odovzdaných priestupkov' },
        { id: 35, cislo: '6', ods: 'V.', label: 'Celkový počet oznámených priestupkov príslušnému orgánu' },
        { id: 36, cislo: '7', ods: 'V.', label: 'Celkový počet priestupkov prejednaných v blokovom konaní' },
        { id: 37, cislo: '7a', ods: 'V.', label: 'Výška finančnej hotovosti (€)' },
        { id: 38, cislo: '8', ods: 'V.', label: 'Celkový počet priestupkov prejednaných v blokovom konaní vydaním bloku na pokutu nezaplatenú na mieste' },
        { id: 39, cislo: '8a', ods: 'V.', label: 'Výška finančnej hotovosti (€)' },

    ],

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    sex_types: ['Nezistené', 'Muž', 'Žena'],
    pes_types: ['Nezistené', 'Pes', 'Suka'],

    degree: '°C',
    login: 'Prihlásenie',
    loginname: 'Prihlasovacie meno (e-mail)',
    loginname_error: 'Prihlasovanie meno musí byť e-mailová adresa',
    login_data: 'Prihlasovacie údaje',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_old: 'Pôvodné heslo',
    password_old_err: 'Pôvodné heslo je nesprávne',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Zadajte nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_change: 'Zmeniť heslo',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje',
    lost_password: 'Zabudnuté heslo',
    lost_password_error: 'Zadané heslo sa nenachádza v našom systéme',
    lost_login_error: 'Zadaná e-mailová adresa sa nenachádza v našom systéme',
    password_changed: 'Zmena hesla prebehla úspešne',

    register: 'Nová registrácia',
    register_button: 'Registrovať sa',
    register_btn: 'Registrovať',
    forgot_password_email1: 'Zadajte Vašu e-mailovu adresu.',
    forgot_password_email2: 'My vám zašleme odkaz pre zmenu hesla',
    forgot_email_error: 'Zadaná e-mailová adresa už je registrovaná v našom systéme',

    required_data: 'Údaje označené * sú povinné',

    verify: 'Overiť',
    verification_code: 'Overovací kód',
    verification_code_enter: 'Overovací kód sme odoslali na Vašu e-mailovú adresu.',
    verification_code_enter_: ' Zadajte kód z Vášho e-mailu',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali ste overovací kód? Odoslať kód.',

    firm_data: 'Firemné údaje',
    contact_data: 'Kontaktné údaje',

    application: 'Aplikácia',
    address: 'Adresa',
    department_name: 'Policajné oddelenie',
    company: 'Názov firmy',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    surname_name: 'Priezvisko a meno',
    name_or_surname: 'Meno, priezvisko',
    street: 'Ulica',
    streets: 'Ulice',
    street_number: 'číslo',
    locality_number: 'číslo lokality',
    locality: 'Lokalita',
    localities: 'Lokality',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    username_error: 'Nesprávny formát prihlasovacieho mena. Musí byť použitá e-mailová adresa!',
    mobil: 'Mobil',
    phone: 'Tel.číslo',
    logo: 'Logo',
    note: 'Poznámka',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    dph: 'DPH',
    birth_date: 'Dátum narodenia',
    birth_date_short: 'Dát.narodenia',
    date_holder: 'dd.mm.rrrr',
    time: 'Čas',
    time_from: 'Čas od',
    time_to: 'do',
    time_to_: 'Čas do',
    time_holder: 'h:m',
    sex: 'Pohlavie',
    rodne_cislo: 'Rodné číslo',
    preukaz: 'Preukaz',
    yes: 'Áno',
    no: 'Nie',
    pieces: 'ks',
    select: 'Vybrať',
    age: 'Vek',
    ip_address: 'IP adresa',
    year: 'Rok',
    month: 'Mesiac',
    actual_year: 'Aktuálny rok',
    whole_year: 'Celý rok',
    string: 'Reťazec',
    select_all: 'Označiť všetky',
    select_none: 'Vymazať všetky',

    required: 'Povinný údaj',
    username_min_len: 'Minimálna dĺžka prihlasovacieho mena musí byť 8 znakov',
    password_min_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    email_checking: 'Prebieha kontrola e-mailovej adresy',
    username_checking: 'Prebieha kontrola prihlasovacieho mena',
    password_exists: 'Zadaná e-mailová adresa už je zaregistrovaná. Použite inú adresu.',
    logout: 'Odhlásiť sa',
    logout_: 'Odhlásenie',
    logout_question: 'Chcete sa odhlásiť z aplikácie?',
    loged_user: 'Prihlásený užívateľ',

    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',


    active: 'Aktívny',
    inactive: 'Neaktívny',
    label: 'Názov',
    number: 'Číslo',
    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_finish: 'Uložiť ako ukončené',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: 'Prebieha ukladanie ...',
    subscribe: 'Odoberať',
    userdata: 'Údaje užívateľa',
    change_logins: 'Zmena prihlasovacích údajov',
    new_username: 'Nové prihlasovacie meno',
    new_password: 'Nové heslo',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet.',
    error: 'Chyba',
    finish: 'Dokončiť',
    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',
    delete: 'Vymazať',
    choose: 'Vybrať',
    price: 'Cena',
    money: '€',
    date: 'Dátum',
    date_from: 'Dátum od',
    date_to: 'Dátum do',
    search_text: 'Hľadaný reťazec',
    copyright: 'copyright',
    preview: 'Náhľad',
    info: 'Informácie',
    created: 'Vytvoril',
    updated: 'Upravil',
    search_date: 'Hľadať podľa dátumu',
    narodnost: 'Národnosť',
    count: 'Počet',
    filter: 'Filtrovať',
    filtered: 'Filter záznamov',
    filter_data: 'Filtrovanie záznamov',
    print: 'Tlačiť',
    rows_selected: 'Označené riadky',

    // POLOZKY - DIALOG
    item_new: 'Nová položka',
    item_edit: 'Úprava položky',
    items_list: 'Zoznam položiek',
    item_show_enabled: 'Zobrazovať len aktívne položky',
    item_inactive: 'Položka je neaktívna',
    item_active: 'Aktívna',
    items_list_search_none: 'Žiadna položka sa nezhoduje s hľadaným výrazom!',

    register_user: 'Registrácia nového zamestnanca',
    register_user_edit: 'Úprava údajov zamestnanca',

    register_data: 'Adresa oddelenia',
    register_contact: 'Kontaktné údaje',
    register_sumar: 'Zhrnutie (kontrola pre ukončením registrácie)',
    register_login: 'Prihlasovacie údaje',
    register_user_login: 'Prihlasovacie údaje do systému',
    register_user_login_text: 'Prihlasovacie údaje do systému vyplňte v prípade, ak chcete zmeniť pôvodné',
    register_ok: 'Registrácia je úspešne dokončená.',
    register_error: 'Pri registrácii nastala chyba. Skúste požiadavku opakovať.',
    register_to_login: 'Pokračovať na prihlásenie',

    register_town: 'Registrácia nového mesta / obce',
    register_town_edit: 'Úprava mesta / obce',
    register_street: 'Registrácia novej ulice',
    register_street_edit: 'Úprava ulice',
    register_state: 'Registrácia nového štátu',
    register_state_edit: 'Úprava štátu',
    register_reason: 'Registrácia nového spôsobu predvedenia osoby',
    register_place: 'Registrácia nového miesta predvedenia osoby',
    register_crime: 'Registrácia nového tresného činu',
    register_car: 'Registrácia nového druhu vozidla',
    register_id_type: 'Registrácia nového preukazu totožnosti',
    register_identify: 'Registrácia nového spôsobu zistenia priestupku',
    register_status: 'Registrácia nového stavu riešenia priestupku',
    register_places: 'Registrácia nového miesta priestupku',
    register_solve: 'Registrácia nového spôsobu riešenia',
    register_vzn: 'Registrácia nového všeobecného záväzného nariadenia',
    register_offense: 'Registrácia nového druhu priestupku',
    register_obvodu: 'Registrácia nového policajného obvodu',
    register_blok: 'Príjem pokutových blokov z daňového úradu',
    register_sek: 'Príjem pokutových šekov z daňového úradu',
    register_odosielatel: 'Registrácia nového odosielateľa pošty',

    record_new: 'Nový záznam',
    record_edit: 'Úprava záznamu',
    record_view: 'Prezeranie záznamu - zmeny nebudú uložené',

    print_tab: 'Tlač',
    export_tab: 'Export',

    town: 'Mesto',
    district_code: 'Kód okresu',
    psc: 'PSČ',
    country: 'Kraj',
    state: 'Štát',
    states: 'Štáty',
    district: 'Okres',
    code: 'Kód',
    foreiner: 'Cudzinec',
    points: 'Body',
    place: 'Miesto',
    reason: 'Dôvod',
    crime: 'Tresný čin',
    paragraf_: 'Paragraf',
    vzn: 'VZN',
    vzn_: 'Všeobecné záväzné nariadenia',
    export: 'export',
    export_record: 'Export záznamu',
    exporting: 'Exportovať',
    export_: 'Export PZ SR',
    permissions: 'Povolenia',
    user: 'Užívateľ',
    titul_pred: 'titul',
    titul_za: 'titul',

    premission_read: 'Prezerať',
    premission_write: 'Zapisovať',
    premission_read_write: 'Prezerať a zapisovať',
    premission_edit: 'Modifikovať',
    premission_create: 'Vytvárať',
    premission_delete: 'Vymazávať',
    premission_print: 'Tlačiť',

    menu_numbers: 'Číselníky',
    menu_users: 'Zamestnanci',
    menu_settings: 'Nastavenia',
    menu_logout: 'Odhlásiť sa',
    menu_rank: 'Funkcia',
    menu_towns: 'Mestá',
    menu_streets: 'Ulice',
    menu_states: 'Štáty',
    menu_invite_reason: 'Dôvod predvedenia',
    menu_invite_reason_: 'Dôvod predvedenia osoby',
    menu_invite_place: 'Miesto predvedenia',
    menu_invite_place_: 'Miesto predvedenia osoby',
    menu_crime: 'Tresný čin',
    menu_car: 'Druh vozidla',
    menu_id_type: 'Preukaz totožnosti',
    menu_identify: 'Spôsob zistenia',
    menu_status: 'Stav riešenia',
    menu_place: 'Miesto priestupku',
    menu_solve: 'Spôsob riešenia',
    menu_vzn: 'VZN',
    menu_offense: 'Druh priestupku',
    menu_permissions: 'Povolenia',
    menu_main: '',

    captcha_error: 'Chýba overenie, nie som robot',

    badge: 'Odznak',
    badge_number: 'Číslo odznaku',
    rank: 'Funkcia',
    ranks: 'Funkcie',
    rank_new: 'Nový záznam - funkcia',
    rank_edit: 'Úprava záznamu - funkcia',
    date_start: 'Dát.nástupu',
    date_start_: 'Dátum nástupu',
    date_end: 'Dát.ukončenia',
    date_end_: 'Dátum ukončenia',
    employee_number: 'Číslo zamestnanca',
    certificate: 'Osvedčenie',
    certificate_owner: 'Držiteľ osvedčenia',
    pin: 'Bezpečnostný pin (6 miestny)',
    user_data: 'Základné údaje',
    no_permission: 'Nemáte oprávnenie',

    priestupok_new: 'Nový priestupok',
    priestupok_edit: 'Úprava záznamu',
    priestupok_pachatel: 'Páchateľ',
    priestupok: 'Priestupok',
    cudzinec: 'Cudzinec',
    prislusnost: 'Štátna príslušnosť',
    prislusnost_short: 'Št.príslušnosť',

    neznamy: 'Neznámy páchateľ',
    riesitel: 'Riešiteľ',
    odznak_cislo: 'č.odznaku',
    druh_priestupku: 'Druh priestupku',
    zakon: 'Zákon',
    paragraf: 'parag.',
    odstavec: 'ods.',
    odstavec_: 'Odstavec',
    pismeno: 'pism.',
    pismeno_: 'Písmeno',
    sposob_zistenia: 'Spôsob zistenia',
    miesto_priestupku: 'Miesto priestupku',
    popis_miesta_priestupku: 'Popis miesta priestupku',
    orientacne_cislo: 'Orient.číslo',

    osvedcenie_datum: 'Dátum',
    osvedcenie_cislo: 'Číslo',
    stav_riesenia: 'Stav riešenia',
    jednacie_cislo: 'Jednacie číslo',
    sposob_riesenia: 'Spôsob riešenia',
    datum_doriesenia: 'Dátum doriešenia',
    datum_doriesenia_: 'Dát.doriešenia',
    hotovost: 'Hotovosť',
    sek: 'Šek',
    zakrok: 'Zákrok',
    popis_priestupku: 'Popis priestupku',
    udaje_vozidla: 'vozidlo',

    opravnene: 'Oprávnené',
    neopravnene: 'Neoprávnené',

    ostatne_cinnosti: 'Ostatné činnosti',
    cinnost: 'Činnosť',
    ostatne_cinnosti_register: 'Registrácia novej činnosti',

    new_record: 'Vytvoriť nový záznam',
    new_record_db: 'Vytvoriť nový záznam v databáze',
    no_exist: 'NEEXISTUJÚCE',

    auto_spz: 'ŠPZ',
    auto_druh: 'Továrenská značka',
    auto_typ: 'Typ vozidla',
    auto_farba: 'Farba',
    auto: 'Vozidlo',
    bez_auta: 'Bez vozidla',
    auto_zoznam: 'Zoznam vozidiel',
    auto_prietupky: 'Spáchané priestupky',

    bloky_date_prijem: 'Dátum príjmu',
    bloky_date_prijem_du: 'Dátum príjmu z DÚ',
    bloky_number: 'Číslo zväzku',
    bloky_from: 'Od',
    bloky_cislo_od: 'Čísla od',
    bloky_to: 'Do',
    bloky_cislo_do: 'do',
    bloky_count: 'Počet',
    bloky_price: '€/1ks',
    bloky_sum: 'Suma €',
    bloky_date_výdaj: 'Dátum výdaja',
    bloky_date_výdaj_obvod: 'Výdaj na obvod',
    bloky_obvod: 'Obvod',
    bloky_pocet: 'Počet kusov',
    bloky_eur: 'Hodnota €/1ks',
    bloky_zvazok_sum: 'Hodnota zväzku',
    bloky_obvod_kod: 'Kód obvodu',
    bloky_obvod_menu: 'Meno preberajúceho',
    bloky_eur_sum: 'Hodnota €/zväzok',
    bloky_vydaj_obvod: 'Výdaj na obvod',
    bloky_vydaj_obvod_label: 'Výdaj zväzku pokutových blokov na obvod',
    bloky_vydaj_obvod_sek_label: 'Výdaj zväzku pokutových šekov na obvod',
    bloky_date_vydaj_teren: 'Výdaj do terénu',
    bloky_vydaj_teren: 'Výdaj do terénu',
    bloky_vydaj_teren_label: 'Výdaj zväzku pokutových blokov do terénu',
    bloky_user: 'Zamestnanec',
    bloky_release: 'Uvoľniť zostávajúce',
    bloky_zvazok_info: 'Informácie o zväzku č.',

    bloky_tab_zvazky: 'Zoznam zväzkov',
    bloky_tab_zvazok_used: 'Použité vo zväzku',
    bloky_tab_used: 'Všetky použité',
    bloky_tab_archive: 'Archivované',
    bloky_tab_canceled: 'Stornované',
    bloky_payment: 'Vyučtovať',
    bloky_paymented: 'Vyučtované',
    bloky_to_pay: 'Zostáva vyučtovať',
    bloky_to_pay_text: 'Vyučtovať použité pokutové bloky',
    bloky_to_pay_sek_text: 'Vyučtovať použité pokutové šeky',
    bloky_archive: 'Archivovať',
    bloky_unarchive: 'Vrátiť späť do zoznamu',
    bloky_cancel: 'Stornovať',
    bloky_cancel_label: 'Stornovať blok č.',
    bloky_cancel_date: 'Dátum stornovania',
    bloky_cancel_dovod: 'Dôvod stornovania',
    bloky_add: 'Pridať bloky',
    bloky_change_price: 'Zmeniť cenu jednotlivých blokov',

    blok_cislo: 'Číslo',
    blok_status: 'Stav',
    blok_pay_date: 'Dátum vyučtovania',
    blok_priestupok_date: 'Dátum priestupku',
    blok_priestupok_date_short: 'Dát.priestupku',
    blok_pay_name: 'Vyučtované komu',
    blok_pay_list: 'Zoznam pokutových blokov',
    blok_pay_all: 'Vyučtovať všetky použité',
    blok_pay_none: '',
    blok_price: 'Cena',
    blok_price_kus: 'Cena bloku (€)',

    used: 'Použité',
    not_used: 'Nepoužité',
    blocks_used: 'Použité pokutové bloky',
    blocks_zvazok: 'Zväzok',
    blocks_suma: 'Suma',
    blocks_pouzil: 'Použil',
    blocks_add: 'Pridať',
    blocks_insert: 'Vložiť',
    blocks_riesitel: 'Riešiteľ',
    blocks_count: 'Počet označených',
    blocks_celkom: 'Celková suma za priestupok',
    blocks_search: 'Číslo bloku bez čísla zväzku',

    permission_read: 'Prezeranie záznamov',
    permission_write: 'Vytváranie záznamov',
    permission_edit: 'Editovanie záznamov',
    permission_edit_my: 'Editovanie záznamov riešiteľa',
    permission_admin: 'Administrátor',
    permission_delete: 'Vymazávanie záznamov',
    permission_print: 'Exportovanie dokumentov',
    permission_bloky: 'Upravovanie pok.blokov a šekov',
    permission_ciselniky: 'Úprava číselníkov',
    permission_unlock: 'Odomykanie záznamov',

    bodovy_system: 'Bodový systém',
    body_euro: '1,00 € = 1 bod',
    body: 'Body',

    obvod_choose: 'Výber obvodu',
    obvod: 'Obvod',
    app_enter: 'Prejsť do aplikácie',

    osoby_list: 'Zoznam osôb',

    settings_priestupky: 'Evidovanie priestupkov',
    settings_pokuty: 'Spôsob ukladania pokút',

    stats: 'Štatistiky',
    stats_riesitelia: 'Riešitelia',
    stats_riesitel: 'Priestupky riešiteľa',
    stats_hotovost: 'Priestupky hotovosť (€)',
    stats_sek: 'Priestupky šeky (€)',
    stats_pokuta: 'Priestupky pokuta (€)',
    stats_priestupky_pocet: 'Počet priestupkov',
    stats_celkom: 'Priestupky celkom (€)',
    stats_pocet_hotovost: 'Počet priestupkov hotovosť',
    stats_pocet_sek: 'Počet priestupkov šeky',
    stats_pocet_pokuta: 'Počet priestupkov pokuta',
    stats_pocet_bez_pokuty: 'Počet priestupkov bez pokuty',
    stats_pocet_celkom: 'Počet\ncelkom',
    stats_posta_celkom: 'Pošta\npočet',
    stats_pocet_celkom_percent: 'Počet celkom (%)',
    stats_body: 'Body',
    stats_search_time: 'Časový rozsah filtrovania',
    stats_search_age: 'Obdobie',
    stats_search_range: 'Určiť rozsah',
    stats_note_enter: 'Pridať poznámku k exportu',
    stats_show_zero: 'Nezobrazovať nulové počty',
    stats_vzn: 'Priestupok VZN',
    stats_miesta_priestupkov: 'Miesta priestupkov',
    stats_sposob_zistenia: 'Spôsob zistenia',
    stats_sposob_riesenia: 'Spôsob riešenia',
    stats_mesiace: 'Mesiac',
    stats_hodiny: 'Hodina',
    stats_select: 'Výber riadku štatistiky',

    stats_label_riesitelia: 'Štatistika riešiteľov priestupkov',
    stats_label_druh: 'Štatistika podľa druhov priestupkov',
    stats_label_vzn: 'Štatistika podľa priestupkov VZN',
    stats_label_miesta: 'Štatistika podľa miesta priestupkov',
    stats_label_sposob_zistenia: 'Štatistika podľa spôsobu zistenia priestupkov',
    stats_label_sposob_riesenia: 'Štatistika podľa spôsobu riešenia priestupkov',
    stats_label_mesiace: 'Štatistika podľa mesiacov v roku',
    stats_label_hodiny: 'Štatistika podľa času spáchania priestupku',
    stats_label_posta: 'Štatistika podľa doručenej pošty',
    stats_label_streets: 'Ulice',
    stats_label_localities: 'Lokality',
    stats_label_udalosti: 'Štatistika udalostí',
    stats_label_udalosti_ulice: 'Štatistika udalostí podľa ulíc',

    stats_from_number: 'Od čísla',
    stats_from_number_text: 'Číslo záznamu, od ktorého budú záznamy zobrazené',
    stats_export_error: 'Pri exporte nastala chyba! Skúste požiadavku opakovať.',
    user_export: 'Zamestnanec',

    value_error: 'Nesprávna hodnota',

    app_not_registered: 'Aplikácia nie je zaregistrovaná!',

    posta_tab_prijate: 'Prijatá pošta',
    posta_tab_odoslane: 'Odoslaná pošta',
    posta_datum_prijate: 'Dátum prijatia',
    posta_datum_odoslane: 'Dátum odoslania',
    posta_cislo_jednacie_prijate: 'Prijaté číslo',
    posta_popis: 'Popis',
    posta_odosielatel: 'Odosielateľ',
    posta_vybavene: 'Vybavené',
    posta_label_new_prijata: 'Registrácia prijatej pošty',
    posta_label_new_odoslana: 'Registrácia odoslanej pošty',

    posta_dialog_cislo_prijate: 'Prijaté číslo jednacie',
    posta_dialog_lehota: 'Lehota (dní)',
    posta_dialog_termin: 'Termín',
    posta_dialog_popis: 'Popis dokumentu',
    posta_dialog_urceny: 'Dokument pridelený',
    posta_dialog_prilohy: 'Zoznam príloh',
    posta_dialog_datum_vybavenia: 'Dátum vybavenia',
    posta_dialog_reg_znak: 'Reg.znak',
    posta_dialog_reg_cislo: 'Reg.číslo',
    posta_dialog_vybavene: 'Vybavené',
    posta_dialog_prijemca: 'Príjemca',
    posta_zobrazit_nevybavene: 'Zobraziť len nevybavené',

    posta_tab1: 'Odosielateľ',
    posta_tab2: 'Popis dokumentu',
    export_select: 'Výber exportovaných súborov',
    export_select_none_error: 'Musí byť vybratý aspoň jeden druh súboru!',

    trestny_cin: 'Trestný čin',
    pocet_osob: 'Počet osôb',
    pocet_osob_sloboda: 'Počet osôb, ktoré mali obmedzenú osobnú slobodu',

    dovod: 'Dôvod',
    dovod_predvedenia: 'Dôvod predvedenia',
    miesto_predvedenia: 'Miesto predvedenia',
    osoba_udaje: 'Údaje o osobe',
    osoba_priestupky: 'Spáchané priestupky',

    search_string: 'Hľadaný reťazec',
    popis_cinnosti: 'Popis činnosti',
    datum_prihlasenia: 'Dátum prihlásenia',

    pes_evidencia: 'Evidencia psov',
    pes_plemeno: 'Plemeno',
    pes_farba: 'Farba',
    cislo_znamky: 'Číslo známky',
    cislo_cipu: 'Číslo čipu',
    pes_vyska: 'Výška (cm)',
    pes_udaje: 'Údaje o psovi',
    pes_osoba_udaje: 'Majiteľ psa',
    pes_majitel_surname: 'Majiteľ (priezvisko)',
    pes_majitel_name: 'Majiteľ (meno)',

    pravoplatne_odsudeny: 'Právoplatne odsúdený za spáchanie úmyselného trestného činu',
    sprava_cinnost: 'Správa o činnosti mestskej polície',

    row_number: 'Číslo riadku',
    export_pz_sr: 'Export údajov pre Policajný zbor SR',
    export_pz_sr_: 'Export PZ SR',
    export_pz_sr_auto: 'Automatické odosielanie údajov prostredníctvom e-mailu',
    export_pz_sr_password: 'Heslo k odomknutiu exportovaného PDF súboru',
    export_pz_sr_emails: 'E-mailové adresy, kde sa exportovaný zoznam odošle',
    kniha_priestupkov: 'Kniha priestupkov',
    kniha_priestupkov_export: 'Exportovanie knihy priestupkov',
    kniha_priestupkov_cele_strany: 'Export len celých strán',
    kniha_priestupkov_cele_strany_2: '2 priestupky na jednu stranu',
    kniha_priestupkov_cislo_strany: 'Číslo prvej strany',

    spis: 'Spis',

    udalosti: 'Udalosti',
    udalost_popis: 'Popis udalosti',
    oznamovatel: 'Oznamovateľ',
    udalost: 'Udalosť',
    opravnene: 'Opravnené',

    export_choose_typ: 'Výber typu exportu',
    export_detailed: 'detailný zoznam',

    centrala: 'Spravovať všetky obvody',

    priestupky: 'Priestupky',
    priestupky_list: 'Zoznam priestupkov',
    predviest_osobu: 'Predvedená osoba',
    trestne_ciny: 'Trestné činy',
    obmedzenie_slobody: 'Obmedzenie osobnej slobody',
    obmedzenie_slobody_: 'Obmedz.os.slobody',
    pocet_zaznamov: 'Počet nájdených záznamov',
    poradie: 'Poradie',
    nazov_mobil: 'Názov v mobile',
    mobilna_aplikacia: 'Mobilná aplikácia',
    select_item: 'Vybrať položku',

    priestupok_logs: 'História záznamu',
    priestupok_action: 'Akcia',

    lost_password_admin: 'Požiadajte administrátora o obnovu hesla!',
    login_again: 'Do svojho účtu sa môžete prihlásiť zmeneným heslom.',
    enter_grid: 'Zadajte kód z grid karty na pozícii:',
    input_text_error: 'Nesprávne vyplnené údaje',
    grid_code_error: 'Nesprávny kód z grid karty',
    codes_error: 'Zadané kódy sú nesprávne',
    max_errors: 'Presiahli ste maximálne množstvo pokusov',
    max_errors_: 'o obnovu hesla!',

    pin_error: 'Nesprávny PIN',
    pin_error_: 'Bezpečnostný pin kód musí obsahovať 6 číslic',
    pin_error_max: 'Dosiahnutý max. počet pokusov!',
    pin_try: 'Zostávajúci počet pokusov',

    photos: 'Fotografie',
    photo: 'Fotografia',
    photos_add: 'Pridať fotografie',
    photo_preview: 'Náhľad fotografie',
    gps: 'GPS',
    photo_gps: 'Miesto zhotovenia fotografie',

    mobile_search: 'Hľadanie osoby / vozidla',
    mobile_search_osoba: 'Rodné číslo hľadanej osoby',
    mobile_search_auto: 'ŠPZ hľadaného auta',
    search_result: 'Výsledok hľadania',
    search_result_none: 'Žiaden záznam!',

    kamera_ziadost: 'Žiadosť o kamerový záznam',
    kamera_ziadosti: 'Žiadosti o kamerové záznamy',
    kamera_ziadatel: 'Žiadateľ kamerového záznamu',
    kamera_ziadatel_: 'Žiadateľ',
    kamera_date: 'Dátum',
    kamera_date_od: 'Požadovaný dátum od',
    kamera_date_do: 'dátum do',
    kamera_číslo: 'Kamera',
    kamera_vystup: 'Forma výstupu',
    kamera_datum_od: 'Dátum od',
    kamera_datum_do: 'Dátum do',
    kamera_riesitel: 'Vybavuje',
    kamera_prevzal: 'Kamerový záznam prevzal',
    kamera_prevzal_: 'Prevzal',
    kamera_vystupy: ['USB', 'DVD disk', 'E-mailom', 'Iné'],

    usep_error: 'Chyba pri zápise do USEP',
    usep_error_text: 'popis chyby',
    usep_error_null: 'Popis chyby nie je k dispozícii',
    pokuta: 'Pokuta',
    pokuta_konanie: 'Výška pokuty',
    pokuta_zaplatena: 'Pokuta je zaplatená',
    narodeny: 'Narodený',
    diacritics: 's diakritikou',

    usep_list1: 'Priestupky neodoslané do systému USEP.',
    usep_list2: 'Počet záznamov:',
    usep_send: 'Odoslať priestupok do USEP',

    pravnicka_osoba: 'Právnická osoba',
    firma: 'Názov spoločnosti',
    firma_: 'Firma',
    ico: 'IČO',

    prevzal_datum: 'Dátum prevzatia',
    graph: 'Graf',
    graph_: 'Grafické zobrazenie',

    vybavenie_policie: 'Vybavenie obecnej / mestskej polície',
    vybavenie_policie_: 'pre potreby štatistických údajov správy',
    pocet_kamier: 'Počet umiestnených kamier',
    pocet_objektov: 'Počet objektov pripojených k pultu centrálnej ochrany',
    pocet_dielni: 'Počet zamestnancov pracujúcich v chránenej dielni',
    pocet_vozidiel: 'Počet motorových vozidiel',
    pocet_psov: 'Počet služobných psov',

    zobrazit_nezaplatene: 'Zobraziť len neuhradené',
    datum_uhradena: 'Dátum úhrady',
    uhradena: 'Uhradená',
    zobrazit_nedoriesene: 'Zobraziť len nedoriešené',
}
