import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, Loading, Today, GetAge, GetDate, GetDateYear } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';


export const PredvedeneOsobyNew = (props) => {

    /*
            USAGE:
            <TrestnyCinNew editing={true} department={department} obvod={obvod} user={user} func={TrestnyCinNewResult.bind(this)} />
 
            const TrestnyCinNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');
    const [tab, setTab] = useState(0);

    // --------------------------------------------------------
    // POLOZKY DIALOGU
    const [cislo, setCislo] = useState('');
    const [datum, setDatum] = useState('');
    const [casOd, setCasOd] = useState('07:00');
    const [casDo, setCasDo] = useState('08:00');
    const [dovod, setDovod] = useState('');
    const [dovodID, setDovodID] = useState(0);
    const [dovodErr, setDovodErr] = useState(false);
    const [miesto, setMiesto] = useState('');
    const [miestoID, setMiestoID] = useState(0);
    const [miestoErr, setMiestoErr] = useState('');
    const [poznamka, setPoznamka] = useState('');
    const [user1, setUser1] = useState('');
    const [user1ID, setUser1ID] = useState(0);
    const [user2, setUser2] = useState('');
    const [user2ID, setUser2ID] = useState(0);

    // --------------------------------------------------------
    // UDAJE O OSOBE
    const [personID, setPersonID] = useState(0);
    const [narodeny, setNarodeny] = useState('');
    const [pohlavie, setPohlavie] = useState(0);
    const [rodneCislo1, setRodneCislo1] = useState('');
    const [rodneCislo2, setRodneCislo2] = useState('');
    const [age, setAge] = useState(0);
    const [meno, setMeno] = useState('');
    const [menoErr, setMenoErr] = useState(false);
    const [priezvisko, setPriezvisko] = useState('');
    const [priezviskoErr, setPriezviskoErr] = useState(false);
    const [ulica, setUlica] = useState('');
    const [ulicaCislo, setUlicaCislo] = useState('');
    const [mesto, setMesto] = useState('');
    const [preukaz, setPreukaz] = useState('');
    const [preukazID, setPreukazID] = useState(0);
    const [preukazCislo, setPreukazCislo] = useState('');
    const [poznamka2, setPoznamka2] = useState('');
    // --------------------------------------------------------


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const rcRef = useRef(null); // rodne cislo 2
    const offset = 15;


    useEffect(() => {

        if (props.editing == true) {

            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();

        } else {

            // NOVY ZAZNAM
            setLabel(lang.new_record);
            setDatum(Today());

            if (props.copy_id != undefined) {
                db_get_priestupok(props.copy_id); // ID PRIESTUPKU - skopirovanie udajov o osobe
            }
        }

        timer.current = setTimeout(() => {
            inputRef.current.focus();
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'predvedene_osoby_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var data = json.item;
                var person = json.person;

                setCislo(data.cislo);
                setDatum(data.datum)
                setCasOd(data.cas_od);
                setCasDo(data.cas_do);
                setDovod(data.dovod);
                setDovodID(data.dovod_id);
                setMiesto(data.miesto);
                setMiestoID(data.miesto_id);
                setPoznamka(data.poznamka);
                setUser1(data.user1);
                setUser1ID(data.user1_id);
                setUser2(data.user2);
                setUser2ID(data.user2_id);

                if (person != null) {
                    setPersonID(person.id);
                    setPreukaz(person.preukaz);
                    setPreukazID(person.preukaz_id);
                    setPreukazCislo(person.preukaz_cislo);
                    if (person.rodne_cislo.length > 0) {
                        setRodneCislo1(person.rodne_cislo.substring(0, 6));
                        setRodneCislo2(person.rodne_cislo.substring(6, person.rodne_cislo.length));
                    }
                    setPohlavie(person.pohlavie);
                    setNarodeny(person.narodeny);
                    setMeno(person.meno);
                    setPriezvisko(person.priezvisko);
                    setMesto(person.mesto);
                    setUlica(person.ulica);
                    setUlicaCislo(person.ulica_cislo);
                    setPoznamka2(person.poznamka);
                } else {
                    setMeno(data.meno);
                    setPriezvisko(data.priezvisko);

                }

            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_get_priestupok = async (id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var person = json.item;

                setPersonID(person.id);
                setPreukaz(person.preukaz);
                setPreukazID(person.preukaz_id);
                setPreukazCislo(person.preukaz_cislo);
                if (person.rodne_cislo.length > 0) {
                    setRodneCislo1(person.rodne_cislo.substring(0, 6));
                    setRodneCislo2(person.rodne_cislo.substring(6, person.rodne_cislo.length));
                }
                setPohlavie(person.pohlavie);
                setNarodeny(person.narodeny);
                setMeno(person.meno);
                setPriezvisko(person.priezvisko);
                setMesto(person.mesto);
                setUlica(person.ulica);
                setUlicaCislo(person.ulica_cislo);
                setPoznamka2(person.poznamka);

            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    const db_update = async (data, data_person) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'predvedene_osoby_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    data_person: data_person,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data, data_person);
                } else {
                    Press(2, data, data_person);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const db_person_find = async (rodne_cislo) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupok_find_person', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    rodne_cislo: rodne_cislo,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.person != null) {
                    setPersonID(json.person.id);
                    setPreukaz(json.person.preukaz);
                    setPreukazID(json.person.preukaz_id);
                    setPreukazCislo(json.person.preukaz_cislo);
                    setMeno(json.person.meno);
                    setPriezvisko(json.person.priezvisko);
                    setMesto(json.person.mesto);
                    setUlica(json.person.ulica);
                    setUlicaCislo(json.person.ulica_cislo);
                    setPoznamka2(json.person.poznamka);
                } else {
                    setPersonID(0);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        var tab_error = 0;
        setError('');
        setDovodErr(false);
        setMiestoErr(false);
        setMenoErr(false);
        setPriezviskoErr(false);

        if (dovodID == 0) {
            setDovodErr(true);
            error = true;
        }
        if (miestoID == 0) {
            setMiestoErr(true);
            error = true;
        }
        if (meno.trim() == '') {
            setMenoErr(true);
            error = true;
            tab_error = 1;
        }
        if (priezvisko.trim() == '') {
            setPriezviskoErr(true);
            error = true;
            tab_error = 1;
        }

        if (error == true) {
            setError(lang.required);
            setTab(tab_error);

        } else {
            var data = {
                id: props.editing == true ? props.item_id : 0,
                updated_user_id: props.user.id,
                department_id: props.department.id,
                obvod: props.obvod.kod,
                obvod_id: props.obvod.id,
                rok: GetDateYear(datum),
                cislo: props.editing == true ? cislo : '',
                datum: datum,
                cas_od: casOd,
                cas_do: casDo,
                dovod: dovod,
                dovod_id: dovodID,
                miesto: miesto,
                miesto_id: miestoID,
                poznamka: poznamka,
                user1: user1,
                user1_id: user1ID,
                user2: user2,
                user2_id: user2ID,
                meno: meno,
                priezvisko: priezvisko,
                rodne_cislo: rodneCislo1 + rodneCislo2,
            }
            if (props.editing == false) {
                data.created_user_id = props.user.id;
            }

            var data_person = {
                id: personID,
                narodnost_id: 0,
                cudzinec: 0,
                department_id: props.department.id,
                preukaz: preukaz,
                preukaz_id: preukazID,
                preukaz_cislo: preukazCislo,
                narodeny: narodeny,
                pohlavie: pohlavie,
                rodne_cislo: rodneCislo1 + rodneCislo2,
                vek: age,
                titul1: '',
                meno: meno,
                priezvisko: priezvisko,
                titul2: '',
                ulica: ulica,
                ulica_cislo: ulicaCislo,
                mesto: mesto,
                psc: '',
                stat: '',
                poznamka: poznamka2
            }

            db_update(data, data_person);

        }
    }

    const SetDovod = (value, key) => {
        if (value != null) {
            setDovod(value.label);
            setDovodID(value.id);
        }
    }

    const SetMiesto = (value, key) => {
        if (value != null) {
            setMiesto(value.label);
            setMiestoID(value.id);
        }
    }

    const SetRiesitel1 = (value, key) => {
        if (value != null) {
            setUser1(value.label);
            setUser1ID(value.id);
        }
    }

    const SetRiesitel2 = (value, key) => {
        if (value != null) {
            setUser2(value.label);
            setUser2ID(value.id);
        }
    }

    const SetPreukaz = (value, key) => {
        if (value != null) {
            setPreukaz(value.label);
            setPreukazID(value.id);
        }
    }

    const SetUlica = (value, key) => {
        if (value != null) {
            setUlica(value.label);
        }
    }

    const SetMesto = (value, key) => {
        if (value != null) {
            setMesto(value.label);
        }
    }

    const SetRodneCislo1 = (value) => {
        setRodneCislo1(value);
        if (value.length == 6) {
            rcRef.current.focus();
        }
    }

    const RodneCislo = () => {
        var value = rodneCislo1;
        if (value.length == 6) {
            var r = parseInt(value.substring(0, 2));
            var m = parseInt(value.substring(2, 4));
            var d = parseInt(value.substring(4, 6));
            if (m > 12) {
                m = m - 50;
                setPohlavie(2);
            } else {
                setPohlavie(1);
            }

            if (r < 54) {
                if (rodneCislo2.length == 3) {
                    r = r + 1900;
                } else {
                    r = r + 2000;
                }
            } else {
                r = r + 1900;
            }
            //var datum = d.toString() + '.' + m.toString() + '.' + r.toString();
            var datum = r.toString() + '-' + m.toString().padStart(2, '0') + '-' + d.toString().padStart(2, '0');

            setNarodeny(datum);

            if (datum != null || datum != '') {
                setAge(GetAge(datum));
            }

            db_person_find(rodneCislo1 + rodneCislo2);
        }
    }

    const Narodeny = () => {
        var datum = narodeny;
        if (datum != null || datum != '') {
            setAge(GetAge(datum));
        }
    }

    const ChangePohlavie = (event) => {
        var value = event.target.value;
        setPohlavie(value);
    }

    const ChangeTab = (event, value) => {
        setTab(value);
        if (value == 1) {
            timer.current = setTimeout(() => {
                inputRef2.current.focus();
            }, 300);
        }
    }

    const CasOd = () => {
        if (props.editing == false) {
            var tmp = casOd.split(':');
            var h = parseInt(tmp[0]);
            var m = parseInt(tmp[1]);

            m = m + global.CasAddPredvolanie;
            if (m > 59) {
                m = m - 60;
                h = h + 1;
                if (h > 23) {
                    h = h - 24;
                }
            }

            setCasDo(h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0'))
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 0, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 10, paddingBottom: 15 }}>

                    {/* DATUM - CAS */}
                    <div style={{ ...styles.BlockRowRaw, marginTop: offset }}>
                        <div style={{ width: 200, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date}</p>
                            <TextField inputRef={inputRef} type={'date'} value={datum} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatum(e.target.value)} />
                        </div>
                        <div style={{ width: 120, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time_from}</p>
                            <TextField onBlur={() => CasOd()} type={'time'} value={casOd} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasOd(e.target.value)} />
                        </div>
                        <div style={{ width: 120, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.time_to}</p>
                            <TextField type={'time'} value={casDo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCasDo(e.target.value)} />
                        </div>
                    </div>
                </div>

                <Tabs
                    value={tab}
                    onChange={ChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: global.theme_light
                        }
                    }}
                    style={{ width: 400 }}
                >
                    <Tab value={0} style={{ width: 200, fontWeight: tab == 0 ? 'bold' : 'normal' }} label={lang.dovod} />
                    <Tab value={1} style={{ width: 200, fontWeight: tab == 1 ? 'bold' : 'normal' }} label={lang.osoba_udaje} />

                </Tabs>

                {tab == 0 ?
                    <div style={{ width: '95%', height: 380, marginTop: offset + 20 }}>

                        {/* DOVOD PREDVEDENIA */}
                        <div style={{ width: '100%', marginTop: offset }}>
                            <AutocompleteText error={dovodErr} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={21} lang={lang} label={lang.dovod_predvedenia} width={'100%'} value={dovod} value_id={dovodID} func={SetDovod.bind(this)} />
                        </div>

                        {/* MIESTO PREDVEDENIA */}
                        <div style={{ width: '100%', marginTop: offset }}>
                            <AutocompleteText error={miestoErr} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={22} lang={lang} label={lang.miesto_predvedenia} width={'100%'} value={miesto} value_id={miestoID} func={SetMiesto.bind(this)} />
                        </div>

                        {/* POZNAMKA */}
                        <div style={{ width: '100%', marginTop: offset }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                            <TextField value={poznamka} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka(e.target.value)} />
                        </div>

                        {/* RIESITEL 1/2 */}
                        <div style={{ ...styles.BlockRow, width: '100%', marginTop: offset }}>
                            <div style={{ width: '50%' }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 1'} width={'100%'} value={user1} value_id={user1ID} func={SetRiesitel1.bind(this)} />
                            </div>
                            <div style={{ width: '50%', marginLeft: 20 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.riesitel + ' 2'} width={'100%'} value={user2} value_id={user2ID} func={SetRiesitel2.bind(this)} />
                            </div>
                        </div>
                    </div>
                    : null}

                {tab == 1 ?
                    <div style={{ width: '95%', height: 380, marginTop: offset + 20 }}>
                        {/* OSOBA - UDAJE */}
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ width: '25%' }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.rodne_cislo}</p>
                                <TextField inputRef={inputRef2}
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={"outlined"} value={rodneCislo1} onInput={e => SetRodneCislo1(e.target.value)} />
                            </div>
                            <div style={{ ...styles.Block, width: 10 }}>
                                <p style={{ ...styles.TextLarge, marginLeft: 5, marginRight: 5, marginTop: 25 }}>/</p>
                            </div>
                            <div style={{ width: '25%' }}>
                                <TextField
                                    onBlur={() => RodneCislo()} inputRef={rcRef} size={'small'} style={{ width: '100%', backgroundColor: global.theme_white, marginTop: 20 }} label={''} variant={"outlined"} value={rodneCislo2} onInput={e => setRodneCislo2(e.target.value)} />
                            </div>
                            <div style={{ width: '25%', marginLeft: 15 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.birth_date}</p>
                                <TextField
                                    onBlur={() => Narodeny()}
                                    size={'small'} type={'date'} placeholder={lang.date_holder} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={"outlined"} value={narodeny} onInput={e => setNarodeny(e.target.value)} />
                            </div>
                            <div style={{ width: '25%', marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.sex}</p>
                                <Select
                                    value={pohlavie}
                                    onChange={ChangePohlavie}
                                    size='small'
                                    variant={"outlined"}
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    <MenuItem value={0}>{lang.sex_types[0]}</MenuItem>
                                    <MenuItem value={1}>{lang.sex_types[1]}</MenuItem>
                                    <MenuItem value={2}>{lang.sex_types[2]}</MenuItem>
                                </Select>
                            </div>
                        </div>

                        {/* PREUKAZ */}
                        <div style={{ ...styles.BlockRow, marginTop: offset }}>
                            <div style={{ width: '50%' }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} check={true} row={false} department={props.department} db={5} lang={lang} label={lang.preukaz} width={'100%'} value={preukaz} value_id={preukazID} func={SetPreukaz.bind(this)} />
                            </div>
                            <div style={{ width: '50%', marginLeft: 15 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.number}</p>
                                <TextField
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={preukazCislo} onInput={e => setPreukazCislo(e.target.value)} />
                            </div>
                        </div>

                        {/* MENO A PRIEZVISKO */}
                        <div style={{ ...styles.BlockRow, marginTop: offset }}>
                            <div style={{ width: '50%' }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.name}</p>
                                <TextField error={menoErr}
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={meno} onInput={e => setMeno(e.target.value)} />
                            </div>
                            <div style={{ width: '50%', marginLeft: 15 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.surname}</p>
                                <TextField error={priezviskoErr}
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={priezvisko} onInput={e => setPriezvisko(e.target.value)} />
                            </div>
                        </div>

                        {/*MESTO, ULICA */}
                        <div style={{ ...styles.BlockRow, marginTop: offset }}>
                            <div style={{ width: 320 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} new_record={false} row={false} check={false} department={props.department} db={3} lang={lang} label={lang.town} width={'100%'} value={mesto} func={SetMesto.bind(this)} />
                            </div>
                            <div style={{ width: 320 }}>
                                <AutocompleteText inputRef={null} decodeKey={props.decodeKey} new_record={false} row={false} check={false} department={props.department} db={2} lang={lang} label={lang.street} width={'100%'} value={ulica} keys={mesto} func={SetUlica.bind(this)} />
                            </div>
                            <div style={{ width: 160 }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.street_number}</p>
                                <TextField
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={ulicaCislo} onInput={e => setUlicaCislo(e.target.value)} />
                            </div>
                        </div>

                        {/* POZNAMKA */}
                        <div style={{ ...styles.Block, marginTop: offset }}>
                            <div style={{ width: '100%' }}>
                                <p style={{ ...styles.TextInput, marginBottom: 3, marginLeft: 2 }}>{lang.note}</p>
                                <TextField
                                    size={'small'} style={{ width: '100%', backgroundColor: global.theme_white }} label={''} variant={'outlined'} value={poznamka2} onInput={e => setPoznamka2(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    : null}

            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {isLoading ?
                <Loading center></Loading>
                : null}
        </Dialog >
    );
}

export const PredvedeneOsobyReview = (props) => {

    /*
            USAGE:
            <PredvedeneOsobyReview item_id={} func={PredvedeneOsobyReviewResult.bind(this)} />
 
            const TrestnyCinReviewRePredvedeneOsobyReviewResultsult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);
    const [person, setPerson] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'predvedene_osoby_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);
                setPerson(json.person);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{lang.dovod_predvedenia}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ width: 1500 }}>
                        <div style={{ ...styles.BlockRow, paddingTop: 15 }}>
                            <TextField value={'PO-' + data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                            <TextField value={data.datum} label={lang.date} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                            <TextField value={data.cas_od} label={lang.time_from} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                            <TextField value={data.cas_do} label={lang.time_to} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                            <TextField value={data.dovod} label={lang.dovod_predvedenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 600 }} />
                            <TextField value={data.miesto} label={lang.miesto_predvedenia} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 250 }} />
                        </div>
                        <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                            <TextField value={data.meno} label={lang.name} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                            <TextField value={data.priezvisko} label={lang.surname} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                            {person != null || person != undefined ?
                                <TextField value={person.rodne_cislo} label={lang.rodne_cislo} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 200 }} />
                                : null}
                            {person != null || person != undefined ?
                                <TextField value={GetDate(person.narodeny)} label={lang.birth_date} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 200 }} />
                                : null}
                            {person != null || person != undefined ?
                                <TextField value={person.ulica + ' ' + person.ulica_cislo + ' ' + person.mesto} label={lang.address} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 450 }} />
                                : null}
                        </div>
                        <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                            <TextField value={data.user1} label={lang.riesitel + ' 1'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                            <TextField value={data.user2} label={lang.riesitel + ' 2'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 300 }} />
                            <TextField value={data.poznamka} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 850 }} />
                        </div>

                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                            </div>
                            {data.updated_meno != '' ?
                                <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                </div>
                                : null}
                        </div>
                    </div>

                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div >
    );
}
