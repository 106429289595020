import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faInfo, faWarning } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';
//import Slider from "react-slick";
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, stepClasses } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import { Debug, LoadLogin, LoadUser, MenuTop, SaveUser, SaveLogin, getRndGridCode } from './items.js';


export const ForgotPasswordDialog = (props) => {
    /*
            <ForgotPassword lang={lang} language={language} func={ForgotPasswordPress.bind(this)} />

            const ForgotPasswordPress = (value) => {
            }
    */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState(global.testing == true ? 'info@vgvstudio.sk' : '');
    const [codeUser, setCodeUser] = useState('');
    const [codeUserErr, setCodeUserErr] = useState(false);
    const [code, setCode] = useState('');
    const [userID, setUserID] = useState(0);
    const [password, setPassword] = useState('');
    const [grid, setGrid] = useState('');
    const [gridErr, setGridErr] = useState(false);
    const [gridCode, setGridCode] = useState('');
    const [gridCodeTable, setGridCodeTable] = useState('');
    const [index, setIndex] = useState(0);
    const [errors, setErrors] = useState(5);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 460;

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const db_check_email = async (next) => {
        if (isBusy == false) {
            setBusy(true);
            setError('');

            try {
                const response = await fetch(
                    global.db_url + 'recover_check_email', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        decode_key: props.decodeKey,
                    })
                })

                const json = await response.json();
                
                if (json.ok > 0) {
                    if (json.sended == true) {
                        // E-MAIL ODOSLANY
                        setCode(json.code);
                        setUserID(json.id);

                        var tmp = getRndGridCode(json.grid);
                        setGridCode(tmp.code);
                        setGridCodeTable(tmp.grid);
                                                
                        if (next == true) {
                            setIndex(index + 1);
                        }

                    } else {
                        // NEODOSLANY EMAIL
                        setIndex(5); // CHYBA
                    }
                    if (json.admin == false) {
                        // NIE JE ADMIN
                        setIndex(4); // poziadavka na admina
                    }
                } else {
                    setError(lang.lost_login_error);
                }
                setBusy(false);
            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_recover_password = async () => {
        if (isBusy == false) {
            setBusy(true);
            setError('');

            try {
                const response = await fetch(
                    global.db_url + 'recover_password', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: userID,
                        password: password,
                        decode_key: props.decodeKey,
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setIndex(index + 1);
                }
                setBusy(false);
            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const Save = () => {
        Press(0, 0);
    }

    const Continue = () => {
        setError('');
        setCodeUserErr(false);
        setGridErr(false);

        if (index == 0) {
            // kontrola e-mailovej adresy
            db_check_email(true);
        }
        if (index == 1) {
            // kontrola vlozeného kódu
            if (codeUser.trim() != '' && grid.trim() != '') {
                if (code == codeUser && grid == gridCode) {
                    setIndex(index + 1);
                } else {
                    if (code != codeUser && grid != gridCode) {
                        setCodeUserErr(true);
                        setGridErr(true);
                        setError(lang.codes_error);
                        var err = errors - 1;
                        setErrors(err);
                        if(err == 0){
                            setIndex(6);
                        }
                    } else {
                        if (code != codeUser) {
                            setCodeUserErr(true);
                            setError(lang.verification_code_error);
                            var err = errors - 1;
                            setErrors(err);
                            if(err == 0){                                
                                setIndex(6);
                            }
    
                        }
                        if (grid != gridCode) {
                            setGridErr(true);
                            setError(lang.grid_code_error);
                            var err = errors - 1;
                            setErrors(err);
                            if(err == 0){
                                setIndex(6);
                            }
    
                        }
                    }
                }
            } else {
                setError(lang.input_text_error);
            }
        }
        if (index == 2) {
            // ulozenie nového hesla
            if (password.trim() != '') {
                if (password.trim().length < 8) {
                    setError(lang.password_min_len);
                } else {
                    db_recover_password();
                }
            }
        }


    }


    return (
        <Dialog open={true} maxWidth={'xl'} style={{ background: '#00000055' }}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center', width: boxWidth }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.lost_password}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            {index == 0 ?
                // EMAILOVA ADRESA
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ width: '90%' }}>
                        <p style={{ textAlign: 'center' }}>{lang.forgot_password_email1}</p>
                        <TextField style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%', marginTop: 20 }} label={lang.loginname} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                    </div>
                </div>
                : null}

            {index == 1 ?
                // KOD Z EMAILU
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ ...styles.Block, width: '100%' }}>
                        <p style={{ textAlign: 'center' }}>{lang.verification_code_enter}</p>
                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30, backgroundColor: global.theme_gray }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 0 }}>{lang.verification_code_enter_}</p>
                            <TextField error={codeUserErr} style={{ backgroundColor: global.theme_white, width: 100, marginTop: 5 }} label={''} variant="outlined" value={codeUser} onInput={e => setCodeUser(e.target.value)} />

                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 30 }}>{lang.enter_grid} <b>{gridCodeTable}</b></p>
                            <TextField error={gridErr} style={{ marginTop: 5, backgroundColor: global.theme_white, width: 100 }} label={''} variant="outlined" value={grid} onInput={e => setGrid(e.target.value)} />
                        </div>
                        <Button onClick={() => db_check_email(false)} style={{ ...styles.ButtonLink, marginTop: 0 }}>{lang.verification_code_resend}</Button>
                    </div>
                </div>
                : null}

            {index == 2 ?
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ width: '90%' }}>
                        <p style={{ textAlign: 'center' }}>{lang.password_new}</p>
                        <TextField type={'password'} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%', marginTop: 20 }} label={lang.password} variant="outlined" value={password} onInput={e => setPassword(e.target.value)} />
                    </div>
                </div>
                : null}

            {index == 3 ?
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <div style={{ ...styles.BlockRound, marginTop: 20, backgroundColor: global.theme_dark_green }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.TextLarge, marginTop: 20, marginBottom: 5, textAlign: 'center' }}>{lang.password_changed}</p>
                        <p style={{ ...styles.TextSmall, marginTop: 5, marginBottom: 5, textAlign: 'center' }}>{lang.login_again}</p>
                    </div>
                </div>
                : null}

            {index == 4 ?
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <div style={{ ...styles.BlockRound, marginTop: 20, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_dark_red }} icon={faWarning} />
                        </div>
                        <p style={{ ...styles.TextSmall, marginTop: 20, marginBottom: 5, textAlign: 'center' }}>{lang.lost_password_admin}</p>
                    </div>
                </div>
                : null}

            {index == 5 ?
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <div style={{ ...styles.BlockRound, marginTop: 20, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_dark_red }} icon={faWarning} />
                        </div>
                        <p style={{ ...styles.TextSmall, marginTop: 20, marginBottom: 5, textAlign: 'center' }}>{lang.send_error}</p>
                    </div>
                </div>
                : null}

{index == 6 ?
                <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <div style={{ ...styles.BlockRound, marginTop: 20, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_dark_red }} icon={faWarning} />
                        </div>
                        <p style={{ ...styles.TextSmall, marginTop: 20, marginBottom: 5, textAlign: 'center' }}>{lang.max_errors}</p>
                        <p style={{ ...styles.TextSmall, marginTop: 2, marginBottom: 5, textAlign: 'center' }}>{lang.max_errors_}</p>
                    </div>
                </div>
                : null}

            <div style={{ ...styles.Block, width: isSmall ? '100%' : boxWidth, height: 150, marginTop: 0 }}>

                <div style={{ height: 60, marginTop: 0 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                                <p style={{...styles.TextXXTiny, color: global.theme_dark_gray}}>Počet zostávajúcich pokusov: {errors}</p>
                            </Alert>
                            : null
                    }
                </div>

                <div style={{ ...styles.BlockRow, width: undefined, marginTop: 10 }}>
                    {index < 3 ?
                        <Button onClick={() => Continue()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.continue}</Button>
                        : null}
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{index < 3 ? lang.cancel : lang.close}</Button>
                </div>
            </div>
        </Dialog >
    );
}