import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSave, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import { useHotkeys } from 'react-hotkeys-hook';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';
import { set } from 'date-fns';
import { useGridPreProcessing } from '@mui/x-data-grid/hooks/core/preProcessing/useGridPreProcessing.js';


export const Settings = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [error, setError] = useState('');

    // nastavenia
    const [settings, setSettings] = useState([]);
    const [hotovost, setHotovost] = useState(false);
    const [seky, setSeky] = useState(false);

    const [department, setDepartment] = useState('');
    const [departmentErr, setDepartmentErr] = useState(false);
    const [street, setStreet] = useState('');
    const [streetErr, setStreetErr] = useState(false);
    const [streetNumber, setStreetNumber] = useState('');
    const [streetNumberErr, setStreetNumberErr] = useState(false);
    const [psc, setPSC] = useState('');
    const [pscErr, setPSCErr] = useState(false);
    const [town, setTown] = useState('');
    const [townErr, setTownErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneErr, setPhoneErr] = useState(false);
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState(false);

    const [exportCron, setExportCron] = useState(false);
    const [pdfPassword, setPdfPassword] = useState('');
    const [pdfPasswordErr, setPdfPasswordErr] = useState(false);
    const [exportEmail1, setExportEmail1] = useState('');
    const [exportEmail1Err, setExportEmail1Err] = useState(false);
    const [exportEmail2, setExportEmail2] = useState('');
    const [exportEmail2Err, setExportEmail2Err] = useState(false);
    const [exportEmail3, setExportEmail3] = useState('');
    const [exportEmail3Err, setExportEmail3Err] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;
    let { func } = props;
    let params = useParams();


    useEffect(() => {

        db_get(false);

        return () => {
        };

    }, []);


    const db_get = async (callback) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'settings', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    obvod_id: props.obvod != null ? props.obvod.id : 0,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                if (callback == false) {
                    setSettings(json.settings);

                    setHotovost(json.settings.hotovost == false ? false : true);
                    setSeky(json.settings.seky == false ? false : true);


                    setDepartment(json.department.name);
                    setStreet(json.department.street);
                    setStreetNumber(json.department.street_number);
                    setPSC(json.department.psc);
                    setTown(json.department.town);
                    setEmail(json.department.email);
                    setPhone(json.department.phone);
                    setMobil(json.department.mobil);

                    setExportCron(json.department.export_cron == 0 ? false : true);
                    setPdfPassword(json.department.pdf_password);
                    setExportEmail1(json.department.export_email_1 == null ? '' : json.department.export_email_1);
                    setExportEmail2(json.department.export_email_2 == null ? '' : json.department.export_email_2);
                    setExportEmail3(json.department.export_email_3 == null ? '' : json.department.export_email_3);
                }

                if (callback == true) {
                    // ODOSLAT DO MAIN
                    func(json.department);
                }
            }


        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const db_update = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get(true);
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const ChangeTab = (event, value) => {
        setTab(value);
    }

    const Check = () => {
        var error = false;
        var tab = 0;
        setError('');
        setDepartmentErr(false);
        setStreetErr(false);
        setStreetNumberErr(false);
        setPSCErr(false);
        setTownErr(false);
        setEmailErr(false);
        setExportEmail1Err(false);

        if (department.trim() == '') {
            error = true;
            tab = 0;
            setDepartmentErr(true);
        }
        if (street.trim() == '') {
            error = true;
            tab = 0;
            setStreetErr(true);
        }
        if (streetNumber.trim() == '') {
            error = true;
            tab = 0;
            setStreetNumberErr(true);
        }
        if (psc.trim() == '') {
            error = true;
            tab = 0;
            setPSCErr(true);
        }
        if (town.trim() == '') {
            error = true;
            tab = 0;
            setTownErr(true);
        }
        if (email.trim() == '') {
            error = true;
            tab = 0;
            setEmailErr(true);
        }

        if (exportCron == true) {
            if (exportEmail1.trim() == '' && exportEmail2.trim() == '' && exportEmail3.trim() == '') {
                error = true;
                tab = 1;
                setExportEmail1Err(true);
            }
        }

        if (error == true) {
            // CHYBA
            setError(lang.required);
            setTab(tab);
        }
        return error;
    }

    const Save = () => {
        if (Check() == false) {
            var data = props.department;

            data.id = props.department.id;
            data.department_id = props.department.id;
            data.obvod_id = props.obvod.id;
            data.alias = department;
            data.name = department;
            data.street = street;
            data.street_number = streetNumber;
            data.psc = psc;
            data.town = town;
            data.email = email;
            data.phone = phone;
            data.mobil = mobil;
            data.hotovost = hotovost;
            data.seky = seky;
            data.export_cron = exportCron;
            data.pdf_password = pdfPassword;
            data.export_email_1 = exportEmail1.trim();
            data.export_email_2 = exportEmail2.trim();
            data.export_email_3 = exportEmail3.trim();


            db_update(data);
        }
    }


    /* *****************************************************************
        RENDER
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%', paddingTop: 20 }}>
            <Tabs
                value={tab}
                onChange={ChangeTab}
                TabIndicatorProps={{
                    style: {
                        color: 'red',
                        backgroundColor: global.theme_light
                    }
                }}
                style={{ width: 400 }}
            >
                <Tab value={0} style={{ width: 200 }} label={lang.application} />
                <Tab value={1} style={{ width: 200 }} label={lang.export_tab} />

            </Tabs>

            {tab == 0 ?
                <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, paddingBottom: 40, border: '1px solid ' + global.theme_light_gray }}>
                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginBottom: 10 }}>{lang.user_data}</p>
                    <div style={{ ...styles.BlockLeft, width: 600, paddingTop: 20, paddingBottom: 20 }}>

                        {/* ODDELENIE */}
                        <div style={{ ...styles.Block, marginTop: 10 }}>
                            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.department_name}</p>
                                <TextField size={'small'} error={departmentErr} style={{ width: '100%' }} variant="outlined" value={department} onInput={e => setDepartment(e.target.value)} />
                            </div>
                        </div>

                        {/* ULICA, CISLO */}
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: 430, paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.street}</p>
                                <TextField size={'small'} error={streetErr} style={{ width: '100%' }} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: 150, paddingTop: 3, marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.street_number}</p>
                                <TextField size={'small'} error={streetNumberErr} style={{ width: '100%' }} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                            </div>
                        </div>

                        {/* PSC, MESTO */}
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: 150, paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.psc}</p>
                                <TextField size={'small'} error={pscErr} style={{ width: '100%' }} variant="outlined" value={psc} onInput={e => setPSC(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: 430, paddingTop: 3, marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.town}</p>
                                <TextField size={'small'} error={townErr} style={{ width: '100%' }} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                            </div>
                        </div>

                        {/* EMAIL */}
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.email}</p>
                                <TextField size={'small'} error={emailErr} style={{ width: '100%' }} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                            </div>
                        </div>

                        {/* TELEFON, MOBIL */}
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: 290, paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.phone}</p>
                                <TextField size={'small'} error={phoneErr} style={{ width: '100%' }} variant="outlined" value={phone} onInput={e => setPhone(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: 290, paddingTop: 3, marginLeft: 20 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.mobil}</p>
                                <TextField size={'small'} error={mobilErr} style={{ width: '100%' }} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                            </div>
                        </div>

                        {/* SPOSOB UKLADANIA POKUT */}
                        {/*
                        <div style={{ ...styles.BlockRow, marginTop: 20, paddingTop: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <div style={{ ...styles.BlockLeft, width: 250 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginBottom: 10 }}>{lang.settings_pokuty}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '90%', paddingTop: 30 }}>
                                <FormControlLabel style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }} label={lang.hotovost}
                                    control={<Checkbox checked={hotovost} size={'small'} onChange={() => setHotovost(!hotovost)} />}
                                />
                                <FormControlLabel style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }} label={lang.sek}
                                    control={<Checkbox checked={seky} size={'small'} onChange={() => setSeky(!seky)} />}
                                />
                            </div>
                        </div>
                        */}

                    </div>
                </div>
                : null}

            {tab == 1 ?
                <div style={{ ...styles.Block, marginTop: 20, paddingTop: 20, paddingBottom: 40, border: '1px solid ' + global.theme_light_gray }}>
                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginBottom: 10 }}>{lang.export_pz_sr}</p>
                    <div style={{ ...styles.Block, width: 600, paddingTop: 20, paddingBottom: 20 }}>
                        {/* Automatický export */}
                        <FormControlLabel style={{ textAlign: 'left', margin: 0, marginTop: 0, padding: 0, fontSize: global.font_tiny }} label={lang.export_pz_sr_auto}
                            control={<Checkbox checked={exportCron} size={'small'} onChange={() => setExportCron(!exportCron)} />}
                        />

                        {/* PDF heslo */}

                        <div style={{ ...styles.Block, width: 400, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 3 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.export_pz_sr_password}</p>
                                <TextField size={'small'} error={pdfPasswordErr} style={{ width: '100%' }} variant="outlined" value={pdfPassword} onInput={e => setPdfPassword(e.target.value)} />
                            </div>
                        </div>

                        {/* EXPORT EMAILS */}
                        {exportCron == true ?
                            <div style={{ ...styles.Block, width: 400, marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 3 }}>
                                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.export_pz_sr_emails}</p>
                                    <TextField size={'small'} error={exportEmail1Err} style={{ width: '100%' }} variant="outlined" value={exportEmail1} onInput={e => setExportEmail1(e.target.value)} />
                                    <TextField size={'small'} error={exportEmail2Err} style={{ width: '100%', marginTop: 10 }} variant="outlined" value={exportEmail2} onInput={e => setExportEmail2(e.target.value)} />
                                    <TextField size={'small'} error={exportEmail3Err} style={{ width: '100%', marginTop: 10 }} variant="outlined" value={exportEmail3} onInput={e => setExportEmail3(e.target.value)} />
                                </div>
                            </div>
                            : null}

                    </div>
                </div>
                : null}

            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                {error != '' ?
                    <Alert severity="error">{error}</Alert>
                    : null}
                {props.permissions.toString().substr(global.permission_admin, 1) == '1' ?
                    <Button onClick={() => Save()} style={{ ...styles.ButtonNew }}>
                        <p style={{ margin: 0, fontSize: global.font_small, color: global.theme_white }}>{lang.save}</p>
                    </Button>
                    : null}
            </div>

            {isLoading ?
                <Loading />
                : null}

        </div >
    )
};

