import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Box } from '@mui/material';
import { sk } from './globals.js';
import { AddDays, AutocompleteText, GetDate3, GetDateYear, Loading, Today, ValidateEmail } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faEye, faInfo, faPen, faPlus, faRotateLeft, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    DataGrid, skSK,
    GridColumnMenu,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import { Pivot as Hamburger } from 'hamburger-react'
import { Priestupok } from './priestupok.js';
import { PriestupokPreview } from './priestupok_preview.js';


export const PostaNew = (props) => {

    /*
            USAGE:
            <PostaNew editing={true} department={department} obvod={obvod} user={user} func={PostaNewResult.bind(this)} />
 
            const PostaNewResult = (value) => {
                Debug(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');

    // POLOZKY DIALOGU
    const [cislo, setCislo] = useState('');
    const [cisloPrijate, setCisloPrijate] = useState('');
    const [datumPrijate, setDatumPrijate] = useState('');
    const [datumVybavene, setDatumVybavene] = useState('');
    const [lehota, setLehota] = useState('');
    const [termin, setTermin] = useState('');
    const [popis, setPopis] = useState('');
    const [popisID, setPopisID] = useState(0);
    const [odosielatel, setOdosielatel] = useState('');
    const [odosielatelErr, setOdosielatelErr] = useState('');
    const [odosielatelID, setOdosielatelID] = useState(0);
    const [prijemca, setPrijemca] = useState('');
    const [prijemcaErr, setPrijemcaErr] = useState('');
    const [user, setUser] = useState('');
    const [userID, setUserID] = useState(0);
    const [poznamka, setPoznamka] = useState('');
    const [priloha, setPriloha] = useState('');
    const [registracnyZnak, setRegistracnyZnak] = useState('');
    const [registracneCislo, setRegistracneCislo] = useState('');
    const [vybavene, setVybavene] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;
    const timer = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const offset = 15;

    useEffect(() => {

        if (props.editing == true) {
            // EDITOVANIE
            setLabel(lang.item_edit);
            db_get();
        } else {
            // NOVY ZAZNAM
            if (props.typ == 0) {
                setLabel(lang.posta_label_new_prijata);
            } else {
                setLabel(lang.posta_label_new_odoslana);
            }

            setDatumPrijate(Today());
            setDatumVybavene(Today());

            setLehota(props.settings.lehota);
            ChangeTermin(props.settings.lehota);

        }

        timer.current = setTimeout(() => {
            if (props.typ == 0) {
                inputRef.current.focus();
            }
            if (props.typ == 1) {
                inputRef2.current.focus();
            }
        }, 300);

        return () => {
        };

    }, []);


    const db_get = async () => {

        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'posta_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                var data = json.item;

                if (props.typ == 0) {
                    // PRIJATA POSTA
                    setCislo(data.cislo);
                    setCisloPrijate(data.cislo_jednacie_prijate);
                    setDatumPrijate(data.datum_prijate);
                    setDatumVybavene(data.datum_vybavene);
                    setLehota(data.dni_termin);
                    setTermin(data.termin);
                    setPopis(data.popis);
                    setPopisID(data.popis_id);
                    setOdosielatel(data.odosielatel);
                    setOdosielatelID(data.odosielatel_id);
                    setUser(data.user);
                    setUserID(data.user_id);
                    setPoznamka(data.poznamka);
                    setPriloha(data.prilohy);
                    setVybavene(data.vybavene == 0 ? false : true);
                    setRegistracnyZnak(data.registracny_znak);
                    setRegistracneCislo(data.registracne_cislo);
                } else {
                    // ODOSLANA POSTA
                    setCislo(data.cislo);
                    setDatumPrijate(data.datum_prijate);
                    setPopis(data.popis);
                    setPopisID(data.popis_id);
                    setPrijemca(data.prijemca);
                    setPoznamka(data.poznamka);
                    setPriloha(data.prilohy);
                }
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }


    const db_update = async (data) => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'posta_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (props.editing == true) {
                    Press(1, data);
                } else {
                    Press(2, data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const Press = (typ, item) => {
        func(typ, item);
    }

    const Save = () => {
        var error = false;
        setOdosielatelErr('');
        setPrijemcaErr('');
        setError('');

        if (props.typ == 0) {
            // PRIJATA POSTA
            if (odosielatel.trim() == '') {
                error = true;
                setOdosielatelErr(lang.required);
            }

            if (error == true) {
                setError(lang.required);
            } else {
                var data = {
                    id: props.editing == true ? props.item_id : 0,
                    updated_user_id: props.user.id,
                    department_id: props.department.id,
                    obvod: props.obvod.kod,
                    obvod_id: props.obvod.id,
                    typ: 0,
                    rok: GetDateYear(datumPrijate),
                    cislo: props.editing == true ? cislo : 0,
                    cislo_jednacie_prijate: cisloPrijate,
                    datum_prijate: datumPrijate,
                    datum_vybavene: datumVybavene,
                    dni_termin: lehota,
                    termin: termin,
                    popis: popis,
                    popis_id: popisID,
                    odosielatel: odosielatel,
                    odosielatel_id: odosielatelID,
                    user: user,
                    user_id: userID,
                    poznamka: poznamka,
                    prilohy: priloha,
                    vybavene: vybavene,
                    registracny_znak: registracnyZnak,
                    registracne_cislo: registracneCislo
                }
                if (props.editing == false) {
                    data.created_user_id = props.user.id;
                }

                db_update(data);
            }
        } else {
            if (prijemca.trim() == '') {
                error = true;
                setPrijemcaErr(lang.required);
            }

            if (error == true) {
                setError(lang.required);
            } else {
                var data = {
                    id: props.editing == true ? props.item_id : 0,
                    created_user_id: props.user.id,
                    updated_user_id: props.user.id,
                    department_id: props.department.id,
                    obvod: props.obvod.kod,
                    obvod_id: props.obvod.id,
                    typ: 1,
                    rok: GetDateYear(datumPrijate),
                    cislo: props.editing == true ? cislo : 0,
                    datum_prijate: datumPrijate,
                    popis: popis,
                    popis_id: popisID,
                    prijemca: prijemca,
                    poznamka: poznamka,
                    prilohy: priloha,
                    //registracny_znak: registracnyZnak,
                    //registracne_cislo: registracneCislo
                    vybavene: true
                }

                db_update(data);
            }
        }
    }

    const SetPopis = (value, key) => {
        if (value != null) {
            setPopis(value.label);
            if (value.id > 0 || popis != value.label) {
                setPopisID(value.id);
            }
        }
    }

    const SetOdosielatel = (value, key) => {
        if (value != null) {
            setOdosielatel(value.label);
            if (value.id > 0 || odosielatel != value.label) {
                setOdosielatelID(value.id);
            }
        }
    }

    const SetUser = (value, key) => {
        if (value != null) {
            setUser(value.label);
            if (value.id > 0 || user != value.label) {
                setUserID(value.id);
            }
        }
    }

    const ChangeTermin = (value) => {
        var now = new Date();
        if (value == undefined) {
            var termin_ = (AddDays(now, parseInt(lehota)));
        } else {
            var termin_ = (AddDays(now, parseInt(value)));
        }
        setTermin(GetDate3(termin_));
    }

    const ChangeVybavene = (value) => {
        if (value == true) {
            setDatumVybavene(Today());
        }
        setVybavene(value);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: global.dialog_title_height, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 32, height: 32, backgroundColor: undefined, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600, paddingTop: 0, paddingBottom: 0, backgroundColor: global.theme_gray }}>
                <div style={{ ...styles.Block, backgroundColor: global.theme_xgray, paddingTop: 10, paddingBottom: 15 }}>
                    {/* PRIJATE CISLO */}
                    {props.typ == 0 ?
                        <div style={{ width: '95%' }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_cislo_prijate}</p>
                            <TextField inputRef={inputRef} value={cisloPrijate} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setCisloPrijate(e.target.value)} />
                        </div>
                        : null}

                    {/* DATUM PRIJATIA - TERMIN */}
                    <div style={{ ...styles.BlockRowRaw, width: '95%', marginTop: offset }}>
                        <div style={{ width: 200, marginRight: 25 }}>
                            <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.date}</p>
                            <TextField type={'date'} value={datumPrijate} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumPrijate(e.target.value)} />
                        </div>
                        {props.typ == 0 ?
                            <div style={{ width: 120, marginRight: 25 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_lehota}</p>
                                <TextField onBlur={() => ChangeTermin()} value={lehota} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setLehota(e.target.value)} />
                            </div>
                            : null}
                        {props.typ == 0 ?
                            <div style={{ width: 200 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_termin}</p>
                                <TextField disabled type={'date'} value={termin} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setTermin(e.target.value)} />
                            </div>
                            : null}
                    </div>
                </div>

                {/* POPIS DOKUMENTU */}
                <div style={{ width: '95%', marginTop: offset }}>
                    <AutocompleteText inputRef={props.typ == 1 ? inputRef2 : null} allowedit={true} decodeKey={props.decodeKey} error={''} check={false} row={false} new_record={true} department={props.department} db={18} lang={lang} label={lang.posta_dialog_popis} width={'100%'} value={popis} func={SetPopis.bind(this)} />
                </div>

                {/* ODOSIELATEL DOKUMENTU */}
                {props.typ == 0 ?
                    <div style={{ width: '95%', marginTop: offset }}>
                        <AutocompleteText error={odosielatelErr == '' ? false : true} decodeKey={props.decodeKey} check={false} row={false} new_record={true} department={props.department} db={19} lang={lang} label={lang.posta_odosielatel} width={'100%'} value={odosielatel} func={SetOdosielatel.bind(this)} />
                    </div>
                    : null}

                {/* PRIJEMCA DOKUMENTU */}
                {props.typ == 1 ?
                    <div style={{ width: '95%', marginTop: offset }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_prijemca}</p>
                        <TextField error={prijemcaErr == '' ? false : true} value={prijemca} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPrijemca(e.target.value)} />
                    </div>
                    : null}

                <div style={{ ...styles.BlockRowRaw, width: '95%', marginTop: offset }}>
                    {/* prideleny */}
                    {props.typ == 0 ?
                        <div style={{ width: '40%', marginTop: offset }}>
                            <AutocompleteText error={''} check={false} decodeKey={props.decodeKey} row={false} new_record={false} department={props.department} db={9} lang={lang} label={lang.posta_dialog_urceny} width={'100%'} value={user} func={SetUser.bind(this)} />
                        </div>
                        : null}

                    {/* POZNAMKA */}
                    <div style={{ width: props.typ == 0 ? '60%' : '100%', marginTop: offset, marginLeft: props.typ == 0 ? 10 : 0 }}>
                        <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.note}</p>
                        <TextField value={poznamka} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPoznamka(e.target.value)} />
                    </div>
                </div>

                {/* PRILOHY */}
                <div style={{ width: '95%', marginTop: offset, paddingBottom: props.typ == 0 ? 0 : 20 }}>
                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_prilohy}</p>
                    <TextField rows={5} multiline={true} value={priloha} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setPriloha(e.target.value)} />
                </div>

                {props.typ == 0 ?
                    <div style={{ ...styles.Block, backgroundColor: global.theme_lighter, paddingTop: 10, paddingBottom: 15, marginTop: 15 }}>
                        <div style={{ ...styles.BlockRowRaw, width: '95%' }}>
                            <div style={{ width: 100, marginLeft: 10 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_reg_znak}</p>
                                <TextField value={registracnyZnak} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setRegistracnyZnak(e.target.value)} />
                            </div>
                            <div style={{ width: 100 }}>
                                <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_reg_cislo}</p>
                                <TextField value={registracneCislo} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setRegistracneCislo(e.target.value)} />
                            </div>
                            {props.typ == 0 ?
                                <div style={{ ...styles.Block, width: 160, paddingTop: 18 }}>
                                    <FormControlLabel style={{ textAlign: 'left', margin: 0, padding: 0, fontSize: global.font_tiny }} label={lang.posta_dialog_vybavene}
                                        control={<Checkbox checked={vybavene} size={'small'} onChange={() => ChangeVybavene(!vybavene)} />}
                                    />
                                </div>
                                : null}
                            {props.typ == 0 && vybavene == true ?
                                <div style={{ width: 200 }}>
                                    <p style={{ ...styles.TextInput, paddingLeft: 5, marginBottom: 3 }}>{lang.posta_dialog_datum_vybavenia}</p>
                                    <TextField type={'date'} value={datumVybavene} size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} label={''} variant="outlined" onInput={e => setDatumVybavene(e.target.value)} />
                                </div>
                                : null}
                        </div>
                    </div>
                    : null}
            </div>

            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>


            {isLoading ?
                <Loading center></Loading>
                : null}
        </Dialog >
    );
}

export const PostaReview = (props) => {

    /*
            USAGE:
            <PostaReview item_id={} func={PostaReviewResult.bind(this)} />
 
            const PostaReviewResult = (value) => {                
            }
    */

    const [isBusy, setBusy] = useState(false);

    // POLOZKY DIALOGU
    const [data, setData] = useState([]);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_get()

        return () => {
        };

    }, [props.item_id]);

    const Press = () => {
        func();
    }

    const db_get = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'posta_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item_id,
                    decode_key: props.decodeKey,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setData(json.item);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    return (
        <div style={{ ...styles.Block, marginTop: 0, backgroundColor: global.theme_back }}>
            <div style={{ ...styles.BlockLeft, width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                {/* LABEL, CLOSE */}
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_lighter, height: 36 }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 36 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <p style={{ ...styles.TextSmall, marginLeft: 10 }}>{data.typ == 0 ? lang.posta_tab_prijate + ' - ' : lang.posta_tab_odoslane}</p>
                            {data.typ == 0 ?
                                <p style={{ ...styles.TextSmall, marginLeft: 10, fontWeight: data.vybavene == true ? 'normal' : 'bold', color: data.vybavene == true ? global.theme_black : global.theme_red }}>{data.vybavene == true ? 'vybavené' : 'nevybavené'}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRight, alignItems: 'flex-start', justifyContent: 'center', width: 30, height: 36 }}>
                        <IconButton onClick={() => Press()} style={{ ...styles.Block, height: 20, width: 20 }}>
                            <FontAwesomeIcon style={{ width: 18, height: 18, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow, height: 220 }}>
                        {data.typ == 0 ?
                            <div style={{ width: 1020 }}>
                                <div style={{ ...styles.BlockRow, paddingTop: 15 }}>
                                    <TextField value={'PP-' + data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                    <TextField value={data.datum_prijate} label={lang.posta_datum_prijate} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                    <TextField value={data.cislo_jednacie_prijate} label={lang.posta_dialog_cislo_prijate} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 280 }} />
                                    <TextField value={data.registracny_znak} label={lang.posta_dialog_reg_znak} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 100 }} />
                                    <TextField value={data.registracne_cislo} label={lang.posta_dialog_reg_cislo} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 160 }} />
                                    <TextField value={data.termin} label={lang.posta_dialog_termin} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 160 }} />
                                </div>
                                <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                    <div style={{ width: 500 }}>
                                        <TextField value={data.popis} label={lang.posta_dialog_popis} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                    <div style={{ width: 500 }}>
                                        <TextField value={data.odosielatel} label={lang.posta_odosielatel} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRow, paddingTop: 20 }}>
                                    <div style={{ width: 400 }}>
                                        <TextField value={data.user} label={lang.posta_dialog_urceny} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                    <div style={{ width: 600 }}>
                                        <TextField value={data.poznamka} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                    <div style={{ ...styles.BlockLeft, width: 100, paddingTop: 24 }}>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.created}: {data.created_meno} ({data.created_datum} {data.created_cas} hod.)</p>
                                    </div>
                                    {data.updated_meno != '' ?
                                        <div style={{ ...styles.BlockLeft, width: '45%' }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.updated}: {data.updated_meno} ({data.updated_datum} {data.updated_cas} hod.)</p>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            :
                            <div style={{ width: 1020 }}>
                                <div style={{ ...styles.BlockRow, paddingTop: 15 }}>
                                    <TextField value={'PO-' + data.cislo} label={lang.number} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                    <TextField value={data.datum_prijate} label={lang.posta_datum_odoslane} type={'date'} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: 150 }} />
                                </div>
                                <div style={{ ...styles.BlockRow, paddingTop: 25 }}>
                                    <div style={{ width: 500 }}>
                                        <TextField value={data.popis} label={lang.posta_dialog_popis} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                    <div style={{ width: 500 }}>
                                        <TextField value={data.prijemca} label={lang.posta_dialog_prijemca} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRow, paddingTop: 25 }}>
                                    <div style={{ width: 1000 }}>
                                        <TextField value={data.poznamka} label={lang.note} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{ width: 300, paddingTop: 15, marginLeft: 20 }}>
                            <TextField value={data.prilohy} label={lang.posta_dialog_prilohy} multiline rows={7} variant="outlined" size="small" style={{ backgroundColor: global.theme_white, width: '100%' }} />
                        </div>
                    </div>
                    : <div style={{ ...styles.Block, height: 220 }}>
                        <CircularProgress color="inherit" size={20} />
                    </div>
                }
            </div>
        </div>
    );
}
