import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sk } from './globals.js';
import { Main } from './main.js';
import { Login } from './login.js';
import { Init } from './items';
import { Register } from './register.js';
import { LogoutUser } from './logout';
import { NotFound } from './notfound';
import { Mobile } from './mobile';
import { MobileLogin } from './mobile_login';


function App() {
    var lang = sk;

    /*
    Text.defaultProps = Text.defaultProps || {};
    Text.defaultProps.allowFontScaling = false;    
    */

    useEffect(() => {
        Init();
    }, [])


    return (
        <div className="App" style={{ backgroundColor: global.theme_white, fontFamily: 'roboto' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <Helmet>
                <title>{lang.title}</title>
                <meta name="description" content={lang.description} />
            </Helmet>
            <Routes>
                <Route path="/" element={<NotFound />} />
                <Route path="/login/:apptoken" element={<Login />} />
                <Route path="/logout/:apptoken" element={<LogoutUser />} />
                <Route path="/main/:apptoken" element={<Main />} />
                <Route path="/register" element={<Register />} />
                <Route path="/mobile/login/:apptoken" element={<MobileLogin />} />
                <Route path="/mobile/:apptoken" element={<Mobile />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
