import { sk } from './globals.js';

// ********************************************************************************************
// DEFAULT
// ********************************************************************************************
export const columnsDefault = [
    {
        field: 'meno',
        headerName: sk.surname_name,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 200,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'hotovost',
        headerName: sk.stats_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'sek',
        headerName: sk.stats_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'pokuta',
        headerName: sk.stats_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'celkom',
        headerName: sk.stats_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'hotovost_pocet',
        headerName: sk.stats_pocet_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'sek_pocet',
        headerName: sk.stats_pocet_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'pokuta_pocet',
        headerName: sk.stats_pocet_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'bez_pokuty_pocet',
        headerName: sk.stats_pocet_bez_pokuty,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.stats_pocet_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'posta',
        headerName: sk.stats_posta_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'body',
        headerName: sk.stats_body,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
];


// ********************************************************************************************
// PRIESTUPKY RIESITELA
// ********************************************************************************************
export const columnsDefaultDetail = [
    {
        field: 'label',
        headerName: sk.priestupok,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 450,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.stats_priestupky_pocet,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'hotovost',
        headerName: sk.stats_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'sek',
        headerName: sk.stats_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'pokuta',
        headerName: sk.stats_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'celkom',
        headerName: sk.stats_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
];

// ********************************************************************************************
// DRUHY PRIESTUPKOV, ...
// ********************************************************************************************
export const columnsDefault2 = [
    {
        field: 'meno',
        headerName: sk.surname_name,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 280,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'hotovost',
        headerName: sk.stats_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'sek',
        headerName: sk.stats_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'pokuta',
        headerName: sk.stats_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'celkom',
        headerName: sk.stats_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'hotovost_pocet',
        headerName: sk.stats_pocet_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'sek_pocet',
        headerName: sk.stats_pocet_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'pokuta_pocet',
        headerName: sk.stats_pocet_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'bez_pokuty_pocet',
        headerName: sk.stats_pocet_bez_pokuty,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.stats_pocet_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 90,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'celkom_percent',
        headerName: sk.stats_pocet_celkom_percent,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 90,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
];

// ********************************************************************************************
// LOKALITY
// ********************************************************************************************
export const columnsLocalities = [
    {
        field: 'label',
        headerName: sk.localities,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 250,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'hotovost',
        headerName: sk.stats_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'sek',
        headerName: sk.stats_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'pokuta',
        headerName: sk.stats_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'celkom',
        headerName: sk.stats_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'hotovost_pocet',
        headerName: sk.stats_pocet_hotovost,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'sek_pocet',
        headerName: sk.stats_pocet_sek,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'pokuta_pocet',
        headerName: sk.stats_pocet_pokuta,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'bez_pokuty_pocet',
        headerName: sk.stats_pocet_bez_pokuty,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.stats_pocet_celkom,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'celkom_percent',
        headerName: sk.stats_pocet_celkom_percent,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
];



// ********************************************************************************************
// POŠTA
// ********************************************************************************************
export const columnsPosta = [
    {
        field: 'odosielatel',
        headerName: sk.posta_odosielatel,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 450,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.count,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    }
];

export const columnsPostaPopis = [
    {
        field: 'label',
        headerName: sk.posta_popis,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 450,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.count,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 110,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'right',
        sum_align: 'flex-end',
        sum_enabled: true
    }
];


// ********************************************************************************************
// UDALOSTI
// ********************************************************************************************
export const columnsUdalosti = [
    {
        field: 'label',
        headerName: sk.udalost,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 450,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'celkom_pocet',
        headerName: sk.count,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'opravnene',
        headerName: sk.opravnene,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'neopravnene',
        headerName: sk.neopravnene,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    }
];

export const columnsUdalostiUlice = [
    {
        field: 'ulica',
        headerName: sk.street,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 300,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'mesto',
        headerName: sk.town,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 300,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'celkom_pocet',
        headerName: sk.count,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'opravnene',
        headerName: sk.opravnene,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'neopravnene',
        headerName: sk.neopravnene,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    }
];



// SPRAVA O CINNOSTI MESTSKEJ POLICIE3
export const columnsCinnosti = [
    {
        field: 'cislo_ods',
        headerName: sk.row_number,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 100,
        editable: false,
        filterable: false,
        hide: false,
        align: 'center',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 700,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'left',
        sum_align: 'flex-end',
        sum_enabled: true
    },
    {
        field: 'pocet',
        headerName: sk.count + ' (spolu)',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: true,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'p47',
        headerName: '§47-48',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'p49',
        headerName: '§49',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'p50',
        headerName: '§50',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'p22',
        headerName: '§22',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'vzn',
        headerName: 'VZN',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'ostatne',
        headerName: 'Ostatné',
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    }
];


// EXPORT PRIESTUPKOV - PZ SR
export const columnsPriestupkyExport = [
    {
        field: 'jednacie_cislo',
        headerName: sk.number,
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 130,
        editable: false,
        filterable: false,
        hide: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'datum',
        headerName: sk.date.toLowerCase(),
        headerClassName: 'super-app-theme--header',
        type: 'date',
        width: 120,
        valueGetter: ({ value }) => value && new Date(value),
        editable: false,
        hide: false,
        filterable: true,
        sum_align: 'flex-start',
        align: 'right'
    },
    {
        field: 'priezvisko',
        headerName: sk.surname,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 250,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'meno',
        headerName: sk.name,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 250,
        editable: true,
        sortable: true,
        filterable: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'priestupok',
        headerName: sk.priestupok,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 450,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'left',
        sum_align: 'flex-start',
        sum_enabled: true
    },
    {
        field: 'paragraf',
        headerName: sk.paragraf_,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 85,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
    {
        field: 'obvod',
        headerName: sk.obvod,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'center',
        sum_align: 'center',
        sum_enabled: true
    },
];

// KNIHA PRIESTUPKOV
export const columnsKnihaPriestupkov = [
    {
        field: 'jednacie_cislo',
        headerName: sk.number.toLowerCase(),
        headerClassName: 'super-app-theme--header',
        type: 'string',
        width: 140,
        editable: false,
        sortable: false,
        filterable: true,
        align: 'left'
    },
    {
        field: 'priezvisko',
        headerName: sk.surname.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        filterable: true,
        align: 'left'
    },
    {
        field: 'meno',
        headerName: sk.name.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'narodeny',
        headerName: sk.birth_date_short.toLowerCase(),
        headerClassName: 'super-app-theme--header',
        type: 'date',
        width: 120,
        valueGetter: ({ value }) => value != null ? value && new Date(value) : '',
        editable: false,
        sortable: false,
        filterable: false,
        hide: false,
        align: 'right'
    },
    {
        field: 'rodne_cislo',
        headerName: sk.rodne_cislo.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'datum',
        headerName: sk.blok_priestupok_date_short.toLowerCase(),
        headerClassName: 'super-app-theme--header',
        type: 'date',
        width: 120,
        valueGetter: ({ value }) => value && new Date(value),
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'right'
    },
    {
        field: 'obvod',
        headerName: sk.obvod.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'cas_od',
        headerName: sk.time.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'priestupok_text',
        headerName: sk.druh_priestupku.toLowerCase(),
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 350,
        editable: false,
        sortable: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'datum_doriesenia',
        headerName: sk.datum_doriesenia.toLowerCase(),
        type: 'date',
        width: 150,
        valueGetter: ({ value }) => value && new Date(value),
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'right'
    },
];

// ****************************************************************************************************************
// CISELNIKY - default
// ****************************************************************************************************************
export const ciselnik_default = [
    {
        field: 'enabled',
        headerName: sk.active,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 500,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
];

export const ciselnik_druh_priestupku = [
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 650,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'zakon',
        headerName: sk.zakon,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: true,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'paragraf',
        headerName: sk.paragraf_,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'odstavec',
        headerName: sk.odstavec_,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'pismeno',
        headerName: sk.pismeno_,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: true,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'export',
        headerName: sk.export,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];


export const ciselnik_vzn = [
    {
        field: 'vzn',
        headerName: sk.vzn,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 450,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'zakon',
        headerName: sk.zakon,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'paragraf',
        headerName: sk.paragraf,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'odstavec',
        headerName: sk.odstavec,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'pismeno',
        headerName: sk.pismeno,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'export',
        headerName: sk.export,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];


export const ciselnik_sposob_riesenia = [
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 250,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'zakon',
        headerName: sk.zakon,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'paragraf',
        headerName: sk.paragraf,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'odstavec',
        headerName: sk.odstavec,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'pismeno',
        headerName: sk.pismeno,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'hotovost',
        headerName: sk.hotovost,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'sek',
        headerName: sk.sek,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'pokuta',
        headerName: sk.pokuta,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'pravnicka_osoba',
        headerName: sk.pravnicka_osoba,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 140,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'bodovy_system',
        headerName: sk.bodovy_system,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'body',
        headerName: sk.body,
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];

export const ciselnik_dovod_predvedenia = [
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 600,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'points',
        headerName: sk.points,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];

export const ciselnik_ostatne = [
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 600,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'points',
        headerName: sk.points,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];

export const ciselnik_trestne_ciny = [
    {
        field: 'label',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 600,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'paragraf',
        headerName: sk.paragraf_,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'points',
        headerName: sk.points,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
];

export const ciselnik_obvody = [
    {
        field: 'enabled',
        headerName: sk.active,
        type: 'boolean',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'nazov',
        headerName: sk.label,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 600,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'kod',
        headerName: sk.code,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    }
];

export const ciselnik_ulice = [
    {
        field: 'ulica',
        headerName: sk.street,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 400,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'mesto',
        headerName: sk.town,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 300,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'lokalita',
        headerName: sk.locality,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 300,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
];


export const ciselnik_mesta = [
    {
        field: 'mesto',
        headerName: sk.town,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 200,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'okres',
        headerName: sk.district,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 200,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'psc',
        headerName: sk.psc,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'kraj',
        headerName: sk.country,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 100,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
];


export const columnsLogs = [
    {
        field: 'datum',
        headerName: sk.date,
        type: 'date',
        width: 120,
        valueGetter: ({ value }) => value && new Date(value),
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'time',
        headerName: sk.time,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 120,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'center'
    },
    {
        field: 'action',
        headerName: sk.priestupok_action,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 300,
        editable: false,
        sortable: false,
        hide: false,
        filterable: false,
        align: 'left'
    },
    {
        field: 'user',
        headerName: sk.user,
        type: 'string',
        headerClassName: 'super-app-theme--header',
        width: 340,
        editable: false,
        sortable: true,
        filterable: false,
        align: 'left',
    }
];
