import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars, faBook, faWindowMaximize, faWindowMinimize, faCar, faLock } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Accordion, Drawer, Slide, Tab, Tabs } from '@mui/material';
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { Pivot as Hamburger } from 'hamburger-react'
import { AutocompleteText, GetDate3, GetPrice, Today, GetAge, GetDate, GetPSC, Loading, Capitalize, GetDateYear, GetTodayTime, GetDefaultFilter, GetTodayYearMonth, SubDays, CopyData } from './items.js';
import { PriestupokBloky } from './priestupok_bloky.js';
import { MobilePriestupok } from './mobile_priestupok.js';


export const MobilePriestupky = (props) => {
    /*
            USAGE:
            <MobilePriestupky lang={lang} editing={editing} func={MobilePriestupkyResult.bind(this)} />

            const MobilePriestupkyResult = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [showItem, setShowItem] = useState(false);
    const [spz, setSPZ] = useState('');

    const headerHeight = 60;
    const footerHeight = 100;

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        // NASTAVENIE FILTRA - LEN JEDEN DEN DOZADU
        var date = GetDate3(SubDays(Today(), 1)); // dnes - 1 den
        console.log(date);
        db_get({ datum_od: date, riesitel_id: props.user.id });

        return () => {
        };

    }, []);

    const db_get = async (data) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'priestupky', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    department_id: props.department.id,
                    data: data,
                    decode_key: props.decodeKey
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, data) => {
        func(typ, data);
    }

    const Select = (item) => {
        if (item.locked == false) {
            console.log(item);
            setItemSelected(item);
            setShowItem(true);
        }
    }

    const PriestupokClose = (typ, data) => {
        if (typ == 1) {
            CopyData(data, itemSelected, ['id']);
        }
        setShowItem(false);
    }

    const SearchSPZ = (value) => {
        setSPZ(value);
        var tmp = items.filter(x => x.spz.toLowerCase().includes(value.toLowerCase()));
        setItemsFiltered(tmp);
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_gray, height: props.height }}>
            {showItem == false ?
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.Block, height: headerHeight, backgroundColor: global.theme_darker }}>
                        <div style={{ ...styles.Block, width: '95%', marginTop: 0 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '60%', height: 40, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextNormal, color: global.theme_white }}>{lang.priestupky_list}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '40%', height: 40, justifyContent: 'center' }}>
                                    <TextField
                                        value={spz}
                                        onInput={e => SearchSPZ(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ width: '100%' }}
                                        placeholder={lang.auto_spz}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => SearchSPZ('')} style={{ width: 20, height: 20 }}>
                                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                                </IconButton>
                                            </InputAdornment>,
                                            style: { backgroundColor: global.theme_white, height: 40 },
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, height: props.height - headerHeight - footerHeight, overflowY: 'scroll' }}>
                        {itemsFiltered.map((item, index) => (
                            <Paper onClick={() => Select(item)} elevation={3} key={item.id} style={{ ...styles.Block, paddingTop: 8, paddingBottom: 8, backgroundColor: item.locked == true ? global.theme_back : global.theme_white, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}>
                                <div style={{ ...styles.Block, width: '95%' }}>

                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '70%' }}>
                                            <p style={{ ...styles.TextTiny }}>
                                                {item.locked == true ?
                                                    <FontAwesomeIcon style={{ height: 14, color: global.theme_dark_red, marginRight: 5 }} icon={faLock} />
                                                    : null}
                                                {GetDate(item.datum)}
                                            </p>
                                            {item.miesto_priestupku != null && item.miesto_priestupku != '' ?
                                                <p style={{ ...styles.TextTiny }}>{item.miesto_priestupku}</p>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '30%' }}>
                                            <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{item.cas_od} hod.</p>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockLeft, overflow: 'hidden', whiteSpace: 'nowrap', marginTop: 10 }}>
                                        <p style={{ ...styles.TextTiny }}>{item.priestupok_id == 1 ? item.vzn : item.priestupok}</p>
                                    </div>

                                    {item.meno != '' ?
                                        <div style={{ ...styles.BlockLeft, overflow: 'hidden', whiteSpace: 'nowrap', marginTop: 3 }}>
                                            <p style={{ ...styles.TextXXTiny }}>{lang.name_surname}: {item.meno} {item.priezvisko}</p>
                                        </div>
                                        : null}

                                    {item.spz != null && item.spz != '' ?
                                        <div style={{ ...styles.BlockRow, marginTop: 3 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.auto_spz}: {item.spz}</p>
                                        </div>
                                        : null}

                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                        <div style={{ ...styles.BlockLeft, width: '60%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            <p style={{ ...styles.TextXXTiny }}>{lang.riesitel}: {item.riesitel_1}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '60%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            <p style={{ ...styles.TextXXTiny, color: item.stav_riesenia_id == 2 ? global.theme_dark_red : global.theme_darker_green }}>{item.stav_riesenia_id == 2 ? 'Objasniť' : 'Doriešené'}</p>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        ))}
                    </div>
                    <div style={{ ...styles.Block, height: footerHeight }}>
                        <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonMobileCancel }}>
                            <FontAwesomeIcon style={{ height: 16, marginRight: 15 }} icon={faXmark} />
                            {lang.cancel}
                        </Button>
                    </div>

                    {isBusy ?
                        <Loading center={true} />
                        : null}


                </div>
                :
                <MobilePriestupok preview={false} decodeKey={props.decodeKey} obvod={props.obvod} lang={props.language} department={props.department} editing={true} user={props.user} permissions={props.permissions} height={props.height} item={itemSelected} func={PriestupokClose.bind(this)} />
            }
        </div >
    );
}


